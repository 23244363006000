import { AxiosHelper } from "../helpers/AxiosHelper";

export default class JobService {
  protected axiosService: AxiosHelper;
  protected urlBase: string;

  constructor() {
    this.axiosService = AxiosHelper.getInstance();
    this.urlBase = process.env.VUE_APP_PRE_URI + "";
  }

  /**  Cuando un trabajo está en "Scheduled" y se cumple la fecha de inicio, se cambia a "In Progress". */
  public async calculateScheduled(): Promise<void> {
    const url = this.urlBase + "/update/api/v1/change-status-inprogress";
    return this.axiosService.get(url);
  }
}
