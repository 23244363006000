import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import moment from "moment";

@Component
export default class TooltipAndDateTime extends Vue {
  /** Título del campo. */
  @Prop() label!: string;
  /** Valor del campo. */
  @Prop() value!: number | Date;
  /** Tooltip del campo. */
  @Prop() labelTooltip?: string;

  momentFormat(dateUnix: number | Date, format: string) {
    if (!this.existsValue()) {
      return "-";
    }
    if (typeof dateUnix === "number") {
      // Devolver formato con unix
      return moment.unix(dateUnix).format(format);
    } else {
      // Devolver formato sin unix
      return moment(dateUnix).format(format);
    }
  }

  existsValue() {
    return this.value !== undefined && this.value !== null;
  }

  existsLabelToolTip() {
    return this.labelTooltip !== undefined && this.labelTooltip !== null;
  }
}
