import tableMixin from "./";
import axios from "axios";

export default {
  mixins: [tableMixin],

  data() {
    return {
      EXPORT_PDF_CONFIRM_SIZE: 10000,
      totalServerItems: 0,
      tableFooterProps: { 'items-per-page-options': [10, 25, 50, 100] },
      axiosSource: null
    }
  },

  computed: {
    tableAttributes() {
      
      return {
        ...this.obtainCommonTableAttributes(),
        'server-items-length': this.totalServerItems,
        'footer-props': this.tableFooterProps
      }
    }
  },

  watch: {
    tableProps: {
      handler(next, latest) {
        if(next.page !== latest.page || next.itemsPerPage !== latest.itemsPerPage)
          this.refreshList();
      },
      deep: true
    }
  },

  methods: {
    configureAxiosCancelToken() {
      if(this.axiosSource)
        this.axiosSource.cancel();

      this.axiosSource = axios.CancelToken.source();
    }, 
    isNotAxiosCancellation(err) {
      return !axios.isCancel(err);
    },
    transformTablePropsForServerRequest() {
      const { itemsPerPage, page, sortBy, sortDesc } = this.tableProps;

      return JSON.parse(JSON.stringify({
        rowsPerPage: itemsPerPage,
        page,
        sortBy: sortBy[0],
        descending: sortDesc[0]
      }));
    },
    changeSort(column) {
      if (this.tableProps.sortBy[0] === column) {
        this.tableProps.sortDesc[0] = !this.tableProps.sortDesc[0];
      } else {
        this.tableProps.sortBy[0] = column;
        this.tableProps.sortDesc[0] = false;
      }

      this.tableProps.page = 1;
      this.refreshList();
    },
  }
}