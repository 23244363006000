import {
  DeviceFamilyInterface,
  DeviceSubtypeInterface,
  DeviceTypeInterface,
} from "@/interfaces/devices";
import { AxiosHelper } from "../helpers/AxiosHelper";

export default class DeviceService {
  protected axiosService: AxiosHelper;
  protected urlBase: string;
  private urlFamily: string;

  constructor() {
    this.axiosService = AxiosHelper.getInstance();
    this.urlBase = process.env.VUE_APP_PRE_URI + "";
    this.urlFamily = this.urlBase + "/firmware/api/v1/family";
  }

  /** Obtiene los datos de una familia, devuelve un error 400 si no existe. */
  public async getFamily(family: string): Promise<DeviceFamilyInterface> {
    const url = this.urlFamily + "/" + family;
    return this.axiosService.get(url);
  }

  /** Obtiene todas las familias. */
  public async getFamilies(): Promise<DeviceFamilyInterface[]> {
    const url = this.urlFamily;
    return this.axiosService.get(url);
  }

  /** Obtiene los datos de un tipo, devuelve un error 400 si no existe. */
  public async getType(
    family: string,
    type: string
  ): Promise<DeviceTypeInterface> {
    const url = this.urlFamily + "/" + family + "/type/" + type;
    return this.axiosService.get(url);
  }

  /** Obtiene todos los tipos de la familia. */
  public async getTypes(family: string): Promise<DeviceTypeInterface[]> {
    const url = this.urlFamily + "/" + family + "/type";
    return this.axiosService.get(url);
  }

  /** Obtiene los datos de un subtipo, devuelve un error 400 si no existe. */
  public async getSubtype(
    family: string,
    type: string,
    subtype: string
  ): Promise<DeviceSubtypeInterface | null> {
    const url =
      this.urlFamily + "/" + family + "/type/" + type + "/subtype/" + subtype;
    return this.axiosService.get(url);
  }

  /** Obtiene todos los subtipos de la familia y el tipo. */
  public async getSubtypes(
    family: string,
    type: string
  ): Promise<DeviceSubtypeInterface[]> {
    const url = this.urlFamily + "/" + family + "/type/" + type + "/subtype";
    return this.axiosService.get(url);
  }
}
