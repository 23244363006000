<template lang="html" src="./InstallationFwsSelector.html"></template>

<!-- eslint-disable @typescript-eslint/ban-types -->
<!-- eslint-disable @typescript-eslint/no-explicit-any -->
<!-- eslint-disable @typescript-eslint/no-non-null-assertion -->
<script lang="ts">
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import jobService from "@/api/job";
import firmwareService from "@/api/firmware";

import AppDeviceIcon from "@/components/AppDeviceIcon.vue";

import FirmwareVersionSelect from "@/views/Firmware/FirmwareVersionSelect/FirmwareVersionSelect.vue";

import { Installation, InsParamsFWOptions } from "@/interfaces/installations";

const PANEL_FAMILY = "PANEL";
const MONITOR_FAMILY = "MONITOR";
const EDIBOX_FAMILY = "EDIBOX";

@Component({
  components: {
    AppDeviceIcon,
    FirmwareVersionSelect,
  },
})
export default class InstallationFwsSelector extends Vue {
  @Prop(Array) installation!: Array<any>;
  @Prop(Array) selectedDevices!: Array<any>;
  @Prop(Array) selectedFws!: Array<any>;

  installationFiltered: [] = [];

  firmwaresOptions: any = [];
  installationDevicesWithJobOn: any = {};

  get localSelectedDevices() {
    return this.selectedDevices;
  }

  set localSelectedDevices(v) {
    this.$emit("update:selectedDevices", v);
  }

  get localSelectedFws() {
    return this.selectedFws;
  }

  set localSelectedFws(v) {
    this.$emit("update:selectedFws", v);
  }

  mounted() {
    this.loadDevicesWithJobOn();
    this.loadInstallationFirmwareOptions();
  }

  @Watch("installation", { immediate: true, deep: true })
  onChangeInstallation(newValue) {
    this.installationFiltered = newValue.filter((i) => {
      return i.family === "PANEL" || i.family === "MONITOR";
    });
  }

  deviceTypeIcon(deviceFamily) {
    let value = "";

    if (deviceFamily === PANEL_FAMILY || deviceFamily === EDIBOX_FAMILY) {
      value = "placa";
    } else if (deviceFamily === MONITOR_FAMILY) {
      value = "veoxs";
    }

    return value;
  }

  getNumberSelectedDevicesForFirmware(firmwareDevices) {
    return firmwareDevices.filter(
      (d) => this.localSelectedDevices.indexOf(d.serialNumber) > -1
    ).length;
  }

  getAllowedDevicesToUpdate(firmwareDevices) {
    return firmwareDevices.filter(
      (d) => !this.installationDevicesWithJobOn[d.serialNumber]
    );
  }

  goToInstallation(v) {
    window.open(
      process.env.VUE_APP_PUBLIC_PATH! +
        process.env.VUE_APP_I18N_LOCALE +
        "/device-" +
        v,
      "_blank"
    );
  }

  selectUnselectAllInstallationDevices(firmwareDevices) {
    let isAllSelected = true;
    let i = 0;
    while (i < firmwareDevices.length && isAllSelected) {
      if (
        !this.localSelectedDevices.find(
          (d) =>
            firmwareDevices[i].serialNumber === d ||
            this.installationDevicesWithJobOn[firmwareDevices[i].serialNumber]
        )
      )
        isAllSelected = false;

      i++;
    }

    this.localSelectedDevices = this.localSelectedDevices.filter(
      (d) => !firmwareDevices.find((fd) => fd.serialNumber === d)
    );
    if (!isAllSelected)
      this.localSelectedDevices = [
        ...this.localSelectedDevices,
        ...this.getAllowedDevicesToUpdate(firmwareDevices).map(
          (d) => d.serialNumber
        ),
      ];
  }

  resetSelectedFw(localSelectedDevices, index) {
    if (
      this.installation[index].devices.filter(
        (d) => localSelectedDevices.indexOf(d.serialNumber) > -1
      ).length === 0
    ) {
      this.localSelectedFws[index] = null;
    }
  }

  loadDevicesWithJobOn() {
    let devices = [];
    for (let i = 0; i < this.installation.length; i++)
      devices = devices.concat(
        this.installation[i].devices.map((d) => d.serialNumber)
      );

    jobService.getDevicesWithJobOn(devices).then((res) => {
      this.installationDevicesWithJobOn = res.data;
    });
  }

  loadInstallationFirmwareOptions() {
    this.localSelectedDevices = [];

    for (let i = 0; i < this.installationFiltered.length; i++) {
      this.firmwaresOptions[i] = undefined;
      const params = this.getParamsInstallationFWOptions(
        this.installationFiltered[i]
      );

      firmwareService.getFirmwaresByType(params).then((res) => {
        this.firmwaresOptions[i] = res.data;
      });
    }
  }

  /** Obtiene los parámetros para la obtención del listado de FW en las instalaciones. */
  getParamsInstallationFWOptions(installation: Installation) {
    let params: InsParamsFWOptions = {
      family: installation.family,
      type: installation.type,
      subtype: installation.subtype,
      versionHW: installation.version,
    };
    if (params.family === "CORA") {
      params.type = installation.type;
    }
    return params;
  }
}
</script>
<style scoped>
span.small {
  font-size: 12px;
}
</style>
