import { FirmwareVersionV2, HWList } from "@/interfaces/firmwares";
import TranslateText from "../helpers/TranslateText";
import { FirmwareController } from "@/domain/controllers/FirmwareController";
import DeviceController from "@/domain/controllers/DeviceController";
import { FirmwareEntity } from "@/domain/entities/FirmwareEntity";

export interface FirmwareModelInterface {
  id: string;
  versionSW: string;
  versionHW: string;
  family: string;
  type: string;
  subtype: string;
  critical: boolean;
  details: string;
  changelog: string;
  sha256: string;
  connectable: boolean;
  automaticUpdate: boolean;
  hwList: HWList[];
  firmwareVersionV2: FirmwareVersionV2;
  file: File;
}

const firmwareModelDefault: FirmwareModelInterface = {
  id: "",
  versionSW: "",
  versionHW: "",
  family: "",
  type: "",
  subtype: "",
  critical: false,
  details: "",
  changelog: "",
  sha256: "",
  connectable: false,
  automaticUpdate: false,
  hwList: [],
  firmwareVersionV2: {
    fwBuild: "",
    fwMajor: "",
    fwMinor: "",
  },
  file: new File([], ""),
};

export class FirmwareModel implements FirmwareModelInterface {
  id: string;
  versionSW: string;
  versionHW: string;
  family: string;
  type: string;
  subtype: string;
  critical: boolean;
  details: string;
  changelog: string;
  sha256: string;
  connectable: boolean;
  automaticUpdate: boolean;
  hwList: HWList[];
  firmwareVersionV2: FirmwareVersionV2;
  file: File;

  private ctrlFirmware: FirmwareController;
  private ctrlDevice: DeviceController;

  constructor(data: FirmwareModelInterface = firmwareModelDefault) {
    this.id = data.id;
    this.versionSW = data.versionSW;
    this.versionHW = data.versionHW;
    this.family = data.family;
    this.type = data.type;
    this.subtype = data.subtype;
    this.critical = data.critical;
    this.details = data.details;
    this.changelog = data.changelog;
    this.sha256 = data.sha256;
    this.connectable = data.connectable;
    this.automaticUpdate = data.automaticUpdate;
    this.hwList = data.hwList;
    this.firmwareVersionV2 = data.firmwareVersionV2;
    this.file = data.file;

    this.ctrlFirmware = new FirmwareController();
    this.ctrlDevice = new DeviceController();
  }

  /** Según el nombre del fichero, asigna los valores de la familia, el tipo, subtipo, fwv2, fwv1 y connectable */
  public async setDataByFile(file: File) {
    this.file = new File([], "");
    const nameFile = file.name;
    const nameSplit = nameFile.split(/[_.]+/);
    await this.setFamily(nameSplit[0]);
    this.type = "";
    this.subtype = "";
    if (nameSplit.length === 7) {
      await this.setType(nameSplit[1]);
      this.subtype = "";
    } else if (nameSplit.length === 8) {
      await this.setType(nameSplit[1]);
      await this.setSubtype(nameSplit[2]);
    }

    this.firmwareVersionV2 = {
      fwBuild: nameSplit[nameSplit.length - 3],
      fwMajor: nameSplit[nameSplit.length - 5],
      fwMinor: nameSplit[nameSplit.length - 4],
    };

    this.versionSW =
      this.firmwareVersionV2.fwMajor +
      "." +
      this.firmwareVersionV2.fwMinor +
      "." +
      this.firmwareVersionV2.fwBuild;

    await this.setConnectable();

    this.file = file;
  }

  public async setFamily(family: string) {
    const existsFamily = await this.ctrlDevice.existsFamily(family);
    if (existsFamily) {
      this.family = family;
    } else {
      throw new Error(
        TranslateText.t("firmware.error.family", { family: family })
      );
    }
  }

  public async setType(type: string) {
    const existsType = await this.ctrlDevice.existsType(this.family, type);
    if (existsType) {
      this.type = type;
    } else {
      throw new Error(
        TranslateText.t("firmware.error.type", {
          family: this.family,
          type: type,
        })
      );
    }
  }

  public async setSubtype(subtype: string) {
    const sub = await this.ctrlDevice.getSubtype(
      this.family,
      this.type,
      subtype
    );
    if (sub === null) {
      throw new Error(
        TranslateText.t("firmware.error.subtype", {
          family: this.family,
          type: this.type,
          subtype: subtype,
        })
      );
    }
    this.subtype = subtype;
  }

  /** Dependiendo del family, type, subtye devuelve si es connectable o no */
  public async setConnectable() {
    const connectable = await this.ctrlFirmware.isConnectable(
      this.family,
      this.type,
      this.subtype
    );
    if (connectable !== undefined) {
      this.connectable = connectable;
    } else {
      throw new Error(TranslateText.t("firmware.error.connectable"));
    }
  }

  public existsFile() {
    return this.file.name !== "";
  }

  public existsSha() {
    return this.sha256 !== "";
  }

  /** Si se ha seleccionado algún HW. */
  private isSelectedAnyHWDialogUpload(): boolean {
    return this.hwList.some((i: HWList) => {
      return i.selectHW === true;
    });
  }

  /** Comprueba si se ha seleccionado un HW. */
  private isSelectedOneHWDialogUpload(): boolean {
    return (
      this.hwList.filter((i: HWList) => {
        return i.selectHW === true;
      }).length === 1
    );
  }

  /**
   * Comprueba si los HW seleccionados son correctos según la versión.
   * Si el firmware es de la primera versión, es necesario que haya seleccionado, unicamente un hardware.
   * Si el firmware es de la segunda versión, es necesario que haya seleccionado, al menos, un hardware.
   */
  public isHWSelectedOk(): boolean {
    const isCompFirm = this.isFirmwareV2();
    if (
      (isCompFirm && this.isSelectedAnyHWDialogUpload()) ||
      (!isCompFirm && this.isSelectedOneHWDialogUpload())
    ) {
      return true;
    }
    return false;
  }

  /**
   * El firmware puede ser actualizado automáticamente si es de tipo MONITOR o PANEL y es conectable.
   * @returns {boolean} true si el firmware puede ser actualizado automáticamente.
   */
  public canAutoUpdate(): boolean {
    return (
      (this.family === "MONITOR" || this.family === "PANEL") &&
      this.connectable === true
    );
  }

  /** Si el firmware es de la segunda versión. */
  public isFirmwareV2(): boolean {
    return parseInt(this.firmwareVersionV2.fwMajor) !== 0;
  }

  getEntity(): FirmwareEntity {
    return new FirmwareEntity({
      id: this.id,
      versionSW: this.versionSW,
      versionHW: this.versionHW,
      family: this.family,
      type: this.type,
      subtype: this.subtype,
      critical: this.critical,
      details: this.details,
      changelog: this.changelog,
      sha256: this.sha256,
      connectable: this.connectable,
      automaticUpdate: this.automaticUpdate,
      hwList: this.hwList,
      firmwareVersionV2: this.firmwareVersionV2,

      createdAt: new Date(),
      updatedAt: new Date(),
      status: "",
      uri: "",
      build: "",
      latest: false,
    });
  }

  public existsId(): boolean {
    return this.id !== "";
  }
}
