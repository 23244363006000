<template>
    <div >
      <qrcode-vue :value="url" ref="qrcode" class="marginImage"></qrcode-vue>
      <v-btn color="blue accent-4" class="white--text" small @click="downloadQRCode">{{ $t("deviceDetails.qrDownload")}}</v-btn>
    </div>
</template>
  
<!-- eslint-disable @typescript-eslint/no-explicit-any -->
  <script  lang="ts">
  import { Vue, Component, Prop } from 'vue-property-decorator'
  import QrcodeVue  from 'qrcode.vue'  
  import html2canvas from 'html2canvas'; 
  

  @Component({
    components: {
      'qrcode-vue': QrcodeVue
    }
  })
  export default class QRCode extends Vue {
    @Prop({ type: String, required: true }) url!: string
  
    get qrValue (): string {
      return this.url
    }

    downloadQRCode() {
      const qrcode = this.$refs.qrcode as any;       

      html2canvas(qrcode.$el).then(canvas => {
        const link = document.createElement('a');                 
        link.download = 'qrcode.png';
        link.href = canvas.toDataURL('image/png');
        link.click();        
        
      });
    }
  }
  </script>
  <style scoped>
  .marginImage {       
    display: grid;
    place-content: center;
    padding: 20px;
  }
  </style>