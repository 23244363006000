<template lang="html" src="./TableGeoFilters.html"></template>

<!-- eslint-disable @typescript-eslint/ban-types -->
<!-- eslint-disable @typescript-eslint/no-explicit-any -->
<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import { displayErrorMessage } from "@/api";
import geoService from "@/api/geo";

import AppSelect from "@/components/AppSelect.vue";

export const GEO_FILTERS_DEFINITIONS = {
  zoneFilter: {
    literal: "geo.zone"
  },
  subzoneFilter: {
    literal: "geo.subzone"
  },
  areaFilter: {
    literal: "geo.area"
  }
};

@Component({
  components: {
    AppSelect
  },
})
export default class TableGeoFilters extends Vue {
  @Prop(Object) value!: any;
  @Prop(Boolean) disabled!: boolean;

  GEO_FILTERS_DEFINITIONS = GEO_FILTERS_DEFINITIONS; 
  isLoadingCombo: number | null = -1;
  selectionOptions: any = {
    zones: null,
    subzones: null,
    areas: null
  }

  get localValue() {
    return this.value;
  }

  set localValue(value) {
    this.$emit("input", value);
  }

  mounted() {
    this.loadZones();
  }

  cleanFilters() {
    this.selectionOptions.subzones = [];
    this.selectionOptions.areas = [];
  }

  loadZones() {
    this.isLoadingCombo = 1;

    geoService
      .getZonesCombo()
      .then(res => {
        this.selectionOptions.zones = res.data;

        this.isLoadingCombo = null;

        if (this.localValue.zoneFilter)
          this.loadSubzones(this.localValue.zoneFilter, false);
      })
      .catch(error => {
        displayErrorMessage(error, { general: ["geo.error.getComboError"] });
      });
  }

  loadSubzones(zone, reset = true) {
    if (reset === true) {
      this.selectionOptions.subzones = [];
      delete this.localValue.subzoneFilter;
      this.selectionOptions.areas = [];
      delete this.localValue.areaFilter;
    }

    if (zone) {
      this.isLoadingCombo = 2;

      const zoneId = this.selectionOptions.zones.find(z => z.name === zone)
        .id;

      geoService
        .getSubzonesCombo(zoneId)
        .then(res => {
          this.selectionOptions.subzones = res.data;

          this.isLoadingCombo = null;

          if (this.localValue.subzoneFilter)
            this.loadAreas(this.localValue.subzoneFilter, false);
        })
        .catch(error => {
          displayErrorMessage(error, {general: ["geo.error.getComboError"]});
        });
    }
  }

  loadAreas(subzone, reset = true) {
    if (reset === true) {
      this.selectionOptions.areas = [];
      delete this.localValue.areaFilter;
    }

    if (subzone) {
      this.isLoadingCombo = 3;

      const subzoneId = this.selectionOptions.subzones.find(
        sz => sz.name === subzone
      ).id;

      geoService
        .getAreasCombo(subzoneId)
        .then(res => {
          this.selectionOptions.areas = res.data;

          this.isLoadingCombo = null;
        })
        .catch(error => {
          displayErrorMessage(error, { general: ["geo.error.getComboError"] });
        });
    }
  }
}
</script>