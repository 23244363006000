export const setPlansListInfo = (state, response) => {
  state.plansListInfo = response;
};

export const setPlanDetails = (state, response) => {
  state.planDetails = response;
};

export const setServicesList = (state, response) => {
  state.servicesList = response;
}; 

export const setServicesByPlan = (state, response) => { 
  state.servicesByPlan = response;
}; 

export const setCommercialServicesByPlan = (state, response) => { 
  state.commercialServicesByPlan = response;
};

export const setParentPlansList = (state, response) => { 
  state.parentPlansList = response;
};