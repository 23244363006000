<template>
  <v-expansion-panel-header class="subheading" @keyup.space.prevent expand-icon="filter_list" :class="{'filter-active': active}">
    <v-layout row pr-2 align-center>
      <v-flex no-wrap xs6 sm2 class="hidden-xs-only" @click="onTitleClick"> {{ title }}</v-flex>
      <v-spacer class="hidden-xs-only" />      
      <v-flex no-wrap xs6 sm2 class="hidden-xs-only" style="text-align: right;">{{$t('general.filters')}}</v-flex>
    </v-layout>
  </v-expansion-panel-header>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
})
export default class FilterTableHeader extends Vue {
  @Prop(String) readonly title!: string;
  @Prop({type: String, default: ''}) search!: string;
  @Prop(Boolean) active!: boolean;
  @Prop(Boolean) disabled!: boolean;

  onSearch(v) {
    this.$emit('onSearch', v);
  }

  onTitleClick() {
    this.$emit('onTitleClick');
  }
}
</script>