import { render, staticRenderFns } from "./AppCopyButton.vue?vue&type=template&id=c6216820&scoped=true"
import script from "./AppCopyButton.vue?vue&type=script&lang=ts"
export * from "./AppCopyButton.vue?vue&type=script&lang=ts"
import style0 from "./AppCopyButton.vue?vue&type=style&index=0&id=c6216820&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c6216820",
  null
  
)

export default component.exports