<template>
  <div @click="onClose">
    <v-autocomplete 
      v-model="localValue" 
      :menu-props="menuProps" 
      :items="items" 
      :label="label" 
      :item-text="itemText" 
      :item-value="itemValue" 
      :rules="rules"
      :required="required"
      :single-line="singleLine"
      :clearable="clearable" 
      :loading="loading"
      :disabled="disabled"
      :readonly="readonly"
    />
  </div>
</template>

<!-- eslint-disable @typescript-eslint/no-explicit-any -->
<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class AppAutoComplete extends Vue {
  @Prop({required: true}) value!: string;
  @Prop(Array) items!: Array<any>;
  @Prop(String) label!: string;
  @Prop(String) readonly itemText!: string;
  @Prop(String) readonly itemValue!: string;
  @Prop(Array) readonly rules!: Array<any>;
  @Prop(Boolean) required!: boolean;
  @Prop(Boolean) singleLine!: boolean;
  @Prop(Boolean) clearable!: boolean;
  @Prop(Boolean) loading!: boolean;
  @Prop(Boolean) disabled!: boolean;
  @Prop(Boolean) readonly!: boolean;

  menuProps = { closeOnClick: true, closeOnContentClick: true }

  get localValue() {
    return this.value;
  }

  set localValue(localValue) {
    this.$emit("input", localValue);
  }

  onClose(e) {
    setTimeout(() => {
      if(document.activeElement && e.target.parentNode.getElementsByTagName("label")[0] && !e.target.parentNode.getElementsByTagName("label")[0].classList.contains("v-label--active"))
        if (document.activeElement instanceof HTMLElement) {
          document.activeElement.blur();
        }
    }, 60);
  }
}
</script>