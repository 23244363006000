import * as i18nIsoLanguages from "@cospired/i18n-iso-languages";
import i18n from "@/lang/i18n";

const HEBREW_LANGUAGE = "iw";

export default {
  methods: {
    getLanguageName(lang) {
      let value = i18nIsoLanguages.getName(lang, this.$route.params.lang);

      if(lang === HEBREW_LANGUAGE)
        value = i18n.t("languages."+lang);

      if(!value)
        value = i18nIsoLanguages.getName(lang, "en");

      return value;
    }
  }
}
/*
export function getLanguageName(lang) {
  let value = i18nIsoLanguages.getName(lang, this.$route.params.lang);

  if(lang === HEBREW_LANGUAGE)
    value = i18n.t("languages."+lang);

  if(!value)
    value = i18nIsoLanguages.getName(lang, "en");

  return value;

}*/