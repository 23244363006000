<template lang="html" src="./Installations.html"></template>

<!-- eslint-disable @typescript-eslint/ban-types -->
<!-- eslint-disable @typescript-eslint/no-explicit-any -->
<script lang="ts">
import { Vue, Component, Watch } from "vue-property-decorator";
import { mapActions } from "vuex";

import AppListMenu from "@/components/AppListMenu.vue";
import AppBreadcrumbs from "@/components/AppBreadcrumbs.vue";
import AppKpiChipInfo from "@/components/AppKpiChipInfo.vue";

import FilterTableHeader from "@/components/Table/FilterTableHeader/FilterTableHeader.vue";

import { displayErrorMessage } from "@/api";
import installationService from "@/api/installation";

import InstallationsFilter, {
  FILTER_DEFINITIONS,
} from "./InstallationsFilter/InstallationsFilter.vue";
import InstallationStatusChip from "./InstallationStatusChip/InstallationStatusChip.vue";
import InstallationSummary from "./InstallationSummary/InstallationSummary.vue";

import ExportTableDataButton from "@/components/Table/ExportTableDataButton/ExportTableDataButton.vue";

import dateMixin from "@/mixins/date.mixin";
import tableBackMixin from "@/mixins/table.mixin/back";
import filterMixin from "@/mixins/filter.mixin";

import tableMarkersMapMixin from "@/mixins/table-markers-map.mixin";

import FullScreenMap from "@/components/FullScreenMap/FullScreenMap.vue";
import MarkerMap from "@/components/MarkerMap/MarkerMap.vue";

import fullDataLoaderMixin from "@/mixins/full-data-loader.mixin";

import deviceService from "@/api/device";
import InstallationService from "@/api/installation";

// Keys para persistir los valores en la sessionStorage con el objetivo de mantenerlo tras un refresco de la página
const keyView = "Installations";
const keySearch = keyView + ".search";
const keyViewMap = keyView + ".viewMap";
const keyFilterOptions = keyView + ".filterOptions";

const keyInstallationsTab = "Installations.tab";

const defaultNameIdTab = "DUOX";
const nameIdTabs = {
  0: { family: "PANEL", subtype: "4G" },
  1: { family: "EDIBOX", subtype: "EDIBOX" },
  2: { family: "EDIBOX", subtype: "BOXM2" },
  3: "ALL",
};

export const TAB_TYPE = {
  DOUX_CONNECT: "douxConnect",
  BOX_M1: "boxM1",
  BOX_M2: "boxM2",
  //ALL: "all",
};

export const getMarkerIcon = (installation) => {
  let type = "marker-icon";
  let color = "grey";

  // TODO: Installation icon

  if (installation.status === "CONNECTED") color = "green";

  return type + "_" + color + ".png";
};

import InstallationsTable from "./InstallationsTable/InstallationsTable.vue";

export interface NumInsTypes {
  all: number;
  duox: number;
  edibox: number;
}

@Component({
  components: {
    AppListMenu,
    AppBreadcrumbs,
    AppKpiChipInfo,
    FilterTableHeader,
    InstallationSummary,
    InstallationsFilter,
    InstallationStatusChip,
    ExportTableDataButton,
    FullScreenMap,
    MarkerMap,
    InstallationsTable,
  },
  mixins: [
    dateMixin,
    tableMarkersMapMixin,
    tableBackMixin,
    filterMixin,
    fullDataLoaderMixin,
  ],
  methods: {
    ...mapActions({
      getInstallationsList: "installations/getInstallationsList",
    }),
  },
})
export default class Installations extends Vue {
  //loadFullData!: Function;
  //newFullDataState!: Function;
  exportTableData!: Function;
  resetFullDataState!: Function;
  saveFilters!: Function;
  transformTablePropsForServerRequest!: Function;
  getInstallationsList!: Function;
  checkHasAnyActiveFilter!: Function;
  filterOptions_!: any;
  isHeatMap!: any;
  loadingData!: any;
  search!: any;
  //totalServerItems: any;

  $refs!: {
    installationsMap: any;
    filters: any;
  };

  dialog = false;
  keySearch = keySearch;
  keyFilterOptions = keyFilterOptions;
  keyViewMap = keyViewMap;
  FILTER_DEFINITIONS = FILTER_DEFINITIONS;
  tableProps: any = {
    sortBy: ["id"],
  };
  expansionPanel = null;
  fullScreenMarkerMap = false;
  selectedInstallation = null;
  mapMarkerInfo = {
    getName: (o) => o.id,
    getIcon: getMarkerIcon,
    isOnline: (o) => o.status === "CONNECTED",
  };
  TAB_TYPE = TAB_TYPE as any;
  //session_setItem!: Function;
  //session_getItem!: Function;
  currentTab = +this.session_getItem(keyInstallationsTab);
  //currentTab = 0;
  renderedTabs = [0];
  refreshDevicesIsLoading = false;
  toggleView!: any;

  devices: any = [];

  installationTitleText = {
    0: this.$t("installation.installationListDuox"),
    1: this.$t("installation.installationListBoxm1"),
    2: this.$t("installation.installationListBoxm2"),
    3: this.$t("installation.installationList"),
  };

  /**  Funcionalidad de la ventana emergente de "exportando". */
  fDLMixin: fullDataLoaderMixin = new fullDataLoaderMixin();

  /** Contadores superiores. */
  numInsType: NumInsTypes = {
    all: 0,
    duox: 0,
    edibox: 0,
  };

  constructor() {
    super();
    this.loadNumInsType();
    this.toggleView = this.session_getItem(keyViewMap);
  }

  /** Obtiene los contadores superiores. */
  loadNumInsType() {
    InstallationService.getNumberOfTypeInstallations().then((res) => {
      this.numInsType = {
        all: res.data.all,
        duox: res.data.duox,
        edibox: res.data.edibox,
      };
    });
  }

  session_getItem(key, defaultValue = "") {
    const value = sessionStorage.getItem(key);
    return value !== null && value !== undefined ? value : defaultValue;
  }

  session_setItem(key, value) {
    if (value === null || value === undefined || value === "") {
      sessionStorage.removeItem(key);
    } else {
      sessionStorage.setItem(key, value);
    }
  }

  @Watch("currentTab")
  onChangeCurrentTab(v) {
    //TODO:Al cambiar de tab se limpan los filtros
    this.session_setItem(keyInstallationsTab, v.toString());
    if (this.renderedTabs.indexOf(v) === -1) this.renderedTabs.push(v);
    this.tableProps.page = 1;
    this.$data.toggleView = 0;

    if (this.$refs.filters) {
      this.$refs.filters.forEach((filter) => {
        filter.cleanFiltersTab();
      });
    }
    this.filterOptions_ = {};
    this.refreshList();
    //this.dataStore.load();
  }

  /*get menuOptions() {
    return [
      {
        icon: "list_alt",
        text: this.$t("general.details"),
        to: (item) => ({
          name: "InstallationDetails",
          params: { installationId: item.id, idTab: this.currentTab },
        }),
        allowed: this.$ability.can("details", "installations"),
      },
      {
        icon: "place",
        text: this.$t("device.viewOnMap"),
        action: (item) => this.openMarkerMap(item),
        allowed: this.$ability.can("map", "installations"),
      },
      {
        icon: "refresh",
        text: this.$t("installationDetails.refresh"),
        action: (item) => this.refreshInstallation(item.id),
        allowed: this.$ability.can("refresh", "installations"),
      },
    ];
  }*/

  /*get menuOptionsAllowed() {
    return hasAllowedMenuOptions(this.menuOptions);
  }*/

  /*get headers() {
    const headers: any = [
      {
        text: this.$t("installation.id"),
        value: "id",
        sortable: true,
        export: {
          width: 90,
        },
      },
      {
        text: this.$t("installation.tag"),
        value: "tag",
        sortable: true,
        export: {
          width: 90,
        },
      },
      {
        text: this.$t("installation.status"),
        value: "status",
        sortable: true,
        export: {
          width: 40,
          value: (row) => getInstallationStatusChipText(row),
          style: (row) =>
            row.status === "CONNECTED"
              ? PREDEFINED_STYLES.SUCCESS
              : row.status === "WARNING"
              ? PREDEFINED_STYLES.WARNING
              : PREDEFINED_STYLES.DARK_GREY,
        },
      },
      {
        text: this.$t("installation.city"),
        value: "city",
        sortable: true,
        export: {
          width: 50,
        },
      },
      {
        text: this.$t("installation.createdAt"),
        value: "createdAt",
        sortable: true,
        export: {
          width: 50,
          format: "datetime",
        },
      },
      {
        text: this.$t("installation.updatedAt"),
        value: "updatedAt",
        sortable: true,
        export: {
          width: 50,
          format: "datetime",
        },
      },
      {
        text: this.$t("installation.coverage"),
        value: "signal",
        sortable: true,
        export: {
          width: 25,
        },
      },
      {
        text: this.$t("installation.summary"),
        sortable: false,
        value: "summary",
        align: "left",

        export: {
          hide: true,
        },
      },
    ];

    if (this.menuOptionsAllowed)
      headers.push({
        text: null,
        value: null,
        sortable: false,
        align: "right",
        sticky: true,
      });

    return headers;
  }*/

  /*get exportHeaders() {
    const headers = [...this.headers];

    if (this.currentTab == 0) {
      headers.push({
        text: this.$t("installationDetails.panels"),
        value: "panels",
        export: {
          width: 20,
          format: "number",
        },
      });
    }

    if (this.currentTab != 0) {
      headers.push({
        text: this.$t("installationDetails.ediboxes"),
        value: "ediboxes",
        export: {
          width: 25,
          format: "number",
        },
      });
    }
    headers.push({
      text: this.$t("installationDetails.monitors"),
      value: "monitors",
      export: {
        width: 25,
        format: "number",
      },
    });

    if (this.currentTab == 0) {
      headers.push({
        text: this.$t("installationDetails.phones"),
        value: "phones",
        export: {
          width: 20,
          format: "number",
        },
      });
    }

    if (this.currentTab == 0) {
      headers.push({
        text: this.$t("installationDetails.gu"),
        value: "gu",
        export: {
          width: 20,
          format: "number",
        },
      });
    }
    return headers;
  }*/

  get hasAnyActiveFilter() {
    const filters = { ...this.filterOptions_ };
    if (filters.onlyProblemsFilter === false) delete filters.onlyProblemsFilter;

    return this.checkHasAnyActiveFilter(filters);
  }

  get installationsList() {
    return this.$store.getters["installations/installationsList"];
  }

  get numberOfInstallations() {
    return this.$store.getters["installations/numberOfInstallations"];
  }

  get numberOfTypeInstallations() {
    return this.$store.getters["installations/numberOfTypeInstallations"];
  }

  refreshInstallation(id) {
    this.loadingData = true;
    this.refreshDevicesIsLoading = true;

    installationService
      .refreshInstallation(id)
      .then(() => {
        this.refreshDevicesIsLoading = false;

        const ref = this.$refs["tab" + this.currentTab][0];
        ref.filterClick(); // Search current list again

        this.renderedTabs = [this.currentTab]; // Delete other tabs data

        this.$store.dispatch("snackbarInfo", {
          text: this.$t("installationDetails.refreshedInfo"),
        });

        this.refreshList();
      })
      .catch(
        () => (
          (this.loadingData = false), (this.refreshDevicesIsLoading = false)
        )
      );
  }

  refreshList() {
    this.filterOptions_.idTab = nameIdTabs[this.currentTab] || defaultNameIdTab;    
  }

  getList(pagination, connectable = null, axiosSource = null) {
    return deviceService.getDevices(
      this.filterOptions_,
      pagination,
      this.search ? this.search.trim() : null,
      pagination.sortBy,
      connectable || undefined,
      axiosSource
    );
  }

  loadInstallations(page, rowsPerPage) {
    const pagination = this.transformTablePropsForServerRequest();
    pagination.rowsPerPage = rowsPerPage;
    pagination.page = page;
    return installationService.getInstallations(
      this.filterOptions_,
      pagination,
      this.search,
      pagination.sortBy
    );
  }

  filterClick() {
    this.saveFilters();
    if (this.$refs.installationsMap && this.$refs.installationsMap.length > 0) {
      this.$refs.installationsMap.forEach((updateMap) => {
        updateMap.updateMarkers();
      });
    }
    this.tableProps.page = 1;
    this.refreshList();
  }

  refreshListSearch() {
    //this.tableProps.page = 1;
    this.refreshList();
  }

  openMarkerMap(installation) {
    this.selectedInstallation = installation;
    this.fullScreenMarkerMap = true;
  }

  getMapData() {
    this.filterOptions_.idTab = nameIdTabs[this.currentTab] || defaultNameIdTab;
    return new Promise((resolve, reject) => {
      const state = this.fDLMixin.newFullDataState();
      this.fDLMixin
        .loadFullData(this.loadInstallations, state)
        .then((res) => {
          resolve(res);
          this.fDLMixin.resetFullDataState(state);
        })
        .catch((err) => {
          displayErrorMessage("geo.error.getDataError", err);

          this.fDLMixin.resetFullDataState(state);
          reject(err);
        });
    });
  }

  get installationTitle() {
    return this.installationTitleText[this.currentTab];
  }
}
</script>
