<template>
  <div>
    <PanelIcon v-if="name === 'placa'" />
    <MonitorVEOXSIcon v-else-if="name === 'veoxs'" />
    <PhoneIcon v-else-if="name === 'telloftextra'" />
    <GuardUnitIcon v-else-if="name === 'guard'" />
    <span
      v-else
      class="di"
      :style="{ backgroundImage: 'url(&quot;' + src + '&quot;)' }"
    ></span>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator";
const ICONS_PATH = process.env.VUE_APP_PUBLIC_PATH + "img/devices_icons/";

const ICONS = {
  placa: "placa",
  edibox: "edibox",
  veo: "veo",
  veoxs: "veoxs",
  veoxl: "veoxl",
  telloft: "tel loft",
  telloftextra: "tel loftextra",
  teliloft: "tel iloft",
  telveo: "tel veo",
  telextra: "tel extra",
  guard: "conserjeria",
};

import PanelIcon from "@/ui/icons/devices/PanelIcon.vue";
import MonitorVEOXSIcon from "@/ui/icons/devices/MonitorVEOXSIcon.vue";
import PhoneIcon from "@/ui/icons/devices/PhoneIcon.vue";
import GuardUnitIcon from "@/ui/icons/devices/GuardUnitIcon.vue";

@Component({
  components: {
    PanelIcon,
    MonitorVEOXSIcon,
    PhoneIcon,
    GuardUnitIcon,
  },
})
export default class AppDeviceIcon extends Vue {
  @Prop(String) readonly name!: string;

  get src() {
    return ICONS_PATH + ICONS[this.name] + ".svg";
  }
}
</script>
<style scope>
span.di {
  width: 26px;
  height: 26px;
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
  margin: 4px 10px;
}
</style>
