import { displayErrorMessage } from "@/api";

import AppClient from "@/api/taboo";

export const getBlacklist = ({ commit }, payload) => new Promise((resolve, reject) => {
  AppClient.getBlacklist(payload.filterOptions, payload.pagination, payload.search, payload.column, payload.axiosSource).then(res => {
    const blacklist = {
      totalElements: res.data.totalElements,
      list: res.data.content
    }
    commit('setBlacklist', blacklist);
    resolve(res.data);
  }).catch(err => {
    displayErrorMessage("taboo.error.getBlacklist", err);
    reject(err);
  });
});

export const getCountActiveBans = ({ commit }) => new Promise((resolve, reject) => {
  AppClient.getCountActiveBans().then(res => {
    commit('setCountActiveBans', res.data);

    resolve(res.data);
  }, err => {
    displayErrorMessage(err, { general: ["user.error.getError"] });

    reject(err);
  });
});