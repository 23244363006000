<template lang="html" src="./LegendCount.html"></template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class LegendCount extends Vue {
  @Prop({ type: Number, default: 0 }) onLineCount!: number;
  @Prop({ type: Number, default: 0 }) offLineCount!: number;

  imgWidth = 18;

  get imgSrcOnLine() {
    return process.env.VUE_APP_PUBLIC_PATH + 'assets/status/green.png';
  }

  get imgSrcOffLine() {
    return process.env.VUE_APP_PUBLIC_PATH + 'assets/status/grey.png';
  }
}
</script>

<style scoped>
.legend-count {
  padding: 5px;
}

.legend-count img {
  vertical-align: bottom;
}

.legend-count h5 {
  color: #777;
  display: inline;  
}
</style>
