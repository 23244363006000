<template>
  <v-expansion-panel-header class="subheading" @keyup.space.prevent expand-icon="filter_list" :class="{'filter-active': active}">
    <v-layout row pr-2 align-center>
      <v-flex no-wrap xs6 sm2 class="hidden-xs-only" @click="onTitleClick"> {{ title }}</v-flex>
      <v-spacer class="hidden-xs-only" />

      <slot name="custom-actions"></slot>

      <v-flex :class="{'pl-5 md3 sm4 xs6': $vuetify.breakpoint.smAndUp}">
        <SearchInput v-model="localSearch" :disabled="disabled" @onSearch="onSearch" />
      </v-flex>
    </v-layout>
  </v-expansion-panel-header>
</template>

<script lang="ts">
import { Vue, Component, Prop, PropSync } from 'vue-property-decorator'
import SearchInput from "./SearchInput.vue";

@Component({
  components: {
    SearchInput
  }
})
export default class FilterTableHeader extends Vue {
  @Prop(String) readonly title!: string;
  @Prop({type: String, default: ''}) search!: string;
  @Prop(Boolean) active!: boolean;
  @Prop(Boolean) disabled!: boolean;

  @PropSync('search') localSearch!: string;

  onSearch(v) {
    this.$emit('onSearch', v);
  }

  onTitleClick() {
    this.$emit('onTitleClick');
  }
}
</script>