<template lang="html" src="./PlanAvailability.html"></template>

<!-- eslint-disable @typescript-eslint/no-explicit-any -->
<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import dateMixin from "@/mixins/date.mixin";

import PlanAvailabilityAddEditModal from "./PlanAvailabilityAddEditModal/PlanAvailabilityAddEditModal.vue";

@Component({
  components: {
    PlanAvailabilityAddEditModal
  },
  mixins: [dateMixin],
  data() {
    return {
      // TS doesn't allow to start as undefined, leaving it here solves this issue without changing anything else.
      addEditAvai: undefined
    }
  }
})
export default class PlanAvailability extends Vue {
  @Prop(String) planCode!: string;
  @Prop(Array) availability!: Array<any>;

  addEditAvai!: any;
  descTableRevision = 0;
  pagination: any = {
    sortBy: 'region',
    rowsPerPage: -1
  };
  selectedAvailability: any = [];

  get tableAvailability() {
    return this.availability.map(a => ({
      ...a,
      iva: `${a.taxRate}%`,
      region: a.country
    }));
  }

  get headers() {
    return [
      {
        text: this.$t("plans.region"),
        align: "left",
        sortable: true,
        value: "region"
      },
      {
        text: this.$t("plans.price"),
        sortable: true,
        value: "price"
      },
      {
        text: this.$t("planDetails.iva"),
        sortable: true,
        value: "iva"
      },
      {
        text: this.$t("planDetails.startingDate"),
        sortable: true,
        value: "starting"
      }
    ];
  }

  get showAddEditAvai() {
    return this.addEditAvai !== undefined;
  }

  set showAddEditAvai(v) {
    this.addEditAvai = undefined;
  }

  toggleAll() {
    if (this.selectedAvailability.length) this.selectedAvailability = [];
    else this.selectedAvailability = this.tableAvailability.slice();
  }

  toggleSelectItem(item, isSelected) {
    if(isSelected)
      this.selectedAvailability = this.selectedAvailability.filter(i => i.region !== item.region);
    else
      this.selectedAvailability.push(item);
  }

  changeSort(column) {
    if (this.pagination.sortBy === column) {
      this.pagination.descending = !this.pagination.descending;
    } else {
      this.pagination.sortBy = column;
      this.pagination.descending = false;
    }
  }

  editAvailability(tableItem) {
    this.addEditAvai = tableItem;
  }

  onCloseAvailabilityModal(isSaved = false) {
    this.addEditAvai = undefined;

    if(isSaved) {
      this.selectedAvailability = [];

      this.$emit("onUpdate");
    }
  }
}
</script>
<style scoped>
.v-card{
  display: flex;
  flex-direction: column;
}
.v-card .v-card__actions{
  margin-top: auto;
  margin-bottom: 0px !important;
}
.table-row-selector{
  width: 60px;
}
</style> 