import * as getters from "../users/getters";
import * as actions from "../users/actions";
import * as mutations from "../users/mutations";

const state = {
  // State for Users component
  usersList: [],
  numberOfUsers: 0,

  // User info
  oneUserData: null,
  oneUserCredit: null,
  oneUserPairings: []
};

export default ({
  namespaced: true,
  state,
  getters,
  actions,
  mutations
});