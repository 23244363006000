import * as i18nIsoLanguages from "@cospired/i18n-iso-languages";
import i18n from "@/lang/i18n";
import EventEmitter from "events";
import events from "events";

const HEBREW_LANGUAGE = "iw";

export default class LanguagesHelper extends events.EventEmitter {
  private static instance: LanguagesHelper;

  private currentLanguage = "en";
  public emitter = new EventEmitter();

  private constructor() {
    super();
    //Singleton
    //const emitter = new EventEmitter();
  }

  public static getInstance(): LanguagesHelper {
    if (!LanguagesHelper.instance) {
      LanguagesHelper.instance = new LanguagesHelper();
    }

    return LanguagesHelper.instance;
  }

  public getLanguage(): string {
    return this.currentLanguage;
  }

  public setLanguage(lang: string): void {
    this.currentLanguage = lang;
    this.emitter.emit("change", this.currentLanguage);
  }

  public getEmitter(): EventEmitter {
    return this.emitter;
  }

  /**
   * Obtiene el nombre del lenguaje a partir de un código en el idioma actual.
   * @param lang Código del lenguaje.
   * @param route Ruta para obtener el parámetro de lenguaje actual.
   * @returns Nombre del lenguaje.
   */
  static getLanguageName(lang: string): string | undefined {
    let languageName = i18nIsoLanguages.getName(lang, i18n.locale);

    if (lang === HEBREW_LANGUAGE) {
      languageName = i18n.t("languages." + lang).toString();
    }

    if (!languageName) {
      languageName = i18nIsoLanguages.getName(lang, "en");
    }

    return languageName;
  }
}
