<template lang="html" src="./ExportTableDataButton.html"></template>

<!-- eslint-disable @typescript-eslint/ban-types -->
<!-- eslint-disable @typescript-eslint/no-explicit-any -->
<script lang="ts">
import { Vue, Component, Mixins, Prop } from "vue-property-decorator";
import i18n from "@/lang/i18n";
import { searchOnTableData } from "@/mixins/table.mixin/front";
import tableExportMixin, { EXPORT_TYPE } from "@/mixins/table-export.mixin";
import fullDataLoaderMixin from "@/mixins/full-data-loader.mixin";

const MAX_EXPORT_SIZE = 10000;

@Component
export default class ExportTableDataButton extends Mixins(
  Vue,
  tableExportMixin
) {
  @Prop(Array) headers!: Array<any>;
  @Prop(String) name!: string;
  @Prop(Boolean) requestConfirmation!: boolean;
  @Prop(Array) data!: Array<any>;
  @Prop(Object) dataSort!: any;
  @Prop(Function) dataSorter!: Function;
  @Prop(String) dataSearch!: string;
  @Prop(Function) dataLoader!: Function;
  @Prop(Number) dataLoaderSize!: number;
  @Prop(Object) appliedFilters!: any;

  /**  Funcionalidad de la ventana emergente de "exportando". */
  fDLMixin: fullDataLoaderMixin = new fullDataLoaderMixin();
  tableEM: tableExportMixin = new tableExportMixin();

  /** Tipos de exportación que se pueden realizar. */
  EXPORT_TYPE = EXPORT_TYPE;

  fullLoaderState = {
    loaded: null,
    total: null,
    data: [],
  };
  confirmExportType: any = null;

  get pagesLoaded(): number {
    return this.fullLoaderState?.loaded || 0;
  }

  get numTotalPages(): number {
    return this.fullLoaderState?.total || 1;
  }

  get isLoading() {
    return this.pagesLoaded > 0;
  }

  get loadedPercent() {
    return this.numTotalPages
      ? Math.floor((100 / this.numTotalPages) * this.pagesLoaded)
      : 0;
  }

  get showConfirmModal() {
    return this.confirmExportType !== null;
  }

  set showConfirmModal(v) {
    if (!v) this.confirmExportType = null;
  }

  /**
   * Exporta los datos de la tabla.
   * @param type Los tipos pueden ser "EXCEL" o "PDF".
   */
  executeLoader(type: string) {
    this.fDLMixin
      .loadFullData(this.dataLoader, this.fullLoaderState)
      .then((res) => {
        this.exportData(res, type);
      })
      .catch((err) => {
        console.error(err);

        this.resetLoader();

        this.$store.dispatch("snackbarError", {
          text: this.$t("general.error.exportingData"),
        });
      });
  }

  /** Resetea el "exportando". */
  resetLoader() {
    this.fDLMixin.resetFullDataState(this.fullLoaderState);
  }

  /**
   * Comprueba los datos antes de ejecutar la exportación.
   * @param type Tipos de exportación "EXCEL" o "PDF".
   * @param confirmed Si es FALSE muestra un mensaje de confirmación de exportación.
   */
  execute(type: string, confirmed = !this.requestConfirmation) {
    if (
      type === EXPORT_TYPE.PDF &&
      ((this.data && this.data.length) || this.dataLoaderSize) > MAX_EXPORT_SIZE
    ) {
      this.$store.dispatch("snackbarError", {
        text: this.$t("general.manyDataToExport", [MAX_EXPORT_SIZE]),
      });
      return;
    }

    if (!confirmed) {
      this.confirmExportType = type;
      return;
    }

    this.confirmExportType = null;
    if (this.dataLoader) {
      this.executeLoader(type);
    } else {
      this.exportData([...this.data], type);
    }
  }

  searchData(data) {
    return data.filter((item) => {
      let isFiltered = false;

      let i = 0;
      while (i < this.headers.length && !isFiltered) {
        const columnValue = this.headers[i].value;

        if (
          columnValue &&
          item[columnValue] &&
          searchOnTableData(item[columnValue], this.dataSearch)
        )
          isFiltered = true;

        i++;
      }

      return isFiltered;
    });
  }

  sortData(data) {
    if (this.dataSort.sortBy) {
      const isDescending = this.dataSort.descending;
      if (this.dataSorter) {
        this.dataSorter(data, this.dataSort.sortBy, isDescending);
      } else {
        const collator = new Intl.Collator(i18n.locale, {
          numeric: true,
          sensitivity: "base",
        });
        data.sort((a, b) => {
          const firstValue =
            a[this.dataSort.sortBy] === null
              ? ""
              : a[this.dataSort.sortBy] === 0 &&
                this.dataSort.sortBy == "refreshTokenValiditySeconds"
              ? Infinity
              : a[this.dataSort.sortBy];
          const secondValue =
            b[this.dataSort.sortBy] === null
              ? ""
              : b[this.dataSort.sortBy] === 0 &&
                this.dataSort.sortBy == "refreshTokenValiditySeconds"
              ? Infinity
              : b[this.dataSort.sortBy];
          return collator.compare(firstValue, secondValue);
        });
        if (isDescending) data.reverse();
      }
    }
  }

  transformFilters() {
    const { search, filters, definitions } = this.appliedFilters;

    let appliedFilters: Array<any> = [];
    if (search)
      appliedFilters.push({
        title: "*" + i18n.t("general.searched") + "*",
        value: search,
      });

    let sortedFilters = Object.entries(filters);
    const order = Object.keys(definitions);
    sortedFilters.sort((a, b) => order.indexOf(a[0]) - order.indexOf(b[0]));

    sortedFilters.forEach((filter) => {
      const key = filter[0];
      if (key != "idTab") {
        const definition = definitions[key];

        let value: any = filter[1];

        if (
          value !== null &&
          value != undefined &&
          value.toString().trim() != ""
        ) {
          // Transform value
          if (Array.isArray(value)) {
            if (value.toString().indexOf("[object Object]") !== -1)
              value = value.map((i) =>
                typeof value === "object" && definition.objectMapper
                  ? i[definition.objectMapper]
                  : i
              );

            value = value.join(", ");
          } else if (typeof value === "object") {
            value = definition.objectMapper
              ? value[definition.objectMapper]
              : value.toSource();
          }

          // Set filter
          appliedFilters.push({
            title: i18n.t(definition.literal),
            value:
              definition.valueLiterals && definition.valueLiterals[value]
                ? i18n.t(definition.valueLiterals[value])
                : value,
            format: definition.format || "string",
          });
        }
      }
    });

    return appliedFilters;
  }

  /** Prepara los datos para ejecutar la exportación y la ejecuta. */
  exportData(data, type) {
    if (this.dataSearch) data = this.searchData(data);

    if (data.length > 0) {
      const appliedFilters = this.appliedFilters ? this.transformFilters() : [];

      if (this.dataSort) {
        this.sortData(data);
      }
      this.tableEM.exportTableData(appliedFilters, this.headers, data, {
        name: this.name,
        type,
      });

      setTimeout(() => this.resetLoader(), 400);
    } else {
      this.resetLoader();

      this.$store.dispatch("snackbarError", {
        text: this.$t("general.noDataToExport"),
      });
    }
  }
}
</script>
<style>
.v-btn.export-to-pdf .v-btn__content {
  font-size: 14px;
}
</style>
<style scoped>
.v-list-item .v-list-item__title {
  font-size: 14px;
}
</style>
