<template lang="html" src="./DevicesFilter.html"></template>
<!-- eslint-disable @typescript-eslint/ban-types -->
<!-- eslint-disable @typescript-eslint/no-explicit-any -->
<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator";
import AppFilterButtons from "@/components/AppFilterButtons.vue";
import AppInputDate from "@/components/AppInputDate.vue";
import AppSelect from "@/components/AppSelect.vue";

import { displayErrorMessage } from "@/api";

import firmwareService from "@/api/firmware";

import TableGeoFilters, {
  GEO_FILTERS_DEFINITIONS,
} from "@/components/TableGeoFilters/TableGeoFilters.vue";

import { TAB_TYPE } from "../../Devices.vue";

export const FILTER_DEFINITIONS = {
  serialNumberFilter: {
    literal: "device.serialNumber",
  },
  familyFilter: {
    literal: "firmware.devicefamily",
  },
  typeFilter: {
    literal: "firmware.devicetype",
  },
  subtypeFilter: {
    literal: "firmware.devicesubtype",
  },
  statusFilter: {
    literal: "device.status",
    valuesLiteral: {
      Online: "general.online",
      Offline: "general.offline",
      Manufactured: "general.manufactured",
    },
  },
  coverageFilter: {
    literal: "installation.coverage",
    valuesLiteral: {
      20: "ranges.coverage.20",
      50: "ranges.coverage.50",
      75: "ranges.coverage.75",
      100: "ranges.coverage.100",
    },
  },
  dateValueStart: {
    literal: "device.rangeStart",
    format: "date",
  },
  dateValueEnd: {
    literal: "device.rangeEnd",
    format: "date",
  },
  olFilter: {
    literal: "device.ol",
  },
  withoutOlFilter: {
    literal: "device.withoutOl",
  },
  versionHw: {
    literal: "device.hwVersion",
  },
  versionSwmin: {
    literal: "device.swVersionMin",
  },
  versionSwmax: {
    literal: "device.swVersionMax",
  },
  buildFilter: {
    literal: "firmware.fwbuild",
  },
  iccidFilter: {
    literal: "deviceDetails.iccid",
  },
  imeiFilter: {
    literal: "deviceDetails.imei",
  },
  trialFilter: {
    literal: "plans.trialAvailable",
  },
  simStatusFilter: {
    literal: "deviceDetails.simStatus",
    valuesLiteral: {
      Activated: "deviceDetails.status.ACTIVATED",
      Deactivated: "deviceDetails.status.DEACTIVATED",
      ActivationReady: "deviceDetails.status.ACTIVATION_READY",
      ActivationPendant: "deviceDetails.status.ACTIVATION_PENDANT",
      Test: "deviceDetails.status.TEST",
      Suspended: "deviceDetails.status.SUSPENDED",
      Inactive: "deviceDetails.status.INACTIVE_NEW",
    },
  },
  ...GEO_FILTERS_DEFINITIONS,
};

@Component({
  components: {
    AppFilterButtons,
    AppInputDate,
    AppSelect,
    TableGeoFilters,
  },
})
export default class DevicesFilter extends Vue {
  @Prop(Object) value!: any;
  @Prop(String) tableType!: string;
  @Prop(Boolean) viewMap!: boolean;
  @Prop({ type: Boolean, default: false }) disabledFilters!: boolean;

  TAB_TYPE = TAB_TYPE;
  FILTER_DEFINITIONS = FILTER_DEFINITIONS;
  isLoadingTypes: number | boolean = false;

  selectionOptions = {
    types: [],
    subtypes: [],
  };

  $refs!: {
    geoFilters: any;
  };

  get status() {
    return [
      {
        label: this.$t(FILTER_DEFINITIONS.statusFilter.valuesLiteral.Online),
        value: "Online",
      },
      {
        label: this.$t(FILTER_DEFINITIONS.statusFilter.valuesLiteral.Offline),
        value: "Offline",
      },
      {
        label: this.$t(
          FILTER_DEFINITIONS.statusFilter.valuesLiteral.Manufactured
        ),
        value: "Manufactured",
      },
    ];
  }

  get coverage() {
    return [
      {
        value: "20",
        label: this.$t(FILTER_DEFINITIONS.coverageFilter.valuesLiteral[20]),
      },
      {
        value: "50",
        label: this.$t(FILTER_DEFINITIONS.coverageFilter.valuesLiteral[50]),
      },
      {
        value: "75",
        label: this.$t(FILTER_DEFINITIONS.coverageFilter.valuesLiteral[75]),
      },
      {
        value: "100",
        label: this.$t(FILTER_DEFINITIONS.coverageFilter.valuesLiteral[100]),
      },
    ];
  }

  get simStatus() {
    return [
      {
        label: this.$t(
          FILTER_DEFINITIONS.simStatusFilter.valuesLiteral.Activated
        ),
        value: "ACTIVE",
      },
      {
        label: this.$t(
          FILTER_DEFINITIONS.simStatusFilter.valuesLiteral.Deactivated
        ),
        value: "DEACTIVATED",
      },
      {
        label: this.$t(
          FILTER_DEFINITIONS.simStatusFilter.valuesLiteral.ActivationReady
        ),
        value: "ACTIVATION_READY",
      },
      {
        label: this.$t(
          FILTER_DEFINITIONS.simStatusFilter.valuesLiteral.ActivationPendant
        ),
        value: "ACTIVATION_PENDANT",
      },
      {
        label: this.$t(FILTER_DEFINITIONS.simStatusFilter.valuesLiteral.Test),
        value: "TEST",
      },
      {
        label: this.$t(
          FILTER_DEFINITIONS.simStatusFilter.valuesLiteral.Suspended
        ),
        value: "SUSPENDED",
      },
      {
        label: this.$t(
          FILTER_DEFINITIONS.simStatusFilter.valuesLiteral.Inactive
        ),
        value: "INACTIVE_NEW",
      },
    ];
  }

  get localValue() {
    return this.value;
  }

  set localValue(localValue) {
    this.$emit("input", localValue);
  }

  get selectionOptionsStatus() {
    let statusOptions = [...this.status];

    if (this.viewMap)
      statusOptions = statusOptions.filter((x) => x.value !== "Manufactured");

    return statusOptions;
  }

  get selectionOptionsSimStatus() {
    let simStatusOptions = [...this.simStatus];
    return simStatusOptions;
  }

  get listFamilies() {
    return this.$store.getters["firmwares/listFamilies"];
  }

  get fieldRequired() {
    return [
      (v) =>
        v === null ||
        v === "" ||
        v === undefined ||
        (v <= 99 && v >= 0) ||
        this.$t("general.fildNumbers"),
    ];
  }

  get fielNewFormatFW() {
    return [
      (v) =>
        v === null ||
        v === "" ||
        v === undefined ||
        (/^(\d{2}\.\d{2})$/.test(v) &&
          parseFloat(v) >= 0 &&
          parseFloat(v) <= 99.99)
          ? true
          : this.$t("general.fildNumbersFW"),
    ];
  }

  get fielNewFormatFWBuild() {
    return [
      (v) =>
        v === null ||
        v === "" ||
        v === undefined ||
        (/^(\d{3})$/.test(v) && parseFloat(v) >= 0 && parseFloat(v) <= 999)
          ? true
          : this.$t("general.fildNumbersBuild"),
    ];
  }

  get filterFamily() {
    return (
      this.tableType !== this.TAB_TYPE.GUARD &&
      this.tableType !== this.TAB_TYPE.WIFI_MONITORS &&
      this.tableType !== this.TAB_TYPE.PANELS_4G &&
      this.tableType !== this.TAB_TYPE.NOWIFI_MONITORS &&
      this.tableType !== this.TAB_TYPE.PHONES &&
      this.tableType !== this.TAB_TYPE.EDIBOX
    );
  }

  get filterSubtype() {
    return (
      this.tableType !== this.TAB_TYPE.PHONES &&
      this.tableType !== this.TAB_TYPE.PANELS_4G &&
      this.tableType !== this.TAB_TYPE.EDIBOX
    );
  }

  get listFamiliesObject() {
    let families = this.listFamilies;
    families.forEach(
      (item) => (item.text = this.$t("device.deviceDetails." + item.name))
    );
    return families;
  }

  mounted() {
    this.getFamilies();

    if (this.localValue.familyFilter)
      this.getDeviceType(this.localValue.familyFilter, false);
  }

  onSubmit(e) {
    e.preventDefault();

    this.applyFilters();
  }

  onWithoutOlChange(v) {
    if (v) this.localValue.olFilter = "";
    else delete this.localValue.withoutOlFilter;
  }

  onTrialChange(v) {
    if (!v) delete this.localValue.trialFilter;
  }

  cleanFilters() {
    let resetFilters: any = {};
    if (this.tableType === TAB_TYPE.WIFI_MONITORS) {
      resetFilters.familyFilter = "MONITOR";
      resetFilters.connectableFilter = true;
    } else if (this.tableType === TAB_TYPE.PANELS_4G) {
      resetFilters.familyFilter = "PANEL";
      resetFilters.connectableFilter = true;
      resetFilters.iccidFilter = "";
      resetFilters.imeiFilter = "";
    } else if (this.tableType === TAB_TYPE.NOWIFI_MONITORS) {
      resetFilters.familyFilter = "MONITOR";
      resetFilters.connectableFilter = false;
    } else if (this.tableType === TAB_TYPE.PHONES) {
      resetFilters.familyFilter = "PHONE";
    } else if (this.tableType === TAB_TYPE.GUARD) {
      resetFilters.familyFilter = "GUARDUNIT";
    } else if (this.tableType === TAB_TYPE.EDIBOX) {
      resetFilters.familyFilter = "EDIBOX";
      resetFilters.iccidFilter = "";
      resetFilters.imeiFilter = "";
    }

    this.$refs.geoFilters.cleanFilters();

    this.localValue = resetFilters;

    this.applyFilters(resetFilters);
  }

  applyFilters(filters = this.localValue) {
    this.$emit("filterClick", filters);
  }

  getFamilies() {
    this.isLoadingTypes = 1;

    this.$store
      .dispatch("firmwares/getDeviceFamily")
      .then(() => (this.isLoadingTypes = false))
      .catch(() => (this.isLoadingTypes = false));
  }

  getDeviceType(familyValue, reset = true) {
    if (reset) {
      delete this.localValue.typeFilter;
      delete this.localValue.subtypeFilter;
    }

    if (familyValue) {
      this.isLoadingTypes = 2;

      firmwareService
        .getDeviceTypes(familyValue)
        .then((res) => {
          this.selectionOptions.types = res.data;
          this.selectionOptions.types.forEach(
            (item: any) =>
              (item.text = this.$t("device.deviceDetails." + item.name))
          );

          this.isLoadingTypes = false;

          if (this.localValue.typeFilter)
            this.getDeviceSubtype(this.localValue.typeFilter, false);
        })
        .catch((err) => {
          displayErrorMessage(err, {
            general: ["general.error.loadingDataForm"],
          });
          this.isLoadingTypes = false;
        });
    }
  }

  getDeviceSubtype(typeValue, reset = true) {
    if (reset) delete this.localValue.subtypeFilter;

    if (typeValue) {
      this.isLoadingTypes = 3;

      let connectable: boolean | null;
      if (this.tableType === TAB_TYPE.WIFI_MONITORS) {
        connectable = true;
      } else if (this.tableType === TAB_TYPE.NOWIFI_MONITORS) {
        connectable = false;
      } else {
        connectable = null;
      }

      firmwareService
        .getDeviceSubTypes(this.localValue.familyFilter, typeValue, connectable)
        .then((res) => {
          this.selectionOptions.subtypes = res.data;
          this.selectionOptions.subtypes.forEach(
            (item: any) =>
              (item.text = this.$t("device.deviceDetails." + item.name))
          );

          this.isLoadingTypes = false;
        })
        .catch((err) => {
          displayErrorMessage(err, {
            general: ["general.error.loadingDataForm"],
          });

          this.isLoadingTypes = false;
        });
    }
  }
}
</script>
