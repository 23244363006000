/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-var-requires */
import "babel-polyfill";
import Vue from "vue";
import App from "./App.vue";
import router from "./router/index";
import store from "./store";
import vuetify from "./plugins/vuetify";

import i18n from "./lang/i18n";
import axios from "axios";
//import VueI18n from 'vue-i18n';
import VueMoment from "vue-moment";
import moment from "moment-timezone";
import lineClamp from "vue-line-clamp";
import VueClipboard from "vue-clipboard2";

import * as i18nIsoCountries from "i18n-iso-countries";
import * as i18nIsoLanguages from "@cospired/i18n-iso-languages";

import { abilitiesPlugin } from "@casl/vue";
import { Ability } from "@casl/ability";
import { defineAbilitiesFor } from "@/casl";

import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/free-brands-svg-icons";

import * as L from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";

import "./style.css";

import { AxiosHelper } from "./domain/helpers/AxiosHelper";

import PrimeVue from "primevue/config";
import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import Tooltip from "primevue/tooltip";

import "@/ui/css/general.scss";

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

i18nIsoCountries.registerLocale(require("i18n-iso-countries/langs/en.json"));
i18nIsoCountries.registerLocale(require("i18n-iso-countries/langs/es.json"));

i18nIsoLanguages.registerLocale(
  require("@cospired/i18n-iso-languages/langs/en.json")
);
i18nIsoLanguages.registerLocale(
  require("@cospired/i18n-iso-languages/langs/es.json")
);

Vue.use(VueMoment, { moment });
Vue.use(lineClamp);
VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);
Vue.use(abilitiesPlugin, new Ability(defineAbilitiesFor()));

Vue.use(PrimeVue);
Vue.directive("tooltip", Tooltip);

Vue.config.productionTip = false;

const getConfigMap = () => {
  const url = process.env.VUE_APP_PRE_URI + "/mavi";
  return axios.get(url + "/maptilerconfig.js");
};

const getConfigEnv = () => {
  const url = process.env.VUE_APP_PRE_URI + "/mavi";
  return axios.get(url + "/frontenv.js");
};

const vueProperties: any = {
  router,
  vuetify,
  store,
  i18n,
  render: (h: any) => h(App),
};

/** Refresca el token si expiró */
function refreshTokenIfItExpired(config) {
  return store.dispatch("renewTimeout", {}).then(
    (token) => {
      config.headers = {
        ...config.headers,
        Authorization: "Bearer " + token,
      };

      return Promise.resolve(config);
    },
    (err) => {
      return Promise.reject(err);
    }
  );
}

const loadApp = () => {
  AxiosHelper.getInstance().interceptors.request.use(refreshTokenIfItExpired);
  new Vue(vueProperties).$mount("#app");
};

getConfigMap()
  .then((res) => {
    (window as any).maptilerconfig = res.data;

    getConfigEnv()
      .then((res) => {
        (window as any).frontenv = res.data;
        loadApp();
      })
      .catch((err) => {
        console.log("RES DATA FRONTENV ERROR: ", err);
        (window as any).frontenv = {};
        throw err;
      });
  })
  .catch((err) => {
    console.log("RES DATA MAPTILER ERROR: ", err);
    (window as any).maptilerconfig = {};
    loadApp();
  });
