import store from "@/store";
import TranslateText from "./TranslateText";

export default class Toast {
  public static error(message: string): void {
    store.dispatch("errorHandlerCustom", {
      text: TranslateText.t(message),
    });
  }

  public static info(message: string): void {
    store.dispatch("snackbarInfo", {
      text: TranslateText.t(message),
    });
  }
}
