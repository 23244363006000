<template lang="html" src="./ServicesEditModal.html"></template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'

import { displayErrorMessage } from "@/api";
import katalogService from "@/api/katalog";

@Component({
  components: {
    
  }
})
export default class ServicesEditModal extends Vue {
  @Prop(Boolean) value!: boolean;
  @Prop(String) deviceId!: string;

  isLoading = false;
  validForm = false;
  isSaving = false;
  
  get show() {
    return this.value;
  }

  set show(localValue) {
    this.$emit("input", localValue);
  }

  get serviceList() { 
    return this.$store.getters["plans/servicesList"];
  }

  getServicesList() {
    this.isLoading = true;
    this.$store.dispatch("plans/getServices").finally(() => this.isLoading = false);
  }

  mounted() {
    this.getServicesList();
  }

  changeCommercialValue(service){
    this.isSaving = true;
    katalogService.setCommercialService(service.serviceId,service.commercial)
      .then(() => this.$store.dispatch("snackbarInfo", {
              text: this.$t("plans.changeOK")
            }))
      .catch((error) =>
        displayErrorMessage(error, {
          general: ["general.error.updateError"]
        }))
      .finally(() => this.isSaving = false);
  }

}
</script>
