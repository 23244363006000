import { InternalUserController } from "@/domain/controllers/InternalUserController";
import { InternalUserEntity } from "@/domain/entities/InternalUserEntity";
import Toast from "@/ui/helpers/Toast";
import { Vue, Component, Prop, Ref, Watch } from "vue-property-decorator";

@Component({
  name: "DialogChangeRoles",
})
export default class DialogChangeRoles extends Vue {
  /** Muestra u oculta el dialog. */
  @Prop({ default: false }) showDialog!: boolean;
  /** Usuario interno. */
  @Prop() internalUser!: InternalUserEntity;
  /** Controlador de usuarios internos. */
  ctrlInternalUser = new InternalUserController();
  /** Muestra u oculta la confirmación del dialog. */
  showConfirmDialog = false;
  /** Referencia al formulario. */
  @Ref("form") form!: HTMLFormElement;
  /** Indica si el formulario es valido. */
  validEditForm = false;
  /** Roles del usuario. */
  userRol: string[] = [];
  /** Lista de roles. */
  roleList: string[] = [];

  /** Al contruir el objeto se obtienen los roles del combobox. */
  constructor() {
    super();
    this.ctrlInternalUser.getRoles().then((roles) => {
      this.roleList.push(...roles);
    });
  }

  /** Al entrar en el diálogo, se asignan los roles. */
  @Watch("showDialog")
  onChangeShowDialog(newValue: boolean) {
    if (newValue) {
      this.internalUser.roles.forEach((rol) => {
        this.userRol.push(rol.name);
      });
    }
  }
  /** Reglas para la contraseña. */
  get rolRules() {
    return [
      (v) => !!v || this.$t("internalusers.error.emptyRol"),
      (v) => v.length > 0 || this.$t("internalusers.error.emptyRol"),
    ];
  }

  /**
   * Para salir del diálogo, se emite el evento cancel.
   * Se limpia el formulario y se resetea la validación.
   * Se oculta el diálogo de confirmación.
   * Se limpia la lista de roles.
   */
  cancel() {
    this.$emit("cancel");
    this.form.reset();
    this.form.resetValidation();
    this.showConfirmDialog = false;
    this.roleList.length = 0;
  }
  /** Antes de guardar, muestra el dialog de confirmación. */
  save() {
    this.showConfirmDialog = true;
  }
  /** Oculta el dialog de confirmación. */
  cancelConfirm() {
    this.showConfirmDialog = false;
  }
  /** Guarda los roles, muestra un mensaje de confirmación y cierra el diálogo. */
  saveConfirm() {
    this.internalUser.setRolesText(this.userRol);
    this.ctrlInternalUser.changeRoles(this.internalUser).then(() => {
      this.cancel();
      Toast.info("internalusers.messages.saveRoles");
    });
  }
}
