import Api from "./index";

const SERVICE_PATH = "/subscription/api/v1";

export default {
  getSubscriptionForDevice: (deviceId, userId) => Api(true).get(`${SERVICE_PATH}/subscriptions/device/${deviceId}?active=true&addCancelInformation=true&userId=${userId}`),

  resetTrialDevice: (deviceId) => Api(true).post(`${SERVICE_PATH}/subscription/device/${deviceId}/trial`, { trialAvailable: true }),

  cancelSubscription: (subscriptionId, immediate, reason, otherReasonText) => Api(true).post(`${SERVICE_PATH}/subscriptions/${subscriptionId}/cancel`, {
    cancellationDetails: otherReasonText || '',
    cancellationReason: reason,
    immediate
  })
};