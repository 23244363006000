<template>
  <v-snackbar
    :color="snackbarColor"
    v-model="localVisible"
    multi-line
    bottom
    :timeout="snackTimeout"
  >
    <v-icon size="24" class="pr-3">{{ icon }}</v-icon>
    {{ text }}
    <v-btn text color="black" @click="localVisible = false">{{ $t("general.close")}}</v-btn>
  </v-snackbar>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class AppSnackbar extends Vue {
  @Prop(Boolean) visible!: boolean;
  @Prop(String) readonly text!: string;
  @Prop(String) readonly snackbarColor!: string;
  @Prop(String) readonly icon!: string;
  
  snackTimeout = 6000;

  get localVisible() {
    return this.visible;
  }
  
  set localVisible(localValue) {
    if (localValue === false) {
      this.$store.dispatch("snackbarError", { active: false, text: "" });
      this.$store.dispatch("snackbarInfo", { active: false, text: "" });
    }
  }
}
</script>
<style>
.v-snack__content {
  padding: 0 15px;
  min-height: 55px !important;
}
</style>
