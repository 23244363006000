<template lang="html" src="./VATEditModal.html"></template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'

import AppSelect from "@/components/AppSelect.vue";

import { displayErrorMessage } from "@/api";
import katalogService from "@/api/katalog";

@Component({
  components: {
    AppSelect
  }
})
export default class VATEditModal extends Vue {
  @Prop(Boolean) value!: boolean;
  @Prop(String) deviceId!: string;

  isLoading = false;
  validForm = false;
  isSaving = false;
  regionalVAT = null;
  availableRegions = [{text:"", value:""}]; // Cambiar cuando se tenga una lista de regiones
  selectedRegion = "";
  
  get show() {
    return this.value;
  }

  set show(localValue) {
    this.loadRegionalVAT();
    this.$emit("input", localValue);
  }

  get numberRules() {
    return [
      v => !isNaN(v) || this.$t("oauthclients.rules.onlynumbers"),
      v => v >= 0 || this.$t("oauthclients.rules.positive"),
      v => !!v || this.$t("firmware.fieldrequired")
    ];
  }

  mounted(){
    this.availableRegions=[{text:this.$tc("plans.country.ES"),value:"ES"}];
    this.selectedRegion="ES";
    this.loadRegionalVAT();
  }

  loadRegionalVAT(){
    this.isLoading = true;
    katalogService.getRegionalVAT(this.selectedRegion)
      .then(response => this.regionalVAT = response.data)
      .finally(() => this.isLoading = false);
  }

  setRegionalVAT(){
    this.isSaving = true;
    katalogService.setRegionalVAT(this.selectedRegion, this.regionalVAT)
      .then(() => this.show = false)
      .catch(error => displayErrorMessage(error, {
          general: ["general.error.updateError"]
        }))
      .finally(() => this.isSaving = false);
  }

}
</script>
