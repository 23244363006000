import { render, staticRenderFns } from "./SubscriptionEvents.html?vue&type=template&id=3f581d65&scoped=true&lang=html&external"
import script from "./SubscriptionEvents.vue?vue&type=script&lang=ts"
export * from "./SubscriptionEvents.vue?vue&type=script&lang=ts"
import style0 from "./SubscriptionEvents.vue?vue&type=style&index=0&id=3f581d65&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f581d65",
  null
  
)

export default component.exports