import tableMixin from "./";

export const searchOnTableData = (value, search) => {
  if (value == null || search == null) return false;
  
  return value.toString().trim().toUpperCase().indexOf(search.trim().toUpperCase()) !== -1;
}

export default {
  mixins: [tableMixin],

  computed: {
    tableAttributes() {
      return {
        ...this.obtainCommonTableAttributes(),
        'search': (this.search ? this.search.trim() : null),
        'custom-filter': searchOnTableData,
        'footer-props': { 'items-per-page-options': [10, 25, 50, 100] }
      }
    }
  },

  methods: {
    searchOnTableData,

    changeSort(column) {

      if (this.tableProps.sortBy[0] === column) {
        this.tableProps.sortDesc[0] = !this.tableProps.sortDesc[0];
      } else {
        this.tableProps.sortBy[0] = column;
        this.tableProps.sortDesc[0] = false;
      }

      this.tableProps.page = 1;
      this.tableProps = { ...this.tableProps };
    },
  }
}