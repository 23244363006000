import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import 'font-awesome/css/font-awesome.min.css'
//import 'vuetify/dist/vuetify.min.css'

import es from "vuetify/src/locale/es";
import en from "vuetify/src/locale/en";
import { UserVuetifyPreset } from 'vuetify';


const opts: UserVuetifyPreset = {
    icons: {
      iconfont: 'md' || 'mdi' || 'fa'
    },
    lang: {
      locales: { es, en },
      current: 'en',
    },
  };

Vue.use(Vuetify);

export default new Vuetify(opts);
