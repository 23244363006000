import Api from "./index";
import i18n from "@/lang/i18n";

const DEVICE_CACHE_READER_SERVICE_PATH = "/devicecachereader/api/v1";
const DEVICE_CACHE_READER_SERVICE_PATH2 = "/devicecachereader/api/v2";
const DEVICE_CACHE_UPDATER_SERVICE_PATH = "/devicecacheupdater/api/v1";
const DEVICE_ACTION = "/deviceaction/api/v1";
const DEVICE_ACTIONV2 = "/deviceaction/api/v2";
const INSTALLATION_SERVICE_PATH = "/installation/api/v1";

const DEVICE_SERVICE_PATH = "/device/api/v1";
const DEVICE_PAIRING_SERVICE_PATH = "/pairing/api/v3";
const DEVICE_SIM_SERVICE_PATH = "/simservice/api/v1";

const DEVICE_CACHE_READER_TIMEOUT = 60 * 1000;

const urlToDevices = (
  filterOptions,
  pagination = null,
  search = null,
  column = null,
  allDevice = false,
  map = false,
  newUpdateDevices = false
) => {
  const sortDirection = !map
    ? pagination && pagination.descending
      ? "-"
      : "+"
    : "";

  let urlDevices = !map
    ? DEVICE_CACHE_READER_SERVICE_PATH + "/devices?"
    : DEVICE_CACHE_READER_SERVICE_PATH2 + "/devices-map?";

  if (newUpdateDevices) {
    urlDevices = DEVICE_CACHE_READER_SERVICE_PATH2 + "/devices-update-fw?";
  }

  if (pagination) {
    /*  urlDevices += !map ? "pageNumber=" + pagination.page + "&pageSize=" + pagination.rowsPerPage + "&" :
    "page=" + pagination.page + "&size=" + pagination.rowsPerPage + "&"; */
    urlDevices +=
      "pageNumber=" +
      pagination.page +
      "&pageSize=" +
      pagination.rowsPerPage +
      "&";
  }

  if (search) {
    urlDevices += "question=" + escape(search) + "&";
  }

  if (column) {
    if (column === "typeAndSubtype") {
      column = "type";
    }
    urlDevices +=
      !map && !newUpdateDevices ? "sort=" + sortDirection + column + "&" : "";
  }

  if (filterOptions.serialNumberFilter) {
    urlDevices += "serialNumber=" + filterOptions.serialNumberFilter + "&";
  }

  if (filterOptions.dateValueStart) {
    urlDevices +=
      "manufacturingDateStart=" +
      filterOptions.dateValueStart +
      " 00:00:00" +
      "&";
  }

  if (filterOptions.dateValueEnd) {
    urlDevices +=
      "manufacturingDateEnd=" + filterOptions.dateValueEnd + " 23:59:59" + "&";
  }

  if (filterOptions.familyFilter) {
    urlDevices +=
      !map && !newUpdateDevices
        ? "Family=" + filterOptions.familyFilter + "&"
        : "family=" + filterOptions.familyFilter + "&";
  }

  if (filterOptions.typeFilter) {
    urlDevices += "type=" + filterOptions.typeFilter + "&";
  }

  if (filterOptions.subtypeFilter) {
    urlDevices += "subtype=" + filterOptions.subtypeFilter + "&";
  }

  if (filterOptions.statusFilter) {
    urlDevices += "status=" + filterOptions.statusFilter + "&";
  }

  if (filterOptions.versionHw) {
    urlDevices +=
      "versionHWMin=" +
      filterOptions.versionHw +
      "&versionHWMax=" +
      filterOptions.versionHw +
      "&";
  }

  if (filterOptions.versionSwmin) {
    urlDevices += "versionSWMin=" + filterOptions.versionSwmin + "&";
  }

  if (filterOptions.versionSwmax) {
    urlDevices += "versionSWMax=" + filterOptions.versionSwmax + "&";
  }

  if (filterOptions.buildFilter) {
    urlDevices += "Build=" + filterOptions.buildFilter + "&";
  }

  if (filterOptions.withoutOlFilter) {
    urlDevices += "emptyOrder=true&";
  }

  if (filterOptions.olFilter) {
    urlDevices += "ol=" + filterOptions.olFilter + "&";
  }

  if (filterOptions.iccidFilter) {
    urlDevices += "iccid=" + filterOptions.iccidFilter + "&";
  }

  if (filterOptions.imeiFilter) {
    urlDevices += "imei=" + filterOptions.imeiFilter + "&";
  }

  if (filterOptions.simStatusFilter) {
    urlDevices += "simStatus=" + filterOptions.simStatusFilter + "&";
  }

  if (filterOptions.zoneFilter) {
    urlDevices += "zone=" + filterOptions.zoneFilter + "&";
  }

  if (filterOptions.subzoneFilter) {
    urlDevices += "subzone=" + filterOptions.subzoneFilter + "&";
  }

  if (filterOptions.areaFilter) {
    urlDevices += "area=" + filterOptions.areaFilter + "&";
  }

  if (filterOptions.connectableFilter != null) {
    urlDevices += "connectable=" + filterOptions.connectableFilter + "&";
  }

  if (filterOptions.installationid) {
    urlDevices += "installationid=" + filterOptions.installationid + "&";
  }

  if (filterOptions.trialFilter) {
    urlDevices += "trialAvailable=true&";
  }

  if (filterOptions.deployed) {
    urlDevices += "deployed=" + filterOptions.deployed + "&";
  }

  if (filterOptions.hardwareList) {
    urlDevices +=
      "hardwareList=" + encodeURIComponent(filterOptions.hardwareList) + "&";
  }

  if (filterOptions.firmwareVersionV2) {
    urlDevices +=
      "firmwareVersionV2=" +
      encodeURIComponent(filterOptions.firmwareVersionV2) +
      "&";
  }

  if (filterOptions.coverageFilter) {
    const signal = {
      20: { url: "signalMax=20&" },
      50: { url: "signalMin=21&signalMax=50&" },
      75: { url: "signalMin=51&signalMax=75&" },
      100: { url: "signalMin=76&" },
    };
    urlDevices += signal[filterOptions.coverageFilter].url;
  }

  urlDevices += allDevice ? "allDevices=true&" : "allDevice=false&";

  return urlDevices.slice(0, urlDevices.length - 1);
};

export default {
  getNumberOfDevices: () =>
    Api(true).get(`${DEVICE_CACHE_READER_SERVICE_PATH}/count/devices`, {
      timeout: DEVICE_CACHE_READER_TIMEOUT,
    }),
  getNumberOfDevicesConnected: () =>
    Api(true).get(
      `${DEVICE_CACHE_READER_SERVICE_PATH}/count/connectedDevices`,
      { timeout: DEVICE_CACHE_READER_TIMEOUT }
    ),

  getDevices: (
    filterOptions,
    pagination = null,
    search = null,
    column = null,
    allDevice = true,
    axiosSource = null
  ) => {
    let url = urlToDevices(
      filterOptions,
      pagination,
      search,
      column,
      allDevice,
      false
    );

    return Api(true)
      .get(url, {
        timeout: DEVICE_CACHE_READER_TIMEOUT,
        cancelToken: axiosSource ? axiosSource.token : null,
      })
      .then((response) => {
        response.data.content.forEach(function (item) {
          for (let key in item) {
            if (item[key] === null || item[key] === undefined) {
              item[key] = "";
            }
          }
          let type = i18n.te("device.deviceDetails." + item.type)
            ? i18n.t("device.deviceDetails." + item.type)
            : item.type;
          let subtype = i18n.te("device.deviceDetails." + item.subtype)
            ? i18n.t("device.deviceDetails." + item.subtype)
            : item.subtype;
          item.typeAndSubtype = type + " " + subtype;
          if (!item.fwVersionV2.fwMajor) {
            item.version = item.versionHW + "." + item.versionSW;
          } else {
            item.version =
              item.fwVersionV2.fwMajor +
              "." +
              item.fwVersionV2.fwMinor +
              "." +
              item.fwVersionV2.fwBuild;
          }
        });
        return response;
      });
  },

  getDevicesMap: (
    filterOptions,
    pagination = null,
    search = null,
    column = null,
    allDevice,
    axiosSource = null
  ) => {
    let url = urlToDevices(
      filterOptions,
      pagination,
      search,
      column,
      allDevice,
      true
    );

    return Api(true)
      .get(url, {
        timeout: DEVICE_CACHE_READER_TIMEOUT,
        cancelToken: axiosSource ? axiosSource.token : null,
      })
      .then((response) => {
        response.data.content.forEach(function (item) {
          for (let key in item) {
            if (item[key] === null || item[key] === undefined) {
              item[key] = "";
            }
          }
          let type = i18n.te("device.deviceDetails." + item.t)
            ? i18n.t("device.deviceDetails." + item.t)
            : item.t;
          let subtype = i18n.te("device.deviceDetails." + item.st)
            ? i18n.t("device.deviceDetails." + item.st)
            : item.st;
          item.typeAndSubtype = type + " " + subtype;
          if (!item.fwv2.fwMajor) {
            item.version = item.hw + "." + item.sw;
          } else {
            item.version =
              item.fwv2.fwMajor +
              "." +
              item.fwv2.fwMinor +
              "." +
              item.fwv2.fwBuild;
          }
        });
        return response;
      });
  },

  getDeviceJobs: (filterOptions, allDevice) => {
    const url = urlToDevices(
      filterOptions,
      null,
      null,
      "serialNumber",
      allDevice,
      false,
      true
    );

    return Api(true).get(url, { timeout: DEVICE_CACHE_READER_TIMEOUT });
  },

  getDevicesUpdateFw: (filterOptions, allDevice) => {
    const url = urlToDevices(
      filterOptions,
      null,
      null,
      "serialNumber",
      allDevice,
      false,
      true
    );

    return Api(true).get(url, { timeout: DEVICE_CACHE_READER_TIMEOUT });
  },

  getDeviceTimezone: (deviceId) =>
    Api(true).get(`${DEVICE_ACTION}/${deviceId}/tz`),

  changeDeviceTimezone: (deviceId, timezone) =>
    Api(true).put(
      `${DEVICE_ACTION}/${deviceId}/tz?value=${encodeURIComponent(timezone)}`
    ),

  refreshDevice: (url) =>
    Api(true).get(
      `${DEVICE_CACHE_UPDATER_SERVICE_PATH}/refresh/devices?${url}`
    ),

  refreshDevices: () =>
    Api(true).get(
      `${DEVICE_CACHE_UPDATER_SERVICE_PATH}/refresh/?overwrite=true`
    ),

  restartDevice: (id) => Api(true).post(`${DEVICE_ACTION}/device/${id}/reboot`),

  getDeviceCache: (id) =>
    Api(true).get(`${DEVICE_CACHE_READER_SERVICE_PATH}/devices/${id}`, {
      timeout: DEVICE_CACHE_READER_TIMEOUT,
    }),
  getDevice: (id) => Api(true).get(`${DEVICE_SERVICE_PATH}/devices/${id}`),

  getDevicePairings: (id) =>
    Api(true).get(`${DEVICE_PAIRING_SERVICE_PATH}/pairings/device/${id}`),

  getDeviceSim: (iccid) =>
    Api(true).get(`${DEVICE_SIM_SERVICE_PATH}/sim/${iccid}`),

  executeDeviceSimTest: (iccid) =>
    Api(true).get(`${DEVICE_SIM_SERVICE_PATH}/sim/${iccid}/test`),

  executeDeviceSimChangeState: (iccid, status) =>
    Api(true).post(`${DEVICE_SIM_SERVICE_PATH}/sim/${iccid}/${status}`),

  activateSim: (iccid) =>
    Api(true).post(`${DEVICE_SIM_SERVICE_PATH}/sim/${iccid}/activate`),

  sendProperties: (id, properties) =>
    Api(true).post(`${DEVICE_SERVICE_PATH}/device/${id}/execute`, properties, {
      headers: { "Content-Type": "application/json" },
    }),

  updateState: (id) =>
    Api(true).post(`${DEVICE_ACTION}/device/${id}/updatestate`),

  refreshLocation: (id) =>
    Api(true).post(`${DEVICE_ACTION}/panel/${id}/geo/refresh`),

  pullSubscriptions: (id) =>
    Api(true).post(`${DEVICE_ACTION}/device/${id}/synchronizedatabase`),

  refreshInstallation: (id) =>
    Api(true).post(`${DEVICE_ACTION}/device/${id}/refreshinstallationinfo`),

  deleteDeiceIoT: (id) =>
    Api(true).delete(`${DEVICE_SERVICE_PATH}/iot/clear/${id}?startwith=false`),

  deleteDevicesFromPanel: (id, devicesList) =>
    Api(true).post(`${DEVICE_ACTION}/device/${id}/forgetdevices`, devicesList, {
      headers: { "Content-Type": "application/json" },
    }),

  deleteDevicesIds: (devicesList) =>
    Api(true).post(
      `${INSTALLATION_SERVICE_PATH}/installation/device`,
      devicesList,
      { headers: { "Content-Type": "application/json" } }
    ),

  executeAutoon: (deviceId, payload) =>
    Api(true).post(`${DEVICE_ACTIONV2}/device/${deviceId}/autoon`, payload),

  updateVolume: (id, type, value) =>
    Api(true).put(`${DEVICE_ACTION}/${id}/box/volume/${type}?value=${value}`),
};
