import * as getters from "../taboo/getters";
import * as actions from "../taboo/actions";
import * as mutations from "../taboo/mutations";

const state = {
  blacklist: [],
  countActiveBans: 0,
};

export default ({
  namespaced: true,
  state,
  getters,
  actions,
  mutations
});
