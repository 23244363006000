<template lang="html">
  <Pie
    :options="options"
    :chartData="data"
    v-if="data"
    style="position: relative; width: 100%; height: 100%"
  />
</template>

<!-- eslint-disable @typescript-eslint/ban-types -->
<!-- eslint-disable @typescript-eslint/no-explicit-any -->
<!-- eslint-disable @typescript-eslint/no-non-null-assertion -->
<script lang="ts">
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import Pie from "@/components/Charts/Pie";

@Component({
  components: {
    Pie,
  },
})
export default class PieChart extends Vue {
  @Prop(Array) tooltips!: Array<any>;
  @Prop(Array) value!: Array<any>;

  data: any | null = null;
  total: number | null = null;
  options = {
    responsive: true,
    scaleBeginAtZero: true,
    maintainAspectRatio: false,
    legend: {
      labels: {
        boxWidth: 20,
        fontSize: 12,
      },
      position: "bottom",
    },
    tooltips: {
      callbacks: {
        label: (tooltipItem, data) => " " + data.labels[tooltipItem.index],
      },
    },
  };

  @Watch("value")
  onChangeValue(next, latest) {
    if (next[0] !== latest[0] || next[1] !== latest[1]) this.calculateData();
  }

  @Watch("tooltips")
  onChangeTooltips() {
    this.calculateData();
  }

  mounted() {
    this.calculateData();
  }

  composePieData(values, colors, labels) {
    const labelsWithPercent = this.calculatePieLabels(values, labels);
    return {
      datasets: [
        {
          data: values,
          backgroundColor: colors,
        },
      ],
      labels: labelsWithPercent,
    };
  }

  calculatePieLabels(values, labels) {
    const labelsWithPercent = labels.map((label, index) => {
      // Le aplicamos formato para mostrar los separadore de decimales
      const percent = parseFloat(
        ((100 * Number(values[index])) / this.total!).toFixed(1)
      );
      const formattedNumber = Number(values[index]).toLocaleString();

      return (
        label +
        " " +
        formattedNumber +
        (!isNaN(percent) ? " (" + percent + "%)" : "")
      );
    });
    return labelsWithPercent;
  }

  calculateData() {
    let data: any | null = null;

    if (
      this.value &&
      this.value.length > 0 &&
      this.value[0] !== null &&
      this.value[1] !== null
    ) {
      this.total = 0;
      for (let i = 0; i < this.value.length; i++) this.total += +this.value[i];

      data = this.composePieData(
        this.value,
        ["#4CAF50", "#E0E0E0"],
        this.tooltips
      );
    }

    this.data = data;
  }
}
</script>
