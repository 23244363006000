import { render, staticRenderFns } from "./PlansFilter.html?vue&type=template&id=5585d1b0&scoped=true&lang=html&external"
import script from "./PlansFilter.vue?vue&type=script&lang=ts"
export * from "./PlansFilter.vue?vue&type=script&lang=ts"
import style0 from "./PlansFilter.vue?vue&type=style&index=0&id=5585d1b0&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5585d1b0",
  null
  
)

export default component.exports