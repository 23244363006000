import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

import i18n from '@/lang/i18n';
import moment from 'moment';

export const imageToBase64 = (src, format) => new Promise((resolve, reject) => {
  const image = new Image();
  image.crossOrigin = 'Anonymous';

  image.onload = () => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    canvas.height = image.naturalHeight;
    canvas.width = image.naturalWidth;
    context.drawImage(image, 0, 0);

    const dataURL = canvas.toDataURL('image/' + format);
    resolve(dataURL);
  };

  image.onerror = reject;
  image.src = src;
});

export default (appliedFilters, headers, data_rows, name) => {
  const TABLE_FONT_SIZE = 7;

  const rows = data_rows.map((r, index) => [{
    fontSize: TABLE_FONT_SIZE,
    text: (index+1).toString()
  }, ...r.map(c => {
    if (c.format === 'date' && c.value)
      c.value = c.value.format('DD/MM/YYYY');
    else if (c.format === 'datetime' && c.value)
      c.value = c.value.format('DD/MM/YYYY HH:mm');

    const column = {
      fontSize: TABLE_FONT_SIZE,
      text: (!c.value && c.value !== 0) ? " " : c.value
    };

    if (c.style) {
      const style = c.style;

      if (style.color) column.color = '#' + style.color;
      if (style.background) column.fillColor = '#' + style.background;
      if (style.cursive) column.italics = true;
      if (style.bold) column.bold = true;
    }

    return column;
  })]);

  pdfMake.tableLayouts = {
    tablelistLayout: {
      hLineWidth: () => 0,
      vLineWidth: () => 0,
      hLineColor: () => null,
      paddingLeft: () => 5,
      paddingRight: () => 5,
      paddingTop: () => 5,
      paddingBottom: () => 5,
      fillColor: (i) => {
        return i === 0 ? '#2F75B5' : ((i - 1) % 2 === 0) ? '#eee' : '#fff';
      }
    }
  };

  const appliedFiltersDefinition = [];
  if (appliedFilters.length > 0) {
    const FILTER_COLUMNS = 5;

    let currentColumn = 0;
    const stackFiltersDef = [];
    for (let i = 0; i < FILTER_COLUMNS; i++) stackFiltersDef.push({ stack: [] });

    for (let i = 0; i < appliedFilters.length; i++) {

      const filter = appliedFilters[i];

      stackFiltersDef[currentColumn].stack.push({
        margin: [0, 0, 0, 5], stack: [
          { text: filter.title, bold: true, fontSize: 10 },
          { text: filter.value, fontSize: 8, color: '#000', margin: [0, 5] }
        ]
      });

      currentColumn = (currentColumn < FILTER_COLUMNS - 1) ? currentColumn + 1 : 0;
    }

    appliedFiltersDefinition.push({
      layout: "noBorders", "fillColor": "#ddd", margin: [0, 0, 0, 15], table: {
        widths: [500],
        body: [[
          {
            margin: [10, 10, 0, 0],
            text: i18n.t('general.filtersApplied'),
            color: '#666',
            bold: true
          }], [{
            margin: [10, 10, 10, 10],
            columns: stackFiltersDef
          }]]
      }
    });
  }

  const WIDTH_SCALE = 1.3;
  const PAGE_WIDTH = 845 - 50 - (10 * (data_rows[0].length+1));

  let staticWidth = 20;
  let staticWidthColumns = 1;
  data_rows[0].forEach(element => {
    if (element.width) {
      staticWidth += element.width * WIDTH_SCALE;
      staticWidthColumns++;
    }
  });

  const percentWidth = (PAGE_WIDTH - staticWidth) / ((data_rows[0].length+1) - staticWidthColumns);

  const generatePdf = () => {
    const docDefinition = {
      pageOrientation: 'landscape',
      pageMargins: [25, 50, 25, 50],
      styles: {
        header: {
          fontSize: 12,
          margin: [0, -30, 0, 10]
        }
      },
      content: [
        {
          stack: [
            {
              columns: [
                { image: maviLogoImg, width: 50 },
                { text: name, fontSize: 14, margin: [5, 10, 0, 0] },
                { text: moment().format('DD/MM/YYYY'), alignment: 'right', color: '#bbb', fontSize: 10, margin: [0, 15, 0, 0] },
              ]
            }
          ],
          style: 'header'
        },
        ...appliedFiltersDefinition,
        {
          layout: 'tablelistLayout',
          table: {
            headerRows: 1,
            dontBreakRows: true,
            widths: [20, ...data_rows[0].map(cd => cd.width ? cd.width * WIDTH_SCALE : percentWidth)],
            body: [[{ text: '#', fontSize: TABLE_FONT_SIZE, color: '#fff' }, ...headers[0].map(h => ({ text: h, fontSize: TABLE_FONT_SIZE, color: '#fff' }))], ...rows]
          }
        }],
      footer: function (currentPage, pageCount) {
        return {
          text:
            `${i18n.t('general.messages.page')} ${currentPage.toString()} ${i18n.t('general.messages.conjunctionOf')} ${pageCount}`, margin: [25, 10, 0, 0], fontSize: 10
        };
      }
    };

    const pdfName = name.toLowerCase().replace(/ /g, '-') + '.pdf';

    pdfMake.createPdf(docDefinition).download(pdfName);
  }

  let maviLogoImg = '';
  imageToBase64(process.env.VUE_APP_PUBLIC_PATH + 'assets/blue.png', 'png').then(res => {
    maviLogoImg = res;

    generatePdf();
  });
};