import Api from "./index";

const SERVICE_PATH = "/rexistro/api/v1";

const EVENTS_PAGE_SIZE = 5;

const TIME = "time,desc"

export default {
  getEventHistory: (filterBy, page) => Api(true).get(`${SERVICE_PATH}/events?${filterBy}&page=${page}&size=${EVENTS_PAGE_SIZE}&sort=${TIME}`)
};
