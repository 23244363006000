import * as getters from "../jobs/getters";
import * as actions from "../jobs/actions";
import * as mutations from "../jobs/mutations";

const state = {
  // State for Jobs component
  jobsList: [],
  jobsInfo: {
    totalJobs: 0,
    ongoingJobs: 0,
    statusArray: [],
    jobNames: []
  },
  devicesListForJob: [],
  selectedDevicesForJob: [],
  devicesWithJob: [],
  lastJobFromDevice: null,
  jobInvolvedOnMyDevice: 0,
  numberDevicesForJob: 0,

  // State for JobDetails component
  oneJobData: {
    status: null,
    firmwareDto: {}
  },
  oneJobInfo: {
    numSuccessDevices: 0,
    numFailedDevices: 0,
    numCancelledDevices: 0,
    numRunningDevices: 0
  },
  numberOfJobs: 0,
  numberOfInProgressJobs: 0
};

export default ({
  namespaced: true,
  state,
  getters,
  actions,
  mutations
});