export default {
  getRoles: () =>
    new Promise((resolve) =>
      setTimeout(
        () =>
          resolve([
            {
              rol: "ROLE_ADMIN",
              details: [
                {
                  access: "TRUE",
                  permission: "R",
                },
                {
                  access: "TRUE",
                  permission: "E",
                },
                {
                  access: "TRUE",
                  permission: "E",
                },
                {
                  access: "TRUE",
                  permission: "E",
                },
                {
                  access: "TRUE",
                  permission: "E",
                },
                {
                  access: "TRUE",
                  permission: "E",
                },
                {
                  access: "TRUE",
                  permission: "E",
                },
                {
                  access: "TRUE",
                  permission: "E",
                },
                {
                  access: "TRUE",
                  permission: "E",
                },
                {
                  access: "TRUE",
                  permission: "E",
                },
                {
                  access: "TRUE",
                  permission: "R",
                },
                {
                  access: "TRUE",
                  permission: "R",
                },
              ],
            },
            {
              rol: "ROLE_MANAGEMENT",
              details: [
                {
                  access: "TRUE",
                  permission: "R",
                },
                {
                  access: "TRUE",
                  permission: "R",
                },
                {
                  access: "TRUE",
                  permission: "R",
                },
                {
                  access: "TRUE",
                  permission: "R",
                },
                {
                  access: "TRUE",
                  permission: "R",
                },
                {
                  access: "TRUE",
                  permission: "R",
                },
                {
                  access: "TRUE",
                  permission: "R",
                },
                {
                  access: "FALSE",
                  permission: "N",
                },
                {
                  access: "FALSE",
                  permission: "N",
                },
                {
                  access: "FALSE",
                  permission: "N",
                },
                {
                  access: "FALSE",
                  permission: "N",
                },
                {
                  access: "FALSE",
                  permission: "N",
                },
              ],
            },
            {
              rol: "ROLE_MANAGER",
              details: [
                {
                  access: "TRUE",
                  permission: "R",
                },
                {
                  access: "TRUE",
                  permission: "E",
                },
                {
                  access: "TRUE",
                  permission: "E",
                },
                {
                  access: "TRUE",
                  permission: "E",
                },
                {
                  access: "TRUE",
                  permission: "E",
                },
                {
                  access: "TRUE",
                  permission: "E",
                },
                {
                  access: "TRUE",
                  permission: "E",
                },
                {
                  access: "FALSE",
                  permission: "N",
                },
                {
                  access: "TRUE",
                  permission: "E",
                },
                {
                  access: "FALSE",
                  permission: "N",
                },
                {
                  access: "FALSE",
                  permission: "N",
                },
                {
                  access: "FALSE",
                  permission: "N",
                },
              ],
            },
            {
              rol: "ROLE_SPANISH_OUTLIER",
              details: [
                {
                  access: "TRUE",
                  permission: "R",
                },
                {
                  access: "TRUE",
                  permission: "R",
                },
                {
                  access: "TRUE",
                  permission: "E",
                },
                {
                  access: "TRUE",
                  permission: "E",
                },
                {
                  access: "TRUE",
                  permission: "R",
                },
                {
                  access: "FALSE",
                  permission: "N",
                },
                {
                  access: "FALSE",
                  permission: "N",
                },
                {
                  access: "FALSE",
                  permission: "N",
                },
                {
                  access: "FALSE",
                  permission: "N",
                },
                {
                  access: "FALSE",
                  permission: "N",
                },
                {
                  access: "FALSE",
                  permission: "N",
                },
                {
                  access: "FALSE",
                  permission: "N",
                },
              ],
            },
            {
              rol: "ROLE_INTL_OUTLIER",
              details: [
                {
                  access: "TRUE",
                  permission: "R",
                },
                {
                  access: "FALSE",
                  permission: "N",
                },
                {
                  access: "FALSE",
                  permission: "N",
                },
                {
                  access: "TRUE",
                  permission: "E",
                },
                {
                  access: "TRUE",
                  permission: "R",
                },
                {
                  access: "FALSE",
                  permission: "N",
                },
                {
                  access: "FALSE",
                  permission: "N",
                },
                {
                  access: "FALSE",
                  permission: "N",
                },
                {
                  access: "FALSE",
                  permission: "N",
                },
                {
                  access: "FALSE",
                  permission: "N",
                },
                {
                  access: "FALSE",
                  permission: "N",
                },
                {
                  access: "FALSE",
                  permission: "N",
                },
              ],
            },
            {
              rol: "ROLE_MANUFACTURER",
              details: [
                {
                  access: "FALSE",
                  permission: "N",
                },
                {
                  access: "FALSE",
                  permission: "N",
                },
                {
                  access: "FALSE",
                  permission: "N",
                },
                {
                  access: "FALSE",
                  permission: "N",
                },
                {
                  access: "FALSE",
                  permission: "N",
                },
                {
                  access: "FALSE",
                  permission: "N",
                },
                {
                  access: "FALSE",
                  permission: "N",
                },
                {
                  access: "FALSE",
                  permission: "N",
                },
                {
                  access: "FALSE",
                  permission: "N",
                },
                {
                  access: "FALSE",
                  permission: "N",
                },
                {
                  access: "TRUE",
                  permission: "E",
                },
                {
                  access: "FALSE",
                  permission: "N",
                },
              ],
            },
            {
              rol: "ROLE_DEVICE",
              details: [
                {
                  access: "FALSE",
                  permission: "N",
                },
                {
                  access: "FALSE",
                  permission: "N",
                },
                {
                  access: "FALSE",
                  permission: "N",
                },
                {
                  access: "FALSE",
                  permission: "N",
                },
                {
                  access: "FALSE",
                  permission: "N",
                },
                {
                  access: "FALSE",
                  permission: "N",
                },
                {
                  access: "FALSE",
                  permission: "N",
                },
                {
                  access: "FALSE",
                  permission: "N",
                },
                {
                  access: "FALSE",
                  permission: "N",
                },
                {
                  access: "FALSE",
                  permission: "N",
                },
                {
                  access: "FALSE",
                  permission: "N",
                },
                {
                  access: "TRUE",
                  permission: "E",
                },
              ],
            },
            {
              rol: "ROLE_THIRDPARTY",
              details: [
                {
                  access: "FALSE",
                  permission: "N",
                },
                {
                  access: "FALSE",
                  permission: "N",
                },
                {
                  access: "FALSE",
                  permission: "N",
                },
                {
                  access: "FALSE",
                  permission: "N",
                },
                {
                  access: "FALSE",
                  permission: "N",
                },
                {
                  access: "FALSE",
                  permission: "N",
                },
                {
                  access: "FALSE",
                  permission: "N",
                },
                {
                  access: "FALSE",
                  permission: "N",
                },
                {
                  access: "FALSE",
                  permission: "N",
                },
                {
                  access: "FALSE",
                  permission: "N",
                },
                {
                  access: "FALSE",
                  permission: "N",
                },
                {
                  access: "TRUE",
                  permission: "E",
                },
              ],
            },
            {
              rol: "ROLE_USER",
              details: [
                {
                  access: "FALSE",
                  permission: "N",
                },
                {
                  access: "FALSE",
                  permission: "N",
                },
                {
                  access: "FALSE",
                  permission: "N",
                },
                {
                  access: "FALSE",
                  permission: "N",
                },
                {
                  access: "FALSE",
                  permission: "N",
                },
                {
                  access: "FALSE",
                  permission: "N",
                },
                {
                  access: "FALSE",
                  permission: "N",
                },
                {
                  access: "FALSE",
                  permission: "N",
                },
                {
                  access: "FALSE",
                  permission: "N",
                },
                {
                  access: "FALSE",
                  permission: "N",
                },
                {
                  access: "FALSE",
                  permission: "N",
                },
                {
                  access: "TRUE",
                  permission: "E",
                },
              ],
            },
            {
              rol: "ROLE_OPENDIT",
              details: [
                {
                  access: "FALSE",
                  permission: "N",
                },
                {
                  access: "FALSE",
                  permission: "N",
                },
                {
                  access: "TRUE",
                  permission: "E",
                },
                {
                  access: "TRUE",
                  permission: "E",
                },
                {
                  access: "FALSE",
                  permission: "N",
                },
                {
                  access: "FALSE",
                  permission: "N",
                },
                {
                  access: "FALSE",
                  permission: "N",
                },
                {
                  access: "FALSE",
                  permission: "N",
                },
                {
                  access: "FALSE",
                  permission: "N",
                },
                {
                  access: "FALSE",
                  permission: "N",
                },
                {
                  access: "FALSE",
                  permission: "N",
                },
                {
                  access: "FALSE",
                  permission: "N",
                },
              ],
            },
          ]),
        500
      )
    ),
};
