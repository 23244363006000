<template lang="html" src="./DeviceList.html"></template>

<!-- eslint-disable @typescript-eslint/no-explicit-any -->
<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator";
import { goTo } from "@/router";

export const MESSAGE_TYPE = {
  SUCCESS: "SUCCESS",
  SCHEDULED: "SCHEDULED",
};

@Component
export default class JobDetailsDeviceList extends Vue {
  @Prop(Object) items!: any;
  @Prop(String) icon!: string;
  @Prop(String) statusFilter!: string;

  showInfoDialog = false;
  deviceResultInfo = {
    status: "",
    description: "",
  };

  get filteredItems() {
    const res = {};

    for (const itemKey of Object.keys(this.items)) {
      const itemValue = this.items[itemKey];

      if (!this.statusFilter || itemValue.summaryStatus === this.statusFilter)
        res[itemKey] = itemValue;
    }

    return res;
  }

  isWarning(item) {
    return item.summaryStatus === MESSAGE_TYPE.SUCCESS && item.warning === true;
  }

  viewDeviceDetails(deviceId) {
    goTo({
      name: "DeviceDetails",
      params: { deviceId: deviceId },
    });
  }

  infoDialog(item, name) {
    this.showInfoDialog = true;
    this.deviceResultInfo = { name, ...item };
    if (this.deviceResultInfo.status === MESSAGE_TYPE.SCHEDULED) {
      let texto = this.deviceResultInfo.description.split(" ");
      /*  texto[0] !== 'Upgrade' ? this.deviceResultInfo.description = this.secondsToString(texto[0]) + texto[2]   : 
        this.deviceResultInfo.description = this.secondsToString(texto[3]) + texto[0] + " " + texto[1]           */
      texto[0] !== "Upgrade"
        ? (this.deviceResultInfo.description = texto[2])
        : (this.deviceResultInfo.description = texto[0] + " " + texto[1]);
    }
  }

  secondsToString(seconds) {
    let hour: any = Math.floor(seconds / 3600);
    hour = hour < 10 ? "0" + hour : hour;
    let minute: any = Math.floor((seconds / 60) % 60);
    minute = minute < 10 ? "0" + minute : minute;
    //let second:any = seconds % 60;
    //second = (second < 10)? '0' + second : second;

    return hour + this.$t("jobs.hours") + minute + this.$t("jobs.minutes");
  }
}
</script>
