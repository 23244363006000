import i18n from "@/lang/i18n";
import moment from "moment";

export const PERIOD_TYPES = {
  "DAILY": 0.01,
  "WEEKLY": 0.07,
  "BIWEEKLY": 0.14,
  "THIRTY_DAYS": 1,
  "SIXTY_DAYS": 2,
  "NINETY_DAYS": 3,
  "MONTHLY": 1,
  "BIMESTRIAL": 2,
  "QUARTERLY": 3,
  "TRIANNUAL": 4,
  "BIANNUAL": 6,
  "ANNUAL": 12,
  "BIENNIAL": 24,
  "NO_BILLING_PERIOD": 0
};

export const getCardIcon = (cardType) => {
  let library = 'fab';
  let icon = 'cc-' + cardType;

  if (cardType === 'mc')
    icon = 'cc-mastercard';
  else if (cardType === 'maestro')
    icon = 'cc-amex';

  return library + " fa-" + icon;
};

export const isCardExpired = (month, year) => {
  const currentDate = moment();

  return (
    (month < currentDate.month()+1 && year == currentDate.year()) ||
    year < currentDate.year()
  );
}

export const getPeriodText = (q) => {

  let unit = null;
  let quantity = PERIOD_TYPES[q];
  let residueText = "";

  const getText = (un, qu) => i18n.tc("general." + un, qu);
  if(quantity==0){
    unit = "forever";
  } else if (quantity < 1) {
    quantity *= 100;
    unit = "days";

    if (quantity > 7) {
      quantity =Math.round(quantity/ 7);
      unit = "weeks";
    }

  } else if (quantity < 12) {
    unit = "months";
  } else {
    unit = "years";
    const residue = quantity % 12;
    quantity /= 12;

    if (residue > 0)
      residueText += " " + getText("months", residue);
  }

  return getText(unit, quantity) + residueText;
}


export const getUserPlanServices = (services, commercialServices) => services.filter(s => commercialServices.find(commercial => (commercial.name==s && commercial.commercial)) != undefined);