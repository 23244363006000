<template lang="html" src="./InstallationSummary.html"></template>

<!-- eslint-disable @typescript-eslint/ban-types -->
<!-- eslint-disable @typescript-eslint/no-explicit-any -->
<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator";
import AppDeviceIcon from "@/components/AppDeviceIcon.vue";
import { translateText, translatePlural } from "@/lang/i18n";
import MonitorVEOXSIcon from "@/ui/icons/devices/MonitorVEOXSIcon.vue";
import MonitorVEOXSWIFIIcon from "@/ui/icons/devices/MonitorVEOXSWIFIIcon.vue";

@Component({
  components: {
    AppDeviceIcon,
    MonitorVEOXSIcon,
    MonitorVEOXSWIFIIcon,
  },
})
export default class InstallationSummary extends Vue {
  @Prop(Object) value!: any;
  t = translateText;
  tc = translatePlural;

  isPanel() {
    return (
      this.value?.deviceMaster?.family == "PANEL" &&
      this.value?.deviceMaster?.subtype == "4G"
    );
  }

  isEdibox() {
    return this.value?.deviceMaster?.family == "EDIBOX";
  }

  getPanelTooltip() {
    const n = this.value.panels;
    return n + " " + this.tc("installationDetails.device.panel", n);
  }
  getEdiboxTooltip() {
    const n = this.value.ediboxes;
    return n + " " + this.tc("installationDetails.device.edibox", n);
  }
  getMonitorNoWifiTooltip() {
    const n = this.value.nonConnectableMonitors;
    return n + " " + this.tc("installationDetails.device.monitorNoWifi", n);
  }
  getMonitorWifiTooltip() {
    const n = this.value.connectableMonitors;
    return n + " " + this.tc("installationDetails.device.monitorWifi", n);
  }
  getPhoneTooltip() {
    const n = this.value.phones;
    return n + " " + this.tc("installationDetails.device.phone", n);
  }
  getGuardUnitTooltip() {
    const n = this.value.gu;
    return n + " " + this.tc("installationDetails.device.guardUnit", n);
  }
}
</script>
<style lang="scss">
.installation-summary {
  min-width: initial;
  font-size: 14px;
  align-items: baseline;
  color: #2b3f6c;
  display: flex;
  justify-content: space-evenly;
  .device {
    display: flex;
    align-content: flex-end;
    align-items: baseline;
    .svgIcon {
      margin-right: 4px;
    }
    padding-right: 6px;
  }
  .panelIcon {
    width: auto;
    height: 30px;
  }
  .monitorXSIcon {
    width: auto;
    height: 20px;
  }
  .monitorVEOXSWIFIIcon {
    width: auto;
    height: 22px;
  }
  .phoneIcon {
    width: auto;
    height: 22px;
    margin-bottom: -3px;
  }
  .guardUnitIcon {
    width: auto;
    height: 20px;
  }
}
</style>
