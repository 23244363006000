<template lang="html" src="./SubscriptionEvents.html"></template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import HttpStatus from "http-status-codes";

import { displayErrorMessage } from "@/api";
import dateMixin from "@/mixins/date.mixin";
import planMixin from "@/mixins/plan.mixin";

import accountService, {
  TRIAL_END_REASON
} from "@/api/account";

const EVENTS_PAGE_SIZE = 3;

@Component({
  mixins: [dateMixin, planMixin]
})
export default class SubscriptionEvents extends Vue {
  @Prop(Number) userId!: number;
  
  showEventDetailsObj = null;
  showAll = false;
  isLoading = true;
  events = [];

  get eventsTotalSize() {
    return this.events.length;
  }

  get eventsToShow() {
    return this.showAll
      ? this.events
      : this.events.slice(0, EVENTS_PAGE_SIZE);
  }

  get showEventDetails() {
    return this.showEventDetailsObj !== null;
  }

  set showEventDetails(v) {
    this.showEventDetailsObj = null;
  }

  async mounted() {
    await this.load();
    this.isLoading = false;
  }
  
  loadEvents() {
    return accountService
      .getSubscriptionsHistory(this.userId)
      .then(res => (this.events = res))
      .catch(err => {
        if (err.response.status === HttpStatus.NOT_FOUND) this.events = [];
        else {
          displayErrorMessage(err, {
            general: ["userdetails.subscriptionEvent.error"]
          });
          throw err;
        }
      });
  }

  async load() {
    this.isLoading = true;
    this.events = [];
    
    await this.loadEvents();
  }

  getResume(event) {
    let result = "";

    const planName = event.commercialName;

    if (event.eventType === "START_ENTITLEMENT")
      result = String(this.$t("userdetails.subscriptionEvent.resumes.contracted", [
        planName
      ]));
    else if (event.eventType === "CANCELLATION_REQUESTED")
      result = String(this.$t(
        "userdetails.subscriptionEvent.resumes.cancellation_requested",
        [planName]
      ));
    else if (event.eventType === "CANCELED")
      result = String(this.$t("userdetails.subscriptionEvent.resumes.canceled", [
        planName
      ]));
    else if (event.eventType === "RESUME_BILLING")
      result = String(this.$t("userdetails.subscriptionEvent.resumes.renewed", [
        planName
      ]));
    else if (event.eventType === "TRIAL_END")
      result = String(this.$t("userdetails.subscriptionEvent.resumes.trial_end", [
        planName
      ]));
    else if (event.eventType === "CHANGE")
      result = String(this.$t("userdetails.subscriptionEvent.resumes.upgrade", [
        planName
      ]));

    return result;
  }

  hasReason(event) {
    return (
      (event.cancellationReasonSubDTO === null ||
        event.cancellationReasonSubDTO.reason !== TRIAL_END_REASON) &&
      (event.eventType === "CANCELLATION_REQUESTED" ||
        event.eventType === "CANCELED")
    );
  }

  getReason(event) {
    let reason = "";

    if (this.hasReason(event) && event.cancellationReasonSubDTO !== null)
      reason = String(this.$t(
        "userdetails.subscriptionInfo.cancelConfirm.reasons." +
          event.cancellationReasonSubDTO.reason
      ));

    return reason;
  }

  showInfo(event) {
    this.showEventDetailsObj = event;
  }
}
</script>
<style scoped>
.v-list {
  border: 1px solid #ccc;
  background: #fbf8f8;
  max-height: 225px;
  overflow-y: scroll;
  padding: 0;
}

div[role="listitem"]:not(:last-child) {
  border-bottom: 1px solid #ddd;
}
.date-label {
  display: block;
  color: #000;
}
.device-label {
  display: block;
  font-size: 12px;
}
</style>