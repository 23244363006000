<template lang="html" src="./DeviceFiltering.html"></template>

<!-- eslint-disable @typescript-eslint/no-explicit-any -->
<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator";
import { displayErrorMessage } from "@/api";
import geoService from "@/api/geo";
import { SIZE_MASSIVE_JOB } from "@/api/job";

import AppSelect from "@/components/AppSelect.vue";

@Component({
  components: {
    AppSelect,
  },
})
export default class DeviceFiltering extends Vue {
  $moment!: any;
  @Prop(Array) value!: Array<any>;
  @Prop(Object) devicesTypeInfo!: any;
  @Prop(Boolean) deferredStart!: boolean;
  @Prop(String) dateToUpdate!: string;
  @Prop(Array) installation!: Array<any>;
  @Prop(String) installationId!: string;
  @Prop(Boolean) allDevice!: boolean;

  isLoading = false;
  installationDevicesWithJobOn = {};

  isLoadingAreas: any = false;
  zones = null;
  subzones = null;
  areas = null;

  filters: any = {
    olString: null,
    zone: undefined,
    subzone: undefined,
    area: undefined,
    installationId: null,
    swVersionMin: null,
    swVersionMax: null,
  };
  showDevicesList = false;

  numberDevicesKey = 0;

  get localValue() {
    return this.value;
  }

  set localValue(v) {
    this.$emit("input", v);
  }

  get isFiltering() {
    return this.isLoading;
  }

  set isFiltering(v) {
    this.isLoading = v;
    this.$emit("loading", v);
  }

  get devicesWithJob() {
    return this.$store.getters["jobs/devicesWithJob"];
  }

  get filteredDevicesForJob() {
    return this.$store.getters["jobs/selectedDevicesForJob"];
  }

  get numberOfDevices() {
    let devices = this.$store.getters["jobs/numberDevicesForJob"];
    if (devices <= SIZE_MASSIVE_JOB) {
      return this.value.length;
    } else {
      return devices;
    }
  }

  get isMassiveJob() {
    if (this.$store.getters["jobs/numberDevicesForJob"] <= SIZE_MASSIVE_JOB) {
      return false;
    } else {
      return true;
    }
  }

  get timeLastUpdate() {
    let numberOfBlocks = Math.floor(this.numberOfDevices / 200) * 20;
    return this.$moment(this.dateToUpdate)
      .add(numberOfBlocks, "minute")
      .utc()
      .format("HH:mm");
  }

  get fielNewFormatFW() {
    return [
      (v) =>
        v === null ||
        v === "" ||
        v === undefined ||
        (/^(\d{2}\.\d{2})$/.test(v) &&
          parseFloat(v) >= 0 &&
          parseFloat(v) <= 99.99)
          ? true
          : this.$t("general.fildNumbers"),
    ];
  }

  mounted() {
    this.loadZones();
  }

  emitChange() {
    this.$emit("change", { ...this.filters });
  }

  loadZones() {
    this.isLoadingAreas = 1;

    geoService
      .getZonesCombo()
      .then((res) => {
        this.zones = res.data;

        this.isLoadingAreas = null;
      })
      .catch((error) => {
        displayErrorMessage(error, { general: ["device.error.getError"] });
      });
  }

  getAllowedDevicesToUpdate(firmwareDevices) {
    return firmwareDevices.filter((d) => !this.filteredDevicesForJob[d]);
  }

  selectUnselectAllDevices(firmwareDevices) {
    let isAllSelected = true;
    let i = 0;
    while (i < firmwareDevices.length && isAllSelected) {
      this.localValue = [] as any;
      if (!(this.localValue as any).find((d) => firmwareDevices[i] === d))
        isAllSelected = false;
      i++;
    }

    if (!isAllSelected)
      this.localValue = [
        ...this.getAllowedDevicesToUpdate(firmwareDevices).map((d) => d),
      ] as any;
  }

  loadSubzones(zone) {
    this.subzones = null;
    this.filters.subzone = undefined;
    this.areas = null;
    this.filters.area = undefined;

    if (zone) {
      this.isLoadingAreas = 2;

      geoService
        .getSubzonesCombo(zone.id)
        .then((res) => {
          this.subzones = res.data;

          this.isLoadingAreas = null;
        })
        .catch((error) => {
          displayErrorMessage(error, { general: ["device.error.getError"] });
        });
    }
  }

  loadAreas(subzone) {
    this.areas = null;
    this.filters.area = undefined;

    if (subzone) {
      this.isLoadingAreas = 3;

      geoService
        .getAreasCombo(subzone.id)
        .then((res) => {
          this.areas = res.data;

          this.isLoadingAreas = null;
        })
        .catch((error) => {
          displayErrorMessage(error, { general: ["device.error.getError"] });
        });
    }
  }

  checkDisabled(serialNum) {
    return this.devicesWithJob.includes(serialNum);
  }

  filter() {
    this.isFiltering = true;

    this.$store
      .dispatch("jobs/filterListDevicesJob", {
        device: {
          familyFilter: this.devicesTypeInfo.family,
          typeFilter: this.devicesTypeInfo.type,
          subtypeFilter: this.devicesTypeInfo.subtype,
          //versionHw: this.devicesTypeInfo.versionHW,
          statusFilter: this.devicesTypeInfo.status,
          deployed: true,
          olFilter: this.filters.olString,
          zoneFilter: this.filters.zone ? this.filters.zone.name : null,
          subzoneFilter: this.filters.subzone
            ? this.filters.subzone.name
            : null,
          areaFilter: this.filters.area ? this.filters.area.name : null,
          installationid:
            this.installationId || this.filters.installationId || null,
          versionSwmin: this.filters.swVersionMin,
          versionSwmax: this.filters.swVersionMax,
          firmwareVersionV2: this.devicesTypeInfo.firmwareVersionV2,
          hardwareList: this.devicesTypeInfo.hardwareList,
        },
        allDevice: this.allDevice,
      })
      .then(() => {
        this.isFiltering = false;

        const selectedDevices: any = [];
        this.filteredDevicesForJob.forEach((element) => {
          if (!this.checkDisabled(element)) selectedDevices.push(element);
        });

        this.localValue = selectedDevices;
        this.emitChange();
        this.numberDevicesKey++;
      })
      .catch(() => (this.isFiltering = false));
  }
}
</script>
