import { displayErrorMessage } from "@/api";

import AppClient from "@/api/roles";  

export const getRoles = ({ commit }) => new Promise((resolve, reject) => {    
    AppClient.getRoles().then(res => {
      const rolesList = res;

      commit('setRolesList', rolesList);
      resolve(rolesList);
    }).catch(err => {
      displayErrorMessage("user.error.getError", err);
  
      reject(err);
    });
  });

export const getRolesPermission = ({ commit }) => new Promise((resolve, reject) => {
    AppClient.getRolesPermission().then(res => {
      const rolesPermission = res;

      commit('setRolesPermission', rolesPermission);
      resolve(rolesPermission);
    }).catch(err => {
      displayErrorMessage("user.error.getError", err);
  
      reject(err);
    });
  });

  