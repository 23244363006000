<template lang="html" src="./StatusChip.html"></template>

<script lang="ts">
export const getStatusChipText = (item) => {
  let status = "";
  
  if(item.deployed === true && item.status === true ||
    item.d === true && item.sts === true){
    status = 'online';
  }else if(item.deployed === true && item.status === false || 
            item.d === true && item.sts === false){
    status = 'offline'
  }else{
    status = 'manufactured';
  }

  return status;
}

export default {
  name: "StatusChip",

  props: {
    status: Boolean,
    deployed: Boolean
  },

};
</script>