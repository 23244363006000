import { displayErrorMessage } from "@/api";
import deviceService from "@/api/device";
import userService from "@/api/user";

export const getOneDeviceCacheData = ({ commit }, payload) => new Promise((resolve, reject) => {
  deviceService.getDeviceCache(payload.deviceId).then(response => {
    commit('setOneDeviceCacheData', response.data);
    
    response.data.version = response.data.fwVersionV2?.fwMajor + "." + response.data.fwVersionV2?.fwMinor + "." + response.data.fwVersionV2?.fwBuild
    
    resolve(response.data);
  }).catch(error => {
    displayErrorMessage(error, { general: ["device.error.getError"], notFound: ["deviceDetails.error.notFound", [payload.deviceId]]});
    reject(error);
  });
});

export const getOneDeviceData = ({ commit }, payload) => new Promise((resolve, reject) => {

  
  deviceService.getDevice(payload.deviceId).then(response => { 
    commit('setOneDeviceData', response.data);
    resolve(response.data);
  }).catch(error => {
    displayErrorMessage(error, { general: ["device.error.getError"] });
    reject(error);
  });
});

export const getOneDevicePairings = ({ commit }, payload) => new Promise((resolve, reject) => {
  deviceService.getDevicePairings(payload.deviceId).then(pairingResponse => {
    const devicePairings = pairingResponse.data;
    const devicePairingsResult = [];
    // In case the second get is not called
    if (devicePairings.length === 0)
      resolve(null);

    devicePairings.forEach(pairing => {
      userService.getUser(pairing.userId).then(userResponse => {
        const userInfo = userResponse.data;
        const pairingAndUser = Object.assign({}, userInfo, pairing);
        devicePairingsResult.push(pairingAndUser);
        devicePairingsResult.sort((x, y) => {
          return y.master - x.master;
        });
        
        commit('setOneDevicePairings', devicePairingsResult)

        resolve(devicePairingsResult);

      }).catch(error => {
        displayErrorMessage(error, { general: ["device.error.getError"] });
        reject(error);
      }
      )
    });
  }).catch(error => {
    displayErrorMessage(error, { general: ["device.error.getError"] });
    reject(error);
  });
});

export const getOneDeviceSimData = ({ commit }, payload) => new Promise((resolve, reject) => {

  deviceService.getDeviceSim(payload.iccid).then(response => {
    commit('setOneDeviceSim', response.data);
    resolve(response.data);
  }).catch(error => {
    displayErrorMessage(error, { general: ["device.error.getError"] });
    reject(error);
  });
});