<template lang="html" src="./PopupTableMap.html"></template>

<!-- eslint-disable @typescript-eslint/ban-types -->
<!-- eslint-disable @typescript-eslint/no-explicit-any -->
<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class PopupTableMap extends Vue {
  @Prop(String) markerName!: string;
  @Prop({type: Object}) marker!: any;
}
</script>