export const jobsList = state => state.jobsList;
export const jobsInfo = state => state.jobsInfo;

export const devicesListForJob = state => state.devicesListForJob;
export const selectedDevicesForJob = state => state.selectedDevicesForJob;
export const devicesWithJob = state => state.devicesWithJob;
export const jobInvolvedOnMyDevice = state => state.jobInvolvedOnMyDevice;
export const numberDevicesForJob = state => state.numberDevicesForJob;

export const oneJobData = state => state.oneJobData;
export const oneJobInfo = state => state.oneJobInfo;

export const lastJobFromDevice = state => state.lastJobFromDevice;

export const numberOfJobs = state => state.numberOfJobs;
export const numberOfInProgressJobs = state => state.numberOfInProgressJobs;