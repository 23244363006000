interface JobEntityInterface {
  status: string;
}
export default class JobEntity implements JobEntityInterface {
  status: string;
  constructor(job: JobEntityInterface) {
    this.status = job.status;
  }

  isWaiting() {
    return this.status === "WAITING";
  }
  isInProgress() {
    return this.status === "INPROGRESS";
  }
  isCompleted() {
    return this.status === "COMPLETED";
  }
  isCancelled() {
    return this.status === "CANCELLED";
  }
  isCancelling() {
    return this.status === "CANCELLING";
  }
  isFailed() {
    return this.status === "FAILED";
  }
  isScheduled() {
    return this.status === "SCHEDULED";
  }
  isWarning() {
    return this.status === "WARNING";
  }
}
