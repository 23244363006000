/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from "moment";

import Vue from "vue";

import exportToPdf from "./exporters/pdf.exporter";
import exportToExcel from "./exporters/excel.exporter";

export const EXPORT_TYPE = {
  PDF: "pdf",
  EXCEL: "excel",
};

export const PREDEFINED_STYLES = {
  SUCCESS: {
    background: "C6EFCE",
    color: "006100",
  },
  FAILED: {
    background: "FFC7CE",
    color: "9C0006",
  },
  WARNING: {
    background: "FFEB9C",
    color: "9C5700",
  },
  WAITING: {
    background: "D9E1F2",
  },
  GREY: {
    background: "EDEDED",
  },
  DARK_GREY: {
    background: "C9C9C9",
  },
};

const rowsToData = (data, definitions) => {
  const rowsData : any[]=[];

  for (const row of data) {
    const columnsData:any[]=[];

    for (const column of definitions) {
      column.export = column.export ? column.export : {};

      if ((column.value || column.export.value) && !column.export.hide) {
        const value = {
          value: "",
          format: column.export.format || "string",
          width: column.export.width,
          style:null
        };

        if (column.export.value) {
          value.value = column.export.value(row);
        } else {
          let val = row;

          for (const key of column.value.split(".")) val = val[key];

          value.value = val;
        }

        if (value.format === "date" || value.format === "datetime") {
          let mom;

          if (value.value) {
            if (column.export.unix) mom = moment.unix(Number(value.value));
            else mom = moment(value.value);
          }

          value.value = mom && mom.isValid() ? mom : null;
        }

        if (column.export.style) {
          let style = column.export.style;

          if (typeof style === "function") style = style(row);

          value.style = style;
        }

        columnsData.push(value);
      }
    }

    rowsData.push(columnsData);
  }

  return rowsData;
};

export default class extends Vue {

  exportTableData(appliedFilters, definitions, data, exportOptions) {
    const headers = [
      definitions
        .filter(
          (d) =>
            !!d.text &&
            (!!d.value || (!!d.export && !!d.export.value)) &&
            (!d.export || !d.export.hide)
        )
        .map((d) => d.text),
    ];
    const data_rows = rowsToData(data, definitions);

    switch (exportOptions.type) {
      case EXPORT_TYPE.PDF:
        exportToPdf(appliedFilters, headers, data_rows, exportOptions.name);
        break;
      case EXPORT_TYPE.EXCEL:
        exportToExcel(appliedFilters, headers, data_rows, exportOptions.name);
        break;
      default:
        console.error("UNSUPPORTED EXPORT TYPE!!");
        return;
    }
  }
}

// export default {
//   methods: {
//     exportTableData(appliedFilters, definitions, data, exportOptions) {
//       const headers = [definitions.filter(d => !!d.text && (!!d.value || (!!d.export && !!d.export.value)) && (!d.export || !d.export.hide)).map(d => d.text)];
//       const data_rows = rowsToData(data, definitions);

//       switch (exportOptions.type) {
//         case EXPORT_TYPE.PDF:
//           exportToPdf(appliedFilters, headers, data_rows, exportOptions.name);
//           break;
//         case EXPORT_TYPE.EXCEL:
//           exportToExcel(appliedFilters, headers, data_rows, exportOptions.name);
//           break;
//         default:
//           console.error('UNSUPPORTED EXPORT TYPE!!');
//           return;
//       }
//     }
//   }
// }
