<template lang="html" src="./HeatMap.html"></template>

<!-- eslint-disable @typescript-eslint/no-explicit-any -->
<script lang="ts">
import { Vue, Component, Mixins, Prop } from 'vue-property-decorator'
import mapMixin from "../commons/map.mixin";
import L from 'leaflet';
import { LGeoJson } from "vue2-leaflet";

import { EnumMapMode } from "./EnumMapMode";
import chroma from "chroma-js";

import LegendModes from "./LegendModes/LegendModes.vue";
import LegendScale from "./LegendScale/LegendScale.vue";

@Component({
  components: { 
    LGeoJson,
    LegendModes,
    LegendScale
  }
})
export default class HeatMap extends Mixins(Vue, mapMixin) {
  @Prop({
      type: Object,
      required: true,
      default: () => ({
        getDataZones: null,
        getDataSubzones: null,
        getDataAreas: null
      })
    }) loaders!: any;

  mode: string | null = EnumMapMode.zones;
  geoData: any | null;
  geojsonRev = 0;
  geojson: any | null = null;
  activePopup: any | null;
  enumMapMode = EnumMapMode;
  legendGrades: Array<any>= [];
  legendGradesColors: Array<any> = [];

  getColorInGrades!: any;

  // $refs!: {
  //   map: any;
  //   geoJson: any;
  // }

  mounted() {
    this.setZonesMode();
  }

  beforeDestroy() {
    this.mode = null;
    this.removeGhostTooltip();
  }

  removeGhostTooltip() {
    if (this.activePopup) {
      this.activePopup._container.remove();
      this.activePopup = null;
    }
  }

  getNumMarkersOnlineAndOffline(item) {
    const online = item && item.online ? item.online : 0;
    const offline = item && item.offline ? item.offline : 0;

    return Number(online) + Number(offline);
  }

  getMaxInGeoData() {
    let max = 0;

    if (this.geoData && this.geoData.length > 0) {
      this.geoData.forEach(data => {
        const num_devices = this.getNumMarkersOnlineAndOffline(data);

        if (num_devices > max) max = num_devices;
      });
    }

    return max;
  }

  calculateGrades() {
    const numGrades = 7;
    const max = this.getMaxInGeoData();

    const delta = max > 6 ? Math.floor(max / numGrades) : 1;

    this.legendGrades = [0,1];
    for (let i = 1; i < numGrades+1; i++) {
      this.legendGrades.push(this.legendGrades[i] + delta);
    }

    // Se emplea la librería Chroma-js para generar la escala de colores (https://gka.github.io/chroma.js/)
    // Chroma-js dispone de las escalas Brewer de colores especificamente pensadas para mapas,
    // de las posibles aquí se indica la 'OrRd', pero hay otras disponibles en Brewer:
    //   The sequential palettes names are:
    //       Blues BuGn BuPu GnBu Greens Greys Oranges OrRd PuBu PuBuGn PuRd Purples RdPu Reds YlGn YlGnBu YlOrBr YlOrRd
    //   http://ugrad.stat.ubc.ca/R/library/RColorBrewer/html/ColorBrewer.html
    //   http://colorbrewer2.org/#type=sequential&scheme=BuGn&n=3
    //
    // El mode 'lch' se usa para indicar la interpolación entre los distintos tonos de la escala, más información en la doc de Chroma-js
    this.legendGradesColors = chroma
      .scale("OrRd")
      .mode("lch")
      .colors(numGrades + 2);
  }

  addGeojson(geojson) {
    if (geojson) {
      this.geojson = geojson;
      // Forzamos la actualización de la capa
      this.geojsonRev = Math.random();

      // Ajustamos el bounds del mapa al bounds de las features del geojson
      this.$nextTick(() => {
        const refMap = this.$refs["geoJson"] as any
        this.$refs.map.mapObject.fitBounds(refMap.getBounds());
        //this.$refs.map.mapObject.fitBounds(this.$refs.geoJson.getBounds());
      });
    }
  }

  getDataInfo(name) {
    if (this.geoData) return this.geoData.find(x => x.name === name);

    return null;
  }

  styleFeature(feature) {
    const dataInfo = this.getDataInfo(feature.properties.name);
    const num_devices = this.getNumMarkersOnlineAndOffline(dataInfo);

    return {
      fillColor: this.getColorInGrades(
        num_devices,
        this.legendGrades,
        this.legendGradesColors
      ),
      weight: 2,
      opacity: 1,
      color: "white",
      dashArray: "3",
      fillOpacity: 0.7
    };
  }

  highlightFeature(e) {
    const layer = e.target;

    layer.setStyle({
      weight: 5,
      color: "#666",
      dashArray: "",
      fillOpacity: 0.7
    });

    if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
      layer.bringToFront();
    }
  }

  resetHighlight(e) {
    const layer = e.target._path;
    layer.removeAttribute("stroke");
  }

  addDeviceCountsFeaturePopup(tooltip, dataInfo) {
    const onLine = Number(dataInfo.online).toLocaleString();
    const offLine = Number(dataInfo.offline).toLocaleString();

    tooltip.appendChild(document.createElement("hr"));

    const onlineDiv = document.createElement("div");
    const greenImg = document.createElement("img");
    greenImg.src = `${process.env.VUE_APP_PUBLIC_PATH}assets/status/green.png`;
    greenImg.style.width = "18px";
    greenImg.setAttribute("alt", "Online");

    onlineDiv.appendChild(greenImg);

    const greenLabel = document.createElement("h5");
    greenLabel.innerText = onLine;
    onlineDiv.appendChild(greenLabel);

    tooltip.appendChild(onlineDiv);

    const offlineDiv = document.createElement("div");
    const greyImg = document.createElement("img");
    greyImg.src = `${process.env.VUE_APP_PUBLIC_PATH}assets/status/grey.png`;
    greyImg.style.width = "18px";
    greyImg.setAttribute("alt", "Offline");

    offlineDiv.appendChild(greyImg);

    const greyLabel = document.createElement("h5");
    greyLabel.innerText = offLine;
    offlineDiv.appendChild(greyLabel);

    tooltip.appendChild(offlineDiv);
  }

  getFeatureIdOnDomClick(e) {
    if (e && e.target) {
      if (
        e.target.nextElementSibling &&
        e.target.nextElementSibling.attributes["alt"].value
      ) {
        return e.target.nextElementSibling.attributes["alt"].value;
      }

      if (e.target.attributes["alt"].value) {
        return e.target.attributes["alt"].value;
      }
    }

    return null;
  }

  getFeatureBounds(id) {
    let bounds = null;

    if (this.geojson) {
      const feature = this.geojson.features.filter(x => x.properties.id === id);
      if (feature && feature.length > 0) {
        bounds = feature[0].properties.bounds_calculated;
      }
    }

    return bounds;
  }

  openFeatureNextLevel(e) {
    const id = this.getFeatureIdOnDomClick(e);
    if (id === null) return;

    this.removeGhostTooltip();

    switch (this.mode) {
      case this.enumMapMode.zones:
        this.setSubzonesMode(id);
        break;
      case this.enumMapMode.subzones:
        this.setAreasMode(id);
        break;
      case this.enumMapMode.areas: {
        const bounds = this.getFeatureBounds(id);
        this.$refs.map.mapObject.fitBounds(bounds);

        this.$emit("close", { bounds });
        break;
      }
    }
  }

  createFeaturePopup(properties) {
    // Generar el DOM
    const popupContent = document.createElement("div");
    const tooltip = document.createElement("div");

    tooltip.className = "tooltip-legend-count";

    const featureTitle = document.createElement("div");
    featureTitle.className = "feature-title";

    const title = document.createElement("h4");
    title.innerText = properties.name;
    featureTitle.appendChild(title);

    if (properties.id) {
      const viewIcon = document.createElement("span");
      viewIcon.setAttribute("class", "v-icon material-icons");
      viewIcon.style.fontSize = "20px";
      viewIcon.setAttribute("alt", properties.id);
      viewIcon.innerText = "visibility";
      featureTitle.appendChild(viewIcon);

      featureTitle.addEventListener("click", this.openFeatureNextLevel);
    }

    tooltip.appendChild(featureTitle);

    const dataInfo = this.getDataInfo(properties.name);
    if (dataInfo != null) this.addDeviceCountsFeaturePopup(tooltip, dataInfo);

    popupContent.appendChild(tooltip);

    return popupContent;
  }

  clickOnFeature(e) {
    const properties = e.target.feature.properties;
    this.activePopup = L.popup({ closeButton: false });
    const popupContent = this.createFeaturePopup(properties);

    this.activePopup
      .setLatLng(e.latlng)
      .setContent(popupContent)
      .openOn(this.$refs.map.mapObject);
  }

  onEachFeature(feature, layer) {
    // Se añaden eventos para resaltar cuando el ratón pasa por encima, y para detectar el click sobre una feature
    layer.on({
      mouseover: this.highlightFeature,
      mouseout: this.resetHighlight,
      click: this.clickOnFeature
    });

    if (feature.properties) {
      feature.properties.bounds_calculated = layer.getBounds();
    }
  }

  setZonesMode() {
    this.mode = EnumMapMode.zones;

    this.setLoading(true);
    this.$store.dispatch("geo/getZonesGeoJson").then(geojson => {
      this.loaders.getDataZones().then(response => {
        this.geoData = response;

        this.calculateGrades();
        this.addGeojson(geojson);
        this.setLoading(false);
      });
    });
  }

  setSubzonesMode(zone) {
    this.mode = EnumMapMode.subzones;

    this.setLoading(true);

    this.$store.dispatch("geo/getSubzonesGeoJson").then(geo => {
      let geojson = JSON.parse(JSON.stringify(geo));
      this.filterSubzones(geojson, zone);

      this.loaders.getDataSubzones().then(response => {
        this.geoData = response;
        this.calculateGrades();
        this.addGeojson(geojson);
        this.setLoading(false);
      });
    });
  }

  filterSubzones(geojson, zone) {
    if (geojson && zone) {
      geojson.features = geojson.features.filter(x => x.properties.zone === zone);
    }
  }

  setAreasMode(subzone) {
    this.mode = EnumMapMode.areas;

    this.setLoading(true);

    this.$store.dispatch("geo/getAreasGeoJson").then(geo => {
      let geojson = JSON.parse(JSON.stringify(geo));

      this.filterAreas(geojson, subzone);
      this.loaders.getDataAreas().then(response => {
        this.geoData = response;
        this.calculateGrades();
        this.addGeojson(geojson);
        this.setLoading(false);
      });
    });
  }

  filterAreas(geojson, subzone) {
    if (geojson && subzone) {
      geojson.features = geojson.features.filter(x => x.properties.subzone === subzone);
    }
  }
}
</script>
<style>
@import "~leaflet.markercluster/dist/MarkerCluster.css";
@import "~leaflet.markercluster/dist/MarkerCluster.Default.css";
</style>