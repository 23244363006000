const DEFAULT_LANGUAGE = "EN";
const DEFAULT_COUNTRY = "ES";

export const BLUA_LANGUAGES = ["ar", "cs", "da", "de", "el", "en", "es", "fi", "fr", "iw", "nl", "no", "pl", "pt", "ru", "sv"];

export default {
  methods: {
    internalizedText(textObject) {
      if(!textObject)
        return null;
        
      const currentLanguage = this.$route.params.lang.toUpperCase();

      return this.internalizedTextFromLanguage(textObject,currentLanguage);
    },

    internalizedSpanishText(textObject) {
      if(!textObject)
        return null;
        
      const currentLanguage = "ES";
  
      return this.internalizedTextFromLanguage(textObject,currentLanguage);
    },
  
    internalizedTextFromLanguage(textObject,language){
      let texts = textObject.find(n => n.language === language);
      if (!texts) texts = textObject.find(n => n.language === DEFAULT_LANGUAGE) || textObject[0];
  
      return texts;
    },

    availavilityRegionSpain(textObject) {
      if(!textObject)
        return null;
        
      const currentCountry = "ES";
  
      let texts = textObject.find(n => n.country === currentCountry);
      if (!texts) texts = textObject.find(n => n.country === DEFAULT_COUNTRY) || textObject[0];
  
      return texts;
    },
  }

}