<template lang="html" src="./JobFwSelector.html"></template>

<!-- eslint-disable @typescript-eslint/no-explicit-any -->
<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator";
import { PANEL_FAMILY_CODE } from "@/constants";
import { EDIBOX_FAMILY_CODE } from "@/constants";

import DeviceTypeSelector from "../DeviceTypeSelector/DeviceTypeSelector.vue";
import FirmwareVersionSelect from "@/views/Firmware/FirmwareVersionSelect/FirmwareVersionSelect.vue";

@Component({
  components: {
    DeviceTypeSelector,
    FirmwareVersionSelect,
  },
})
export default class JobFwSelector extends Vue {
  @Prop(Object) form!: any;
  @Prop(Object) value!: any;
  @Prop(Object) devicesTypeInfo!: any;
  @Prop(Object) device!: any;
  @Prop(String) installationId!: string;
  @Prop(Boolean) allDevice!: boolean;

  PANEL_FAMILY_CODE = PANEL_FAMILY_CODE;
  EDIBOX_FAMILY_CODE = EDIBOX_FAMILY_CODE;
  keyFirmware = 0;

  isLoading = false;
  isFiltering = false;
  versionHW = "";

  get selectedFw() {
    return this.value;
  }

  set selectedFw(v) {
    this.$emit("input", v);
  }

  get localDevicesTypeInfo() {
    return this.devicesTypeInfo;
  }

  set localDevicesTypeInfo(v) {
    this.$emit("update:devicesTypeInfo", v);
  }

  get firmwaresPerType() {
    return this.$store.getters["firmwares/firmwaresPerType"];
  }

  get numberDevicesForJob() {
    return this.$store.getters["jobs/numberDevicesForJob"];
  }

  set numberDevicesForJob(newValue) {
    this.$store.commit("jobs/setNumberDevicesForJob", newValue);
  }

  get filteredDevicesForJob() {
    return this.$store.getters["jobs/selectedDevicesForJob"];
  }

  mounted() {
    if (this.device) {
      const deviceFamily = this.device.family;
      let deviceType = null;
      let deviceSubtype = null;
      
      if (
        deviceFamily !== PANEL_FAMILY_CODE &&
        deviceFamily !== EDIBOX_FAMILY_CODE
      ) {
        deviceType = this.device.type;
        deviceSubtype = this.device.subtype;
      }

      if (       
        deviceFamily !== EDIBOX_FAMILY_CODE
      ) {
        deviceType = this.device.type;        
      }

      this.versionHW = this.device.versionHW;

      this.retrieveFwVersions({
        family: deviceFamily,
        type: deviceType,
        subtype: deviceSubtype,
        versionHW: this.device.versionHW,
      });
    }
  }

  emitChange() {
    this.$emit("change", {
      devices: [...this.filteredDevicesForJob],
    });
  }

  retrieveFwVersions(data) {
    this.versionHW = data?.versionHW;
    this.selectedFw = null;

    if (
      data.family &&
      (data.family === PANEL_FAMILY_CODE ||
        data.family === EDIBOX_FAMILY_CODE ||
        (data.type && data.subtype))
    ) {
      this.isLoading = true;

      this.$store
        .dispatch("firmwares/getFirmwaresPerType", {
          device: {
            family: data.family,
            type: data.type,
            subtype: data.subtype,
            versionHW: data.versionHW,
          },
        })
        .then(() => (this.isLoading = false));
    }
    this.keyFirmware++;
  }

  getListDevicesToJob(version) {
    if (!this.device) {
      const firmwareVersionV2 = `${version.firmwareVersionV2.fwMajor}.${version.firmwareVersionV2.fwMinor}.${version.firmwareVersionV2.fwBuild}`;

      const hardwareList = version.hwList.map((e) => e.hw).join(",");

      this.isFiltering = true;
      this.numberDevicesForJob = 0;

      // versionHw
      if (version.versionHW && version.versionHW.trim() != "")
        this.localDevicesTypeInfo.versionHW = version.versionHW;

      this.localDevicesTypeInfo.status =
        this.localDevicesTypeInfo.subtype === null ? null : "Online";

      this.devicesTypeInfo.hardwareList = hardwareList;
      this.devicesTypeInfo.firmwareVersionV2 = firmwareVersionV2;

      this.$store
        .dispatch("jobs/getDevicesUpdateFw", {
          device: {
            familyFilter: this.localDevicesTypeInfo.family,
            typeFilter: this.localDevicesTypeInfo.type,
            subtypeFilter: this.localDevicesTypeInfo.subtype,
            //versionHw: this.localDevicesTypeInfo.versionHW,
            installationid: this.installationId || null,
            statusFilter: this.localDevicesTypeInfo.status,
            deployed: true,
            hardwareList,
            firmwareVersionV2,
          },
          allDevice: this.allDevice,
        })
        .then(() => {
          this.emitChange();

          this.isFiltering = false;
        })
        .catch(() => (this.isFiltering = false));
    }
  }
}
</script>
