/* eslint-disable */
import Vue from "vue";
import Component from "vue-class-component";

import { Prop, Ref, Watch } from "vue-property-decorator";
import DxfDataGrid from "../../../components/DxfDataGrid/DxfDataGrid.vue";
import { translateText } from "@/lang/i18n";

import { Column } from "devextreme/ui/data_grid";
import CustomStore from "devextreme/data/custom_store";

import JobService from "@/api/job";
import { LoadResultObject } from "devextreme/common/data/custom-store";

import DateHelper from "@/helpers/dateHelper";
import JobController from "@/domain/controllers/JobController";

export interface TablePagination {
  rowsPerPage: number;
  page: number;
  descending: boolean;
}

@Component({
  components: {
    DxfDataGrid,
  },
})
export default class JobsTable extends Vue {
  /** Filtro que viene de pantalla. */
  @Prop() filterOptions!: any;
  /** Palabra a buscar. */
  @Prop() search!: string | null;
  /** Referencia a la tabla. */
  @Ref("refDxfDataGrid") tableDevExtreme!: DxfDataGrid;

  /** Título de la tabla. */
  title: string;

  /** Conexión para obtener los datos de la tabla. */
  dataStore: CustomStore;

  /** Columnas de la tabla. */
  columns: Column[];

  showExport = this.$ability.can("export", "jobs");

  private ctrl: JobController;

  /** Se inicializan las variables. */
  constructor() {
    super();
    this.title = translateText("menu.jobs");
    this.dataStore = this.getNewCustomStore();
    this.columns = this.getColumns();
    this.ctrl = new JobController();
  }

  getColumns(): Column[] {
    const columns: Column[] = [
      {
        dataField: "createdDate",
        caption: translateText("jobs.jobcreatedtime"),
        alignment: "left",
        cellTemplate: this.$ability.can("details", "jobs")
          ? "linkDetail"
          : "notLinkDetail",
        calculateCellValue: (rowData: any) => {
          return DateHelper.dateFormat(
            rowData.createdDate,
            "DD/MM/YYYY HH:mm:ss"
          );
        },
      },

      {
        dataField: "endDate",
        caption: translateText("jobs.jobendtime"),
        calculateCellValue: (rowData: any) => {
          return DateHelper.dateFormat(rowData.endDate, "DD/MM/YYYY HH:mm:ss");
        },
      },
      {
        dataField: "jobName",
        caption: translateText("jobs.jobname"),
        /*calculateDisplayValue: (rowData: any) => {
          return i18nIsoCountries.getName(rowData.country, i18n.locale);
        },*/
      },
      {
        dataField: "succeeded",
        caption: translateText("jobs.jobsucceeded"),
        visible: false,
      },
      {
        dataField: "failed",
        caption: translateText("jobs.jobfailed"),
        visible: false,
      },
      {
        caption: translateText("jobs.status.running"),
        visible: false,
        calculateDisplayValue: (rowData: any) => {
          return rowData.numDevices -
            rowData.succeeded -
            rowData.failed -
            rowData.cancelled <
            0
            ? 0
            : rowData.numDevices -
                rowData.succeeded -
                rowData.failed -
                rowData.cancelled;
        },
        allowSorting: false,
      },
      {
        dataField: "cancelled",
        caption: translateText("jobs.status.cancelled"),
        visible: false,
      },
      {
        dataField: "firmware",
        caption: translateText("general.version"),
        calculateDisplayValue: (rowData: any) => {
          if (rowData.firmware != null) {
            let fw = "";
            if (rowData.firmware.family != null) {
              fw += rowData.firmware.family + " ";
            }
            if (rowData.firmware.type != null) {
              fw += rowData.firmware.type + " ";
            }
            if (rowData.firmware.subtype != null) {
              fw += rowData.firmware.subtype + " ";
            }
            if (rowData.firmware.versionSW != null) {
              fw += rowData.firmware.versionSW;
            }
            return fw;
          } else {
            return " ";
          }
        },
        allowSorting: false,
      },
      {
        dataField: "automaticUpdate",
        caption: translateText("firmware.automaticUpdate"),
      },
      {
        dataField: "userName",
        caption: translateText("jobs.jobuser"),
      },
      {
        dataField: "status",
        caption: translateText("jobs.jobstatus"),
        cellTemplate: "chipJobs",
        allowSorting: false,
      },
      {
        dataField: "summary",
        caption: translateText("jobs.jobsummary"),
        cellTemplate: "resumeJobs",
        allowSorting: false,
        allowExporting: false,
        width: 150,
      },
      {
        dataField: "id",
        caption: translateText("jobs.jobid"),
        allowSorting: false,
      },
      {
        cellTemplate: "menu",
        width: 60,
        allowExporting: false,
      },
    ];

    return columns;
  }

  get menuOptions() {
    return [
      {
        icon: "assignment",
        text: this.$t("jobdetails.title"),
        to: (item) => ({ name: "JobDetails", params: { jobid: item.id } }),
        allowed: this.$ability.can("details", "jobs"),
      },
      {
        icon: "cancel",
        text: this.$t("general.cancel"),
        action: (item) => this.setCancelJobIdModal(item.id),
        allowed: this.$ability.can("cancel", "jobs"),
        allowedForItem: (item) =>
          item.immediate === false && item.status === "INPROGRESS",
      },
      {
        icon: "delete",
        text: this.$t("general.delete"),
        action: (item) => this.setDeleteJobIdModal(item.id),
        allowed: this.$ability.can("delete", "jobs"),
        allowedForItem: (item) =>
          item.status === "COMPLETED" ||
          item.status === "CANCELLED" ||
          item.status === "FAILED",
      },
    ];
  }

  setCancelJobIdModal(v) {
    this.$emit("setCancelJobIdModal", v);
  }
  setDeleteJobIdModal(v) {
    this.$emit("setDeleteJobIdModal", v);
  }

  /** Cuando cambia el fitro se recarga la tabla. */
  @Watch("filterOptions", { immediate: true, deep: true })
  onChangeFilterOptions() {
    this.dataStore = this.getNewCustomStore();
  }

  /** Cuando cambia la palabra a buscar se recarga la tabla. */
  @Watch("search", { immediate: true, deep: true })
  onChangeSearch() {
    this.dataStore = this.getNewCustomStore();
  }

  onClickDetail(item: any) {
    this.$router.push({
      name: "JobDetails",
      params: { jobid: item.data.id },
    });
  }

  /** Obtiene el objeto store y hace un load de los datos. */
  getNewCustomStore(): CustomStore {
    const loadJobsRecursively = async (pagination: TablePagination) => {
      try {
        const sort = "createdAt";
        const res = await JobService.getJobs(
          this.filterOptions,
          pagination as any,
          this.search as any,
          sort as any
        );

        loadedJobs = loadedJobs.concat(res.data.content);

        if (loadedJobs.length < res.data.totalElements) {
          const nextPage = pagination.page + 1;
          const nextPagination: TablePagination = {
            ...pagination,
            page: nextPage,
          };
          await loadJobsRecursively(nextPagination);
        } else {
          allJobsLoaded = true;
        }
      } catch (err) {
        console.error(err);
      }
    };

    let loadedJobs: any[] = [];
    let allJobsLoaded = false;

    return new CustomStore({
      key: "id",
      load: async (loadOptions: any) => {
        if (loadOptions.isLoadingAll === true) {
          loadedJobs = [];
          const pagination: TablePagination = {
            rowsPerPage: 2000,
            page: 1,
            descending: false,
          };

          if (!allJobsLoaded) {
            await loadJobsRecursively(pagination);
          }
        } else {
          const pagination: TablePagination = {
            rowsPerPage: loadOptions.take ?? 10,
            page: Math.ceil(
              ((loadOptions.skip ?? 0) + 1) / (loadOptions.take ?? 0)
            ),
            descending: loadOptions.sort?.[0]?.desc ?? true,
          };

          let sort = loadOptions.sort?.[0]?.selector ?? "createdAt";

          if (sort === "createdDate") {
            sort = "createdAt";
          }

          try {
            await this.ctrl.calculateScheduled();
            const res = await JobService.getJobs(
              this.filterOptions,
              pagination as any,
              this.search as any,
              sort as any
            );

            loadedJobs = res.data.content;

            const result: LoadResultObject = {
              data: loadedJobs,
              totalCount: res.data.totalElements,
            };

            return result;
          } catch (err) {
            console.error(err);
          }
        }

        const data = allJobsLoaded ? loadedJobs : [];

        const result: LoadResultObject = {
          data: data,
          totalCount: allJobsLoaded ? loadedJobs.length : 0,
        };

        allJobsLoaded = false;
        return result;
      },
    });
  }

  public refresh() {
    this.tableDevExtreme.refresh();
  }
}
