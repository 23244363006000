<template lang="html" src="./JobDetails.html"></template>

<!-- eslint-disable @typescript-eslint/no-explicit-any -->
<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import { displayErrorMessage } from "@/api";
import jobService from "@/api/job";

import { goTo } from "@/router";

import PieChart from "@/components/Charts/Pie";
import AppBreadcrumbs from "@/components/AppBreadcrumbs.vue";
import AppListMenu from "@/components/AppListMenu.vue";

import dateMixin from "@/mixins/date.mixin";

import DeviceList from "./DeviceList/DeviceList.vue";

import JobDeleteModal from "../JobDeleteModal/JobDeleteModal.vue";
import JobCancelModal from "../JobCancelModal/JobCancelModal.vue";

import pdfJobExporter from "./exporter/pdf.exporter";

import ChipJobStatus from "@/ui/components/ChipJobStatus/ChipJobStatus.vue";
import JobController from "@/domain/controllers/JobController";

@Component({
  components: {
    PieChart,
    AppBreadcrumbs,
    AppListMenu,
    DeviceList,
    JobDeleteModal,
    JobCancelModal,
    ChipJobStatus,
  },
  mixins: [dateMixin],
  data() {
    return {
      // JobID from the jobs list view
      jobid: this.$route.params.jobid,
    };
  },
})
export default class JobDetails extends Vue {
  jobid!: string;
  // Data to build the pie chart
  chartoptions = {
    responsive: true,
    scaleBeginAtZero: true,
    legend: {
      labels: {
        boxWidth: 20,
        fontSize: 14,
      },
      position: "right",
    },
  };
  // Dialog to show the filters applied in a job
  filtersAppliedDialog = false;
  // Confirm delete window
  showDeleteDialog = false;
  showCancelDialog = false;
  // Device result for the job
  menuCardActions = false;
  imgHasError = false;
  questionSrc =
    process.env.VUE_APP_PUBLIC_PATH + "assets/devices/" + "unknown.jpg";

  private ctrl: JobController;

  constructor() {
    super();
    this.ctrl = new JobController();
  }

  get menuOptions() {
    return [
      {
        icon: "refresh",
        text: this.$t("general.refresh"),
        action: () => this.refreshJob(),
        allowed:
          this.jobData.status === "INPROGRESS" ||
          this.jobData.status === "WAITING" ||
          (this.jobData.status === "CANCELLING" &&
            this.$ability.can("refresh", "jobs")),
      },
      {
        icon: "fa-file-pdf-o",
        iconProps: { size: 16, style: { "margin-left": "5px" } },
        text: this.$t("jobdetails.pdfExport"),
        action: () => this.exportToPdf(),
        allowed: this.jobData && this.$ability.can("export", "jobs"),
      },
      {
        icon: "cancel",
        text: this.$t("general.cancel"),
        action: () => this.setShowCancelDialog(true),
        allowed:
          this.jobData.immediate === false &&
          (this.jobData.status === "INPROGRESS" ||
            this.jobData.status === "SCHEDULED") &&
          this.$ability.can("cancel", "jobs"),
      },
      {
        icon: "delete",
        text: this.$t("general.delete"),
        action: () => this.setShowDeleteDialog(true),
        allowed:
          (this.jobData.status === "COMPLETED" ||
            this.jobData.status === "CANCELLED" ||
            this.jobData.status === "FAILED") &&
          this.$ability.can("delete", "jobs"),
      },
    ];
  }

  get jobData() {
    return this.$store.getters["jobs/oneJobData"];
  }

  get jobInfo() {
    return this.$store.getters["jobs/oneJobInfo"];
  }

  get image() {
    return this.jobData && this.jobData.imgURL
      ? this.imgHasError
        ? this.questionSrc
        : this.jobData.imgURL
      : undefined;
  }

  get hasWarningDeviceJobs() {
    return (
      this.jobData.results &&
      Object.values(this.jobData.results).find(
        (d: any) => d.summaryStatus === "SUCCESS" && d.warning === true
      )
    );
  }

  get chartData() {
    let chartdata: any = null;

    if (this.jobInfo) {
      const jobLabels = [
        this.$t("jobs.deviceStatus.success"),
        this.$t("jobs.deviceStatus.failed"),
      ];

      const jobBackgroundColor = ["green", "red"];
      const jobChartData = [
        this.jobInfo.numSuccessDevices,
        this.jobInfo.numFailedDevices,
      ];

      if (this.jobInfo.numRunningDevices > 0) {
        jobLabels.push(this.$t("jobs.deviceStatus.running"));
        jobBackgroundColor.push("#2196f3");
        jobChartData.push(this.jobInfo.numRunningDevices);
      }

      if (
        this.jobData.status === "CANCELLED" ||
        this.jobData.status === "CANCELLING"
      ) {
        jobLabels.push(this.$t("jobs.deviceStatus.cancelled"));
        jobBackgroundColor.push("#616161");
        jobChartData.push(this.jobInfo.numCancelledDevices);
      }

      chartdata = {
        labels: jobLabels,
        datasets: [
          {
            backgroundColor: jobBackgroundColor,
            data: jobChartData,
          },
        ],
      };
    }

    return chartdata;
  }

  async mounted() {
    await this.ctrl.calculateScheduled();
    this.refreshList();
  }

  setShowCancelDialog(v) {
    this.showCancelDialog = v;
  }

  setShowDeleteDialog(v) {
    this.showDeleteDialog = v;
  }

  refreshList() {
    this.$store
      .dispatch("jobs/getOneJobInfo", {
        jobId: this.jobid,
      })
      .then((res) => {
        const worker = (window as any).activeJobsFinishedWorkers[this.jobid];
        if (worker) worker.postMessage({ cmd: "check", response: res });

        // Consultar datos hardware version
        this.$store
          .dispatch("firmwares/getFirmwareId", res.firmware.id)
          .then((res) => {
            if (res) {
              const HWList = res.hwList.map((item) => item.hw);
              this.jobData.firmware.versionHW = HWList.join(",");
            }
          })
          .catch(() => {
            this.jobData.firmware.versionHW = "";
          });
      })
      .catch(() => goTo("jobs"));
  }

  refreshJob(messageOnRefresh = true) {
    jobService
      .refreshJobInfo(this.jobid)
      .then(() => {
        this.refreshList();

        if (messageOnRefresh)
          this.$store.dispatch("snackbarInfo", {
            text: this.$t("jobs.info.refreshJobInfo"),
          });
      })
      .catch((err) => {
        displayErrorMessage(err, { general: ["general.error.refreshError"] });
      });
  }

  exportToPdf() {
    pdfJobExporter(this.jobData, this.jobInfo);
  }

  onDeletedJob() {
    goTo("jobs");
  }
}
</script>
<style>
.devices-summary-expansion,
.devices-summary-expansion .v-expansion-panel {
  border-radius: 0px !important;
}
.devices-summary-expansion
  .v-expansion-panel-content
  .v-expansion-panel-content__wrap {
  padding: 10px;
}
</style>
<style scoped>
.fa-file-pdf-o {
  position: relative;
  left: 6px;
}
</style>
