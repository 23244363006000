<template>
    <v-text-field
      :label="$t('general.search')"
      single-line
      v-model="search"
      append-icon="search"
      @input="execute"
      clearable
      :disabled="disabled"
      maxlength="64"
      @click:append.stop="execute"
      @click.stop
    />
</template>
<script lang="ts">
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

const TIMEOUT_MILLISECONDS = 500;

@Component
export default class SearchInput extends Vue {
  @Prop(String) value!: string;
  @Prop(Boolean) disabled!: boolean;

  search: string | null = null;
  timeout: number | null = null;

  @Watch('value')
  onChangeValue() {
    this.search = this.value;
  }

  mounted() {
    this.search = this.value;
  }

  execute() {
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = window.setTimeout(() => {
      this.$emit("input", this.search);
      this.$emit("onSearch", this.search);
    }, TIMEOUT_MILLISECONDS);
  }
}
</script>