import Api from "@/api";

const SERVICE_PATH = "/geo/api/v1";

export default {

  getGeojsonZones: () => {
    return Api(true).get(SERVICE_PATH + "/geojson/zones");
  },

  getGeojsonSubzones: () => {
    return Api(true).get(SERVICE_PATH + "/geojson/subzones");
  },

  getGeojsonAreas: () => {
    return Api(true).get(SERVICE_PATH + "/geojson/areas");
  },

  getZonesCombo: () => Api(true).get(`${SERVICE_PATH}/zones`),

  getSubzonesCombo: (zone) => Api(true).get(`${SERVICE_PATH}/subzones?zone=${zone}`),

  getAreasCombo: (subzone) => Api(true).get(`${SERVICE_PATH}/areas?subzone=${subzone}`)
}