import TableMarkersMap from "@/components/TableMarkersMap/TableMarkersMap.vue";
import TableMarkersToggle from "@/components/TableMarkersToggle/TableMarkersToggle.vue";

export default {
  components: {
    TableMarkersMap,
    TableMarkersToggle
  },

  data() {
    return {
      toggleView: 0,
      disabledFilters: false,
      mapLoaders: {
        getDataAreas: this.getDataAreas,
        getDataZones: this.getDataZones,
        getDataSubzones: this.getDataSubzones,
        getData: this.getMapData
      }
    }
  }, 

  computed: {
    viewMap() {
      return this.toggleView === 1 || this.toggleView === 2;
    },
    isHeatMap: {
      get() {
        return this.toggleView === 2;
      },
      set(value) {
        if (value !== this.isHeatMap) this.$refs.toggleView.setValue(value ? 2 : 1);
      }
    }
  },

  watch: {
    toggleView() {
      this.session_setItem(this.keyViewMap, this.toggleView);
    }
  },

  created() {
    this.toggleView = +this.session_getItem(this.keyViewMap);
  },

  methods: {
    applySearch(newSearch) {
      this.search = newSearch;
      this.refreshList();
    },

    applyFilter(newFilter) {
      this.filterOptions = newFilter;
      this.filterClick();
    },

    disableFilters(disabled) {
      if (disabled) {
        this.expansionPanel = null;
        this.search = null;

        if (this.$refs.filters) this.$refs.filters.cleanFilters();
      }

      this.disabledFilters = disabled;
    },

    geoTableColumns() {
      return [{
        text: this.$t("geo.zone"),
        value: "zone",
        sortable: true
      },
      {
        text: this.$t("geo.subzone"),
        value: "subzone",
        sortable: true,
        export: {
          width: 80
        }
      },
      {
        text: this.$t("geo.area"),
        value: "area",
        sortable: true,
        export: {
          width: 80
        }
      }]
    }
  }
}