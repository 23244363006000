import {
  DeviceFamilyInterface,
  DeviceSubtypeInterface,
  DeviceTypeInterface,
} from "@/interfaces/devices";
import DeviceService from "../services/DeviceService";
import { AxiosError } from "axios";

export default class DeviceController {
  /** Servicio de firmware. */
  protected service: DeviceService;

  constructor() {
    this.service = new DeviceService();
  }

  /** Indica si la familia existe. */
  public async existsFamily(family: string): Promise<boolean> {
    return this.service
      .getFamily(family)
      .then(() => {
        // Si no hay error, es que existe.
        return true;
      })
      .catch((err: AxiosError) => {
        if (err.response?.status === 400) {
          // Si el error que devuelve es 400 no existe.
          return false;
        }
        throw err;
      });
  }

  /** Indica si el tipo existe. */
  public async existsType(family: string, type: string): Promise<boolean> {
    return this.service
      .getType(family, type)
      .then(() => {
        // Si no hay error, es que existe.
        return true;
      })
      .catch((err: AxiosError) => {
        if (err.response?.status === 400) {
          // Si el error que devuelve es 400 no existe.
          return false;
        }
        throw err;
      });
  }

  /** Obtiene el subtipo o `null` si no existe. */
  public async getSubtype(
    family: string,
    type: string,
    subtype: string
  ): Promise<DeviceSubtypeInterface | null> {
    return this.service
      .getSubtype(family, type, subtype)
      .catch((err: AxiosError) => {
        if (err.response?.status === 400) {
          // Si el error que devuelve es 400 no existe.
          return null;
        }
        throw err;
      });
  }

  public async getFamilies(): Promise<DeviceFamilyInterface[]> {
    return this.service.getFamilies();
  }

  public async getTypes(family: string): Promise<DeviceTypeInterface[]> {
    return this.service.getTypes(family);
  }

  public async getSubtypes(
    family: string,
    type: string
  ): Promise<DeviceSubtypeInterface[]> {
    return this.service.getSubtypes(family, type);
  }
}
