<template lang="html" src="./PlanEditDescription.html"></template>

<script lang="ts">
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import PlanEditDescriptionModal from "./PlanEditDescriptionModal/PlanEditDescriptionModal.vue";
import languagesMixin from "@/mixins/languages.mixin";

import { displayErrorMessage } from "@/api";
import katalogService from "@/api/katalog";
import { RegionalDetail } from "@/interfaces/plans";

@Component({
  components: {
    PlanEditDescriptionModal,
  },
  mixins: [languagesMixin],
})
export default class PlanEditDescription extends Vue {
  @Prop(Array) regionalDetails!: RegionalDetail[];

  selectedRegionalDetail: RegionalDetail | null = null;
  isDeleting = false;
  descTableRevision = 0;
  selectedLanguages: RegionalDetail[] = [];
  showConfirmRemove = false;
  ENGLISH_LANGUAGE = "EN";
  /** Muestra u oculta el vantana del formulario. */
  showConfirmAddEdit = false;
  /** Registros de la tabla. */
  rows: RegionalDetail[] = [];

  @Watch("regionalDetails", { immediate: true, deep: true })
  onChangeRegionalDetails(newValue: RegionalDetail[] | null) {
    if (newValue === null) {
      this.rows = [];
    } else {
      this.rows = newValue;
    }
  }

  get headers() {
    return [
      {
        text: this.$t("menu.language"),
        sortable: true,
        value: "language",
      },
      {
        text: this.$t("planDetails.planName"),
        value: "name",
      },
      {
        text: this.$t("planDetails.description"),
        value: "description",
      },
    ];
  }

  get selectedLanguage(): RegionalDetail | null {
    if (this.selectedLanguages.length === 1) {
      return this.selectedLanguages[0];
    }
    return null;
  }

  /** Seleccionar todos o desseleccionarlos. */
  toggleAll() {
    if (this.selectedLanguages.length) {
      this.selectedLanguages = [];
    } else {
      this.selectedLanguages = this.rows.slice();
    }
  }

  /**
   * Cuando seleccionas la fila, se selecciona el combobox.
   * @param item Registro.
   * @param isSelected Si es para seleccionar o desseleccionar.
   */
  toggleSelectItem(item: RegionalDetail, isSelected: boolean) {
    if (isSelected) {
      this.selectedLanguages = this.selectedLanguages.filter(
        (item: RegionalDetail) => {
          item.language !== item.language;
        }
      );
    } else {
      this.selectedLanguages.push(item);
    }
  }

  add() {
    this.showConfirmAddEdit = true;
  }

  edit() {
    this.showConfirmAddEdit = true;
  }

  /** Cuando se cierra la ventana de edición*/
  onCloseDescriptionModal() {
    this.showConfirmAddEdit = false;
  }

  /** Cuando se guarda la ventana se añade o modifica el registro. */
  onSaveDescriptionModal(data: RegionalDetail, isNew: boolean) {
    if (data) {
      if (isNew) {
        this.rows.push(data);
      } else {
        const descriptionPos = this.rows.findIndex(
          (d) => d.language === data.language
        );

        if (descriptionPos !== -1) this.rows[descriptionPos] = data;
      }

      this.descTableRevision++;
      this.selectedLanguages = [];
    }
    this.showConfirmAddEdit = false;
  }

  /** Eliminar un registro. */
  remove(languages) {
    this.isDeleting = true;

    const planCode = this.$route.params.planCode;

    katalogService.deletePlanLanguages(planCode, languages).then(
      () => {
        this.isDeleting = false;

        this.selectedLanguages = [];
        this.rows = this.rows.filter(
          (d) => !languages.find((desc) => desc.language === d.language)
        );

        this.showConfirmRemove = false;
      },
      (err) => {
        this.isDeleting = false;

        displayErrorMessage(err, {
          general: ["planDetails.error.languageDeleting"],
        });
      }
    );
  }
}
</script>
<style>
.plan-edit-language-table .v-data-table__wrapper {
  overflow: auto;
  max-height: 200px;
  position: relative;
  border: 1px solid #ddd;
}
</style>
