import SimpleThead from "@/components/Table/SimpleThead";

import sessionStorageMixin from "@/mixins/session.storage.mixin";

export default {
  components: {
    SimpleThead
  },

  mixins: [sessionStorageMixin],

  data() {
    return {
      tableProps: {
        page: 1,
        itemsPerPage: 10,
        sortBy: [],
        sortDesc: []
      },
      search: '',
      // Data table filters
      filterOptions_: {},
      filterOptions: {},
      // Loading bar
      loadingData: false
    }
  },

  computed: {
    exportAppliedFilters() {

      const res = { filters: this.filterOptions_, definitions: this.FILTER_DEFINITIONS };

      if (this.search && this.search.trim() !== "")
        res.search = this.search;

      return res;
    }
  },
  created() {
    this.search = this.session_getItem(this.keySearch);
    this.filterOptions = this.session_getItemObject(this.keyFilterOptions, {});
    this.filterOptions_ = { ...this.filterOptions };
  },

  mounted() {
    this.refreshList();
  },

  watch: {
    search() {
      this.session_setItem(this.keySearch, this.search);
    }
  },

  methods: {
    obtainCommonTableAttributes() {
      return {
        'loading': this.loadingData, 
        'hide-default-header': true, 
        'must-sort': true, 
        'loading-text': this.$t('general.messages.loading'),
        'no-data-text': this.$t('general.messages.noDataInTable'),
        'no-results-text': this.$t('general.messages.searchNoResults', [this.search])
      }
    },

    cleanFilters() {
      this.filterOptions_ = {};
      this.filterOptions = {};
      this.saveFilters();

      this.tableProps.page = 1;
      this.refreshList();
    },

    saveFilters() {
      this.session_setItemObject(this.keyFilterOptions, this.filterOptions);
      this.filterOptions_ = { ...this.filterOptions };
    }
  }
}