import Api from "./index";
import * as getters from "../store/getters";

const SERVICE_PATH = "/appclient/api/v1";

export default {
  getClients: () => Api(true).get(`${SERVICE_PATH}/clients`),

  getWacByEmail: (email) => Api(true).get(`${SERVICE_PATH}/user/encrypted?email=${email}`),

  getEmailByWac: (wac) => Api(true).get(`${SERVICE_PATH}/user/decrypted/${wac}`),

  setPropertiesEdit: (id, editProps) => Api(true).post(`${SERVICE_PATH}/clients/${id}`, editProps, {headers: {"Content-Type": "application/json"}}),

  // for add new redirect uris
  setUrisEdit: (uri, id) => Api(true).post(`${SERVICE_PATH}/clients/uri?Uri=${uri}&clientId=${id}`, {}, {headers: {"Content-Type": "application/json"}}),

  // for insert
  setInsertClient: (clientProps) => Api(true).post(`${SERVICE_PATH}/clients`, clientProps, {headers: {"Content-Type": "application/json"}}),

  // for modifying grant types
  setGrantTypes: (id, grantTypeProps) => Api(true).post(`${SERVICE_PATH}/clients/${id}/grant?grant=${grantTypeProps.grant}`, grantTypeProps, {headers: {"Content-Type": "application/json"}}),
};