
export const setJobsList = (state, response) => {
  state.jobsList = response;
};
export const setJobsInfo = (state, response) => {
  state.jobsInfo = response;
}
export const setDevicesListForJob = (state, response) => {
  state.devicesListForJob = response;
};
export const setSelectedDevicesForJob = (state, response) => {
  state.selectedDevicesForJob = response;
};
export const setDevicesWithJob = (state, response) => {
  state.devicesWithJob = response;
};
export const setJobInvolvedOnMyDevice = (state, response) => {
  state.jobInvolvedOnMyDevice = response;
};
export const setNumberDevicesForJob = (state, response) => {
  state.numberDevicesForJob = response;
};
export const setOneJobData = (state, response) => {
  state.oneJobData = response;
};
export const setOneJobInfo = (state, response) => {
  state.oneJobInfo = response;
};
export const setLastJobFromDevice = (state, response) => {
  state.lastJobFromDevice = response;
};

export const setNumberOfJobs = (state, response) => {
  state.numberOfJobs = response;
};

export const setNumberOfInProgressJobs = (state, response) => {
  state.numberOfInProgressJobs = response;
};