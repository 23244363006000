import Api from "./index";

export const SIZE_MASSIVE_JOB = 20;

const SERVICE_PATH = "/update/api/v1";

const urlToJobs = (
  filterOptions,
  pagination = null,
  search = null,
  column = null
) => {
  const sortDirection = pagination && pagination.descending ? ",desc" : ",asc";

  let urlJobs = SERVICE_PATH + "/jobs?";

  if (pagination) {
    urlJobs +=
      "page=" + pagination.page + "&size=" + pagination.rowsPerPage + "&";
  }

  if (search) {
    urlJobs += "question=" + escape(search) + "&";
  }
  if (column) {
    urlJobs += "sort=" + column + sortDirection + "&";
  }

  if (filterOptions.idFilter) {
    urlJobs += "id=" + filterOptions.idFilter + "&";
  }

  if (filterOptions.nameFilter) {
    urlJobs += "jobName=" + filterOptions.nameFilter + "&";
  }

  if (filterOptions.statusFilter) {
    urlJobs += "status=" + filterOptions.statusFilter + "&";
  }

  if (filterOptions.dateValueStart) {
    urlJobs +=
      "createDateStart=" + filterOptions.dateValueStart + " 00:00:00" + "&";
  }

  if (filterOptions.dateValueEnd) {
    urlJobs +=
      "createDateEnd=" + filterOptions.dateValueEnd + " 23:59:59" + "&";
  }

  if (filterOptions.failFilter === true) {
    urlJobs += "onlyFailed=true" + "&";
  }

  if (filterOptions.familyFilter) {
    urlJobs += "family=" + filterOptions.familyFilter.name + "&";
  }

  if (filterOptions.typeFilter) {
    urlJobs += "type=" + filterOptions.typeFilter.name + "&";
  }

  if (filterOptions.subtypeFilter) {
    urlJobs += "subtype=" + filterOptions.subtypeFilter.name + "&";
  }

  if (filterOptions.automaticUpdateFilter === true) {
    urlJobs += "automaticUpdate=true" + "&";
  }

  return urlJobs.slice(0, urlJobs.length - 1);
};

export default {
  getJobs: (filterOptions, pagination = null, search, column = null) => {
    const url = urlToJobs(filterOptions, pagination, search, column);

    return Api(true).get(url);
  },

  getNumberOfJobs: (inprogress) => {
    var url = SERVICE_PATH + "/jobs?";
    if (inprogress) url += "status=INPROGRESS";

    return Api(true).get(url);
  },

  getDevicesWithJobOn: (selectedMonitors) =>
    Api(true).post(`${SERVICE_PATH}/relatedjobs`, selectedMonitors, {
      headers: { "Content-Type": "application/json" },
    }),

  getJob: (id) => Api(true).get(`${SERVICE_PATH}/jobs/${id}`),

  refreshJobInfo: (id) => Api(true).post(`${SERVICE_PATH}/jobs/${id}/refresh`),

  applyJob: (info) =>
    Api(true).post(`${SERVICE_PATH}/jobs`, info, {
      headers: { "Content-Type": "application/json" },
    }),

  cancelJob: (id) => Api(true).post(`${SERVICE_PATH}/jobs/${id}/cancel`),

  deleteJob: (id) => Api(true).delete(`${SERVICE_PATH}/jobs/${id}`),

  getLastFromDevice: (deviceId) =>
    Api(true).get(`${SERVICE_PATH}/lastjobs/${deviceId}`),
};
