<template lang="html" src="./TableMarkersToggle.html"></template>
<!-- eslint-disable @typescript-eslint/ban-types -->
<!-- eslint-disable @typescript-eslint/no-explicit-any -->
<!-- eslint-disable @typescript-eslint/no-unused-vars -->
<script lang="ts">
import { Vue, Component, Prop, Watch } from "vue-property-decorator";

@Component
export default class TableMarkersToggle extends Vue {
  @Prop(Number) value!: number;
  @Prop(Boolean) hasAnyActiveFilter!: boolean;
  @Prop(String) search!: string;
  @Prop(Boolean) withoutHeatMap!: boolean;
  @Prop(Boolean) withoutMap!: boolean;

  tmpValue = 0;
  showConfirmHeatMapModal = false;

  get localValue() {
    return this.value;
  }

  set localValue(value) {
    this.$emit("input", value);
  }

  @Watch("value", { immediate: true, deep: true })
  onChange(value) {
    this.tmpValue = value;
  }

  setValue(value) {
    if (value === 2 && (this.hasAnyActiveFilter || this.search)) {
      this.showConfirmHeatMapModal = true;

      return false;
    }

    this.localValue = value;
    return true;
  }

  onConfirmHeatMapMode() {
    this.showConfirmHeatMapModal = false;
    this.localValue = 2;
  }

  onCancelHeatMapMode() {
    this.showConfirmHeatMapModal = false;
    this.tmpValue = this.value;
  }
}
</script>
<style scoped>
.toggle-view {
  pointer-events: auto;
  box-shadow: none;
}

.filter-mobile .toggle-view {
  position: absolute;
  top: -40px;
  width: calc(100% - 53px);
  display: grid;
  grid-template-columns: auto auto auto;
}
</style>
<style>
.filter-mobile {
  margin-top: 60px;
}
</style>
