<template>
  <v-chip small :color="color" text-color="white">{{ infoText }}</v-chip>
</template>

<!-- eslint-disable @typescript-eslint/no-explicit-any -->
<script lang="ts">
const infoColor = "blue accent-1";

const disabledColor = "grey darken-4";

const redColor = "red";
const greenColor = "green";

export const getChipInfoText = (type, property) => {
  let status = "";

  if (type === "user") {
    status = property === true ? "accepted" : "notAccepted";
  }

  if (type === "disableduser") {
    status = "disabled";
  }

  return status;
};

import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class AppChipInfo extends Vue {
  @Prop(String) type!: string;
  @Prop() params!: any;

  get color() {
    let colour = "";

    switch (this.type) {
      case "info":
        colour = infoColor;
        break;
      case "user":
        colour = this.params.property === true ? greenColor : redColor;
        break;
      case "disableduser":
        colour = disabledColor;
        break;
    }

    return colour;
  }

  get infoText() {
    let text = "";

    switch (this.type) {
      case "info":
        text = this.params.number;
        break;
      case "user":
        text =
          this.params.property === true
            ? this.$tc("user.accepted")
            : this.$tc("user.notAccepted");
        break;
      case "disableduser":
        text = this.$tc("user.disabled");
        break;
    }

    return text;
  }
}
</script>
