import moment from "moment";

export default class DateHelper {
  static dateFormat(date, format): string {
    return date ? moment(date).format(format) : "";
  }
  static dateFormatUnix(date: number, format: string): string {
    return date ? moment.unix(date).format(format) : "";
  }
}
