/* eslint-disable */
import JobEntity from "@/domain/entities/JobEntity";
import i18n, { translateText } from "@/lang/i18n";
import JobModel from "@/ui/models/JobModel";
import { Cell } from "devextreme/pdf_exporter";
import dxDataGrid from "devextreme/ui/data_grid";

export default class DxfExportHelper {
  /** Título de la tabla. */
  protected title: string;
  /** Columnas que están ocultas (temporal) */
  private hiddenColumnsIndex: number[];

  private colorGreen = "4caf50";
  private colorGray = "616161";
  private colorGrayLight = "e0e0e0";
  private colorYellow = "ffa000";
  private colorRed = "f44336";

  /**
   * Ayuda a la exportación de datos de la tabla de DevExtreme.
   * @param title Título de la tabla.
   */
  constructor(title: string) {
    this.title = title;
    this.hiddenColumnsIndex = [];
  }

  /**
   * Obtiene el título de la tabla en formato de nombre de fichero.
   * Elimina caracteres no permitidos y reemplaza espacios por guiones bajos.
   * @returns Nombre del fichero.
   */
  protected getFileName(): string {
    return this.title.replace(/[^a-z0-9.]/gi, "_").replace(/_+/g, "_");
  }

  /** Muestra todas las columnas y guarda las que estaban ocultas. */
  protected showAllColumns(component: dxDataGrid) {
    const columnCount = component.instance().columnCount();
    this.hiddenColumnsIndex = [];
    for (let i = 0; i < columnCount; i++) {
      if (component.instance().columnOption(i, "visible") === false) {
        this.hiddenColumnsIndex.push(
          component.instance().columnOption(i, "index")
        );
        component.instance().columnOption(i, "visible", true);
      }
    }
  }

  /** Vuelve a ocultar las columnas que estaban ocultas. */
  protected restoreVisibilityColumns(component: dxDataGrid) {
    const columnCount = component.instance().columnCount();
    for (let i = 0; i < columnCount; i++) {
      for (const hiddenCol of this.hiddenColumnsIndex) {
        if (component.instance().columnOption(i, "index") === hiddenCol) {
          component.instance().columnOption(i, "visible", false);
        }
      }
    }
  }

  /** Transforma el valor del estado de la instralación en formato Excel. */
  protected transformExcelCellStatusInstallation(
    status: string,
    excelCell: any
  ) {
    if (status === "CONNECTED") {
      excelCell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: this.colorGreen },
      };
      excelCell.value = translateText("installation.online");
    } else if (status === "DISCONNECTED") {
      excelCell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: this.colorGray },
      };
      excelCell.value = translateText("installation.offline");
    } else if (status === "WARNING") {
      excelCell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: this.colorYellow },
      };
      excelCell.value = translateText("installation.warning");
    }
  }

  /** Transforma el valor del estado de la instralación en formato Pdf. */
  protected transformPdfCellStatusInstallation(status: string, pdfCell: Cell) {
    if (status === "CONNECTED") {
      pdfCell.backgroundColor = "#" + this.colorGreen;
      pdfCell.textColor = "#ffffff";
      pdfCell.text = translateText("installation.online");
    } else if (status === "DISCONNECTED") {
      pdfCell.backgroundColor = "#" + this.colorGray;
      pdfCell.textColor = "#ffffff";
      pdfCell.text = translateText("installation.offline");
    } else if (status === "WARNING") {
      pdfCell.backgroundColor = "#" + this.colorYellow;
      pdfCell.textColor = "#ffffff";
      pdfCell.text = translateText("installation.warning");
    }
  }

  protected transformExcelCellStatusChip(
    status: boolean,
    excelCell: any,
    deployed = true
  ) {
    if (deployed) {
      excelCell.font = { color: { argb: "FFFFFF" } };
      if (status) {
        excelCell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: this.colorGreen },
        };
        excelCell.value = translateText("general.online");
      } else {
        excelCell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: this.colorGray },
        };
        excelCell.value = translateText("general.offline");
      }
    } else {
      excelCell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: this.colorGrayLight },
      };
      excelCell.value = translateText("general.manufactured");
    }
  }

  protected transformPdfCellStatusChip(
    status: boolean,
    pdfCell: Cell,
    deployed = true
  ) {
    if (deployed) {
      pdfCell.textColor = "#ffffff";
      if (status) {
        pdfCell.backgroundColor = "#" + this.colorGreen;
        pdfCell.text = translateText("general.online");
      } else {
        pdfCell.backgroundColor = "#" + this.colorGray;
        pdfCell.text = translateText("general.offline");
      }
    } else {
      pdfCell.backgroundColor = "#" + this.colorGrayLight;
      pdfCell.text = translateText("general.manufactured");
    }
  }

  protected transformExcelCellAppChipInfoUser(status: boolean, excelCell: any) {
    if (status) {
      excelCell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: this.colorGreen },
      };
      excelCell.value = translateText("user.accepted");
    } else {
      excelCell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: this.colorRed },
      };
      excelCell.value = translateText("user.notAccepted");
    }
  }

  protected transformPdfCellAppChipInfoUser(status: boolean, pdfCell: Cell) {
    if (status) {
      pdfCell.backgroundColor = "#" + this.colorGreen;
      pdfCell.textColor = "#ffffff";
      pdfCell.text = translateText("user.accepted");
    } else {
      pdfCell.backgroundColor = "#" + this.colorRed;
      pdfCell.textColor = "#ffffff";
      pdfCell.text = translateText("user.notAccepted");
    }
  }

  protected transformExcelCellChipJobs(status: string, excelCell: any) {
    const jobEntity = new JobEntity({ status: status });
    const jobModel = new JobModel(jobEntity);

    excelCell.font = { color: { argb: "FFFFFF" } };
    excelCell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: jobModel.getStatusColorPdf().substring(1) },
    };
    excelCell.value = jobModel.getStatusText();
  }

  protected transformPdfCellChipJobs(status: string, pdfCell: Cell) {
    const jobEntity = new JobEntity({ status: status });
    const jobModel = new JobModel(jobEntity);
    pdfCell.textColor = "#ffffff";
    pdfCell.backgroundColor = jobModel.getStatusColorPdf();
    pdfCell.text = jobModel.getStatusText();
  }

  protected transformExcelCellEnableTick(status: boolean, excelCell: any) {
    if (status) {
      excelCell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: this.colorGreen },
      };
      excelCell.value = translateText("general.yes");
    } else {
      excelCell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: this.colorRed },
      };
      excelCell.value = translateText("general.no");
    }
  }

  protected transformPdfCellEnableTick(status: boolean, pdfCell: Cell) {
    if (status) {
      pdfCell.backgroundColor = "#" + this.colorGreen;
      pdfCell.textColor = "#ffffff";
      pdfCell.text = translateText("general.yes");
    } else {
      pdfCell.backgroundColor = "#" + this.colorRed;
      pdfCell.textColor = "#ffffff";
      pdfCell.text = translateText("general.no");
    }
  }

  protected transformExcelCellRolTick(statusRol: any, excelCell: any) {
    if (statusRol.access === "TRUE") {
      excelCell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: this.colorGreen },
      };
      excelCell.value = translateText("general.yes");
    } else {
      excelCell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: this.colorRed },
      };
      excelCell.value = translateText("general.no");
    }
  }

  protected transformPdfCellRolTick(statusRol: any, pdfCell: any) {
    if (statusRol.access === "TRUE") {
      pdfCell.backgroundColor = "#" + this.colorGreen;
      pdfCell.textColor = "#ffffff";
      pdfCell.text = translateText("general.yes");
    } else {
      pdfCell.backgroundColor = "#" + this.colorRed;
      pdfCell.textColor = "#ffffff";
      pdfCell.text = translateText("general.no");
    }
  }
}
