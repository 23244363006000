<template lang="html" src="./ChangeTimezoneModal.html"></template>

<!-- eslint-disable @typescript-eslint/no-explicit-any -->
<script lang="ts">
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import moment from "moment";
import AppAutocomplete from "@/components/AppAutocomplete.vue";

import { displayErrorMessage } from "@/api";
import deviceService from "@/api/device";

@Component({
  components: {
    AppAutocomplete
  }
})
export default class ChangeTimezoneModal extends Vue{
  @Prop(Boolean) value!: boolean;
  @Prop(String) deviceId!: string;

  isLoading = false;
  zones = (moment as any).tz.names();
  validForm = false;
  tz: any | null = null;
  isSaving = false;

  $refs!: {
    form: HTMLFormElement
  }

  get show() {
    return this.value;
  }

  set show(localValue) {
    this.$emit("input", localValue);
  }

  get timezoneRules() {
    return [
      v => !!v || this.$t("firmware.fieldrequired")
    ];
  }

  @Watch('show')
  onChangeShow() {
    if(this.$refs.form) this.$refs.form.reset();
    if (
      this.show &&
      !this.isLoading
    ) {
      this.loadTimezone();
    }
  }

  loadTimezone() {
    this.isLoading = true;
    deviceService.getDeviceTimezone(this.deviceId).then(res => {
      this.tz = res.data;
      this.isLoading = false;
    }).catch(error => {
      this.isLoading = false;
      this.tz = null;
      displayErrorMessage(error, { general: ["deviceDetails.changeTimezone.error.obtain"] });
    });
  }

  execute(deviceId) {
    if(!this.tz){
      if(this.$refs.form) this.$refs.form.validate();
      return;
    }

    this.isSaving = true;
    deviceService.changeDeviceTimezone(deviceId, this.tz).then(() => {
      this.show = false;
      this.isSaving = false;

      this.$store.dispatch("snackbarInfo", {
        text: this.$t("deviceDetails.changeTimezone.tzChangedSuccesfully")
      });
    }).catch(error => {
      this.isSaving = false;
      displayErrorMessage(error, { general: ["deviceDetails.changeTimezone.error.save"] });
    });
  }
}
</script>
