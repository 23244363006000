import { render, staticRenderFns } from "./UserDetails.html?vue&type=template&id=3a1c2804&scoped=true&lang=html&external"
import script from "./UserDetails.vue?vue&type=script&lang=ts"
export * from "./UserDetails.vue?vue&type=script&lang=ts"
import style0 from "./UserDetails.vue?vue&type=style&index=0&id=3a1c2804&prod&scoped=true&lang=css"
import style1 from "./UserDetails.vue?vue&type=style&index=1&id=3a1c2804&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a1c2804",
  null
  
)

export default component.exports