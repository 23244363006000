<template lang="html" src="./Taboo.html"></template>
<!-- eslint-disable @typescript-eslint/no-explicit-any -->
<!-- eslint-disable @typescript-eslint/ban-types -->

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'
import { mapActions } from "vuex";

import { displayErrorMessage } from "@/api";
import AppClient from "@/api/taboo";

import AppListMenu, { hasAllowedMenuOptions } from "@/components/AppListMenu.vue";
import AppBreadcrumbs from "@/components/AppBreadcrumbs.vue";
import FilterTableHeader from "@/components/Table/FilterTableHeader/FilterTableHeader.vue";

import AppFilterButtons from "@/components/AppFilterButtons.vue";
import AppKpiChipInfo from "@/components/AppKpiChipInfo.vue";
import AppSelect from "@/components/AppSelect.vue";

//import ExportTableDataButton from "@/components/Table/ExportTableDataButton/ExportTableDataButton.vue";

import dateMixin from "@/mixins/date.mixin";
import tableBackMixin from "@/mixins/table.mixin/back";
import filterMixin from "@/mixins/filter.mixin";

// Keys para persistir los valores en la sessionStorage con el objetivo de mantenerlo tras un refresco de la página
const keyView = "Taboo";
const keySearch = keyView + ".search.";
const keyFilterOptions = keyView + ".filterOptions";

const FILTER_DEFINITIONS = {
  ip: {
    literal: "taboo.filter.ip",
    valueLiterals: {}
  },
  alsoExpired: {
    literal: "taboo.filter.alsoExpired"
  }
};

const ipRegex = /^((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/;

@Component({
  components: {
    AppListMenu,
    AppBreadcrumbs,
    FilterTableHeader,
    AppFilterButtons,
    AppKpiChipInfo,
    AppSelect,
    //ExportTableDataButton
  },
  mixins: [dateMixin, tableBackMixin, filterMixin],
  methods: {
    ...mapActions({ 
      getBlacklist: "taboo/getBlacklist",
      getCountActiveBans: "taboo/getCountActiveBans",
    })
  }
})
export default class Taboo extends Vue {
  getBlacklist!: Function;
  getCountActiveBans!: Function;
  loadingData = false;
  filterOptions_!: any;
  axiosSource!: any;
  search!: string;
  totalServerItems!: number;
  filterStartWith!: Function;
  checkHasAnyActiveFilter!: Function;
  saveFilters!: Function;
  transformTablePropsForServerRequest!: Function;
  isNotAxiosCancellation!: Function;
  configureAxiosCancelToken!: Function;
  
  FILTER_DEFINITIONS = FILTER_DEFINITIONS;
  keySearch = keySearch;
  insertDialog = false;
  confirmDeleteDialog = false;
  dialogSteps = 1;
  validFormStep1 = false;
  validFormStep2 = false;
  newTaboo = {
    ip: '',
    expiresIn: ''
  };
  tabooToDelete = '';
  keyFilterOptions = keyFilterOptions;
  tableProps: any = {
    sortBy: ["expiresAt"]
  }

  $refs!: {
    formStep1: HTMLFormElement,
    formStep2: HTMLFormElement
  }

  get menuOptions() {
    return [
      {
        icon: "delete",
        text: this.$t("general.delete"),
        action: (item) => this.prepareToDelete(item),
        allowed: this.$ability.can('delete','taboo')
      }
    ];
  }

  get ipRules(){
    return [
    v => !!v || this.$t("taboo.error.emptyIP"),
    v => ipRegex.test(v) || this.$t("taboo.error.ip")
    ]
  }

  get expiresInRules(){
    return [
    v => /^([0-9]*$)/.test(v) || this.$t("taboo.error.expiresIn")
    ]
  }

  get menuOptionsAllowed() {
    return hasAllowedMenuOptions(this.menuOptions);
  }

  get hasAnyActiveFilter() {
    const filters = { ...this.filterOptions_ };

    return this.checkHasAnyActiveFilter(filters);
  }

  get countActiveBans() {
    return this.$store.getters["taboo/countActiveBans"];
  }

  get countExpiredBans() {
    return this.tabooList.totalElements - this.countActiveBans || 0;
  }

  get tabooList() {
    const listInfo = { ...this.$store.getters["taboo/blacklist"] };
    if (this.filterOptions_ && listInfo.list)
      listInfo.list = listInfo.list.filter(i =>
        this.filterInternal(i, this.filterOptions_)
      );

    return listInfo;
  }

  get headers() {
    const headers: any = [
      {
        text: this.$t("taboo.ip"),
        value: "ip",
        sortable: true,
        export: {
          width: 90
        }
      },
      {
        text: this.$t("taboo.expiresAt"),
        value: "expiresAt",
        sortable: true,
        align: "left",
        export: {
          format: "datetime"
        }
      },
      {
        text: this.$t("taboo.updatedAt"),
        value: "updatedAt",
        sortable: true,
        align: "left",
        export: {
          format: "datetime"
        }
      },
      {
        text: this.$t("taboo.createdAt"),
        value: "createdAt",
        sortable: true,
        align: "left",
        export: {
          format: "datetime"
        }
      }
    ];

    if (this.menuOptionsAllowed)
      headers.push({
        text: null,
        value: null,
        sortable: false,
        align: "right",
        sticky: true
      });

    return headers;
  }

  cancelInsert() {
    this.insertDialog = false;
    this.resetFormValues();
    this.dialogSteps = 1;
  }

  prepareToInsert() {
    this.insertDialog = true;
  }

  insertNewBan() {
    this.insertDialog = false;
    AppClient.banIP(this.newTaboo.ip, this.newTaboo.expiresIn)
      .then(() => {
        this.refreshList();
        this.dialogSteps = 1;
        this.resetFormValues();

        this.$store.dispatch("snackbarInfo", {
          active: true,
          text: this.$t("taboo.newTabooAlert")
        });
      })
      .catch(err => {
        displayErrorMessage(err, {
          general: ["taboo.error.newTabooAlert"]
        });
      });
  }

  prepareToDelete(taboo) {
    this.confirmDeleteDialog = true;
    this.tabooToDelete = taboo.ip;
  }

  deleteBan() {
    this.confirmDeleteDialog = false;
    AppClient.unbanIP(this.tabooToDelete)
      .then(() => {
        this.refreshList();
        this.tabooToDelete = "";

        this.$store.dispatch("snackbarInfo", {
          active: true,
          text: this.$t("taboo.deleteTabooAlert")
        });
      })
      .catch(err => {
        this.tabooToDelete = "";
        displayErrorMessage(err, {
          general: ["taboo.error.deleteTabooAlert"]
        });
      });
  }

  resetFormValues() {
    this.newTaboo.ip = '';
    this.newTaboo.expiresIn = '';
    if (this.$refs.formStep1) this.$refs.formStep1.resetValidation();
    if (this.$refs.formStep2) this.$refs.formStep2.resetValidation();
  }

  refreshList() {
    this.loadingData = true;

    const pagination = this.transformTablePropsForServerRequest();

    this.saveFilters();
    this.configureAxiosCancelToken();
    
    this.getBlacklist({
      filterOptions: this.filterOptions_,
      pagination: pagination,
      search: this.search ? this.search.trim() : null,
      column: pagination.sortBy,
      axiosSource: this.axiosSource
    }).then((res) => {
      this.totalServerItems = res.totalElements;
      this.getCountActiveBans().then(() => {
        setTimeout(() => {
          this.loadingData = false;
        }, 500);
      });
    })
    .catch(err => {
        if (this.isNotAxiosCancellation(err)) {
          console.error(err);
          this.loadingData = false;
        }
      });
  }

  refreshListSearch() {
    this.tableProps.page = 1;
    this.refreshList();
  }

  onFilterSubmit(e) {
    e.preventDefault();

    this.saveFilters();

    this.tableProps.page = 1;

    this.refreshList();
  }

  filterInternal(item, filters) {
    if (filters.ip) {
      if (!this.filterStartWith(item.ip, filters.ip))
        return false;
    }
    return true;
  }

  goStep(stepNumber) {
    this.dialogSteps = stepNumber;
  }

  sortListAlphabetically(list) {
    list.sort(function(a, b) {
      if (a < b) {
        return -1;
      }
      if (a > b) {
        return 1;
      }
      return 0;
    });
  }

  isBanActive(expiresAt) {
    return (expiresAt ? Date.parse(expiresAt) > Date.now() : true);
  }
}
</script>

