import { render, staticRenderFns } from "./VATEditModal.html?vue&type=template&id=430a6b6e&lang=html&external"
import script from "./VATEditModal.vue?vue&type=script&lang=ts"
export * from "./VATEditModal.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports