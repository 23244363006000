export const setOneDeviceData = (state, response) => {
  state.oneDeviceData = response
};
export const setOneDeviceCacheData = (state, response) => {
  state.oneDeviceCacheData = response
};

export const setOneDevicePairings = (state, response) => {
  state.oneDevicePairings = response
};

export const setOneDeviceSim = (state, response) => {
  state.oneDeviceSim = response
};