import { InternalUserInterface } from "../entities/InternalUserEntity";
import { AxiosHelper } from "../helpers/AxiosHelper";
import { InternalUserServiceInterface } from "../interfaces/InternalUserServerInterface";

export class InternalUserService implements InternalUserServiceInterface {
  protected axiosService: AxiosHelper;
  protected urlBase: string;

  constructor() {
    this.axiosService = AxiosHelper.getInstance();
    this.urlBase = process.env.VUE_APP_PRE_URI + "/appclient/api/v1";
  }

  /** Obtiene todos los usuarios internos. */
  public async getAll(): Promise<InternalUserInterface[]> {
    const url = this.urlBase + "/internalusers";
    return this.axiosService.get(url);
  }

  /** Obtiene un usuario interno por su id. */
  public async get(id: string): Promise<InternalUserInterface> {
    const url = this.urlBase + "/internalusers/" + id;
    return this.axiosService.get(url);
  }

  /** Obtiene los posibles roles para los usuarios internos */
  public async getRoles(): Promise<Array<string>> {
    const url = this.urlBase + "/internalusers/roles";
    return this.axiosService.get(url);
  }

  /** Inserta un nuevo usuario interno. */
  public async insert(params: {
    email: string;
    password: string;
    roles: string[];
  }): Promise<void> {
    const url = this.urlBase + "/internalusers";
    return this.axiosService.post(url, params);
  }

  /** Actualiza la contraseña de un usuario interno. */
  public async changePassword(params: {
    email: string;
    password: string;
  }): Promise<void> {
    const url = this.urlBase + "/internalusers/changepass";
    return this.axiosService.post(url, params);
  }

  /** Actualiza los roles de un usuario interno. */
  public async changeRoles(id: string, roles: string[]): Promise<void> {
    const url = this.urlBase + "/internalusers/" + id + "/changerole";
    return this.axiosService.post(url, roles);
  }

  /** Elimina un usuario interno. */
  public async delete(id: string): Promise<void> {
    const url = this.urlBase + "/internalusers/" + id;
    return this.axiosService.delete(url);
  }
}
