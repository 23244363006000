interface UserTokenInterface {
  aud: string[];
  authorities: string[];
  client_id: string;
  exp: number;
  jti: string;
  scope: string[];
  user_name: string;
}
export default class UserTokenEntity implements UserTokenInterface {
  aud: string[];
  authorities: string[];
  client_id: string;
  exp: number;
  jti: string;
  scope: string[];
  user_name: string;
  constructor(token: string) {
    const userToken: UserTokenInterface = JSON.parse(atob(token.split(".")[1]));
    this.aud = userToken.aud;
    this.authorities = userToken.authorities;
    this.client_id = userToken.client_id;
    this.exp = userToken.exp;
    this.jti = userToken.jti;
    this.scope = userToken.scope;
    this.user_name = userToken.user_name;
  }

  /** Devuelve si el usuario tiene el rol de postventa */
  get isAfterSales() {
    return this.authorities.includes("ROLE_SPANISH_OUTLIER");
  }
}
