import { displayErrorMessage } from "@/api";
import HttpStatus from "http-status-codes";

import jobService, { SIZE_MASSIVE_JOB } from "@/api/job";
import deviceService from "@/api/device";

export const getJobsList = ({ commit }, payload) =>
  new Promise((resolve, reject) => {
    jobService
      .getJobs(
        payload.filterOptions,
        payload.pagination,
        payload.search,
        payload.column
      )
      .then((res) => {
        commit("setJobsList", res.data);

        resolve(res.data);
      })
      .catch((err) => {
        displayErrorMessage(err, { general: ["jobs.error.getError"] });
        reject(err);
      });
  });

export const getNumberOfJobs = ({ commit }) =>
  new Promise((resolve, reject) => {
    jobService.getNumberOfJobs().then(
      (res) => {
        commit("setNumberOfJobs", res.data.totalElements);

        resolve(res.data);
      },
      (err) => {
        displayErrorMessage(err, { general: ["jobs.error.getError"] });

        reject(err);
      }
    );
  });

export const getNumberOfInProgressJobs = ({ commit }) =>
  new Promise((resolve, reject) => {
    jobService.getNumberOfJobs({ inprogress: true }).then(
      (res) => {
        commit("setNumberOfInProgressJobs", res.data.totalElements);

        resolve(res.data);
      },
      (err) => {
        displayErrorMessage(err, { general: ["jobs.error.getError"] });

        reject(err);
      }
    );
  });

/* export const getListDevicesJob = ({ commit, dispatch }, payload) =>
  new Promise((resolve, reject) => {
    deviceService.getDeviceJobs(payload.device).then(
      (res) => {
        const devicesListForJob = res.data.content;
        const selectedDevicesForJob = [
          ...new Set(devicesListForJob.map((item) => item.serialNumber)),
        ];

        commit("setDevicesListForJob", devicesListForJob);
        commit("setSelectedDevicesForJob", selectedDevicesForJob);

        if (res.data.totalElements > SIZE_MASSIVE_JOB) {
          commit("setNumberDevicesForJob", res.data.totalElements);
          resolve(selectedDevicesForJob);
        } else {
          // ximo console.log(JSON.parse(JSON.stringify(res.data.content)));
          dispatch("getDevicesWithJobOn", {
            selectedMonitors: selectedDevicesForJob,
          }).then((currentJobsRes) => resolve(currentJobsRes));
        }
      },
      (err) => {
        reject(err);
        displayErrorMessage(err, {
          general: ["general.error.loadingDataForm"],
        });
      }
    );
  }); */

export const getDevicesUpdateFw = ({ commit, dispatch }, payload) =>
  new Promise((resolve, reject) => {
    deviceService.getDevicesUpdateFw(payload.device, payload.allDevice).then(
      (res) => {
        const devicesListForJob = res.data.content;
        const selectedDevicesForJob = [
          ...new Set(devicesListForJob.map((item) => item.serialNumber)),
        ];

        commit("setDevicesListForJob", devicesListForJob);
        commit("setSelectedDevicesForJob", selectedDevicesForJob);

        if (res.data.totalElements > SIZE_MASSIVE_JOB) {
          commit("setNumberDevicesForJob", res.data.totalElements);
          resolve(selectedDevicesForJob);
        } else {
          // ximo console.log(JSON.parse(JSON.stringify(res.data.content)));
          dispatch("getDevicesWithJobOn", {
            selectedMonitors: selectedDevicesForJob,
          }).then((currentJobsRes) => resolve(currentJobsRes));
        }
      },
      (err) => {
        reject(err);
        displayErrorMessage(err, {
          general: ["general.error.loadingDataForm"],
        });
      }
    );
  });

export const getDevicesWithJobOn = ({ commit }, payload) =>
  new Promise((resolve, reject) => {
    jobService
      .getDevicesWithJobOn(payload.selectedMonitors)
      .then((res) => {
        // Extracts te keys (deviceId) from the response array
        const devicesWithJob = Object.keys(res.data);
        const selectedMonitorsNoJobs = payload.selectedMonitors.filter(
          (item) => !devicesWithJob.includes(item)
        );

        commit("setDevicesWithJob", devicesWithJob);
        commit("setSelectedDevicesForJob", selectedMonitorsNoJobs);
        commit("setNumberDevicesForJob", selectedMonitorsNoJobs.length);

        // To extract the jobId from one monitor that has a job running
        if (devicesWithJob.length > 0) {
          // It has a job running, get the job ID
          const deviceJobID = Object.values(res.data)[0];
          commit("setJobInvolvedOnMyDevice", deviceJobID);
        }

        resolve(devicesWithJob);
      })
      .catch((err) => {
        commit("setNumberDevicesForJob", 0);

        reject(err);
        displayErrorMessage(err, {
          general: ["general.error.loadingDataForm"],
        });
      });
  });

export const filterListDevicesJob = ({ commit, dispatch }, payload) =>
  new Promise((resolve, reject) => {
    deviceService.getDeviceJobs(payload.device, payload.allDevice).then(
      (res) => {
        const devicesListForJob = res.data.content;
        const selectedDevicesForJob = [
          ...new Set(devicesListForJob.map((item) => item.serialNumber)),
        ];

        commit("setDevicesListForJob", devicesListForJob);
        commit("setDevicesListForJob", selectedDevicesForJob);

        if (res.data.totalElements > SIZE_MASSIVE_JOB) {
          commit("setNumberDevicesForJob", res.data.totalElements);
          resolve(selectedDevicesForJob);
        } else {
          dispatch("getDevicesWithJobOn", {
            selectedMonitors: selectedDevicesForJob,
          }).then((currentJobsRes) => resolve(currentJobsRes));
        }
      },
      (err) => {
        reject(err);
        displayErrorMessage(err, {
          general: ["general.error.loadingDataForm"],
        });
      }
    );
  });

export const getLastJobFromDevice = ({ commit }, payload) =>
  new Promise((resolve, reject) => {
    commit("setLastJobFromDevice", null);

    jobService
      .getLastFromDevice(payload.deviceId)
      .then((res) => {
        let last = null;
        if (
          res.data &&
          res.status !== HttpStatus.NO_RESPONSE &&
          res.data.length > 0
        )
          last = res.data[res.data.length - 1];

        commit("setLastJobFromDevice", last);
        resolve(last);
      })
      .catch((err) => {
        reject(err);

        displayErrorMessage(err, { general: ["jobs.error.getOneJob"] });
      });
  });

export const getOneJobInfo = ({ commit }, payload) =>
  new Promise((resolve, reject) => {
    commit("setOneJobData", null);

    const checkEmptyFilters = (jobDataParam) => {
      return jobDataParam.oL ||
        jobDataParam.country ||
        jobDataParam.originMinVersion ||
        jobDataParam.originMaxVersion
        ? false
        : true;
    };

    jobService
      .getJob(payload.jobId)
      .then((res) => {
        const jobData = res.data;
        jobData.checkEmpty = checkEmptyFilters(jobData);

        let imgUrl =
          process.env.VUE_APP_PUBLIC_PATH +
          "assets/devices/" +
          jobData.firmware.family;

        if (jobData.firmware.type) imgUrl += "_" + jobData.firmware.type;

        if (jobData.firmware.subtype) imgUrl += "_" + jobData.firmware.subtype;

        jobData.imgURL = imgUrl + ".jpg";

        commit("setOneJobData", jobData);

        const oneJobInfo = {
          numSuccessDevices: jobData.succeeded,
          numFailedDevices: jobData.failed,
          numCancelledDevices: jobData.cancelled,
          numRunningDevices:
            jobData.numDevices -
            (jobData.succeeded + jobData.failed + jobData.cancelled),
        };

        commit("setOneJobInfo", oneJobInfo);
        resolve(jobData);
      })
      .catch((err) => {
        reject(err);
        displayErrorMessage(err, { general: ["jobs.error.getOneJob"] });
      });
  });
