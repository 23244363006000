import { InternalUserController } from "@/domain/controllers/InternalUserController";
import { InternalUserEntity } from "@/domain/entities/InternalUserEntity";
import Toast from "@/ui/helpers/Toast";
import { Vue, Component, Prop, Ref } from "vue-property-decorator";

@Component({
  name: "DialogDelete",
})
export default class DialogDelete extends Vue {
  /** Muestra u oculta el dialog. */
  @Prop({ default: false }) showDialog!: boolean;
  /** Usuario interno. */
  @Prop() internalUser!: InternalUserEntity;
  /** Controlador de usuarios internos. */
  ctrlInternalUser = new InternalUserController();
  /** Muestra u oculta el dialog de confirmación. */
  showConfirmDialog = false;
  /** Referencia al formulario. */
  @Ref("form") form!: HTMLFormElement;
  /** Valida el formulario. */
  validEditForm = false;
  /** Input de seguridad para la aliminación. */
  dataSecurity = "";

  get dataSecurityRules() {
    return [
      (v) => !!v || this.$t("internalusers.error.emptyDataSecurity"),
      (v) =>
        v === this.internalUser.email ||
        this.$t("internalusers.error.confirmDataSecurity"),
    ];
  }

  cancel() {
    this.form?.reset();
    this.form?.resetValidation();
    this.showConfirmDialog = false;
    this.$emit("cancel");
  }

  /** Si el usuario tiene permisos de administrador, se muestra un dialog de confirmación. */
  save() {
    if (this.internalUser.isAdmin) {
      this.showConfirmDialog = true;
    } else {
      this.saveConfirm();
    }
  }

  cancelConfirm() {
    this.form?.reset();
    this.form?.resetValidation();
    this.showConfirmDialog = false;
  }

  saveConfirm() {
    this.ctrlInternalUser.delete(this.internalUser).then(() => {
      Toast.info("internalusers.messages.deleteUserOK");
      this.form?.reset();
      this.form?.resetValidation();
      this.showConfirmDialog = false;
      this.$emit("delete");
    });
  }
}
