export const setUsersList = (state, response) => {
  state.usersList = response;
};
export const setNumberOfUsers = (state, response) => {
  state.numberOfUsers = response;
};

export const setOneUserData = (state, response) => {
  state.oneUserData = response;
}
export const setOneUserCredit = (state, response) => {
  state.oneUserCredit = response;
}
export const setOneUserPairings = (state, response) => {
  state.oneUserPairings = response;
}