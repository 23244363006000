import Api from "./index";

const SERVICE_PATH = "/appclient/api/v1";


export default {
  

  getInternalUsers: () => Api(true).get(`${SERVICE_PATH}/internalusers`),

  getRoles: () => Api(true).get(`${SERVICE_PATH}/internalusers/roles`),

  getUser: (id) => Api(true).get(`${SERVICE_PATH}/internalusers/${id}`),
 
  getRolUser: (id) => Api(true).get(`${SERVICE_PATH}/internalusers/${id}`),

  removeInternalUsers: (id) => Api(true).delete(`${SERVICE_PATH}/internalusers/${id}`),

  setPassEdit: (editProps) => Api(true).post(`${SERVICE_PATH}/internalusers/changepass/`, editProps, {headers: {"Content-Type": "application/json"}}),

  setInsertClient: (clientProps) => Api(true).post(`${SERVICE_PATH}/internalusers`, clientProps, {headers: {"Content-Type": "application/json"}}),

  setInsertRoles: (id, userRol) => Api(true).post(`${SERVICE_PATH}/internalusers/${id}/changerole`, userRol, {headers: {"Content-Type": "application/json"}}),
} 

