<template>
  <v-layout :class="classStyle">
    <v-btn color="grey" class="white--text" @click.native="clickClean" :disabled="disabled">{{$t('general.clean')}}</v-btn>
    <v-btn
      type="submit"
      color="blue accent-4"
      class="ml-4 white--text"
      :disabled="disabled"
    >
      {{$t('general.filter')}}
    </v-btn>
  </v-layout>
</template>

<!-- eslint-disable @typescript-eslint/ban-types -->
<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class AppFilterButtons extends Vue {
  @Prop(String) classStyle!: string;
  @Prop(Function) clickClean!: Function;
  @Prop({type: Boolean, default: false}) disabled!: boolean;
}
</script>