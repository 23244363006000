import Vue from "vue";
import Component from "vue-class-component";

import { Prop } from "vue-property-decorator";
import DxfDataGrid from "../../../components/DxfDataGrid/DxfDataGrid.vue";
import { translateText } from "@/lang/i18n";

import { Column } from "devextreme/ui/data_grid";
import { InternalUserEntity } from "@/domain/entities/InternalUserEntity";

@Component({
  components: {
    DxfDataGrid,
  },
})
export default class InternalUsersTable extends Vue {
  /** Datos de la tabla. */
  // eslint-disable-next-line
  @Prop() dataArray!: Array<any>;
  /** Opciones del menú. */
  // eslint-disable-next-line
  @Prop() menuOptions!: Array<any>;

  /** Título de la tabla. */
  title: string;

  /** Columnas de la tabla. */
  columns: Column[];
  /** Se inicializan las variables. */
  constructor() {
    super();
    this.title = translateText("menu.internalUsers");
    this.columns = this.getColumns();
  }
  getColumns(): Column[] {
    const columns: Column[] = [
      {
        dataField: "email",
        caption: translateText("user.email"),
        sortIndex: 1,
        sortOrder: "asc",
      },
      {
        caption: translateText("oauthclients.rol"),
        allowSorting: false,
        calculateCellValue: function (rowData: InternalUserEntity) {
          return rowData.rolesString;
        },
        cellTemplate: "multiChip",
        cssClass: "whiteSpaceNormal",
      },
      {
        cellTemplate: "menu",
        width: 60,
        allowExporting: false,
      },
    ];

    return columns;
  }
}
