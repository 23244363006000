import { displayErrorMessage } from "@/api";
import geoService from "@/api/geo";

export const getZonesGeoJson = ({ commit, state }) => new Promise((resolve, reject) => {
  if (state.zonesGeoJson)
    resolve(state.zonesGeoJson);
  else
    geoService.getGeojsonZones().then(res => {
      const geoJson = res.data;

      commit('setZonesGeoJson', geoJson);

      resolve(geoJson);
    }).catch(error => {
      displayErrorMessage(error, { general: ["geo.error.getGeoJSONError"] });

      reject(error);
    });
});

export const getSubzonesGeoJson = ({ commit, state }) => new Promise((resolve, reject) => {
  if (state.subzonesGeoJson)
    resolve(state.subzonesGeoJson);
  else
    geoService.getGeojsonSubzones().then(res => {
      const geoJson = res.data;

      commit('setSubzonesGeoJson', geoJson);

      resolve(geoJson);
    }).catch(error => {
      displayErrorMessage(error, { general: ["geo.error.getGeoJSONError"] });

      reject(error);
    });
});

export const getAreasGeoJson = ({ commit, state }) => new Promise((resolve, reject) => {
  if (state.areasGeoJson)
    resolve(state.areasGeoJson);
  else
    geoService.getGeojsonAreas().then(res => {
      const geoJson = res.data;

      commit('setAreasGeoJson', geoJson);

      resolve(geoJson);
    }).catch(error => {
      displayErrorMessage(error, { general: ["geo.error.getGeoJSONError"] });

      reject(error);
    });
});