import { displayErrorMessage } from "@/api";

import AppClient from "@/api/internalusers";

export const getInternalUsers = ({ commit }) => new Promise((resolve, reject) => {
  AppClient.getInternalUsers().then(res => {
    const internalList = res.data;
    var totalInternalUsers = internalList.length;

    var usersList = {
      totalInternalUsers: totalInternalUsers,
      list: internalList
    }

    commit('setUsersList', usersList);
    resolve(usersList);
  }).catch(err => {
    displayErrorMessage(err, { general: ["user.error.getError"] });

    reject(err);
  });
});

export const getRoles = ({ commit }) => new Promise((resolve, reject) => {
  //console.log("Get roles!!!")
  AppClient.getRoles().then(res => {
    const rolesList = res.data;

    commit('setRolesList', rolesList);
    resolve(rolesList);
  }).catch(err => {
    displayErrorMessage(err, { general: ["user.error.getError"] });

    reject(err);
  });
});

export const getUser = ({ commit }, payload) => new Promise((resolve, reject) => {
  AppClient.getUser(payload.id).then(res => {
    commit('setOneUserData', res.data);

    resolve(res.data);
  }, err => {
    displayErrorMessage(err, { general: ["userdetails.error.getError"], notFound: ["userdetails.error.getDetailsNotFound", [payload.id]] });

    reject(err);
  });
});

export const getRolUser = ({ commit }, payload) => new Promise((resolve, reject) => {
  AppClient.getRolUser(payload.id).then(res => {
    const array1 = res.data.roles;
    const arrayRoles = [];
    array1.forEach(function (element) {
      arrayRoles.push(element.name)
    });


    commit('setroleUserData', arrayRoles);
    resolve(arrayRoles);
  }, err => {
    displayErrorMessage(err, { general: ["userdetails.error.getError"] });

    reject(err);
  });
});

