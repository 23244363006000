import dxDataGrid from "devextreme/ui/data_grid";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import {
  exportDataGrid
} from "devextreme/pdf_exporter";
import DateHelper from "./dateHelper";
import DxfExportHelper from "./DxfExportHelper";

export default class DxfPdfHelper extends DxfExportHelper {
  public export(component: dxDataGrid) {
    const doc = new jsPDF({
      orientation: "landscape",
    });
    const lastPoint = { x: 0, y: 0 };

    this.showAllColumns(component);

    exportDataGrid({
      jsPDFDocument: doc,
      component: component,
      margin: {
        top: 10,
        left: 10,
        right: 10,
        bottom: 10,
      },
      topLeft: { x: 1, y: 15 },
      //columnWidths: [30, 20, 30, 15, 22, 22, 20, 20],
      customDrawCell: ({ rect }) => {
        if (rect) {
          if (lastPoint.x < rect.x + rect.w) {
            lastPoint.x = rect.x + rect.w;
          }
          if (lastPoint.y < rect.y + rect.h) {
            lastPoint.y = rect.y + rect.h;
          }
        }
      },
      customizeCell: ({ gridCell, pdfCell }) => {
        if (gridCell && gridCell.column && pdfCell) {
          pdfCell.wordWrapEnabled = true;
          if (gridCell.rowType === "header") {
            pdfCell.textColor = "#ffffff";
            pdfCell.backgroundColor = "#2F75B5";
          } else if (gridCell.rowType === "data") {
            const cellTemplate = gridCell.column.cellTemplate;

            pdfCell.font = { size: 6 };

            if (cellTemplate === "dateFormat") {
              // Formato de fecha.
              pdfCell.text = DateHelper.dateFormat(
                gridCell.value,
                "DD/MM/YYYY HH:mm"
              );
            } else if (cellTemplate === "dateFormatUnix") {
              // Formato de fecha Unix.
              pdfCell.text = DateHelper.dateFormatUnix(
                gridCell.value,
                "DD/MM/YYYY HH:mm"
              );
            } else if (cellTemplate === "statusInstallation") {
              // Estado de la instalación.
              this.transformPdfCellStatusInstallation(gridCell.value, pdfCell);
            } else if (cellTemplate === "cellStatusChip") {
              this.transformPdfCellStatusChip(gridCell.value, pdfCell);
            } else if (cellTemplate === "cellStatusChipDervice") {
              this.transformPdfCellStatusChip(
                gridCell.value,
                pdfCell,
                gridCell.data.deployed
              );
            } else if (cellTemplate === "cellAppChipInfoUser") {
              this.transformPdfCellAppChipInfoUser(gridCell.value, pdfCell);
            } else if (cellTemplate === "chipJobs") {
              this.transformPdfCellChipJobs(gridCell.value, pdfCell);
            } else if (cellTemplate === "enableTick") {
              this.transformPdfCellEnableTick(gridCell.value, pdfCell);
            } else if (cellTemplate === "rolTick") {
              this.transformPdfCellRolTick(gridCell.value, pdfCell);
            }
          }
        }
      },
    }).then(() => {
      // Ponerse en la primera página.
      doc.setPage(1);
      // Cabecear.
      const headerText = this.title;
      doc.setFontSize(15);
      doc.text(headerText, 35, 20);
      // Logo.
      const imageUrl = process.env.VUE_APP_PUBLIC_PATH + "assets/blue.png";
      doc.addImage(imageUrl, "PNG", 10, 10, 25, 17);
      // Guardar el fichero.
      doc.save(this.getFileName() + ".pdf");
      // Volver a mostrar las columnas ocultas.
      this.restoreVisibilityColumns(component);
    });
  }
}
