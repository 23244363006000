/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Device } from "mediasoup-client";
export default function useMedia() {
  let consumerTransportVideo: any = null;
  let consumerTransportAudio: any = null;

  let producerTransport: any = null;
  let rtpCapabilities: any = null;
  let _srcObject: any = null;
  let _socket: any = null;
  //let consumerTransportId: any = null;

  async function join_call(
    json: any,
    srcObject: any,
    onClose: Function,
    socket: any
  ) {    
    _socket = socket;

    json = await new Promise(function (resolve, reject) {
      _socket.emit("join_call", json, function ({ error, result }: any) {
        if (error) return reject(error);
        resolve(result);
      });
    });

    console.log("join call data from signaling:", JSON.stringify(json));
    const json_ = await _join_call(json, srcObject, onClose);

    const consumer = await new Promise(function (resolve, reject) {
      _socket.emit(
        "transport_consume",
        json_,
        function ({ error, result }: any) {
          if (error) return reject(error);
          resolve(result);
        }
      );
    });

    return transport_consume(consumer);
  }

  async function _join_call(
    {
      iceServers,
      producerId,
      recvTransportVideo,
      recvTransportAudio,
      routerRtpCapabilities,
      sendTransport,
    }: any,
    srcObject: any,
    onClose: any
  ) {
    _srcObject = srcObject;

    // A device is an endpoint connecting to a `Router` on the server side to
    // send/receive media
    const device = new Device();

    await device.load({ routerRtpCapabilities });

    ({ rtpCapabilities } = device);

    // Create two local WebRTCTransports to send and receive RTP
    consumerTransportVideo = device.createRecvTransport({
      ...recvTransportVideo,
      iceServers,
    });

    consumerTransportAudio = device.createRecvTransport({
      ...recvTransportAudio,
      iceServers,
    });

    consumerTransportVideo.once(
      "connect",
      async ({ dtlsParameters }: any, callback: Function, errback: any) => {
        const json = { dtlsParameters, transportId: consumerTransportVideo.id };

        //Signal local DTLS parameters to the server side transport
        // see server's socket.on('transport-recv-connect', ...)
        _socket.emit("transport_connect", json, function ({ error }: any = {}) {
          // Tell the transport that something was wrong
          if (error) return errback(error);

          // Tell the transport that parameters were transmitted.
          callback();
        });
      }
    );

    consumerTransportAudio.once(
      "connect",
      async ({ dtlsParameters }: any, callback: Function, errback: any) => {
        const json = { dtlsParameters, transportId: consumerTransportAudio.id };

        _socket.emit("transport_connect", json, function ({ error }: any = {}) {
          // Tell the transport that something was wrong
          if (error) return errback(error);

          // Tell the transport that parameters were transmitted.
          callback();
        });
      }
    );

    producerTransport = device.createSendTransport({
      ...sendTransport,
      iceServers,
    });
    producerTransport.once(
      "connect",
      async ({ dtlsParameters }: any, callback: Function, errback: any) => {
        const json = { dtlsParameters, transportId: producerTransport.id };

        //Signal local DTLS parameters to the server side transport
        // see server's socket.on('transport-recv-connect', ...)
        _socket.emit("transport_connect", json, function ({ error }: any = {}) {
          // Tell the transport that something was wrong
          if (error) return errback(error);

          // Tell the transport that parameters were transmitted.
          callback();
        });
      }
    );

    // Close `Transport`s when receiving `end_up` message
    consumerTransportVideo.observer.once("close", onClose);

    _socket.once("end_up", function ({ code, reason }: any) {
      console.log(code, reason);

      consumerTransportVideo.close();
      consumerTransportAudio.close();
      producerTransport.close();
    });

    // TODO: find a way to don't move back and forward the producerId
    // TODO: remove transportId
    //return { producerId, rtpCapabilities, transportId: recvTransport.id };
    return { producerId, rtpCapabilities, transportId: recvTransportVideo.id };
  }

  async function transport_consume({
    id,
    kind,
    producerId,
    rtpParameters,
  }: any) {
    if (kind == "video") {
      const { track } = await consumerTransportVideo.consume({
        id,
        kind,
        producerId,
        rtpParameters,
      });
      _srcObject.addTrack(track);
    } else {
      const { track } = await consumerTransportAudio.consume({
        id,
        kind,
        producerId,
        rtpParameters,
      });
      _srcObject.addTrack(track);
    }
  }

  // function onConnect(params: any, callback: Function, errback: Function) {

  //     const dtlsParameters = params.dtlsParameters;

  //     const json = { dtlsParameters, transportId: this.id };

  //     //Signal local DTLS parameters to the server side transport
  //     // see server's socket.on('transport-recv-connect', ...)
  //     _socket.emit("transport_connect", json, function ({ error }: any = {}) {
  //         // Tell the transport that something was wrong
  //         if (error) return errback(error);

  //         // Tell the transport that parameters were transmitted.
  //         callback();
  //     });
  // }

  async function pickup(track: any) {
    let consumer;

    producerTransport.once(
      "produce",
      function (parameters: any, callback: any, errback: any) {
        const json = { parameters, rtpCapabilities };

        _socket.emit("pickup", json, function ({ error, result }: any) {
          // TODO: clean-up resources from previous call
          if (error) return errback(error);

          let id;
          ({ consumer, producerId: id } = result);

          callback({ id });
        });
      }
    );
    await producerTransport.produce({ track, zeroRtpOnPause: true });
    return transport_consume(consumer);
  }

  function hang_up() {
    if (_socket == undefined) {
      return Promise.resolve();
    }

    if (producerTransport!==null)
        producerTransport.close();

    return new Promise(function (resolve: Function, reject: Function) {
      _socket.emit("hang_up", {}, function ({ error }: any = {}) {
        if (error) return reject(error);
        return resolve();
      });
    });
  }

  return { join_call, hang_up, pickup };
}
