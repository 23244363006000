export const setUsersList = (state, response) => {
    state.usersList = response;
  };

  export const setOneUserData = (state, response) => {
    state.oneUserData = response;
  }

  export const setroleUserData = (state, response) => {
    state.roleUserData = response;
  }

  export const setRolesList = (state, response) => {
    state.rolesList = response;
  }
  

  

