<template>
  <div>
    <v-select 
      v-model="localValue" 
      :menu-props="menuProps" 
      :label="label"
      :items="items" 
      :item-text="itemText" 
      :item-value="itemValue" 
      :return-object="returnObject" 
      :rules="rules" 
      :single-line="singleLine" 
      :clearable="clearable" 
      :multiple="multiple"  
      :loading="loading"
      :disabled="disabled"
      :readonly="readonly"
      @change="onChange"
    />
  </div>
</template>

<!-- eslint-disable @typescript-eslint/ban-types -->
<!-- eslint-disable @typescript-eslint/no-explicit-any -->
<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class AppSelect extends Vue {
  @Prop() value!: any;
  @Prop(Array) items!: Array<any>;
  @Prop(String) label!: String;
  @Prop(String) itemText!: String;
  @Prop(String) itemValue!: String;
  @Prop(Array) rules!: Array<any>;
  @Prop(Boolean) required!: boolean;
  @Prop(Boolean) singleLine!: boolean;
  @Prop(Boolean) returnObject!: boolean;
  @Prop(Boolean) clearable!: boolean;
  @Prop(Boolean) multiple!: boolean;
  @Prop(Boolean) loading!: boolean;
  @Prop(Boolean) disabled!: boolean;
  @Prop(Boolean) readonly!: boolean;

  menuProps = { offsetY: true, closeOnClick: true, closeOnContentClick: true };

  get localValue() {
    return this.value;
  }

  set localValue(localValue) {
    this.$emit("input", localValue);
  }

  onChange(v) {
    this.$emit("change", v);
  }
}
</script>