import Vue from "vue";

export default class extends Vue {
  is_empty(str) {
    return !str || 0 === str.length;
  }
  has_content(str) {
    return !this.is_empty(str);
  }
  all_has_content(...strs) {
    let result = true;
    let i = 0;

    while (i < strs.length && result) {
      if (this.is_empty(strs[i])) result = false;

      i++;
    }

    return result;
  }
}

// export default {

//     methods: {
//         is_empty(str) {
//             return (!str || 0 === str.length);
//         },

//         has_content(str) {
//             return !this.is_empty(str);
//         },

//         all_has_content(...strs) {
//             let result = true;
//             let i = 0;

//             while (i < strs.length && result) {
//                 if (this.is_empty(strs[i]))
//                     result = false;

//                 i++;
//             }

//             return result;
//         }
//     }

// }
