import Vue from "vue";
import "es6-promise/auto";
import Vuex from "vuex";
import * as getters from "./getters";
import * as actions from "./actions";
import * as mutations from "./mutations";


import users from './users';
import jobs from './jobs';
import firmwares from './firmwares';
import devices from './devices';
import dashboard from './dashboard';
import oauthclients from './oauthclients';
import installations from './installations';
import plans from './plans';
import internalusers from './internalusers';
import geo from './geo';
import roles from './roles';
import taboo from './taboo';


Vue.use(Vuex);

const remember = localStorage.getItem("remember") === "true";

const state = {
  // Login information
  isUserLogged: remember ? localStorage.getItem("isUserLogged") === "true" : false,
  user: remember ? localStorage.getItem("user") : null,
  password: remember ? localStorage.getItem("password") : null,
  remember: remember ? localStorage.getItem("remember") : null,
  accessToken: remember ? localStorage.getItem("accessToken") : null,
  expiresIn: remember ? localStorage.getItem("expiresIn") : null,
  getMyOauth: "",
  
  // To show the info and error snackbar
  snackbar: false,
  snackText: null,
  snackbarInfo: false,
  snackTextInfo: null,

  // Notifications
  notifications: []
};

const store = new Vuex.Store({
  state,
  getters,
  actions,
  mutations,
  modules: {
    users,
    jobs,
    firmwares,
    devices,
    dashboard,
    oauthclients,
    installations,
    plans,
    internalusers,
    geo,
    roles,
    taboo
  }
});

if (module.hot) {
  module.hot.accept(["./getters", "./actions", "./mutations"], () => {
    store.hotUpdate({
      getters: require("./getters"),
      actions: require("./actions"),
      mutations: require("./mutations")
    });
  });
}

export default store;
