import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import Chip from "primevue/chip/Chip";
import JobEntity from "@/domain/entities/JobEntity";
import JobModel from "@/ui/models/JobModel";

@Component({
  components: {
    Chip,
  },
})
export default class ChipJobStatus extends Vue {
  @Prop() status!: string;

  jobModel: JobModel = this.createJobModel();

  @Watch("status")
  onStatusChanged() {
    this.jobModel = this.createJobModel();
  }

  createJobModel(): JobModel {
    const jobEntity = new JobEntity({ status: this.status });
    return new JobModel(jobEntity);
  }
}
