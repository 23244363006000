export const setInstallationsList = (state, response) => {
  state.installationsList = response;
};

export const setNumberOfInstallations = (state, response) => {
  state.numberOfInstallations = response;
};

export const setInstallationDetails = (state, response) => {
  state.installationDetails = response;
};

export const setInstallationTopology = (state, response) => {
  state.installationTopology = response;
};

export const setNumberOfTypeInstallations = (state, response) => {
  state.numberOfTypeInstallations = response;
};