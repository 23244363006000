<template lang="html" src="./JobCreateModal.html"></template>

<!-- eslint-disable @typescript-eslint/ban-types -->
<!-- eslint-disable @typescript-eslint/no-explicit-any -->
<script lang="ts">
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import jobService, { SIZE_MASSIVE_JOB } from "@/api/job";
import JobFwSelector from "./general/JobFwSelector/JobFwSelector.vue";
import DeviceFiltering from "./general/DeviceFiltering/DeviceFiltering.vue";
import InstallationFwsSelector from "./installation/InstallationFwsSelector/InstallationFwsSelector.vue";
import dateMixin from "@/mixins/date.mixin";
import jobsFinishedWatcher from "../jobs-finished.watcher";

@Component({
  components: {
    JobFwSelector,
    DeviceFiltering,
    InstallationFwsSelector,
  },

  mixins: [dateMixin],
})
export default class JobCreateModal extends Vue {
  $moment: any;
  @Prop(Boolean) value!: boolean;
  @Prop(Object) device!: any;
  @Prop(Array) installation!: Array<any>;
  @Prop(String) installationId!: string;
  @Prop(Function) onSuccess!: Function;
  @Prop(String) versionHWProp!: string;

  downgrade = false;
  date_format!: Function;
  versionHW = null;
  swVersionMax = null;
  swVersionMin = null;
  olString = null;
  zone = null;
  subzone = null;
  area = null;

  $refs!: {
    formStep1: any;
    formStep2: any;
    formStep3: any;
    formStep4: any;
  };

  deviceCacheDetails: any = {};
  dialogSteps = 1;
  wharnWhenFinished = false;

  isApplying = false;
  jobsCreated = 0;
  applyConfirmDialog = false;

  openPanel = 0;

  // Step 1
  jobName = null;
  deferredStart = false;

  datePickermodal = false;
  //datePicked = null;
  datePicked = this.addDate(1);

  timePickermodal = false;
  timePicked = this.addHoursMinutes(0, 0);

  // Step 2
  devicesTypeInfo: any = {};

  selectedFws: any = [];

  // Step 3
  isFiltering = false;
  deviceFilters: any = {};

  selectedDevicesForJob: any = [];

  // Validators
  validFormStep1 = false;
  validFormStep2 = false;
  validFormStep3 = false;
  validFormStep4 = false;

  get show() {
    return this.value;
  }

  set show(localValue) {
    this.$emit("input", localValue);
  }

  get numberDevicesForJob() {
    return this.$store.getters["jobs/numberDevicesForJob"];
  }

  // Step 1
  get nameRules() {
    return [
      (v) => !!v || this.$t("jobs.rules.nameRequired"),
      (v) => !v || v.length <= 32 || this.$t("jobs.rules.jobNameMaxLength"),
    ];
  }

  get dateRules() {
    return [(v) => !!v || this.$t("updatefw.reqdate")] ;
  }

  get timeRules() {   
    return [(v) => !!v || this.$t("updatefw.reqtime"),
    (v) => this.validateDateTime(this.datePicked, v) || this.$t('updatefw.invalidtime') 
    ];
  }
  
  validateDateTime(selectedDate, selectedTime) {    
      if (!selectedDate || !selectedTime) return true;
      
      // Obtener la fecha y hora actuales
      const now = new Date();
      const currentDate = now.toISOString().split('T')[0];
      const currentHours = now.getHours().toString().padStart(2, '0');
      const currentMinutes = now.getMinutes().toString().padStart(2, '0');
      const currentTime = `${currentHours}:${currentMinutes}`;     
      // Comparar la fecha y la hora seleccionadas con la fecha y hora actuales
      if (selectedDate < currentDate) {
        return false;
      } else if (selectedDate === currentDate && selectedTime < currentTime) {
        return false;
      }
      return true;
    }

  @Watch('datePicked')
  onTimePickedChange() {
    // Obtener la hora actual
    const currentTime = new Date().toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' });
    this.timePicked = currentTime;
  }

  get currentDate() {
    return this.date_format(new Date(), "YYYY-MM-DD");
  }

  get currentDatePlusThirty() {
    const date = new Date();
    const dateTimestamp = date.setDate(date.getDate() + 30);
    const dateString = this.date_format(dateTimestamp, "YYYY-MM-DD");

    return dateString;
  }

  // Step 2

  get isAnyFirmwareIntroduced() {
    return !!this.selectedFws.find((f) => f !== null);
  }

  get isMassiveJob() {
    if (
      !!this.installation ||
      this.$store.getters["jobs/numberDevicesForJob"] <= SIZE_MASSIVE_JOB
    ) {
      return false;
    } else {
      return true;
    }
  }

  get dateToUpdate() {
    if (this.deferredStart) {
      return this.datePicked + "T" + this.timePicked + ":00Z";
    } else {
      return this.$moment().utc().format();
    }
  }

  get timeLastUpdate() {
    let numberOfBlocks = Math.floor(this.numberDevicesForJob / 200) * 20;
    return this.$moment(this.dateToUpdate)
      .add(numberOfBlocks, "minute")
      .utc()
      .format("HH:mm");
  }

  addDate(days: number) {
    const date = new Date();
    const dateTimestamp = date.setDate(date.getDate() + days);
    const dateString = this.date_format(dateTimestamp, "YYYY-MM-DD");

    return dateString;
  }

  addHoursMinutes(hours: number, minutes: number) {
    const date = new Date();
    let dateTimestamp = date.setMinutes(minutes);
    dateTimestamp = date.setHours(hours);
    const dateString = this.date_format(dateTimestamp, "HH:mm");

    return dateString;
  }

  @Watch("show")
  onChangeShow() {
    if (this.show) this.openDialog();
  }

  openDialog() {
    if (this.$refs.formStep1) this.$refs.formStep1.reset();

    if (this.device) {
      const { family, type, subtype, versionHW } = this.device;

      this.devicesTypeInfo = { family, type, subtype, versionHW };
    }
  }

  cancelDialog() {
    if (!this.isApplying) {
      this.dialogSteps = 1;
      this.show = false;

      this.resetFormValues();
    }
  }

  resetFormValues() {
    this.jobName = null;
    this.wharnWhenFinished = false;
    this.deferredStart = false;
    this.datePickermodal = false;
    this.datePicked = this.addDate(1);
    this.timePickermodal = false;
    this.timePicked = this.addHoursMinutes(0, 0);

    this.selectedFws = [];
    this.deviceFilters = {};
    this.devicesTypeInfo = {};
    this.isFiltering = false;
    this.selectedDevicesForJob = [];

    this.jobsCreated = 0;
    this.isApplying = false;

    this.$refs.formStep1.resetValidation();
    this.$refs.formStep2.resetValidation();
    this.$refs.formStep4.resetValidation();
  }

  checkEmpty() {
    return (
      !!this.olString &&
      !!this.zone &&
      !!this.subzone &&
      !!this.area &&
      !!this.swVersionMin &&
      !!this.swVersionMax
    );
  }

  goStep(stepNumber) {
    if (stepNumber === 3) {
      this.versionHW = this.selectedFws[0] && this.selectedFws[0].versionHW;
      if (!this.versionHW) this.versionHW = this.devicesTypeInfo.versionHW;
    }

    this.dialogSteps = stepNumber;
  }

  // Step 2
  onFwSelectorChange(data) {
    if (!this.device) this.selectedDevicesForJob = data.devices;
  }

  // Apply job
  applyJobSendRequest(jobInfo) {
    const selectedFws = this.selectedFws.filter((sf) => sf);

    jobService
      .applyJob(jobInfo)
      .then((res) => {
        if (this.wharnWhenFinished)
          for (let i = 0; i < res.data.length; i++)
            jobsFinishedWatcher({
              id: res.data[i],
              immediate: jobInfo.immediate,
            });

        this.jobsCreated++;
        if (this.jobsCreated === selectedFws.length) {
          this.applyConfirmDialog = false;
          this.show = false;

          this.dialogSteps = 1;

          this.resetFormValues();

          if (this.onSuccess) this.onSuccess();

          this.$store.dispatch("snackbarInfo", {
            text: this.$t("jobs.info.jobCreated"),
          });
        }
      })
      .catch((err) => {
        this.jobsCreated++;
        if (this.jobsCreated === selectedFws.length) {
          this.isApplying = false;
          this.applyConfirmDialog = false;
        }        

        const errorText = this.getErrorText(err.response.data.detail);

        this.$store.dispatch("errorHandler", {
          error: err,
          notFoundText: ["jobs.error.notExistFirmware"],
          notAcceptableText: ["jobs.error.deferredNeedsDate"],
          conflictText: [errorText],
          lengthHeaderRequired: ["jobs.error.noDevice"],
          genericErrorText: ["jobs.error.createJob"],
        });
      });
  }

  getErrorText(detail:any) {
    const codeError = this.extractErrorCode(detail);
    return codeError
      ? this.$t(`jobs.error.${codeError}`).toString()
      : this.$t("jobs.error.devicesWithJob").toString();
  }

  extractErrorCode(errorMessage: string): string | null {    
    const match = errorMessage.match(/^(\w+):/);
    return match ? match[1] : null;
  }

  applyJob() {
    this.isApplying = true;
    this.jobsCreated = 0;

    for (let i = 0; i < this.selectedFws.length; i++) {
      const selectedFw: any = this.selectedFws[i];

      if (selectedFw) {
        let devices = this.device
          ? [this.device.serialNumber]
          : this.selectedDevicesForJob;

        let jobName: any = this.jobName;
        if (this.installation) {
          jobName += i + 1;

          devices = devices.filter((d) =>
            this.installation[i].devices.find((insd) => insd.serialNumber === d)
          );
        }

        const applyJobInfo = {
          jobName: jobName,
          dateToStart: this.dateToUpdate,
          devices: this.isMassiveJob ? [] : devices,
          idFirmware: selectedFw.id,
          immediate: !this.deferredStart,
          oL: this.deviceFilters.olString ? this.deviceFilters.olString : null,
          zone: this.deviceFilters.zone ? this.deviceFilters.zone.name : null,
          subzone: this.deviceFilters.subzone
            ? this.deviceFilters.subzone.name
            : null,
          area: this.deviceFilters.area ? this.deviceFilters.area.name : null,
          swVersionMin: this.deviceFilters.swVersionMin
            ? this.deviceFilters.swVersionMin
            : null,
          swVersionMax: this.deviceFilters.swVersionMax
            ? this.deviceFilters.swVersionMax
            : null,
          installationId: this.deviceFilters.installationId
            ? this.deviceFilters.installationId
            : null,
          massive: this.isMassiveJob,
          downgrade: this.downgrade,
        };

        this.applyJobSendRequest(applyJobInfo);
      }
    }
  }
}
</script>
<style>
.job-resume .v-expansion-panel-content .v-list-item__title,
.job-resume .v-expansion-panel-content .v-list-item__subtitle {
  font-size: 14.5px;
}
.job-resume .v-expansion-panel-content .v-list-item__title {
  font-weight: normal;
  margin-bottom: 5px;
}
.job-resume .v-expansion-panel-content .v-list-item__subtitle {
  margin-bottom: 10px;
}
.v-btn.very-small .v-btn__content {
  font-size: 11px;
}
.contentFormStep1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 2rem;
}
</style>
