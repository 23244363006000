<template lang="html" src="./MarkerMap.html"></template>

<!-- eslint-disable @typescript-eslint/ban-types -->
<!-- eslint-disable @typescript-eslint/no-explicit-any -->
<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator";
import L from "leaflet";
import PopupTableMap from "@/components/PopupTableMap/PopupTableMap.vue";
import {
  LMap,
  LTileLayer,
  LMarker,
  LIcon,
  LControl,
  LPopup,
} from "vue2-leaflet";
import { mapOptions, LAT_FERMAX, LONG_FERMAX, getMarkerLatLong, markerIcon } from "@/mixins/marker";

declare const window: any;

@Component({
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
    LControl,
    LPopup,
    PopupTableMap,
  },
  //mixins: [markerMixin],
})
export default class MarkerMap extends Vue {
  @Prop(String) markerName!: string;
  @Prop({ type: Object }) marker!: any;
  @Prop({ type: Function }) getIcon!: Function;

  tileLayerUrl = `https://api.maptiler.com/maps/${window.maptilerconfig.MAP_ID}/{z}/{x}/{y}.png?key=${window.maptilerconfig.KEY}` // Idioma local en cada pais
  tileLayerAttribution = '<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>'

  //mapOptions: any

  currentZoom = 7;
  iconSize = [30, 30];
  circle: any = null;
  minZoomToShowPrecission = 10;
  popupOptions = {
    closeButton: false,
  };

  $refs!: {
    myMap: LMap;
  };

  get mapOptions() {
    return mapOptions;
  }

  getMarkerLatLong(name, marker) {
    return getMarkerLatLong(name, marker)
  }

  markerIcon(path) {
    markerIcon(path)
  }

  get currentCenter() {
    const marker = { ...this.marker } as any;

    if (
      (!marker.latitude && marker.latitude !== 0) ||
      (!marker.longitude && marker.longitude !== 0)
    ) {
      return L.latLng(LAT_FERMAX, LONG_FERMAX);
    }

    return L.latLng(marker.latitude, marker.longitude);
  }

  get notAcurracy() {
    return this.marker && !this.marker.accuracy;
  }

  mounted() {
    this.$nextTick(() => {
      this.$refs.myMap.mapObject.invalidateSize(true);
    });
  }

  zoomUpdate(zoom) {
    this.currentZoom = zoom;

    if (this.currentZoom >= this.minZoomToShowPrecission) {
      if (this.circle === null) {
        this.circle = L.circle(
          [
            this.marker.latitude || LAT_FERMAX,
            this.marker.longitude || LONG_FERMAX,
          ],
          { radius: this.marker.accuracy }
        );
        this.circle.addTo(this.$refs.myMap.mapObject);
      }
    } else {
      if (this.circle !== null) {
        this.circle.removeFrom(this.$refs.myMap.mapObject);
        this.circle = null;
      }
    }
  }
}
</script>

<style scoped>
.geomap-container {
  width: 100%;
  opacity: 0.99;
}
.geomap-container .leaflet-container {
  height: 100%;
}
</style>

<style>
.geomap-container.no-marker-point path[stroke="#3388ff"].leaflet-interactive {
  display: none;
}
.geomap-container .leaflet-bar a {
  color: rgb(0, 0, 0, 0.54);
}
</style>
