import i18n from "@/lang/i18n";

export const isUserLogged = (state) => state.isUserLogged;
export const user = (state) => state.user;
export const password = (state) => state.password;
export const accessToken = (state) => state.accessToken;
export const expiresIn = (state) => state.expiresIn;
export const remember = (state) => state.remember;

export const getMyOauth = () => {
  return window.frontenv.OAUTH_URL;
};

export const getMyAuth = () => {
  return window.frontenv.FRONT_AUTH;
};

export const getCallDiverts = () => {
  return window.frontenv.CALLDIVERT_URLS;
};
/* La url de la api se configura dentro del proyecto https://github.com/fermax-sw/swbluedeploymentrepository
 en la varaible URI_MYCONNECT (para devel: environments/development/environments/devel/configmap/frontenv.yaml) */
export const getUriMyConnect = () => {
  return window.frontenv.URI_MYCONNECT;
};

/* La url de la api se configura dentro del proyecto https://github.com/fermax-sw/swbluedeploymentrepository
 en la varaible URI_GRAFANA_DEVICE (para devel: environments/development/environments/devel/configmap/frontenv.yaml) */
export const getUriGrafanaDevice = () => {
  return window.frontenv.URI_GRAFANA_DEVICE;
};

export const breadcrumbItems = () => (payload) => {
  const createItem = (label, link = null) => ({
    text: i18n.t(label),
    to: link,
    disabled: link === null,
  });

  switch (payload.path.name) {
    case "Devices":
      return [createItem("menu.devices")];
    case "DeviceDetails":
      return [
        createItem("menu.devices", "devices"),
        createItem("menu.deviceDetails"),
      ];
    case "Users":
      return [createItem("menu.users")];
    case "Billing":
      return [createItem("menu.billing")];
    case "Jobs":
      return [createItem("menu.jobs")];
    case "JobDetails":
      return [createItem("menu.jobs", "jobs"), createItem("menu.jobDetails")];
    case "Dashboard":
      return [createItem("menu.dashboard")];
    case "Firmwares":
      return [createItem("menu.firmware")];
    case "OAuthClients":
      return [createItem("menu.oauthclients")];
    case "UserDetails":
      return [
        createItem("menu.users", "users"),
        createItem("menu.userDetails"),
      ];
    case "Installations":
      return [createItem("menu.installations")];
    case "InstallationDetails":
      return [
        createItem("menu.installations", "installations"),
        createItem("menu.installationDetails"),
      ];
    case "InternalUsers":
      return [createItem("menu.internalUsers")];
    case "InternalDetails":
      return [
        createItem("menu.internalUsers", "internalusers"),
        createItem("user.userDetails"),
      ];
    case "Roles":
      return [createItem("menu.titleRoles")];
    case "DetailsRoles":
      return [
        createItem("menu.titleRoles", "roles"),
        createItem("roles.rolDetails"),
      ];
    case "Plans":
      return [createItem("menu.plans")];
    case "PlanDetails":
      return [
        createItem("menu.plans", "plans"),
        createItem("menu.planDetails"),
      ];
    case "Taboo":
      return [createItem("menu.titleTaboo")];
  }
};

export const snackbar = (state) => state.snackbar;
export const snackText = (state) => state.snackText;
export const snackbarInfo = (state) => state.snackbarInfo;
export const snackTextInfo = (state) => state.snackTextInfo;

export const notifications = (state) => state.notifications;
export const pendingNotificationsCount = (state) =>
  state.notifications.filter((n) => !n.dismissed).length;
