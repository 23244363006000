<template lang="html" src="./Devices.html"></template>

<!-- eslint-disable @typescript-eslint/no-explicit-any -->
<!-- eslint-disable @typescript-eslint/ban-types -->
<script lang="ts">
import { Vue, Component, Watch } from "vue-property-decorator";
import AppBreadcrumbs from "@/components/AppBreadcrumbs.vue";
import AppKpiChipInfo from "@/components/AppKpiChipInfo.vue";
import sessionStorageMixin from "@/mixins/session.storage.mixin";
import { hasRol } from "@/casl";

import { displayErrorMessage } from "@/api";
import deviceService from "@/api/device";

import DeviceTabItem from "./DeviceTabItem/DeviceTabItem.vue";

const keyDeviceTab = "Devices.tab";

export const TAB_TYPE = {
  WIFI_MONITORS: "monwifi",
  NOWIFI_MONITORS: "monnowifi",
  PHONES: "phones",
  GUARD: "guard",
  PANELS_4G: "panels4g",
  EDIBOX: "edibox",
  ALL_DEVICES: "all",
};

@Component({
  components: {
    AppBreadcrumbs,
    AppKpiChipInfo,
    DeviceTabItem,
  },
  mixins: [sessionStorageMixin],
})
export default class Devices extends Vue {
  // session_setItem!: Function;
  // session_getItem!: Function;

  numberOfDevices = null;
  numberOfConnectedDevices = null;
  TAB_TYPE = TAB_TYPE as any;
  currentTab = +this.session_getItem(keyDeviceTab);
  renderedTabs = [0];
  showRefreshDevicesConfirm = false;
  refreshDevicesIsLoading = false;
  loadingData = false;

  @Watch("currentTab")
  onChangeCurrentTab(v) {
    this.session_setItem(keyDeviceTab, v.toString());
    if (this.renderedTabs.indexOf(v) === -1) this.renderedTabs.push(v);
  }

  session_getItem(key, defaultValue = "") {
    const value = sessionStorage.getItem(key);
    return value !== null && value !== undefined ? value : defaultValue;
  }

  session_setItem(key, value) {
    if (value === null || value === undefined || value === "") {
      sessionStorage.removeItem(key);
    } else {
      sessionStorage.setItem(key, value);
    }
  }

  mounted() {
    this.getNumberOfDevices();
    this.getNumberOfDevicesConnected();
    if (hasRol("ROLE_OPENDIT")) {
      this.TAB_TYPE = {
        NOWIFI_MONITORS: "monnowifi",
        PHONES: "phones",
        GUARD: "guard",
        PANELS_4G: "panels4g",
        EDIBOX: "edibox",
      };
    }
  }

  getNumberOfDevices() {
    deviceService.getNumberOfDevices().then(
      (response) => {
        this.numberOfDevices = response.data;
      },
      (error) => {
        this.loadingData = false;

        displayErrorMessage(error, { general: ["device.error.getError"] });
      }
    );
  }

  getNumberOfDevicesConnected() {
    deviceService.getNumberOfDevicesConnected().then(
      (response) => {
        this.numberOfConnectedDevices = response.data;
      },
      (error) =>
        displayErrorMessage(error, { general: ["device.error.getError"] })
    );
  }

  refreshDevices() {
    this.showRefreshDevicesConfirm = false;
    this.refreshDevicesIsLoading = true;

    deviceService
      .refreshDevices()
      .then(() => {
        this.refreshDevicesIsLoading = false;

        const cTab = this.$refs["tab" + this.currentTab] as any;
        const ref = cTab; //this.$refs["tab" + this.currentTab][0];
        ref.filterClick(); // Search current list again

        this.renderedTabs = [this.currentTab]; // Delete other tabs data

        this.$store.dispatch("snackbarInfo", {
          text: this.$t("device.refreshDevices.success"),
        });
      })
      .catch((err) => {
        this.refreshDevicesIsLoading = false;

        displayErrorMessage(err, {
          general: ["device.error.refreshingError"],
          tooManyRequests: ["device.error.refreshingActiveError"],
        });
      });
  }
}
</script>
