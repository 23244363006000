<template lang="html" src="./PlanEditDescriptionModal.html"></template>
<!-- eslint-disable @typescript-eslint/ban-types -->
<!-- eslint-disable @typescript-eslint/no-explicit-any -->
<script lang="ts">
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { BLUA_LANGUAGES } from "@/mixins/plan.mixin";
import LanguagesHelper from "../../../../../../helpers/languagesHelper";

import { displayErrorMessage } from "@/api";
import katalogService from "@/api/katalog";

import AppSelect from "@/components/AppSelect.vue";
import { RegionalDetail } from "@/interfaces/plans";

@Component({
  components: {
    AppSelect,
  },
})
export default class PlanEditDescriptionModal extends Vue {
  @Prop(Object) regionalDetail!: RegionalDetail | null;
  @Prop(Array) currentLanguages!: Array<any>;

  dataForm: RegionalDetail = {
    commercialName: null,
    description: null,
    language: null,
    longDescription: null,
    plan: null,
    regionalDetailsId: null,
  };

  isSaving = false;

  $refs: any = {
    form: HTMLFormElement,
  };

  @Watch("regionalDetail", { immediate: true, deep: true })
  onChangeRegionalDetail(newValue: RegionalDetail | null) {
    if (newValue === null) {
      this.dataForm = {
        commercialName: null,
        description: null,
        language: null,
        longDescription: null,
        plan: null,
        regionalDetailsId: null,
      };
    } else {
      this.dataForm = { ...newValue };
    }
  }

  /** Indica si el formulario es nuevo o es una edición. */
  get isNew(): boolean {
    return this.regionalDetail === null;
  }

  /** Obtiene un listado de los idiomas con su código y descripción. */
  get languages() {
    const langs = BLUA_LANGUAGES.map((i) => ({
      code: i.toUpperCase(),
      name: LanguagesHelper.getLanguageName(i),
    }));

    return this.isNew
      ? langs.filter(
          (l) => !this.currentLanguages.find((cl) => cl.language === l.code)
        )
      : langs;
  }

  /** Un array con las validaciones de los campos del formulario. */
  get fieldRequired(): any[] {
    return [(value) => !!value || this.$t("general.fieldIsRequired")];
  }

  /** Cuando se cierra la ventana se resetean los datos. */
  close() {
    this.$refs.form.reset();
    this.$refs.form.resetValidation();
    this.$emit("onCloseRequest");
  }

  save(e) {
    e.preventDefault();

    if (!this.$refs.form.validate()) return false;

    this.isSaving = true;

    let planCode = this.$route.params.planCode;

    katalogService.savePlanLanguage(planCode, this.dataForm).then(
      (res) => {
        this.isSaving = false;
        this.$refs.form.reset();
        this.$refs.form.resetValidation();
        this.$emit("onSaveRequest", res.data, this.isNew);
      },
      (err) => {
        this.isSaving = false;

        displayErrorMessage(err, {
          general: ["planDetails.error.languageSaving"],
        });
      }
    );
  }
}
</script>
