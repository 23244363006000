<template lang="html" src="./MapSearch.html"></template>

<script lang="ts">
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

@Component
export default class MapSearch extends Vue {
  @Prop(String) search!: string;

  dialogVisible = false;
  searchValue = this.search;

  get searchActive() {
    return this.searchValue !== null && this.searchValue !== undefined && this.searchValue.length > 0;
  }

  @Watch('search')
  onSearchChange(newSearch) {
    this.searchValue = newSearch;
  }

  openDialog() {
    this.dialogVisible = true;
  }

  closeDialog() {
    this.dialogVisible = false;
    this.searchValue = this.search;
  }

  applySearch() {
    this.$emit("applySearch", this.searchValue);
    this.closeDialog();
  }
}
</script>

<style scoped>
.monitors-map-search .search-active {
  color: #82b1ff !important;
  font-weight: bold;
}

.monitors-map-search .theme--light.v-btn {
  color: rgb(0, 0, 0, .54);
}

</style>
