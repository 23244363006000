export const setFirmwaresInfo = (state, response) => {
  state.firmwaresInfo = response;
};
export const setNumberDevices = (state, response) => {
  state.numberOfDeviceTypes = response;
};

export const setFirmwaresPerType = (state, response) => {
  state.firmwaresPerType = response;
};
export const setListFamilies = (state, response) => {
  state.listFamilies = response
};
export const setListTypes = (state, response) => {
  state.listTypes = response
};
export const setListSubtypes = (state, response) => {
  state.listSubtypes = response
};

export const setOneFirmwareInfoByType = (state, response) => {
  state.oneFirmwareInfoByType = response
};

export const setFirmwareRecord = (state, response) => {
  state.firmwareRecord = response
};
