<template lang="html" src="./InternalDetails.html"></template>

<!-- eslint-disable @typescript-eslint/no-explicit-any -->
<!-- eslint-disable @typescript-eslint/ban-types -->
<!-- eslint-disable no-useless-escape -->
<script lang="ts">
import { Vue, Component } from "vue-property-decorator";

import AppBreadcrumbs from "@/components/AppBreadcrumbs.vue";
import AppChipInfo from "@/components/AppChipInfo.vue";
import AppListMenu from "@/components/AppListMenu.vue";

import DialogChangePassword from "./DialogChangePassword/DialogChangePassword.vue";
import DialogChangeRoles from "./DialogChangeRoles/DialogChangeRoles.vue";
import DialogDelete from "./DialogDelete/DialogDelete.vue";
import { InternalUserEntity } from "@/domain/entities/InternalUserEntity";
import { InternalUserController } from "@/domain/controllers/InternalUserController";

@Component({
  components: {
    AppBreadcrumbs,
    AppChipInfo,
    AppListMenu,
    DialogChangePassword,
    DialogChangeRoles,
    DialogDelete,
  },
})
export default class InternalUsersList extends Vue {
  //#region Diálogo cambio de contraseña
  /** Muestra u oculta el dialogo de cambio de contraseña */
  showDialogChangePassword = false;
  /** Abre el dialogo de cambio de contraseña */
  openDialogChangePassword() {
    this.showDialogChangePassword = true;
  }
  /** Cierra el dialogo de cambio de contraseña */
  cancelDialogChangePassword() {
    this.showDialogChangePassword = false;
  }
  //#endregion

  //#region Diálogo cambio de roles
  /** Muetsra u oculta el dialogo de cambio de roles */
  showDialogChangeRoles = false;
  /** Abre el dialogo de cambio de roles */
  openDialogChangeRoles() {
    this.showDialogChangeRoles = true;
  }
  /** Cierra el dialogo de cambio de roles */
  cancelDialogChangeRoles() {
    this.showDialogChangeRoles = false;
  }
  //#endregion

  //#region Diálogo eliminar usuario interno

  /** Muestra u oculta el dialogo de eliminar usuario interno */
  showDialogDelete = false;
  /** Abre el dialogo de eliminar usuario interno */
  openDialogDelete() {
    this.showDialogDelete = true;
  }
  /** Cierra el dialogo de eliminar usuario interno */
  cancelDialogDelete() {
    this.showDialogDelete = false;
  }

  //#endregion

  /** Usuario interno */
  internalUser: InternalUserEntity = new InternalUserEntity();
  /** Controlador de usuarios internos */
  ctrlInternalUser = new InternalUserController();

  get menuOptions() {
    return [
      {
        icon: "edit",
        text: this.$t("internalusers.editInternalUser"),
        action: () => this.openDialogChangePassword(),
        allowed:
          !this.internalUser.isSuperAdmin &&
          this.$ability.can("edit", "internalusers"),
      },
      {
        icon: "person_add",
        text: this.$t("internalusers.editRoles"),
        action: () => this.openDialogChangeRoles(),
        allowed:
          !this.internalUser.isSuperAdmin &&
          this.$ability.can("edit", "internalusers"),
      },
      {
        icon: "delete",
        text: this.$t("internalusers.deleteUser"),
        action: () => this.openDialogDelete(),
        allowed:
          !this.internalUser.isSuperAdmin &&
          this.$ability.can("delete", "internalusers"),
      },
    ];
  }

  mounted() {
    this.ctrlInternalUser.get(this.$route.params.id).then((internalUser) => {
      this.internalUser = internalUser;
    });
  }

  /*editUserProperties() {
    this.infoDialog = false;
    this.editDialog = false;
    const editProps = {
      email: this.emailData.email,
      password: this.currentPassword,
    };
    // To update the properties in edit
    AppClient.setPassEdit(editProps)
      .then(() => {
        this.refreshList();
      })
      .catch((err) => {
        displayErrorMessage(err, {
          general: ["oauthclients.error.updateFail"],
        });
      });

    this.refreshList();
  }*/

  /*editUserRole() {
    this.infoDialogRol = false;
    this.dialogRol = false;

    // To update the properties in edit
    AppClient.setInsertRoles(this.id, this.userRol)
      .then(() => {
        this.refreshListRol();
      })
      .catch((err) => {
        displayErrorMessage(err, {
          general: ["oauthclients.error.updateFail"],
        });
      });
  }*/

  /*removeUsers() {
    if (this.roleData[0] === "ROLE_ADMIN" && this.id != 1) {
      this.dialogSecurity = true;

      if (this.dataSecurity === this.id) {
        this.showConfirmRemoveUser = false;

        AppClient.removeInternalUsers(this.id)
          .then(() => {
            this.$store.dispatch("snackbarInfo", {
              text: this.$t("internalusers.deletedJob"),
            });
            this.$router.push("internalusers");
            this.refreshList();
          })
          .catch((err) => {
            this.$store.dispatch("errorHandler", {
              error: err,
              genericErrorText: ["internalusers.deleteFail"],
            });
          });
      }
    } else {
      this.showConfirmRemoveUser = false;

      AppClient.removeInternalUsers(this.id)
        .then(() => {
          this.$store.dispatch("snackbarInfo", {
            text: this.$t("internalusers.deletedJob"),
          });
          this.$router.push("internalusers");
          this.refreshList();
        })
        .catch((err) => {
          this.$store.dispatch("errorHandler", {
            error: err,
            genericErrorText: ["internalusers.deleteFail"],
          });
        });
    }
  }*/

  onDelete() {
    this.$router.push("internalusers");
  }
}
</script>
