import JobEntity from "@/domain/entities/JobEntity";
import TranslateText from "../helpers/TranslateText";

export default class JobModel {
  private jobEntity: JobEntity;
  constructor(jobEntity: JobEntity) {
    this.jobEntity = jobEntity;
  }

  getStatusColorPdf() {
    if (this.jobEntity.isWaiting()) {
      return "#ff9800";
    } else if (this.jobEntity.isInProgress()) {
      return "#2196f3";
    } else if (this.jobEntity.isCompleted()) {
      return "#4caf50";
    } else if (this.jobEntity.isCancelled()) {
      return "#000";
    } else if (this.jobEntity.isCancelling()) {
      return "#878787";
    } else if (this.jobEntity.isFailed()) {
      return "#e03131";
    } else if (this.jobEntity.isScheduled()) {
      return "#5fc5d1";
    } else if (this.jobEntity.isWarning()) {
      return "#e1c22b";
    }
    return "";
  }

  getStatusText() {
    if (this.jobEntity.isWaiting()) {
      return TranslateText.t("jobs.status.waiting");
    } else if (this.jobEntity.isInProgress()) {
      return TranslateText.t("jobs.status.running");
    } else if (this.jobEntity.isCompleted()) {
      return TranslateText.t("jobs.status.finished");
    } else if (this.jobEntity.isCancelled()) {
      return TranslateText.t("jobs.status.cancelled");
    } else if (this.jobEntity.isCancelling()) {
      return TranslateText.t("jobs.status.cancelling");
    } else if (this.jobEntity.isFailed()) {
      return TranslateText.t("jobs.status.failed");
    } else if (this.jobEntity.isScheduled()) {
      return TranslateText.t("jobs.status.scheduled");
    } else if (this.jobEntity.isWarning()) {
      return TranslateText.t("jobs.status.warning");
    }
    return "";
  }

  static getStatusCombobox(): Array<{ value: string; text: string }> {
    return [
      { value: "WAITING", text: TranslateText.t("jobs.status.waiting") },
      { value: "INPROGRESS", text: TranslateText.t("jobs.status.running") },
      { value: "COMPLETED", text: TranslateText.t("jobs.status.finished") },
      { value: "CANCELLED", text: TranslateText.t("jobs.status.cancelled") },
      { value: "CANCELLING", text: TranslateText.t("jobs.status.cancelling") },
      { value: "FAILED", text: TranslateText.t("jobs.status.failed") },
      { value: "SCHEDULED", text: TranslateText.t("jobs.status.scheduled") },
      { value: "WARNING", text: TranslateText.t("jobs.status.warning") },
    ];
  }

  getStatusClass() {
    if (this.jobEntity.isWaiting()) {
      return "waiting";
    } else if (this.jobEntity.isInProgress()) {
      return "inprogress";
    } else if (this.jobEntity.isCompleted()) {
      return "completed";
    } else if (this.jobEntity.isCancelled()) {
      return "cancelled";
    } else if (this.jobEntity.isCancelling()) {
      return "cancelling";
    } else if (this.jobEntity.isFailed()) {
      return "failed";
    } else if (this.jobEntity.isScheduled()) {
      return "scheduled";
    } else if (this.jobEntity.isWarning()) {
      return "warningStatus";
    }
    return "";
  }
}
