import { InternalUserController } from "@/domain/controllers/InternalUserController";
import { InternalUserEntity } from "@/domain/entities/InternalUserEntity";
import { EMAIL_REGEX, PASSWORD_REGEX } from "@/ui/helpers/FormRules";
import { Vue, Component, Prop, Ref } from "vue-property-decorator";

@Component({
  name: "DialogNewInternalUser",
})
export default class DialogUploadFW extends Vue {
  /** Muestra u oculta el dialog. */
  @Prop({ default: false }) showDialog!: boolean;
  /** Controlador de usuarios internos. */
  ctrlInternalUser = new InternalUserController();
  /** Posición del step activo. */
  activeStep = 1;
  /** Usuario interno. */
  internalUser: InternalUserEntity = new InternalUserEntity();

  cancel() {
    this.$emit("cancel");
    this.activeStep = 1;
    this.internalUser = new InternalUserEntity();
    this.userRol = [];
    this.formStep1.reset();
    this.formStep1.resetValidation();
    this.formStep2.reset();
    this.formStep2.resetValidation();
    this.formStep3.reset();
    this.formStep3.resetValidation();
  }

  //#region Step 1
  validStep1 = false;
  @Ref("formStep1") formStep1!: HTMLFormElement;
  get emailRules() {
    const emailRegex = EMAIL_REGEX;
    return [
      (v) => !!v || this.$t("login.error.emptyEmail"),
      (v) => emailRegex.test(v) || this.$t("login.error.email"),
    ];
  }

  continueStep1() {
    this.activeStep = 2;
  }

  //#endregion
  //#region Step 2
  validStep2 = false;
  @Ref("formStep2") formStep2!: HTMLFormElement;
  get passwordRules() {
    const passwordRegex = PASSWORD_REGEX;
    return [
      (v) => !!v || this.$t("login.error.emptyPassword"),
      (v) => passwordRegex.test(v) || this.$t("login.error.passwordInternal"),
    ];
  }

  async continueStep2() {
    await this.ctrlInternalUser.getRoles().then((res) => {
      this.roleList = res;
    });
    this.activeStep = 3;
  }

  generateSecret() {
    this.internalUser.generatePassword();
  }

  //#endregion
  //#region Step 3
  validStep3 = false;
  @Ref("formStep3") formStep3!: HTMLFormElement;
  userRol: string[] = [];
  roleList: string[] = [];

  get rolRules() {
    return [
      (v) => !!v || this.$t("internalusers.error.emptyRol"),
      (v) => v.length > 0 || this.$t("internalusers.error.emptyRol"),
    ];
  }

  async continueStep3() {
    this.internalUser.setRolesText(this.userRol);
    this.$emit("save", this.internalUser);
    this.cancel();
  }
  //#endregion
}
