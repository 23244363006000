<template lang="html" src="./DeviceTypeSelector.html"></template>

<!-- eslint-disable @typescript-eslint/no-explicit-any -->
<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator";
import { PANEL_FAMILY_CODE } from "@/constants";
import { EDIBOX_FAMILY_CODE, PANEL_TYPE_CODE } from "@/constants";

import AppSelect from "@/components/AppSelect.vue";

const PHONE_FAMILY = "PHONE";
const SKYLINE_TYPE = "SKYLINE";
const CITYLINE_TYPE = "CITYLINE";
const MARINE_TYPE = "MARINE";

@Component({
  components: {
    AppSelect,
  },
})
export default class DeviceTypeSelector extends Vue {
  @Prop(Object) form!: any;
  @Prop(Object) typeInfo!: any;

  typeKey = 0;
  subtypeKey = 0;
  hwVersionKey = 0;
  PANEL_FAMILY_CODE = PANEL_FAMILY_CODE;
  EDIBOX_FAMILY_CODE = EDIBOX_FAMILY_CODE;
  PANEL_TYPE_CODE = PANEL_TYPE_CODE
  isLoading: any = null;
  hasFocus = false;

  get localTypeInfo() {
    return this.typeInfo;
  }

  set localTypeInfo(v) {
    this.$emit("update:typeInfo", v);
  }

  get listFamilies() {
    const filterFamily = this.$store.getters["firmwares/listFamilies"].filter(
      (f) => f.name != PHONE_FAMILY
    );
    filterFamily.forEach(
      (item) => (item.text = this.$t("device.deviceDetails." + item.name))
    );
    return filterFamily;
  }

  get listTypes() {
    let types = this.$store.getters["firmwares/listTypes"].filter(
      (t) => t.name != SKYLINE_TYPE && t.name != CITYLINE_TYPE && t.name != MARINE_TYPE
    );

    types.forEach(
      (item) => (item.text = this.$t("device.deviceDetails." + item.name))
    );
    return types;
  }

  get listSubtypes() {
    let subtypes = this.$store.getters["firmwares/listSubtypes"];
    subtypes.forEach(
      (item) => (item.text = this.$t("device.deviceDetails." + item.name))
    );
    return subtypes;
  }

  get notEmptyRule() {
    return [(v) => !!v || this.$t("firmware.fieldrequired")];
  }

  mounted() {
    this.$store.dispatch("firmwares/getDeviceFamily");
  }

  emitChange() {    
    if (!this.hasFocus) this.hwVersionKey++;
    this.$emit("change", {
      family: this.localTypeInfo.family,
      type: this.localTypeInfo.type,
      subtype: this.localTypeInfo.subtype,
      versionHW: this.localTypeInfo.versionHW,
      versionHWProp: this.localTypeInfo.versionHW,
    });
  }

  updateDeviceType() {
    this.form.resetValidation();

    this.localTypeInfo.type = null;
    this.localTypeInfo.subtype = null;

    if (
      this.localTypeInfo?.family &&      
      this.localTypeInfo.family !== EDIBOX_FAMILY_CODE
    ) {
      this.isLoading = "type";
      this.$store
        .dispatch("firmwares/getDeviceType", {
          family: this.localTypeInfo.family,
        })
        .then(() => (this.isLoading = null));
    }

    this.emitChange();
    this.typeKey++;
    this.subtypeKey++;
  }

  updateDeviceSubtype() {    
    if (this.localTypeInfo?.type) {
      this.isLoading = "subtype";

      this.form.resetValidation();
      this.localTypeInfo.subtype = null;

      this.$store
        .dispatch("firmwares/getDeviceSubtype", {
          family: this.localTypeInfo.family,
          type: this.localTypeInfo.type,
        })
        .then(() => (this.isLoading = null));

      //this.emitChange();
      this.subtypeKey++;
    }
    this.emitChange();
  }
}
</script>
