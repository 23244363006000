<template lang="html" src="./LegendModes.html"></template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import { EnumMapMode } from "../EnumMapMode";

@Component
export default class LegendModes extends Vue {
  @Prop(String) mode!: string;

  iconSize = 18;
  
  get buttonZonesColor() {
    return this.mode === EnumMapMode.zones ? "blue accent-1" : "";
  }

  get buttonSubzonesColor() {
    return this.mode === EnumMapMode.subzones ? "blue accent-1" : "";
  }

  get buttonAreasColor() {
    return this.mode === EnumMapMode.areas ? "blue accent-1" : "";
  }

  setModeZones() {
    this.$emit('setModeZones');
  }

  setModeSubzones() {
    this.$emit('setModeSubzones');
  }

  setModeAreas() {
    this.$emit('setModeAreas');
  }
}
</script>

<style scoped>
.legend-modes .mode-active {
  color: #82b1ff !important;
}
.legend-modes .v-btn {
  margin: 0;
}
.v-btn .v-btn__content .v-icon {
  color: rgb(0, 0, 0, 0.54);
}
</style>
