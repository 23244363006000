import * as getters from "./getters";
import * as actions from "./actions";
import * as mutations from "./mutations";

const state = {
  plansListInfo: {},
  planDetails: null,
  servicesList: [],
  servicesByPlan: [],
  commercialServicesByPlan: [],
  parentPlansList: []
};

export default ({
  namespaced: true,
  state,
  getters,
  actions,
  mutations
});