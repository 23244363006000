<template lang="html" src="./HeatMap.html"></template>

<!-- eslint-disable @typescript-eslint/ban-types -->
<!-- eslint-disable @typescript-eslint/no-explicit-any -->
<script lang="ts">
declare const window: any;
import { Vue, Component, Prop } from 'vue-property-decorator'
import L from "leaflet";
import { LControl, LMap, LTileLayer } from "vue2-leaflet";
//import markerMixin from "@/mixins/marker.mixin";
import * as marker from '@/mixins/marker'
import { LGeoJson } from "vue2-leaflet";

import PopupTableMap from "@/components/PopupTableMap/PopupTableMap.vue";


@Component({
  components: {
    LMap,
    LTileLayer,
    LGeoJson,
    LControl,
    PopupTableMap
  },
  //mixins: [markerMixin]
})
export default class HeatMap extends Vue {
  @Prop(String) markerName!: string;
  @Prop({type: Object}) marker!: any;

  currentZoom = 7;
  geojson = null;
  geojsonRev = 0;
  activePopup: any = null;
  isLoading = false;
  //mapOptions = {}

  tileLayerUrl = `https://api.maptiler.com/maps/${window.maptilerconfig.MAP_ID}/{z}/{x}/{y}.png?key=${window.maptilerconfig.KEY}` // Idioma local en cada pais
  tileLayerAttribution = '<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>'

  $refs!: {
    myMap: LMap,
    geoJson: any,
    tooltip: HTMLElement
  }

  mounted() {
    this.loadAreas();

    this.$nextTick(() => {
      this.$refs.myMap.mapObject.invalidateSize(true);
    });
  }

  get mapOptions() {
    return marker.mapOptions
  }
  
  styleFeature() {
    return {
      fillColor: "#e5ad5e",
      weight: 2,
      opacity: 1,
      color: "white",
      dashArray: "3",
      fillOpacity: 0.7
    };
  }

  resetHighlight(e) {
    const layer = e.target._path;
    layer.removeAttribute("stroke");
  }

  createPopup() {
    return this.$refs.tooltip.innerHTML;
  }

  clickOnFeature(e) {
    this.activePopup = L.popup({ closeButton: false });
    const popupContent = this.createPopup();

    this.activePopup
      .setLatLng(e.latlng)
      .setContent(popupContent)
      .openOn(this.$refs.myMap.mapObject);
  }

  onEachFeature(feature, layer: any) {
    // Se añaden eventos para resaltar cuando el ratón pasa por encima, y para detectar el click sobre una feature
    layer.on({
      mouseover: this.highlightFeature,
      mouseout: (this as any).resetHighlight,
      click: (this as any).clickOnFeature
    });

    if (feature.properties) {
      feature.properties.bounds_calculated = layer.getBounds();
    }
  }

  highlightFeature(e) {
    const layer = e.target;

    layer.setStyle({
      weight: 5,
      color: "#666",
      dashArray: "",
      fillOpacity: 0.7
    });

    if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
      layer.bringToFront();
    }
  }

  addGeojson(geojson) {
    if (geojson) {
      this.geojson= geojson;

      // Forzamos la actualización de la capa
      this.geojsonRev = Math.random();

      // Ajustamos el bounds del mapa al bounds de las features del geojson
      this.$nextTick(() => {
        this.$refs.myMap.mapObject.fitBounds(this.$refs.geoJson.getBounds());
      });
    }
  }

  loadAreas() {
    this.isLoading = true;

    this.$store.dispatch("geo/getAreasGeoJson").then(res => {
      const geojson = JSON.parse(JSON.stringify(res));

      this.filterAreas(geojson, this.marker.area);

      this.addGeojson(geojson);

      this.isLoading = false;
    });
  }

  filterAreas(geojson, area) {
    if (geojson && area) {
      geojson.features = geojson.features.filter(
        x => x.properties.name === area
      );
    }
  }

  zoomUpdate(zoom) {
    this.currentZoom = zoom;
  }
}
</script>

<style scoped>
.geomap-container {
  width: 100%;
  opacity: 0.99;
}
.geomap-container .leaflet-container {
  height: 100%;
}
.tooltip{
  display: none;
}
</style>

<style>
.geomap-container.no-marker-point path[stroke="#3388ff"].leaflet-interactive {
  display: none;
}
.geomap-container .leaflet-bar a {
  color: rgb(0, 0, 0, 0.54);
}
</style>
