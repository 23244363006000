<template lang="html" src="./NotificationsMenu.html"></template>

<script>
import dateMixin from "@/mixins/date.mixin";

export default {
  name: "NotificationsMenu",
  mixins: [dateMixin],

  computed: {
    notifications(){
      return this.$store.getters["notifications"];
    }
  },

  methods: {
    dismissAll(){
      this.$store.dispatch("dismissAllNotifications");
    },
    makeAction(notificationId, action){
      if(action) action();

      this.dismiss(notificationId);
    },
    dismiss(notificationId){
      this.$store.dispatch("dismissNotification", notificationId)
    }
  }
};
</script>