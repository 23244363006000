export const downloadData = (title, ext, data) => {
  const limitchar = 50;
  let csvname = title;

  if (csvname.length > limitchar) {
    csvname = csvname.substring(0, limitchar - 1);
  }

  const link = document.createElement("a");
  link.setAttribute("href", data);
  link.setAttribute("download", csvname +"."+ ext);

  document.body.appendChild(link);
  link.click();
  link.remove();
}