<template lang="html" src="./Dashboard.html"></template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'
import ChartCard from "./ChartCard.vue";
import PieChart from "./PieChart/PieChart.vue";
import LineChart from "./LineChart/LineChart.vue";

@Component({
  components: {
    ChartCard,
    PieChart,
    LineChart 
  }
})
export default class Dashboard extends Vue {
  cardHeight: number | null = null;

  get totalDeviceDeployed() {
    return this.getValue("totalDeviceDeployed");
  }

  get totalDeviceManufactured() {
    return this.getValue("totalDeviceManufactured");
  }

  get totalDeviceOnLine() {
    return this.getValue("totalDeviceOnLine");
  }

  get totalDevicesUpdated() {
    return this.getValue("totalDevicesUpdated");
  }

  get totalDevices() {
    return this.getValue("totalDevices");
  }

  get totalOnlineWifiMonitors() {
    return this.getValue("totalOnlineWifiMonitors");
  }

  get totalOfflineWifiMonitors() {
    return this.getValue("totalOfflineWifiMonitors");
  }

  get totalWifiMonitorsDeployed() {
    return this.getValue("totalWifiMonitorsDeployed");
  }

  get totalWifiMonitorsManufactured() {
    return this.getValue("totalWifiMonitorsManufactured");
  }

  get totalPairedUsers() {
    return this.getValue("totalPairedUsers");
  }

  get totalUserNumber() {
    return this.getValue("totalUserNumber");
  }

  get totalLastYearUserNumber() {
    return JSON.parse(this.getValue("totalLastYearUserNumber"));
  }

  get totalLastMonthUserNumber() {
    return JSON.parse(this.getValue("totalLastMonthUserNumber"));
  }

  get data_Deployed_vs_Manufactured() {
    return [this.totalDeviceDeployed, this.totalDeviceManufactured];
  }

  get data_OnLine_vs_Deployed() {
    return [
      this.totalDeviceOnLine,
      this.totalDeviceDeployed - this.totalDeviceOnLine
    ];
  }

  get data_Updated_vs_Total() {
    return [
      +this.totalDevicesUpdated,
      this.totalDevices - this.totalDevicesUpdated
    ];
  }

  get data_OnlineWifiMonitors_vs_OfflineWifiMonitors() {
    return [this.totalOnlineWifiMonitors, this.totalOfflineWifiMonitors];
  }

  get data_WifiMonitorsDeployed_vs_WifiMonitorsManufactured() {
    return [
      this.totalWifiMonitorsDeployed,
      this.totalWifiMonitorsManufactured
    ];
  }

  get data_Paired_vs_TotalUsers() {
    return [
      this.totalPairedUsers,
      this.totalUserNumber - this.totalPairedUsers
    ];
  }

  get title_Deployed_vs_Manufactured() {
    let value = this.$t("dashboard.totalDevices");

    if (
      this.data_Deployed_vs_Manufactured &&
      this.data_Deployed_vs_Manufactured.length === 2 && 
      (this.data_Deployed_vs_Manufactured[0] || this.data_Deployed_vs_Manufactured[0] === 0) && 
      (this.data_Deployed_vs_Manufactured[1] || this.data_Deployed_vs_Manufactured[1] === 0)
    ) {
      const total =
        +this.data_Deployed_vs_Manufactured[0] +
        +this.data_Deployed_vs_Manufactured[1];
      value += " (" + total + ")";
    }

    return value;
  }

  get title_OnLine_vs_Deployed() {
    let value = this.$t("dashboard.deployedDevices");

    if (
      this.data_OnLine_vs_Deployed &&
      this.data_OnLine_vs_Deployed.length === 2 && 
      (this.data_OnLine_vs_Deployed[0] || this.data_OnLine_vs_Deployed[0] === 0) && 
      (this.data_OnLine_vs_Deployed[1] || this.data_OnLine_vs_Deployed[1] === 0)
    ) {
      const total =
        +this.data_OnLine_vs_Deployed[0] +
        +this.data_OnLine_vs_Deployed[1];
      value += " (" + total + ")";
    }

    return value;
  }

  get title_Updated_vs_Total() {
    let value = this.$t("dashboard.updatedDevices");

    if (
      this.data_Updated_vs_Total &&
      this.data_Updated_vs_Total.length === 2 && 
      (this.data_Updated_vs_Total[0] || this.data_Updated_vs_Total[0] === 0) && 
      (this.data_Updated_vs_Total[1] || this.data_Updated_vs_Total[1] === 0)
    ) {
      const total =
        +this.data_Updated_vs_Total[0] +
        +this.data_Updated_vs_Total[1];
      value += " (" + total + ")";
    }

    return value;
  }

  get title_WifiMonitorsDeployed_vs_WifiMonitorsManufactured() {
    let value = this.$t("dashboard.totalWifiMonitors");

    if (
      this.data_WifiMonitorsDeployed_vs_WifiMonitorsManufactured &&
      this.data_WifiMonitorsDeployed_vs_WifiMonitorsManufactured.length === 2 && 
      (this.data_WifiMonitorsDeployed_vs_WifiMonitorsManufactured[0] || this.data_WifiMonitorsDeployed_vs_WifiMonitorsManufactured[0] === 0) && 
      (this.data_WifiMonitorsDeployed_vs_WifiMonitorsManufactured[1] || this.data_WifiMonitorsDeployed_vs_WifiMonitorsManufactured[1] === 0)
    ) {
      const total =
        +this.data_WifiMonitorsDeployed_vs_WifiMonitorsManufactured[0] +
        +this.data_WifiMonitorsDeployed_vs_WifiMonitorsManufactured[1];
      value += " (" + total + ")";
    }

    return value;
  }

  get title_OnlineWifiMonitors_vs_OfflineWifiMonitors() {
    let value = this.$t("dashboard.deployedWifiMonitors");

    if (
      this.data_OnlineWifiMonitors_vs_OfflineWifiMonitors &&
      this.data_OnlineWifiMonitors_vs_OfflineWifiMonitors.length === 2 && 
      (this.data_OnlineWifiMonitors_vs_OfflineWifiMonitors[0] || this.data_OnlineWifiMonitors_vs_OfflineWifiMonitors[0] === 0) && 
      (this.data_OnlineWifiMonitors_vs_OfflineWifiMonitors[1] || this.data_OnlineWifiMonitors_vs_OfflineWifiMonitors[1] === 0)
    ) {
      const total =
        +this.data_OnlineWifiMonitors_vs_OfflineWifiMonitors[0] +
        +this.data_OnlineWifiMonitors_vs_OfflineWifiMonitors[1];
      value += " (" + total + ")";
    }

    return value;
  }

  get title_Paired_vs_TotalUsers() {
    let value = this.$t("dashboard.totalUsers");

    if (
      this.data_Paired_vs_TotalUsers &&
      this.data_Paired_vs_TotalUsers.length === 2 && 
      (this.data_Paired_vs_TotalUsers[0] || this.data_Paired_vs_TotalUsers[0] === 0) && 
      (this.data_Paired_vs_TotalUsers[1] || this.data_Paired_vs_TotalUsers[1] === 0)
    ) {
      const total =
        +this.data_Paired_vs_TotalUsers[0] +
        +this.data_Paired_vs_TotalUsers[1];
      value += " (" + total + ")";
    }

    return value;
  }

  get data_Last_Year_Users() {
    return this.totalLastYearUserNumber
      ? this.totalLastYearUserNumber.map(item => item.counter)
      : null;
  }

  get labels_Last_Year_Users() {
    return this.totalLastYearUserNumber
      ? this.totalLastYearUserNumber.map(item => item.init)
      : null;
  }

  get data_Last_Month_Users() {
    return this.totalLastMonthUserNumber
      ? this.totalLastMonthUserNumber.map(item => item.counter)
      : null;
  }

  get labels_Last_Month_Users() {
    return this.totalLastMonthUserNumber
      ? this.totalLastMonthUserNumber.map(item => item.init)
      : null;
  }

  get lastUpdate_Deployed_vs_Manufactured() {
    const hoursLastUpdate = this.getMax(
      this.getHoursLastUpdate("totalDeviceDeployed"),
      this.getHoursLastUpdate("totalDeviceManufactured")
    );
    return hoursLastUpdate;
  }

  get lastUpdate_OnLine_vs_Deployed() {
    const hoursLastUpdate = this.getMax(
      this.getHoursLastUpdate("totalDeviceOnLine"),
      this.getHoursLastUpdate("totalDeviceDeployed")
    );
    return hoursLastUpdate;
  }

  get lastUpdate_Updated_vs_Total() {
    const hoursLastUpdate = this.getMax(
      this.getHoursLastUpdate("totalDevicesUpdated"),
      this.getHoursLastUpdate("totalDevices")
    );
    return hoursLastUpdate;
  }

  get lastUpdate_OnlineWifiMonitors_vs_OfflineWifiMonitors() {
    const hoursLastUpdate = this.getMax(
      this.getHoursLastUpdate("totalOnlineWifiMonitors"),
      this.getHoursLastUpdate("totalOfflineWifiMonitors")
    );
    return hoursLastUpdate;
  }

  get lastUpdate_WifiMonitorsDeployed_vs_WifiMonitorsManufactured() {
    const hoursLastUpdate = this.getMax(
      this.getHoursLastUpdate("totalWifiMonitorsDeployed"),
      this.getHoursLastUpdate("totalWifiMonitorsManufactured")
    );
    return hoursLastUpdate;
  }

  get lastUpdate_Paired_vs_TotalUsers() {
    const hoursLastUpdate = this.getMax(
      this.getHoursLastUpdate("totalPairedUsers"),
      this.getHoursLastUpdate("totalUserNumber")
    );
    return hoursLastUpdate;
  }

  get lastUpdate_LastYearUserNumber() {
    const hoursLastUpdate = this.getHoursLastUpdate(
      "totalLastYearUserNumber"
    );
    return hoursLastUpdate;
  }

  get lastUpdate_LastMonthUserNumber() {
    const hoursLastUpdate = this.getHoursLastUpdate(
      "totalLastMonthUserNumber"
    );
    return hoursLastUpdate;
  }
  
  mounted() {
    this.getCardHeight();
    this.getKpis();
  }

  getCardHeight() {
    const windowHeight = window.innerHeight;

    // Se le resta a la altura total:
    // - 50px de la barra superior
    // - 10px espacio entre la primera fila de cards y la barra superior

    this.cardHeight = (windowHeight - (50 + 10 + 10 + 20 + 10)) / 3;
    // Se establece una altura mínima de 180 para su correcta visualización en móviles
    this.cardHeight = this.cardHeight < 180 ? 180 : this.cardHeight;
  }

  getKpis() {
    this.$store.dispatch("dashboard/getTotalDeviceDeployed").then(() => {
      this.$store
        .dispatch("dashboard/getTotalDeviceManufactured")
        .then(() => {
          this.$store.dispatch("dashboard/getTotalDeviceOnLine");
        });
    });

    this.$store.dispatch("dashboard/getTotalDevicesUpdated").then(() => {
      this.$store.dispatch("dashboard/getTotalDevices");
    });

    this.$store.dispatch("dashboard/getTotalOnlineWifiMonitors").then(() => {
      this.$store.dispatch("dashboard/getTotalOfflineWifiMonitors");
    });

    this.$store
      .dispatch("dashboard/getTotalWifiMonitorsDeployed")
      .then(() => {
        this.$store.dispatch("dashboard/getTotalWifiMonitorsManufactured");
      });

    this.$store.dispatch("dashboard/getTotalPairedUsers").then(() => {
      this.$store.dispatch("dashboard/getTotalUserNumber");
    });

    this.$store.dispatch("dashboard/getTotalLastYearUserNumber");

    this.$store.dispatch("dashboard/getTotalLastMonthUserNumber");
  }

  getObject(key) {
    return key && this.$store.getters["dashboard/" + key]
      ? this.$store.getters["dashboard/" + key]
      : null;
  }

  getValue(key) {
    const object = this.getObject(key);
    return object ? object.value : null;
  }

  getHoursLastUpdate(key) {
    const object = this.getObject(key);
    return object ? object.hoursLastUpdate : null;
  }

  getMax(value1, value2) {
    return value1 && value2 && Number(value1) > Number(value2)
      ? value1
      : value2;
  }

  hasEmptyValues(values) {
    return values && (values[0] == 0 && values[1] == 0);
  }
}
</script>

<style scoped>
.over{
overflow: hidden !important;
padding-left: 10px;
} 

.pad{
  padding: 8px !important;
}

.altura{
  height: 286px;
}

.card-flex{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.cont{
overflow: hidden !important;
} 

.row:not(.kpis) {
  margin: 0 !important;
}
span.subheading {
  font-size: 16px;
}
.v-card__text {
  overflow: hidden;
}
.last-update {
  font-size: 10px;
  white-space: nowrap;
}   

@media screen and (max-width:600px){
.over{
overflow: visible !important;
} 

.mob{
  height: calc(100vh - 70px); 
  position: relative;
  overflow: visible;
} 
}
  
</style>