import { render, staticRenderFns } from "./DerviceTable.html?vue&type=template&id=905165b8&external"
import script from "./DerviceTable.ts?vue&type=script&lang=ts&external"
export * from "./DerviceTable.ts?vue&type=script&lang=ts&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports