/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, {
  AxiosError,
  AxiosInstance,
  AxiosProgressEvent,
  AxiosRequestConfig,
} from "axios";

export class AxiosHelper {
  private static instance: AxiosHelper;
  private axiosService: AxiosInstance;

  private constructor() {
    this.axiosService = axios.create();
  }
  public static getInstance(): AxiosHelper {
    if (!AxiosHelper.instance) {
      AxiosHelper.instance = new AxiosHelper();
    }
    return AxiosHelper.instance;
  }

  public get interceptors() {
    return this.axiosService.interceptors;
  }

  /** Realiza una petición get. */
  public async get(url: string, params?: any): Promise<any> {
    const config: AxiosRequestConfig = {
      method: "get",
      url: url,
    };
    if (params) {
      config.params = params;
    }
    return this.request(config);
  }
  /** Realiza una petición post. */
  public async post(url: string, params: any): Promise<any> {
    const config: AxiosRequestConfig = {
      method: "post",
      url: url,
    };
    if (params) {
      config.data = params;
    }
    return this.request(config);
  }
  /** Realiza una petición put. */
  public async put(url: string, params: any): Promise<any> {
    const config: AxiosRequestConfig = {
      method: "put",
      url: url,
    };
    if (params) {
      config.data = params;
    }
    return this.request(config);
  }
  /** Realiza una petición delete. */
  public async delete(url: string): Promise<any> {
    const config: AxiosRequestConfig = {
      method: "delete",
      url: url,
    };
    return this.request(config);
  }
  /** Realiza una petición patch. */
  public async patch(url: string, params: any): Promise<any> {
    const config: AxiosRequestConfig = {
      method: "patch",
      url: url,
    };
    if (params) {
      config.data = params;
    }
    return this.request(config);
  }
  public async uploadFile(
    url: string,
    file: File,
    uploadFileProgress: (progressEvent: AxiosProgressEvent) => void
  ): Promise<any> {
    const formData = new FormData();
    formData.append("file", file);
    const config: AxiosRequestConfig = {
      method: "put",
      url: url,
      headers: { "Content-Type": "multipart/form-data" },
      data: formData,
      onUploadProgress: uploadFileProgress,
    };
    return this.request(config);
  }
  /** Realiza una petición. */
  public async request(config: AxiosRequestConfig): Promise<any> {
    return this.axiosService
      .request(config)
      .then((res) => {
        return res.data;
      })
      .catch((err: AxiosError) => {
        throw err;
      });
  }
}
