<template>
  <v-tooltip left>
    <template v-slot:activator="{ on }">
      <v-btn icon @click="maximize" v-on="on">
        <v-icon dark>{{ fullScreenIcon }}</v-icon>
      </v-btn>
    </template>
    <span>{{ $t("device.fullScreen") }}</span>
  </v-tooltip>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class FullscreenButton extends Vue {
  @Prop(Boolean) value!: boolean;

  get localValue() {
    return this.value;
  }

  set localValue(value) {
    this.$emit("input", value);
  }

  get fullScreenIcon() {
    return this.localValue
      ? "fa-compress-arrows-alt"
      : "fa-expand-arrows-alt";
  }

  maximize(e) {
    this.localValue = !this.localValue;
    e.stopPropagation();
  }
}
</script>