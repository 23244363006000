import * as i18nIsoCountries from 'i18n-iso-countries';
import i18n from "@/lang/i18n";

export default {
    methods: {
        getCountry(countryCode) {
            return i18nIsoCountries.getName(countryCode, i18n.locale)
        },

        getCountries() {
            return i18nIsoCountries.getNames(i18n.locale);
        }
    }
}
