import * as getters from "./getters";
import * as actions from "./actions";
import * as mutations from "./mutations";

const state = {
  zonesGeoJson: null,
  subzonesGeoJson: null,
  areasGeoJson: null
};

export default ({
  namespaced: true,
  state,
  getters,
  actions,
  mutations
});