<template lang="html" src="./InstallationsFilter.html"></template>

<!-- eslint-disable @typescript-eslint/no-explicit-any -->
<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import AppInputDate from "@/components/AppInputDate.vue";
import AppSelect from "@/components/AppSelect.vue";
import AppFilterButtons from "@/components/AppFilterButtons.vue";
import TableGeoFilters from "@/components/TableGeoFilters/TableGeoFilters.vue";
import AppAutocomplete from "@/components/AppAutocomplete.vue";
import installationService from "@/api/installation";
import { displayErrorMessage } from "@/api";




export const FILTER_DEFINITIONS = {
  idFilter: {
    literal: "installation.id"
  },
  tagFilter: {
    literal: "installation.tag"
  },
  statusFilter: {
    literal: "installation.status",
    valuesLiteral: {
      CONNECTED: "installation.online",
      DISCONNECTED: "installation.offline"
    }
  },
  coverageFilter: {
    literal: "installation.coverage",
    valuesLiteral: {
      20: "ranges.coverage.20",
      50: "ranges.coverage.50",
      75: "ranges.coverage.75",
      100: "ranges.coverage.100",
    }
  },
  createdAtValueStart: {
    literal: "installation.createdStartDate",
    format: "date"
  },
  createdAtValueEnd: {
    literal: "installation.createdEndDate",
    format: "date"
  },
  updatedAtValueStart: {
    literal: "installation.updatedStartDate",
    format: "date"
  },
  updatedAtValueEnd: {
    literal: "installation.updatedEndDate",
    format: "date"
  },
  cityFilter: {
    literal: "installation.city",
  },
  panels: {
    literal: "installation.panel",
    valuesLiteral: {
      1: "ranges.panels.1",
      5: "ranges.panels.5",
      10: "ranges.panels.10",
      20: "ranges.panels.20",
      100: "ranges.panels.100"
    }
  },
  monitors: {
    literal: "installation.monitors",
    valuesLiteral: {
      10: "ranges.monitors.10",
      20: "ranges.monitors.20",
      50: "ranges.monitors.50",      
      100: "ranges.monitors.100"
    }
  },
  phone: {
    literal: "installation.phone",
    valuesLiteral: {
      10: "ranges.monitors.10",
      20: "ranges.monitors.20",
      50: "ranges.monitors.50",      
      100: "ranges.monitors.100"
    }
  },
  guard: {
    literal: "installation.guard",
    valuesLiteral: {
      1: "ranges.guard.1",
      5: "ranges.guard.5",
      100: "ranges.guard.100"
    }
  },
  ediboxes: {
    literal: "installation.box",
    valuesLiteral: {
      1: "ranges.panels.1",
      5: "ranges.panels.5",
      10: "ranges.panels.10",
      20: "ranges.panels.20",
      100: "ranges.panels.100"
    }
  },
  olFilter: {
    literal: "device.ol"
  },
  onlyProblemsFilter: {
    literal: "installation.onlyProblemsFilter"
  },
};



@Component({
  components: {
    AppInputDate,
    AppSelect,
    AppFilterButtons,
    TableGeoFilters,
    AppAutocomplete    
  }
})
export default class InstallationFilter extends Vue {
  @Prop(Object) value!: any;
  @Prop({type: Boolean, default: false}) disabledFilters!: boolean;
  @Prop(Number) tabID!: number;
  

  form: any = null;
  FILTER_DEFINITIONS = FILTER_DEFINITIONS;
  selectionOptions: any = {
    city: null
  }

  $refs!: {
    geoFilters: any
  }

  get localValue() {
    return this.value;
  }

  set localValue(localValue) {
    this.$emit("input", localValue);
  }

  get status() {
    return [
      {
        value: "CONNECTED",
        label: this.$t(FILTER_DEFINITIONS.statusFilter.valuesLiteral.CONNECTED)
      },
      {
        value: "DISCONNECTED",
        label: this.$t(FILTER_DEFINITIONS.statusFilter.valuesLiteral.DISCONNECTED)
      },
      {
        value: "WARNING",
        label: "Warning"
      }
    ]
  }
  get coverage() {
    return [
      {
        value: "20",
        label: this.$t(FILTER_DEFINITIONS.coverageFilter.valuesLiteral[20])
      },
      {
        value: "50",
        label: this.$t(FILTER_DEFINITIONS.coverageFilter.valuesLiteral[50])
      },
      {
        value: "75",
        label: this.$t(FILTER_DEFINITIONS.coverageFilter.valuesLiteral[75])
      },
      {
        value: "100",
        label: this.$t(FILTER_DEFINITIONS.coverageFilter.valuesLiteral[100])
      }
    ]
  }

  get rangsPanels() {
    return [
      {
        value: "1",
        label: this.$t(FILTER_DEFINITIONS.panels.valuesLiteral[1])
      },
      {
        value: "5",
        label: this.$t(FILTER_DEFINITIONS.panels.valuesLiteral[5])
      },
      {
        value: "10",
        label: this.$t(FILTER_DEFINITIONS.panels.valuesLiteral[10])
      },
      {
        value: "20",
        label: this.$t(FILTER_DEFINITIONS.panels.valuesLiteral[20])
      },
      {
        value: "100",
        label: this.$t(FILTER_DEFINITIONS.panels.valuesLiteral[100])
      }
    ]
  }

  get rangsMonitor() {
    return [
      {
        value: "10",
        label: this.$t(FILTER_DEFINITIONS.monitors.valuesLiteral[10])
      },
      {
        value: "20",
        label: this.$t(FILTER_DEFINITIONS.monitors.valuesLiteral[20])
      },
      {
        value: "50",
        label: this.$t(FILTER_DEFINITIONS.monitors.valuesLiteral[50])
      },
      {
        value: "100",
        label: this.$t(FILTER_DEFINITIONS.monitors.valuesLiteral[100])
      }
    ]
  }

  get rangsGuard() {
    return [
      {
        value: "1",
        label: this.$t(FILTER_DEFINITIONS.guard.valuesLiteral[1])
      },
      {
        value: "5",
        label: this.$t(FILTER_DEFINITIONS.guard.valuesLiteral[5])
      },
      {
        value: "100",
        label: this.$t(FILTER_DEFINITIONS.guard.valuesLiteral[100])
      }
    ]
  }

  get fieldRequired() {
    return [
    v =>
        v === null ||
        v === "" ||
        v === undefined ||
        v <= 20 && v >= 0 ||
        this.$t("general.fildNumbers20")
    ];
  }

  
  get fieldRequiredText() {
   return [
   v =>
        v === null ||
        v === "" ||
        v === undefined ||
        v.length >= 14  ||
        this.$t("general.fildNumbers14")  
  ];
  }

  mounted() {
    this.loadCity();    
  }

  onSubmit(e) {
    e.preventDefault();

    this.applyFilters();
  }

  cleanFilters() {
    const RESET_FILTERS = {};


    if(this.$refs.geoFilters) this.$refs.geoFilters.cleanFilters();
    
    this.localValue = RESET_FILTERS;

    this.applyFilters(RESET_FILTERS);
  }


  cleanFiltersTab() {
    const RESET_FILTERS = {};

    if(this.$refs.geoFilters) this.$refs.geoFilters.cleanFilters();
    
    this.localValue = RESET_FILTERS;
  }


  applyFilters(filters = this.localValue) {

    if (filters.onlyProblemsFilter === false)
      delete filters.onlyProblemsFilter;

    this.$emit("filterClick", filters);
  }


  loadCity(){
    
    installationService.getInstallationCity().then(res =>{
      this.selectionOptions.city = res.data.cities

    }).catch(error =>{
      displayErrorMessage(error, { general: ["installation.error.getInstallationCityError"] });
    })
  }
}
</script>