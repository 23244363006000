import Api from "./index";
import moment from "moment";

const SERVICE_PATH = "/account/api/v1";

const VALID_HISTORY_EVENTS = ["START_ENTITLEMENT", "STOP_ENTITLEMENT", "STOP_BILLING", "RESUME_BILLING", "CHANGE"];
export const TRIAL_END_REASON = "TRIAL_END";

const FULL_SERVICE_PLAN_SKU = "fullservice-annual-wifi";

export default {
  getSubscriptionsHistory: (userId) => Api(true).get(`${SERVICE_PATH}/history/${userId}`).then(res => {
    if(res.status === 200) {
    return res.data.filter(ev => {
      let result = true;

      if (VALID_HISTORY_EVENTS.indexOf(ev.eventType) < 0) result = false;

      if (
        ev.eventType === "STOP_ENTITLEMENT" && ev.cancelAt !== null && ((ev.cancellationReasonSubDTO !== null && ev.cancellationReasonSubDTO.reason === TRIAL_END_REASON) || ev.planSKU === FULL_SERVICE_PLAN_SKU ||
          !moment(ev.endDate)
            .startOf('day')
            .isSame(moment(ev.cancelAt).startOf('day')))
      )
        result = false;

      const isAFutureCancellationEvent = moment().startOf('day').isBefore(moment(ev.cancelAt).startOf('day'));

      if (ev.eventType === "STOP_BILLING" && (ev.cancelAt === null || isAFutureCancellationEvent))
        result = false;

      return result;
    }).map(ev => {
      let event = ev;

      if (ev.eventType === "STOP_ENTITLEMENT" && ev.cancelAt !== null) {
        ev.eventType = "CANCELLATION_REQUESTED";
      }

      if (ev.eventType === "STOP_BILLING") {
        ev.eventType = (ev.cancellationReasonSubDTO === null || ev.cancellationReasonSubDTO.reason !== TRIAL_END_REASON) ? "CANCELED" : "TRIAL_END";

        if (!moment(ev.changeDate)
          .startOf('day')
          .isSame(moment(ev.cancelAt).startOf('day')))
          ev.changeDate = ev.cancelAt;
      }

      return event;
    }).sort((a, b) => {
      var nameA = a.subscriptionId.toUpperCase();
      var nameB = b.subscriptionId.toUpperCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      return 0;
    }).sort((a, b) => {
      let dateA = moment(a.changeDate);
      let dateB = moment(b.changeDate);

      if (dateA.isAfter(dateB))
        return -1;
      else if (dateA.isBefore(dateB))
        return 1;

      return 0;
    })
  }
return [];
})
};