/* eslint-disable */
import Vue from "vue";
import Component from "vue-class-component";

import { Prop, Watch } from "vue-property-decorator";
import DxfDataGrid from "../../../components/DxfDataGrid/DxfDataGrid.vue";
import { translateText } from "@/lang/i18n";

import { Column } from "devextreme/ui/data_grid";
import CustomStore from "devextreme/data/custom_store";

import UserService from "@/api/user";
import { LoadResultObject } from "devextreme/common/data/custom-store";

import LanguagesHelper from "../../../helpers/languagesHelper";
import * as i18nIsoCountries from "i18n-iso-countries";
import i18n from "@/lang/i18n";
import DateHelper from "@/helpers/dateHelper";

export interface TablePagination {
  rowsPerPage: number;
  page: number;
  descending: boolean;
}

@Component({
  components: {
    DxfDataGrid,
  },
})
export default class UsersTable extends Vue {
  /** Filtro que viene de pantalla. */
  @Prop() filterOptions!: any;
  /** Palabra a buscar. */
  @Prop() search!: string | null;
  //@Prop() dataArray!: Array<any>;
  //@Prop() menuOptions!: Array<any>;
  /** Referencia a la tabla. */
  //@Ref("refDxfDataGrid") tableDevExtreme!: DxfDataGrid;
  title = translateText("menu.users");

  showExport = this.$ability.can("export", "users");

  /** Conexión para obtener los datos de la tabla. */
  dataStore: CustomStore;

  /** Columnas de la tabla. */
  columns: Column[];

  /** Se inicializan las variables. */
  constructor() {
    super();
    this.dataStore = this.getNewCustomStore();
    this.columns = this.getColumns();
  }

  getColumns(): Column[] {
    const columns: Column[] = [
      {
        dataField: "email",
        caption: translateText("user.email"),
        cellTemplate: this.$ability.can("details", "users")
          ? "linkDetail"
          : "notLinkDetail",
      },
      {
        dataField: "locale",
        caption: translateText("menu.language"),
        calculateDisplayValue: (rowData: any) => {
          return LanguagesHelper.getLanguageName(rowData.locale.toLowerCase());
        },
        allowSorting: false,
      },
      {
        dataField: "country",
        caption: translateText("general.country"),
        calculateDisplayValue: (rowData: any) => {
          return i18nIsoCountries.getName(rowData.country, i18n.locale);
        },
        calculateSortValue: () => "country",
      },
      {
        dataField: "createdAt",
        caption: translateText("user.eDate"),
        calculateDisplayValue: (rowData: any) => {
          return DateHelper.dateFormat(
            rowData.createdAt,
            "DD/MM/YYYY HH:mm:ss"
          );
        },
        calculateSortValue: () => "createdAt",
      },
      {
        dataField: "acceptPrivacy",
        caption: translateText("user.privacy"),
        cellTemplate: "cellAppChipInfoUser",
      },
      {
        dataField: "acceptSharing",
        caption: translateText("user.data"),
        cellTemplate: "cellAppChipInfoUser",
      },
      {
        cellTemplate: "menu",
        width: 60,
        allowExporting: false,
      },
    ];

    return columns;
  }

  get menuOptions() {
    return [
      {
        icon: "people",
        text: this.$t("user.userDetails"),
        to: (item) => ({ name: "UserDetails", params: { email: item.email } }),
        allowed: this.$ability.can("details", "users"),
      },
      {
        icon: "cloud_download",
        text: this.$t("user.export"),
        action: (item) => this.exportData(item.email),
        allowed: this.$ability.can("exportDetails", "users"),
      },
    ];
  }

  /** Cuando cambia el fitro se recarga la tabla. */
  @Watch("filterOptions", { immediate: true, deep: true })
  onChangeFilterOptions() {
    this.dataStore = this.getNewCustomStore();
    //this.columns = this.getColumns();
  }

  /** Cuando cambia la palabra a buscar se recarga la tabla. */
  @Watch("search", { immediate: true, deep: true })
  onChangeSearch() {
    this.dataStore = this.getNewCustomStore();
    //this.columns = this.getColumns();
  }

  exportData(email) {
    UserService.getUserId(email)
      .then((userRes) => {
        const userId = userRes.data;

        UserService.exportData(userId, email).catch((err) => {
          console.error(err);
          //displayErrorMessage(err, { general: ["user.error.exportData"] });
        });
      })
      .catch((err) => {
        //displayErrorMessage(err, { general: ["user.error.getError"] })
        console.error(err);
      });
  }

  onClickDetail(item: any) {
    this.$router.push({
      name: "UserDetails",
      params: { email: item.data.email },
    });
  }

  /** Obtiene el objeto store y hace un load de los datos. */
  getNewCustomStore(): CustomStore {
    const loadUserRecursively = async (pagination: TablePagination) => {
      try {
        const res = await UserService.getUsers(
          this.filterOptions,
          pagination as any,
          this.search as any
        );

        loadedUser = loadedUser.concat(res.data.content);

        if (loadedUser.length < res.data.totalElements) {
          const nextPage = pagination.page + 1;
          const nextPagination: TablePagination = {
            ...pagination,
            page: nextPage,
          };
          await loadUserRecursively(nextPagination);
        } else {
          allUserLoaded = true;
        }
      } catch (err) {
        console.error(err);
      }
    };

    let loadedUser: any[] = [];
    let allUserLoaded = false;

    return new CustomStore({
      key: "email",
      load: async (loadOptions: any) => {
        if (loadOptions.isLoadingAll === true) {
          loadedUser = [];
          const pagination: TablePagination = {
            rowsPerPage: 2000,
            page: 1,
            descending: false,
          };

          if (!allUserLoaded) {
            await loadUserRecursively(pagination);
          }
        } else {
          const pagination: TablePagination = {
            rowsPerPage: loadOptions.take ?? 10,
            page: Math.ceil(
              ((loadOptions.skip ?? 0) + 1) / (loadOptions.take ?? 0)
            ),
            descending: loadOptions.sort?.[0]?.desc ?? false,
          };

          const sort = this.getSort(loadOptions, "createdAt");

          try {
            const res = await UserService.getUsers(
              this.filterOptions,
              pagination as any,
              this.search as any,
              sort as any
            );

            loadedUser = res.data.content;

            const result: LoadResultObject = {
              data: loadedUser,
              totalCount: res.data.totalElements,
            };

            return result;
          } catch (err) {
            console.error(err);
          }
        }

        const data = allUserLoaded ? loadedUser : [];

        const result: LoadResultObject = {
          data: data,
          totalCount: allUserLoaded ? loadedUser.length : 0,
        };

        allUserLoaded = false;
        return result;
      },
    });
  }

  getSort(loadOptions: any, defaultSort: string) {
    let sort = loadOptions.sort?.[0]?.selector ?? defaultSort;
    if (typeof sort === "function") {
      sort = sort();
    }
    return sort;
  }
}
