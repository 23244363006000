export default {
  methods: {
    session_setItem(key, value) {
      if (value === null || value === undefined || value === "") {
        sessionStorage.removeItem(key);
      } else {
        sessionStorage.setItem(key, value);
      }
    },

    session_setItemObject(key, value) {
      if (value === null || value === undefined || value === {}) {
        sessionStorage.removeItem(key);
      } else {
        sessionStorage.setItem(key, JSON.stringify(value));
      }
    },

    session_getItemBoolean(key, defaultValue = false) {
      const value = sessionStorage.getItem(key);
      return value === "true" ? true : (value === "false" ? false : defaultValue);
    },

    session_getItemObject(key, defaultValue = {}) {
      const value = sessionStorage.getItem(key);
      return value !== null && value !== undefined && value !== "" ?
        JSON.parse(value) :
        defaultValue;
    },

    session_getItem(key, defaultValue = "") {
      const value = sessionStorage.getItem(key);
      return value !== null && value !== undefined ? value : defaultValue;
    },

    session_clearStorage() {
      sessionStorage.clear();
    }
  }
}
