import { HWList } from "@/interfaces/firmwares";
import { Vue, Component, Prop } from "vue-property-decorator";

import {
  DxDataGrid,
  DxColumn,
  DxColumnChooser,
  DxColumnFixing,
  DxHeaderFilter,
  DxScrolling,
  DxPager,
  DxPaging,
  DxFilterRow,
  DxExport,
  DxToolbar,
  DxItem,
  DxOperationDescriptions,
  DxStateStoring,
  DxGrouping,
  DxMasterDetail,
  DxEditing,
  DxSearchPanel,
} from "devextreme-vue/data-grid";
import { DxCheckBox } from "devextreme-vue";
import { FirmwareModel } from "@/ui/models/FirmwareModel";

@Component({
  components: {
    DxDataGrid,
    DxCheckBox,
    DxEditing,
    DxColumn,
    DxColumnChooser,
    DxColumnFixing,
    DxHeaderFilter,
    DxScrolling,
    DxPager,
    DxPaging,
    DxFilterRow,
    DxExport,
    DxToolbar,
    DxItem,
    DxOperationDescriptions,
    DxStateStoring,
    DxGrouping,
    DxMasterDetail,
    DxSearchPanel,
  },
})
export default class DialogUploadFwStep2 extends Vue {
  /** Firmware que se está trabajando. */
  @Prop() firmwareUpload!: FirmwareModel;
  /** Lista de HW compatibles. */
  @Prop() hwCompatibles!: HWList[];

  /** Indica si el formulario es válido. */
  validForm = true;

  /** Selecciona o desselecciona todos los registros de la tabla. */
  onChangeToggleAll(newValue: boolean) {
    this.hwCompatibles.forEach((item: HWList) => {
      item.selectHW = newValue;
    });
  }
  /** Botón aceptar y se asignan los hw seleccionados. */
  onContinue() {
    this.firmwareUpload.hwList = this.hwCompatibles.filter(
      (item: HWList) => item.selectHW
    );
    this.$emit("continue");
  }
  /** Botón cancelar. */
  onCancel() {
    this.$emit("cancel");
  }

  /** Des/habilita la casilla de actualización automática. */
  isDisabledAutUpdate(selectHW: boolean) {
    return !(selectHW && this.firmwareUpload.canAutoUpdate());
  }

  /** Evento de cuando cambia el checkbox de actualización automática. */
  onChangeCheckBoxAutUpdate(dataRow: HWList) {
    this.$emit("changeCheckBoxAutUpdate", dataRow);
  }

  /** Evento de cuando cambia el checkbox de más reciente. */
  onChangeCheckBoxLatest(dataRow: HWList) {
    this.$emit("changeCheckBoxLatest", dataRow);
  }
}
