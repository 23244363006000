<template lang="html" src="./InstallationInformation.html"></template>

<!-- eslint-disable @typescript-eslint/no-explicit-any -->
<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator";
import InstallationStatusChip from "@/views/Installations/InstallationStatusChip/InstallationStatusChip.vue";

import { goTo } from "@/router";
import DeviceEntity from "@/domain/entities/DeviceEntity";
import InstallationController from "@/domain/controllers/InstallationController";

@Component({
  components: {
    InstallationStatusChip,
  },
})
export default class InstallationInformation extends Vue {
  @Prop(Object) deviceData!: any;
  @Prop(Object) installationData!: any;

  ctrlInstallation!: InstallationController;
  deviceEntity!: DeviceEntity;
  callDivertValue = false;

  created() {
    this.ctrlInstallation = new InstallationController();
    this.deviceEntity = new DeviceEntity(this.deviceData);
    this.getCallDivert();
  }

  get returnType() {
    return (
      this.deviceEntity.isMonitor() ||
      this.deviceEntity.isPanel() ||
      this.deviceEntity.isGuardUnit() ||
      this.deviceEntity.isPhone()
    );
  }
  goToInstallation() {
    goTo(`installation-${this.installationData.id}`);
  }
  showDivertCall() {
    return this.deviceEntity.showDivertCall();
  }
  async getCallDivert() {
    const response = await this.ctrlInstallation.getCallDivert(
      this.deviceEntity
    );
    this.callDivertValue = response;
  }
}
</script>
