export const setZonesGeoJson = (state, response) => {
  state.zonesGeoJson = response;
};

export const setSubzonesGeoJson = (state, response) => {
  state.subzonesGeoJson = response;
}

export const setAreasGeoJson = (state, response) => {
  state.areasGeoJson = response;
}