export interface PaginationInterface {
  page: string;
  rowsPerPage: string;
  descending?: boolean;
}

export default class FilterHelper {
  private url: string;
  /** Valores de paneles. */
  private PANELS = {
    1: { min: 1, max: 1 },
    5: { min: 2, max: 5 },
    10: { min: 6, max: 10 },
    20: { min: 11, max: 20 },
    100: { min: 21, max: 1000 },
  };
  /** Valores de monitores. */
  private MONITORS = {
    10: { min: 0, max: 10 },
    20: { min: 11, max: 20 },
    50: { min: 21, max: 50 },
    100: { min: 51, max: 1000 },
  };
  /** Valores de conserjerías. */
  private GUARDS = {
    1: { min: 1, max: 1 },
    5: { min: 2, max: 5 },
    100: { min: 6, max: 1000 },
  };
  /** Valores de señales. */
  private SIGNALS = {
    20: { url: "signalMax=20&" },
    50: { url: "signalMin=21&signalMax=50&" },
    75: { url: "signalMin=51&signalMax=75&" },
    100: { url: "signalMin=76&" },
  };

  constructor(url: string) {
    this.url = url;
  }

  /** Añade un parámetro a la url. */
  public addParam(param: string, value: string | number | boolean | undefined) {
    const typeValue = typeof value;
    if (typeValue === "boolean" || typeValue === "number") {
      this.url += value !== undefined ? param + "=" + value + "&" : "";
    } else {
      this.url += value ? param + "=" + value + "&" : "";
    }
  }

  /** Añade el filtro de paginación. */
  public addPagination(pagination: PaginationInterface | null) {
    if (!pagination) {
      return;
    }
    this.addParam("page", pagination.page);
    this.addParam("size", pagination.rowsPerPage);
  }

  /** Añade el filtro de búsqueda. */
  public addSearch(search: string | null) {
    if (!search) {
      return;
    }
    this.addParam("question", encodeURIComponent(search));
  }

  /** Añade el filtro de ordenación. */
  public addSort(
    column: string | null,
    pagination: PaginationInterface | null
  ) {
    if (!column || !pagination) {
      return;
    }
    const sortDirection = pagination.descending ? ",desc" : ",asc";
    this.addParam("sort", column + sortDirection);
  }

  /** Añade el filtro de rango de fechas. */
  public addRangeDate(
    param: string,
    valueStart: string | undefined,
    valueEnd: string | undefined
  ) {
    if (valueStart) {
      this.addParam(param + "Start", valueStart + " 00:00:00");
    }
    if (valueEnd) {
      this.addParam(param + "End", valueEnd + " 23:59:59");
    }
  }

  /** Añade el filtro de rango de valores (min y max). */
  public addRangeMinMax(
    param: string,
    valueMin: number | undefined,
    valueMax: number | undefined
  ) {
    if (valueMin !== undefined && valueMax !== undefined) {
      this.addParam(param + "Min", valueMin);
      this.addParam(param + "Max", valueMax);
    }
  }

  /** Añade el filtro de paneles. */
  public addPanels(devices: string | undefined) {
    if (devices) {
      const { min, max } = this.PANELS[devices] ?? {};
      this.addRangeMinMax("panelsNum", min, max);
    }
  }

  /** Añade el filtro de monitores. */
  public addMonitors(devices: string | undefined) {
    if (devices) {
      const { min, max } = this.MONITORS[devices] ?? {};
      this.addRangeMinMax("monitorsNum", min, max);
    }
  }

  /** Añade el filtro de teléfonos. */
  public addPhones(devices: string | undefined) {
    if (devices) {
      const { min, max } = this.MONITORS[devices] ?? {};
      this.addRangeMinMax("phonesNum", min, max);
    }
  }

  /** Añade el filtro de consergerías. */
  public addGuards(devices: string | undefined) {
    if (devices) {
      const { min, max } = this.GUARDS[devices] ?? {};
      this.addRangeMinMax("guardsNum", min, max);
    }
  }

  /** Añade el filtro de edibox. */
  public addEdiboxes(devices: string | undefined) {
    if (devices) {
      const { min, max } = this.PANELS[devices] ?? {};
      this.addRangeMinMax("ediboxsNum", min, max);
    }
  }

  /** Añade el filtro de señal. */
  public addSignal(devices: string | undefined) {
    if (devices) {
      this.url += this.SIGNALS[devices].url;
    }
  }

  /** Devuelve la url sin el último caracter (&). */
  public getUrl(): string {
    return this.url.slice(0, this.url.length - 1);
  }
}
