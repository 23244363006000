<template lang="html" src="./Plans.html"></template>

<!-- eslint-disable @typescript-eslint/ban-types -->
<!-- eslint-disable @typescript-eslint/no-explicit-any -->
<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import AppBreadcrumbs from "@/components/AppBreadcrumbs.vue";
import AppListMenu from "@/components/AppListMenu.vue";
import AppKpiChipInfo from "@/components/AppKpiChipInfo.vue";

import FilterTableHeader from "@/components/Table/FilterTableHeader/FilterTableHeader.vue";
import ExportTableDataButton from "@/components/Table/ExportTableDataButton/ExportTableDataButton.vue";

import PlanCreateModal from "./PlanCreateModal/PlanCreateModal.vue";
import VATEditModal from "./VATEditModal/VATEditModal.vue";
import ServicesEditModal from "./ServicesEditModal/ServicesEditModal.vue";

import tableFrontMixin from "@/mixins/table.mixin/front";
import filterMixin from "@/mixins/filter.mixin";
import planMixin from "@/mixins/plan.mixin";

import { getUserPlanServices } from "@/utils/plan";
import PlansFilter, { FILTER_DEFINITIONS } from "./PlansFilter/PlansFilter.vue";

import PlansTable from "./PlansTable/PlansTable.vue";

// Keys para persistir los valores en la sessionStorage con el objetivo de mantenerlo tras un refresco de la página
const keyView = "Plans";
const keySearch = keyView + ".search";
const keyFilterOptions = keyView + ".filterOptions";

const NEW_PLAN = "NEWPLAN";
const CHANGE_VAT = "CHANGEVAT";
const CHANGE_SERVICES = "CHANGESERVICES";

@Component({
  components: {
    AppBreadcrumbs,
    AppListMenu,
    AppKpiChipInfo,
    PlansFilter,
    FilterTableHeader,
    ExportTableDataButton,
    PlanCreateModal,
    VATEditModal,
    ServicesEditModal,
    PlansTable,
  },
  mixins: [tableFrontMixin, filterMixin, planMixin],
})
export default class Plans extends Vue {
  $moment!: any;
  loadingData!: boolean;
  filterOptions!: any;
  filterOptions_!: any;
  internalizedText!: Function;
  filterDate!: Function;
  filterInList!: Function;
  filterInListMultiple!: Function;
  filterStartWith!: Function;
  saveFilters!: Function;
  checkHasAnyActiveFilter!: Function;

  keySearch = keySearch;
  keyFilterOptions = keyFilterOptions;
  FILTER_DEFINITIONS = FILTER_DEFINITIONS;
  tableProps: any = {
    sortBy: ["code"],
  };
  expansionPanel = null;
  editVATDialog = false;
  createPlanDialog = false;
  editServicesDialog = false;

  getListInfoList: Array<any> = [];

  /*get menuOptions() {
    return [
      {
        icon: "list_alt",
        text: this.$t("general.details"),
        to: (item) => ({
          name: "PlanDetails",
          params: { planCode: item.plan.planId },
        }),
        allowed: this.$ability.can("details", "plans"),
      },
    ];
  }*/

  /*get menuOptionsAllowed() {
    return hasAllowedMenuOptions(this.menuOptions);
  }*/

  // get headers() {
  //   const items: any = [
  //     {
  //       text: this.$t("plans.code"),
  //       value: "code",
  //       sortable: true,
  //       export: {
  //         width: 130,
  //       },
  //     },
  //     {
  //       text: this.$t("plans.name"),
  //       value: "name",
  //       sortable: true,
  //       export: {
  //         width: 100,
  //       },
  //     },
  //     /*{
  //       text: this.$t("planDetails.startingDate"),
  //       value: "plan.activationDate",
  //       sortable: true,
  //       export: {
  //         width: 50,
  //         value: row => this.$moment(row.plan.activationDate).format("DD/MM/YYYY")
  //       }
  //     },
  //     {
  //       text: this.$t("planDetails.endingDate"),
  //       value: "plan.deactivationDate",
  //       sortable: true,
  //       export: {
  //         width: 50,
  //         value: row => row.plan.deactivationDate!== null ? this.$moment(row.plan.deactivationDate).format("DD/MM/YYYY") : '-'
  //       }
  //     },*/
  //     {
  //       text: this.$t("plans.services"),
  //       value: "services",
  //       sortable: true,
  //       export: {
  //         width: 140,
  //       },
  //     },
  //     {
  //       text: this.$t("plans.renovation"),
  //       value: "renovation",
  //       sortable: false,
  //       export: {
  //         width: 30,
  //         value: (row) =>
  //           this.$t("plans.renewalTypes." + row.plan.renewalPeriod),
  //       },
  //     },
  //     {
  //       text: this.$t("plans.trialAvailable"),
  //       value: "trial",
  //       sortable: false,
  //       export: {
  //         width: 30,
  //         value: (row) =>
  //           row.plan.trial !== null
  //             ? this.$t("general.yes")
  //             : this.$t("general.no"),
  //       },
  //     },
  //   ];

  //   if (this.menuOptionsAllowed)
  //     items.push({
  //       text: null,
  //       value: null,
  //       sortable: false,
  //       align: "right",
  //       sticky: true,
  //     });

  //   return items;
  // }

  get planActions() {
    return [
      /*{
        title: this.$t("plans.newplan"),
        click: NEW_PLAN
      },*/
      {
        title: this.$t("plans.changeServices"),
        click: CHANGE_SERVICES,
      },
      {
        title: this.$t("plans.changeVAT"),
        click: CHANGE_VAT,
      },
    ];
  }

  getServicesList() {
    this.$store.dispatch("plans/getServices");
  }

  get serviceList() {
    return this.$store.getters["plans/servicesList"];
  }

  get getListInfo() {
    const listInfo = { ...this.$store.getters["plans/plansListInfo"] };

    if (listInfo.list) {
      if (this.filterOptions_)
        listInfo.list = listInfo.list.filter((i) =>
          this.filterPlans(i, this.filterOptions_)
        );

      listInfo.list = listInfo.list.map((p) => {
        const internalized = this.internalizedText(p.regionalDetailsList);
        let services = p.plan.services.map((s) => s.name);
        if (!this.filterOptions_.showAllServicesFilter) {
          services = getUserPlanServices(services, this.serviceList);
        }
        return {
          ...p,
          code: p.plan.sku,
          name: internalized && internalized.commercialName,
          services: services.join(", "),
        };
      });
    }

    const searchString = this.getSearchText();
    if (searchString !== null) {
      this.getListInfoList = listInfo.list.filter((i) => {
        let include = false;
        if (i.code.toLowerCase().includes(searchString)) {
          include = true;
        }
        if (i.name.toLowerCase().includes(searchString)) {
          include = true;
        }
        if (i.services.toLowerCase().includes(searchString)) {
          include = true;
        }
        return include;
      });
    } else {
      this.getListInfoList = listInfo.list;
    }

    return listInfo;
  }

  getSearchText() {
    return sessionStorage.getItem(keySearch)?.toLowerCase() ?? null;
  }

  /*get getListInfoList() {
    return this.getListInfo.list;
  }*/

  get hasAnyActiveFilter() {
    const filters = { ...this.filterOptions_ };

    return this.checkHasAnyActiveFilter(filters);
  }

  obtainPlans() {
    this.$store
      .dispatch("plans/getPlansList")
      .then(() => setTimeout(() => (this.loadingData = false), 500));
  }

  refreshList() {
    this.loadingData = true;

    this.obtainPlans();
    this.getServicesList();
  }

  filterPlans(item, filters) {
    if (filters.codeFilter) {
      const show = this.filterStartWith(item.plan.sku, filters.codeFilter);

      if (!show) return false;
    }

    if (filters.servicesFilter) {
      const show = this.filterInListMultiple(
        item.plan.services,
        "name",
        filters.servicesFilter.map((i) => i.name)
      );

      if (!show) return false;
    }

    if (filters.regionFilter) {
      const show = this.filterInList(
        item.availabilityRegionList,
        "country",
        filters.regionFilter
      );

      if (!show) return false;
    }

    if (
      filters.priceFilterMin &&
      (filters.priceFilterMax === null ||
        +filters.priceFilterMax >= +filters.priceFilterMin)
    ) {
      const show =
        item.availabilityRegionList.find(
          (av) => av.price >= +filters.priceFilterMin
        ) !== undefined;

      if (!show) return false;
    }

    if (
      filters.priceFilterMax &&
      (filters.priceFilterMin === null ||
        +filters.priceFilterMin <= +filters.priceFilterMax)
    ) {
      const show =
        item.availabilityRegionList.find(
          (av) => av.price <= +filters.priceFilterMax
        ) !== undefined;

      if (!show) return false;
    }

    const creationStartDate = filters.dateValueStart
      ? this.$moment(filters.dateValueStart).startOf("day")
      : null;
    const creationEndDate = filters.dateValueEnd
      ? this.$moment(filters.dateValueEnd).endOf("day")
      : null;

    if (creationStartDate || creationEndDate) {
      const show = item.availabilityRegionList.some((av) =>
        this.filterDate(av.availabilityDate, creationStartDate, creationEndDate)
      );

      if (!show) return false;
    }

    if (filters.renovationFilter) {
      const show = item.plan.renewalPeriod === filters.renovationFilter;

      if (!show) return false;
    }

    if (filters.trialAvailableFilter) {
      let show = item.plan.trial
        ? filters.trialAvailableFilter === "true"
        : filters.trialAvailableFilter === "false";

      if (!show) return false;
    }

    /*const endTodayDate = this.$moment().endOf("day");
    const startTodayDate = this.$moment().startOf("day");

    if (!filters.showAllPlansFilter) {
      let show = this.filterDate(
          item.plan.activationDate,
          null,
          endTodayDate
        );

      if (!show) return false;

      show = this.filterDate(
          item.plan.deactivationDate !== null ? item.plan.deactivationDate: 9999999990000,
          startTodayDate,
          null
        );

      if (!show) return false;
    }*/

    return true;
  }

  /*viewPlanDetails(id) {
    return {
      name: "PlanDetails",
      params: { planCode: id },
    };
  }*/

  filterClick() {
    this.saveFilters();

    this.tableProps.page = 1;
    this.refreshList();
  }

  onClickPlans(clickAction) {
    switch (clickAction) {
      case NEW_PLAN:
        this.createPlanDialog = true;
        return;
      case CHANGE_VAT:
        this.editVATDialog = true;
        return;
      case CHANGE_SERVICES:
        this.editServicesDialog = true;
        return;
    }
  }
  searchClick() {
    this.refreshList();
  }
}
</script>
