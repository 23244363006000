<template>
  <v-form class="layout inline-edit" @submit="onSubmit">
    <v-layout column :class="classes" :style="styles">
      <span>{{ label }}</span>

      <span
        class="inline-edit-value grey--text ellipsis"
        @click="onClickToEdit"
        :style="{opacity: (isEditMode ? 0 : null)}"
      >{{ localValue }}</span>

      <v-text-field
        :disabled="isSaving"
        autofocus
        @blur="onCancel"
        v-model="localValue"
        v-if="isEditMode"
        :rules="rules"
        :required="required"
        v-bind="inputProps"
      />
    </v-layout>

    <v-btn
      text
      icon
      small
      class="mr-4"
      :color="(isEditMode) ? 'blue lighten-1' : 'grey lighten-1'"
      :loading="isSaving"
      type="submit"
      v-if="!disabled"
      :disabled="isSaving"
    >
      <v-icon small v-if="isEditMode">check</v-icon>
      <v-icon small v-else>edit</v-icon>
    </v-btn>
  </v-form>
</template>

<!-- eslint-disable @typescript-eslint/ban-types -->
<!-- eslint-disable @typescript-eslint/no-explicit-any -->

<script lang="ts">
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

@Component
export default class AppInlineFieldEdit extends Vue {
  @Prop(String) readonly label!: string
  @Prop({required: true}) value!: any
  @Prop(Array) readonly rules!: Array<any>
  @Prop({type: Boolean, default: true}) required!: boolean
  @Prop({type: Boolean, default: false}) disabled!: boolean
  @Prop(Object) readonly inputProps!: Object
  @Prop(Boolean) isSaving!: boolean
  @Prop(Object) classes!: Object
  @Prop(Object) styles!: Object

  isEditMode = false;
  localValue = this.value;

  onClickToEdit() {
    if(!this.disabled) 
      this.isEditMode = true
  }

  onSubmit(e) {
    e.preventDefault();

    if (this.isEditMode) {
      if (
        this.localValue !== this.value &&
        (!this.rules || this.rules[0](this.localValue))
      ) {
        this.isEditMode = false;
        this.$emit("onSubmit", this.localValue);
      } else this.onCancel();
    } else this.isEditMode = true;
  }

  onCancel() {
    setTimeout(() => {
      if (!this.isSaving) {
        this.isEditMode = false;
        this.localValue = this.value;
      }
    }, 200);
  }

  @Watch('value')
  onChangeValue(v) {
    this.localValue = v;
  }
}
</script>
<style scoped>
.inline-edit > .layout {
  position: relative;
}
.inline-edit .inline-edit-value {
  margin-bottom: auto;
  margin-top: 9px;
}
.inline-edit .v-input {
  width: 100%;
  padding: 0;
  margin: 0;
  font-size: 14px;
  height: 25px;
  position: absolute;
  margin-top: 21px;
}
</style>