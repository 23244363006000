import { InternalUserController } from "@/domain/controllers/InternalUserController";
import { InternalUserEntity } from "@/domain/entities/InternalUserEntity";
import { PASSWORD_REGEX } from "@/ui/helpers/FormRules";
import Toast from "@/ui/helpers/Toast";
import { Vue, Component, Prop, Ref } from "vue-property-decorator";

@Component({
  name: "DialogChangePassword",
})
export default class DialogChangePassword extends Vue {
  /** Muestra u oculta el dialog. */
  @Prop({ default: false }) showDialog!: boolean;
  /** Usuario interno. */
  @Prop() internalUser!: InternalUserEntity;
  /** Controlador de usuarios internos. */
  ctrlInternalUser = new InternalUserController();
  /** Muestra u oculta la confirmación del dialog. */
  showConfirmDialog = false;
  /** Referencia al formulario. */
  @Ref("form") form!: HTMLFormElement;
  /** Indica si el formulario es valido. */
  validEditForm = false;
  /** Reglas para la contraseña. */
  get passwordRules() {
    const passwordRegex = PASSWORD_REGEX;
    return [
      (v) => !!v || this.$t("login.error.emptyPassword"),
      (v) => passwordRegex.test(v) || this.$t("login.error.passwordInternal"),
    ];
  }

  generateSecret() {
    this.internalUser.generatePassword();
  }

  /**
   * Para salir del diálogo, se emite el evento cancel.
   * Se limpia el formulario y se resetea la validación.
   * Se oculta el diálogo de confirmación.
   * Se limpia la contraseña.
   */
  cancel() {
    this.$emit("cancel");
    this.internalUser.password = "";
    this.form.reset();
    this.form.resetValidation();
    this.showConfirmDialog = false;
  }
  /** Antes de guardar, muestra el dialog de confirmación. */
  save() {
    this.showConfirmDialog = true;
  }
  /** Oculta el dialog de confirmación. */
  cancelConfirm() {
    this.showConfirmDialog = false;
  }
  /** Guarda la contraseña, muestra un mensaje de confirmación y cierra el diálogo. */
  saveConfirm() {
    this.ctrlInternalUser.changePassword(this.internalUser).then(() => {
      this.cancel();
      Toast.info("internalusers.messages.savePassword");
    });
  }
}
