import * as getters from "../oauthclients/getters";
import * as actions from "../oauthclients/actions";
import * as mutations from "../oauthclients/mutations";

const state = {
  clientsInfo: {
    totalClients: 0,
    list: []
  }
};

export default ({
  namespaced: true,
  state,
  getters,
  actions,
  mutations
});