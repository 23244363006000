<template lang="html" src="./PlansFilter.html"></template>

<!-- eslint-disable @typescript-eslint/no-explicit-any -->
<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import { PERIOD_TYPES } from "@/utils/plan";
import AppInputDate from "@/components/AppInputDate.vue";
import AppFilterButtons from "@/components/AppFilterButtons.vue";
import AppAutocomplete from "@/components/AppAutocomplete.vue";
import AppSelect from "@/components/AppSelect.vue";

const YES_OR_NO_LITERALS = {
  true: "general.yes",
  false: "general.no"
};

const PERIOD_TYPES_LITERALS = {};
for (const periodType in PERIOD_TYPES)
  PERIOD_TYPES_LITERALS[periodType] = "plans.renewalTypes." + periodType;

export const FILTER_DEFINITIONS = {
  codeFilter: {
    literal: "plans.code"
  },

  servicesFilter: {
    literal: "plans.services",
    objectMapper: "name"
  },

  regionFilter: {
    literal: "plans.region"
  },

  priceFilterMin: {
    literal: "plans.priceMin"
  },

  priceFilterMax: {
    literal: "plans.priceMax"
  },

  dateValueStart: {
    literal: "plans.rangeStartDateStart"
  },
  dateValueEnd: {
    literal: "plans.rangeStartDateEnd"
  },

  renovationFilter: {
    literal: "plans.renovation",
    valueLiterals: PERIOD_TYPES_LITERALS
  },

  trialAvailableFilter: {
    literal: "plans.trialAvailable",
    valueLiterals: YES_OR_NO_LITERALS
  },

  showAllServicesFilter: {
    literal: "plans.showAllServices",
    valueLiterals: YES_OR_NO_LITERALS
  }
};

@Component({
  components: {
    AppInputDate,
    AppFilterButtons,
    AppAutocomplete,
    AppSelect
  }
})
export default class PlansFilter extends Vue {
  @Prop(Object) value!: any;

  FILTER_DEFINITIONS = FILTER_DEFINITIONS
  priceMin: any = null;
  priceMax: any = null;
  resetKey = 0;

  get localValue() {
    return this.value;
  }

  set localValue(localVal) {
    this.$emit("input", localVal);
  }

  get yesNoOptions() {
    return [
      { label: this.$t(YES_OR_NO_LITERALS.true), value: "true" },
      { label: this.$t(YES_OR_NO_LITERALS.false), value: "false" }
    ];
  }

  get periodTypes() {
    return ["MONTHLY", "ANNUAL", "NO_BILLING_PERIOD"].map(i => ({
      label: this.$t(PERIOD_TYPES_LITERALS[i]),
      value: i
    }));
  }

  get listInfo() {
    return this.$store.getters["plans/plansListInfo"];
  }

  get services() {
    return this.$store.getters["plans/servicesList"];
  }

  get regions() {
    return (this.listInfo && this.listInfo.regions) || [];
  }

  get maxPriceRule() {
    return [
      v => v === null || v >= 0 || this.$t("plans.error.positiveNumber"),
      v =>
        v === null ||
        v === "" ||
        v === undefined ||
        !this.priceMin || this.priceMin < 0 || this.priceMin <= +v ||
        this.$t("plans.error.minValue")
    ];
  }

  get minPriceRule() {
    return [
      v => v === null || v >= 0 || this.$t("plans.error.positiveNumber"),
      v =>
        v === null ||
        v === "" ||
        v === undefined ||
        v === '__.__' || /^\d{2}\.\d{2}$/.test(v) || 
        this.$t("plans.error.maxValue")
    ];
  }

  mounted() {
    this.priceMin = (this.value && this.value.priceFilterMin) || null;
    this.priceMax = (this.value && this.value.priceFilterMax) || null;
  }

  checkRules(value, rules) {
    let result = false;

    let i = 0;
    while (i < rules.length && !result) {
      result = rules[i](value) === true;

      i++;
    }
    return result;
  }

  onSubmit(e) {
    e.preventDefault();

    if (this.checkRules(this.priceMin, this.minPriceRule))
      this.localValue.priceFilterMin = this.priceMin;

    if (this.checkRules(this.priceMax, this.maxPriceRule))
      this.localValue.priceFilterMax = this.priceMax;

    if (!this.localValue.showAllServicesFilter)
      delete this.localValue.showAllServicesFilter;

    this.applyFilters();
  }

  cleanFilters() {
    const RESET_FILTERS = {};

    this.priceMin = null;
    this.priceMax = null;
    this.localValue = RESET_FILTERS;
    this.resetKey++;

    this.applyFilters(RESET_FILTERS);
  }

  applyFilters(filters = this.localValue) {
    this.$emit("filterClick", filters);
  }
}
</script>

<style scoped>
.price-range {
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 10px;
}
</style>
