/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { io } from 'socket.io-client';

class SocketioService {
  socket: any;
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() { }

  setupSocketConnection(URL: string, connect: Function) {
    this.socket = io(URL || '', {
      reconnection: false,
      rejectUnauthorized: false
    });

    this.socket.on('connect', () => {
      connect(true);
    });

    this.socket.on('connect_error', (err:any) => {
        connect(false);
    });

    this.socket.on("disconnect", () => {
      console.log("disconnect: ", this.socket.id); // undefined
    });

  }

  disconnect() {
    if (this.socket) {
      this.socket.disconnect();
    }
  }

}

export {
  SocketioService
}