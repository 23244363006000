<template lang="html" src="./RolesInfo.html"></template>

<script>
export default {
  name: "RolesInfo",
  props: {
    value: {
      default: false,
      type: Boolean
    }
  },
  computed: {
    roleData() {
      return this.$store.getters["internalusers/roleUserData"];
    },

    localValue: {
      get() {
        return this.value;
      },
      set(localValue) {
        this.$emit("input", localValue);
      }
    }
  },
  mounted() {
    this.getRoleData();
  },

  methods: {
    getRoleData() {
      var idd = null;
      if (this.$store.state.user === "admin@fermax.com") {
        idd = 1;
      } else if (this.$store.state.user === "management@fermax.com") {
        idd = 2;
      } else if (this.$store.state.user === "manufacturer@fermax.com") {
        idd = 3;
      } else if (this.$store.state.user === "manager@fermax.com") {
        idd = 4;
      } else {
        idd = this.$store.state.user;
      }
      this.$store.dispatch("internalusers/getRolUser", {
        id: idd
      });
    }
  }
};
</script>

<style scoped>
.v-dialog__content .v-dialog__content--active {
  align-items: baseline;
  left: 200px;
}
</style>
