export const totalDeviceDeployed = state => state.totalDeviceDeployed;
export const totalDeviceManufactured = state => state.totalDeviceManufactured;
export const totalDeviceOnLine = state => state.totalDeviceOnLine;

export const totalDevicesUpdated = state => state.totalDevicesUpdated;
export const totalDevices = state => state.totalDevices;

export const totalOnlineWifiMonitors = state => state.totalOnlineWifiMonitors;
export const totalOfflineWifiMonitors = state => state.totalOfflineWifiMonitors;

export const totalWifiMonitorsDeployed = state => state.totalWifiMonitorsDeployed;
export const totalWifiMonitorsManufactured = state => state.totalWifiMonitorsManufactured;

export const totalPairedUsers = state => state.totalPairedUsers;
export const totalUserNumber = state => state.totalUserNumber;

export const totalLastYearUserNumber = state => state.totalLastYearUserNumber;
export const totalLastMonthUserNumber = state => state.totalLastMonthUserNumber;