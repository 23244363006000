/* eslint-disable */
import Vue from "vue";
import Component from "vue-class-component";

import { Prop, Ref, Watch } from "vue-property-decorator";
import CustomStore from "devextreme/data/custom_store";
import { LoadResultObject } from "devextreme/common/data/custom-store";

import DxfDataGrid from "../../../../components/DxfDataGrid/DxfDataGrid.vue";
import { translateText, translateExists } from "@/lang/i18n";

import { Column } from "devextreme/ui/data_grid";

import deviceService from "@/api/device";

export interface TableFilterTab {
  family: string;
  subtype: string;
}

export interface TableFilter {
  idTab: TableFilterTab | string;
  createdAtValueEnd?: string;
  createdAtValueStart?: string;
}

export interface TablePagination {
  rowsPerPage: number;
  page: number;
  descending: boolean;
}

@Component({
  components: {
    DxfDataGrid,
  },
})
export default class DerviceTable extends Vue {
  /** Filtro que viene de pantalla. */
  @Prop() filterOptions!: any;
  /** Palabra a buscar. */
  @Prop() search!: string | null;
  /** Identificador del tab al que pertenece esta tabla. */
  @Prop() tabIndex!: number;

  @Prop() menuOptions!: any;

  /** Referencia a la tabla. */
  @Ref("refDxfDataGrid") tableDevExtreme!: DxfDataGrid;

  title = translateText("menu.devices");

  //TODO: Hay límite para exportar

  /** Listado de tabs. */
  //tabsList: any;

  /** Filtro que se le va a mandar al endpoint. */
  filter: TableFilter = {
    idTab: { family: "PANEL", subtype: "4G" },
  };

  /** Conexión para obtener los datos de la tabla. */
  dataStore: CustomStore;

  /** Columnas de la tabla. */
  columns: Column[];

  /** Se inicializan las variables. */
  constructor() {
    super();
    this.dataStore = this.getNewCustomStore();
    this.columns = this.getColumns();
  }

  /** Cuando cambia el fitro se recarga la tabla. */
  @Watch("filterOptions", { immediate: true, deep: true })
  onChangeFilterOptions() {
    if (this.tabIndex === this.getCurrentTab()) {
      this.dataStore = this.getNewCustomStore();
      this.columns = this.getColumns();
    }
  }

  /** Cuando cambia la palabra a buscar se recarga la tabla. */
  @Watch("search", { immediate: true, deep: true })
  onChangeSearch() {
    if (this.tabIndex === this.getCurrentTab()) {
      this.dataStore = this.getNewCustomStore();
    }
  }

  /** Obtiene el objeto store y hace un load de los datos. */
  getNewCustomStore(): CustomStore {
    const loadDevicesRecursively = async (pagination: TablePagination) => {
      try {
        const sort = "serialNumber";
        const res = await deviceService.getDevices(
          this.filterOptions,
          pagination as any,
          this.search as any,
          sort as any
        );

        loadedDevices = loadedDevices.concat(res.data.content);

        if (loadedDevices.length < res.data.totalElements) {
          const nextPage = pagination.page + 1;
          const nextPagination: TablePagination = {
            ...pagination,
            page: nextPage,
          };
          await loadDevicesRecursively(nextPagination);
        } else {
          allDevicesLoaded = true;
        }
      } catch (err) {
        console.error(err);
      }
    };

    let loadedDevices: any[] = [];
    let allDevicesLoaded = false;

    return new CustomStore({
      key: "id",
      load: async (loadOptions: any) => {
        if (loadOptions.isLoadingAll === true) {
          loadedDevices = [];
          const pagination: TablePagination = {
            rowsPerPage: 2000,
            page: 1,
            descending: false,
          };

          if (!allDevicesLoaded) {
            await loadDevicesRecursively(pagination);
          }
        } else {
          const pagination: TablePagination = {
            rowsPerPage: loadOptions.take ?? 10,
            page: Math.ceil(
              ((loadOptions.skip ?? 0) + 1) / (loadOptions.take ?? 0)
            ),
            descending: loadOptions.sort?.[0]?.desc ?? false,
          };

          const sort = this.getSort(loadOptions, "serialNumber");

          try {
            const res = await deviceService.getDevices(
              this.filterOptions,
              pagination as any,
              this.search as any,
              sort
            );

            loadedDevices = res.data.content;

            const result: LoadResultObject = {
              data: loadedDevices,
              totalCount: res.data.totalElements,
            };

            return result;
          } catch (err) {
            console.error(err);
          }
        }

        const data = allDevicesLoaded ? loadedDevices : [];

        const result: LoadResultObject = {
          data: data,
          totalCount: allDevicesLoaded ? loadedDevices.length : 0,
        };

        allDevicesLoaded = false;
        return result;
      },
    });
  }

  getSort(loadOptions: any, defaultSort: string) {
    let sort = loadOptions.sort?.[0]?.selector ?? defaultSort;
    if (typeof sort === "function") {
      sort = sort();
    }
    return sort;
  }

  getColumns(): Column[] {
    const columns: Column[] = [
      {
        dataField: "serialNumber",
        caption: translateText("device.serialNumber"),
        cellTemplate: this.$ability.can("details", "devices")
          ? "linkDetail"
          : "notLinkDetail",
      },
      {
        dataField: "status",
        caption: translateText("device.status"),
        cellTemplate: "cellStatusChipDervice",
        alignment: "left",
      },
      {
        dataField: "typeAndSubtype",
        caption: translateText("device.type"),
      },
      {
        dataField: "manufacturingDate",
        caption: translateText("device.productionDate"),
        cellTemplate: "dateFormatUnix",
        alignment: "left",
      },
      {
        dataField: "lastActivityDate",
        caption: translateText("device.lastActivityDate"),
        cellTemplate: "dateFormatUnix",
        alignment: "left",
      },
      {
        dataField: "lastTelemetryDate",
        caption: translateText("device.lastTelemetryDate"),
        cellTemplate: "dateFormatUnix",
        alignment: "left",
      },
      {
        dataField: "versionHW",
        caption: translateText("general.hardware"),
      },
      {
        dataField: "version",
        caption: translateText("general.version"),
      },
      {
        dataField: "ol",
        caption: translateText("device.ol"),
      },
    ];

    const idTab = this.getCurrentTab();
    if (idTab === 6) {
      columns.splice(2, 0, {
        dataField: "family",
        caption: translateText("device.family"),
        visible: true,
        calculateDisplayValue: (rowData: any) => {
          return translateText("device.deviceDetails." + rowData.family);
        },
        calculateSortValue: () => "family",
      });
    } else if (idTab === 4 || idTab === 5) {
      columns.push({
        dataField: "installationId",
        caption: translateText("deviceDetails.installation"),
        cellTemplate: "linkInstallationDetail",
      });
      columns.push({
        dataField: "wirelessSignal",
        caption: translateText("device.properties.signal_wifi"),
      });
      columns.push({
        dataField: "iccid",
        caption: translateText("deviceDetails.iccid"),
      });
      columns.push({
        dataField: "imei",
        caption: translateText("deviceDetails.imei"),
      });
      columns.push({
        dataField: "simStatus",
        caption: translateText("deviceDetails.simStatus"),
        calculateDisplayValue: (rowData: any) => {
          const status = rowData.simStatus;
          if (translateExists("deviceDetails.status." + status)) {
            return translateText("deviceDetails.status." + status);
          }
          return "";
        },
        calculateSortValue: () => "simStatus",
      });
      columns.push({
        dataField: "simDateUpdated",
        caption: translateText("deviceDetails.simDate"),
        cellTemplate: "dateFormat",
        alignment: "left",
      });
    }

    columns.push({
      dataField: "zone",
      caption: translateText("geo.zone"),
    });
    columns.push({
      dataField: "subzone",
      caption: translateText("geo.subzone"),
    });
    columns.push({
      dataField: "area",
      caption: translateText("geo.area"),
    });

    columns.push({
      cellTemplate: "menu",
      width: 60,
      allowExporting: false,
    });

    return columns;
  }

  /** Obtiene el identificador del tab del filtro. */
  getCurrentTab(): number {
    const idTab: string = sessionStorage.getItem("Devices.tab") ?? "0";
    return parseInt(idTab);
  }

  /** Opciones del menú desplegable. */
  // get menuOptions() {
  //   return [
  //     {
  //       icon: "list_alt",
  //       text: this.$t("general.details"),
  //       to: (item) => ({
  //         name: "InstallationDetails",
  //         params: { installationId: item.id, idTab: this.getCurrentTab() },
  //       }),
  //       allowed: this.$ability.can("details", "installations"),
  //     },
  //     {
  //       icon: "place",
  //       text: this.$t("device.viewOnMap"),
  //       action: (item) => {
  //         this.$emit("viewOnMap", item);
  //       },
  //       allowed: this.$ability.can("map", "installations"),
  //     },
  //     {
  //       icon: "refresh",
  //       text: this.$t("installationDetails.refresh"),
  //       action: (item) => this.refreshItem(item.id),
  //       allowed: this.$ability.can("refresh", "installations"),
  //     },
  //   ];
  // }

  /** Obtiene del back la información de un registro. */
  // refreshItem(id: string) {
  //   this.tableDevExtreme.setLoading(true);
  //   installationService
  //     .refreshInstallation(id)
  //     .then((res) => {
  //       this.$store.dispatch("snackbarInfo", {
  //         text: this.$t("installationDetails.refreshedInfo"),
  //       });
  //       this.tableDevExtreme.updateRecord(res.data.id, res.data);
  //     })
  //     .finally(() => {
  //       this.tableDevExtreme.setLoading(false);
  //     });
  // }

  onClickDetail(cellData: any) {
    this.$router.push({
      name: "DeviceDetails",
      params: {
        deviceId: cellData.data.serialNumber,
      },
    });
  }

  /*getExcelCustomizeCell({ gridCell, excelCell }) {
    if (gridCell.column.dataField === "status") {
      if (gridCell.value === "CONNECTED") {
        excelCell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "4caf50" },
        };
        excelCell.value = translateText("installation.online");
      } else if (gridCell.value === "DISCONNECTED") {
        excelCell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "616161" },
        };
        excelCell.value = translateText("installation.offline");
      } else if (gridCell.value === "WARNING") {
        excelCell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "ffa000" },
        };
        excelCell.value = translateText("installation.warning");
      }
    } else if (gridCell.column.dataField === "createdAt") {
      excelCell.value = DateHelper.dateFormat(
        gridCell.value,
        "DD/MM/YYYY HH:mm"
      );
    } else if (gridCell.column.dataField === "updatedAt") {
      excelCell.value = DateHelper.dateFormat(
        gridCell.value,
        "DD/MM/YYYY HH:mm"
      );
    }
  }*/
  get showExport() {
    return this.$ability.can("export", "installations");
  }
}

/*      
if (gridCell.rowType === 'data') {
        if (gridCell.column.dataField === 'Phone') {
          excelCell.value = parseInt(gridCell.value, 10);
          excelCell.numFmt = '[<=9999999]###-####;(###) ###-####';
        }
        if (gridCell.column.dataField === 'Website') {
          excelCell.value = { text: gridCell.value, hyperlink: gridCell.value };
          excelCell.font = { color: { argb: 'FF0000FF' }, underline: true };
          excelCell.alignment = { horizontal: 'left' };
        }
      }
      if (gridCell.rowType === 'group') {
        excelCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'BEDFE6' } };
      }
      if (gridCell.rowType === 'totalFooter' && excelCell.value) {
        excelCell.font.italic = true;
      }
          
        },*/
