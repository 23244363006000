import { FirmwareModel } from "@/ui/models/FirmwareModel";
import { Vue, Component, Emit, Prop } from "vue-property-decorator";

@Component
export default class DialogUploadFwStep2 extends Vue {
  /** Firmware que se está trabajando. */
  @Prop() firmwareUpload!: FirmwareModel;
  /** Indica si el formulario es válido. */
  validForm = true;

  /*public showType() {
    return (
      this.firmwareUpload.family !== "PANEL" &&
      this.firmwareUpload.family !== "EDIBOX"
    );
  }

  public showSubType() {
    return (
      this.firmwareUpload.family !== "PANEL" &&
      this.firmwareUpload.family !== "EDIBOX"
    );
  }*/

  public get changelogRules() {
    return [(v) => !v || v.length <= 50 || this.$t("firmware.changelogmax")];
  }

  public get detailsRules() {
    return [(v) => !v || v.length <= 255 || this.$t("firmware.detailsmax")];
  }

  @Emit("continue")
  onContinue() {
    if (this.validForm) {
      return true;
    }
  }

  @Emit("cancel")
  onCancel() {
    return true;
  }
}
