export const setUserLogged = (state, payload) => {
  state.isUserLogged = payload.isUserLogged;
  state.user = payload.user;
  state.password = payload.password;
  state.remember = payload.remember;
  state.accessToken = payload.accessToken;
  state.expiresIn = payload.expiresIn;
  if (payload.remember) {
    if (payload.isUserLogged) {
      localStorage.setItem("isUserLogged", payload.isUserLogged);
    } else {
      localStorage.removeItem("isUserLogged");
    }
    localStorage.setItem("user", payload.user);
    localStorage.setItem("password", payload.password);
    localStorage.setItem("remember", payload.remember);
    localStorage.setItem("accessToken", payload.accessToken);
    localStorage.setItem("expiresIn", payload.expiresIn);
  } else {
    localStorage.removeItem("isUserLogged");
    localStorage.removeItem("user");
    localStorage.removeItem("password");
    localStorage.removeItem("remember");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("expiresIn");
  }
};

export const setUserLogout = (state) => {
  state.isUserLogged = false;
  localStorage.removeItem("isUserLogged");
  localStorage.removeItem("user");
  localStorage.removeItem("password");
  localStorage.removeItem("remember");
  localStorage.removeItem("accessToken");
  localStorage.removeItem("expiresIn");
}

export const snackbarError = (state, payload) => {
  state.snackbar = payload.active;
  state.snackText = payload.text;
}

export const snackbarInfo = (state, payload) => {
  state.snackbarInfo = payload.active;
  state.snackTextInfo = payload.text;
}

export const setNotifications = (state, payload) => {
  state.notifications = payload;
}

export const addNotification = (state, payload) => {
  state.notifications = [...state.notifications, payload];
}