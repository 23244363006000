import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import AppCopyButton from "@/components/AppCopyButton.vue";

@Component({
  components: {
    AppCopyButton,
  },
})
export default class TooltipAndCopy extends Vue {
  @Prop() label!: string;
  @Prop() value!: string;
}
