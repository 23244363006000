<template lang="html" src="./InstallationDetailsMonitor.html"></template>

<!-- eslint-disable @typescript-eslint/no-explicit-any -->
<!-- eslint-disable @typescript-eslint/ban-types -->
<script lang="ts">
import { Vue, Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import { goTo } from "@/router";
import stringMixin from "@/mixins/string.mixin";
import DeviceProperties from "../../../Devices/DeviceProperties/DeviceProperties.vue";
import rexistroService from "@/api/rexistro";
import { displayErrorMessage } from "@/api";
import AppInputDate from "@/components/AppInputDate.vue";
import AppEventList from "@/components/AppEventList.vue";
import Ustring from "@/mixins/string";

@Component({
  components: {
    DeviceProperties,
    AppInputDate,
    AppEventList
  }
})
export default class InstallationDetailsMonitor extends Mixins(Vue, stringMixin) {

  @Prop(Object) value!: any;
  @Prop(Function) closeModal!: Function;
  @Prop(Boolean) hideButtons!: false;

 /*  all_has_content!: any;
  has_content!: any; */


  questionSrc = process.env.VUE_APP_PUBLIC_PATH + "assets/devices/" + "unknown.jpg";
  imgHasError = false;
  imgURL = "";
  showDeviceProperties = false;
  deviceSerialNumber = '';

  //Para la pantalla de History
  $moment!: any;
  showEventHistory = false;
  isLoadingEvents = false;
  eventsHistory = Array<{time: number, deviceId: string, type: string, subtype: string}>();
  totalPagesEvent = 0;
  totalSizeEvent = 0;
  pageNumberEvent = 1;
  endDateHistory = this.$moment().format('YYYY-MM-DD');
  startDateHistory= this.$moment().subtract(30,'days').format('YYYY-MM-DD');
 


  get image() {
    return this.imgHasError ? this.questionSrc : this.imgURL;
  }

  get version() {
    return this.value.versionHW + "." + this.value.versionSW;
  }

  get isGU() {
    return this.value.guard;
  }

  get devicesDetails() {
    return this.$store.getters["devices/oneDeviceData"];
  }
  
  @Watch('value')
  onChangeValue() {
    this.setMonitorImage();
  }

  mounted() {
    this.setMonitorImage();
    if(this.value.guard) {
        this.$store.dispatch("devices/getOneDeviceData", {
        deviceId: this.value.serialNumber
      });
    }
  }

  setMonitorImage() {
    let deviceFile = "unknown.jpg";
  
    let isAllContent = false;
    
    isAllContent  = Ustring.all_has_content(
      this.value.family,
      this.value.type,
      this.value.subtype
    );
    
    if (isAllContent && this.value.family != "GUARDUNIT") {
      deviceFile =
        this.value.family +
        "_" +
        this.value.type +
        "_" + 
        this.value.subtype +
        ".jpg";
    }

    else if(Ustring.has_content(this.value.family)) {
      deviceFile =  this.value.family + ".jpg";
    
    }
    this.imgURL =
      process.env.VUE_APP_PUBLIC_PATH + "assets/devices/" + deviceFile;
  }

  goToDetails() {
    if (this.closeModal) this.closeModal();

    goTo(`device-${this.value.serialNumber}`);
  }

  showProperties() {
    this.showDeviceProperties = true;    
    this.deviceSerialNumber = this.value.serialNumber;
  }

  async openEventHistory() {
    this.isLoadingEvents = true;
    this.pageNumberEvent = 0;
    this.eventsHistory = [];
    this.showEventHistory = true;
    await this.loadEvents();
    this.isLoadingEvents = false;
  }

  loadMoreEvents(page){
    this.pageNumberEvent = page;
    this.loadEvents();
  }
  
  loadEvents() {
    
    return rexistroService
      .getEventHistory("deviceId="+this.value.serialNumber+"&startDateHistory="+this.startDateHistory+"&endDateHistory="+this.endDateHistory,this.pageNumberEvent)
      .then(res => {
        this.totalPagesEvent = res.data.totalPages;
        this.totalSizeEvent = res.data.totalElements;
        this.eventsHistory.push(...res.data.content);
      })
      .catch(err => {
          displayErrorMessage(err, {
            general: ["general.error.errorGetInfo"]
          });
          throw err;
        }
      );
  }

}
</script>