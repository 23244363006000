import * as getters from "./getters";
import * as actions from "./actions";
import * as mutations from "./mutations";

const state = {
  firmwaresInfo: {
    familyOptions: [],
    typeOptions: [],
    subtypeOptions: [],
    list: [],
    buildOptions: [],
    totalFirmwares: 0
  },
  numberOfDeviceTypes: 0,

  firmwaresPerType: [],
  listFamilies: [],
  listTypes: [],
  listSubtypes: [],
  firmwareRecord: {},

  oneFirmwareInfoByType: {
    changelog: "-",
    details: "-"
  }
};

export default ({
  namespaced: true,
  state,
  getters,
  actions,
  mutations
});