import Api from "./index";

const SERVICE_PATH = "/payment/api/v1";

export const forceDownloadDocument = (name, data) => {
  
  const link = document.createElement("a");
  link.setAttribute("href", window.URL.createObjectURL(data));
  link.setAttribute("download", name + ".pdf");

  document.body.appendChild(link);
  link.click();
  link.remove();
}

const paymentService = {
  getUserAmount: (userId) => Api(true).get(`${SERVICE_PATH}/accounts/credit?userId=${userId}`),

  updateUserAmount: (userId, amount, currency, reason) => Api(true).post(`${SERVICE_PATH}/accounts/credit/update/${userId}?amount=${amount}&reason=${reason}&currency=${currency}`),

  //getUserDeviceLastInvoice: (userId, deviceId) => Api(true).get(`${SERVICE_PATH}/invoices/last?userId=${userId}&deviceId=${deviceId}`),

  generateInvoiceDoc: (invoiceId) => Api(true).get(`${SERVICE_PATH}/invoices/${invoiceId}/document`, { responseType: 'blob' }),

  getPaymentMethods: (userId) => Api(true).get(`${SERVICE_PATH}/paymentmethods?userId=${userId}`),
}

export default paymentService;