import { createWorker } from "@/worker.utils";
import { createAppNotificacion } from "@/mixins/notification.mixin";
import { goTo } from "@/router";

import jobService from "@/api/job";

window.activeJobsFinishedWorkers = {};

export const createJobsWatcherWorker = () => createWorker(() => self.addEventListener(
  "message",
   e => {

    if (e.origin === process.env.VUE_APP_PUBLIC_PATH) {return}// Compliant
    
    const data = e.data;

    const finish = (jobData) => {
      self.postMessage({ state: "finished", params: { jobData } });
      self.close();
    };

    switch (data.cmd) {
      case "start": {

        self.postMessage({ state: "do_check" });

        setInterval(() => {
          self.postMessage({ state: "do_refresh" });
        }, data.interval);

        self.postMessage({ state: "started" });

        break;
      }
      case "send_check":
        self.postMessage({ state: "do_check" });
        break;
      case "check":
        if (data.response.status === 'COMPLETED' || data.response.status === 'FAILED' || data.response.status === 'CANCELLED')
          finish(data.response);
        break;
      default:
        self.postMessage({ state: "error", msg: "Unknown command: " + data.msg });
    }
  },
  false
));

export default (jobData) => {
  const worker = createJobsWatcherWorker();
  window.activeJobsFinishedWorkers[jobData.id] = worker;

  const interval = (jobData.immediate ? 300000 : 3600000);

  worker.addEventListener("message", e => {
    if (e.data.state === "do_refresh") { // worker requests job refresh
      jobService.refreshJobInfo(jobData.id, false).then(() => {
        worker.postMessage({ cmd: 'send_check' });
      });
    }

    if (e.data.state === "do_check") { // worker requests job data
      // obtain job status
      jobService.getJob(jobData.id).then(res => {
        worker.postMessage({ cmd: 'check', response: res.data }); // pass the job data to worker for check
      });
    }

    if (e.data && e.data.state === "finished") { // worker sends finished state (job is finished)
      const job = e.data.params.jobData;            
      const randomsArr = new Uint32Array(1);
      window.crypto.getRandomValues(randomsArr);

      if (job.failed >= 1) {
      createAppNotificacion({
        id: `jf-${job.id}${randomsArr[0]}`,
        name: ['jobs.notificationsWatcher.wrong', [job.jobName]],
        action: () => goTo('job-' + job.id)
      });
      
    } else {
      createAppNotificacion({
        id: `jf-${job.id}${randomsArr[0]}`,
        name: ['jobs.notificationsWatcher.' + job.status.toLowerCase(), [job.jobName]],
        action: () => goTo('job-' + job.id)
      });
    }
      if(window.activeJobsFinishedWorkers) delete window.activeJobsFinishedWorkers[job.id];
    }
  }, false);

  worker.postMessage({ cmd: 'start', jobId: jobData.id, interval });

}