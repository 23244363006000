import { Vue, Component, Prop} from 'vue-property-decorator'
import { ChartData, ChartOptions } from 'chart.js';
import { Line, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

@Component({
  mixins: [reactiveProp],
  extends: Line
})
export default class extends Vue {
  @Prop(ChartData) chartData?: ChartData;
  @Prop(ChartOptions) options?: ChartOptions;

  public renderChart!: (chartData: ChartData, options: ChartOptions) => void;

  mounted() {
    this.renderChart(this.chartData, this.options)
  }

  refresh() {
    this.renderChart(this.chartData, this.options)
  }
}
