<template lang="html" src="./PlanAvailabilityAddEditModal.html"></template>

<!-- eslint-disable @typescript-eslint/no-explicit-any -->
<script lang="ts">
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import dateMixin from "@/mixins/date.mixin";

import { displayErrorMessage } from "@/api";
import katalogService from "@/api/katalog";

@Component({
  mixins: [dateMixin],
})
export default class PlanAvailabilityAddEditModal extends Vue {
  @Prop(String) planCode!: string;
  @Prop(Object) value!: any;
  @Prop(Array) regions!: Array<any>;
  @Prop(Array) currentRegions!: Array<any>;

  isSaving = false;
  isNew = this.value === null;
  data = { ...this.value };

  $refs: any = {
    form: HTMLFormElement,
  };

  get filteredRegions() {
    return this.isNew
      ? this.regions.filter(
          (l) => !this.currentRegions.find((cr) => cr.code === l.code)
        )
      : this.regions;
  }

  get fieldRequired() {
    return [(v) => !!v || this.$t("general.fieldIsRequired")];
  }

  get priceRules() {
    return [
      (v) => !!v || this.$t("general.fieldIsRequired"),
      (v) => v >= 0 || this.$t("planDetails.priceMustBeGreatherThan0"),
    ];
  }

  @Watch("value", { immediate: true, deep: true })
  onChangeValue(v) {
    this.isNew = v === null;
    this.data = { ...v };
  }

  close(isSaved = false) {
    this.$refs.form.reset();
    this.$refs.form.resetValidation();

    this.$emit("onCloseRequest", isSaved);
  }

  save(e) {
    e.preventDefault();

    if (!this.$refs.form.validate()) return false;

    this.isSaving = true;

    katalogService.modifyPlanPrice(this.planCode, this.data).then(
      () => {
        this.close(true);

        this.$store.dispatch("snackbarInfo", {
          text: this.$t("planDetails.planPriceModified"),
        });

        this.isSaving = false;
      },
      (err) => {
        this.isSaving = false;

        displayErrorMessage(err, {
          general: ["planDetails.error.modifyPlanPrice"],
        });
      }
    );
  }
}
</script>
