import store from "@/store";
import i18n from "@/lang/i18n";

const createNativeNotification = (data) => {
  const notification = new Notification("MAVI", {
    body: i18n.t(data.name[0], data.name[1]),
    icon: process.env.VUE_APP_PUBLIC_PATH + "favicon.png"
  });

  notification.onclick = () => {
    if(data.action)
      data.action();

    store.dispatch("dismissNotification", data.id);
  }
}

export const createAppNotificacion = (data) => {

  store.dispatch("createNotification", {
    date: new Date(),
    ...data,
    dismissed: false
  });

  // Let's check if the browser supports notifications
  if (!("Notification" in window))
    return;

  const permission = Notification.permission;
  if (permission === "granted"){
    createNativeNotification(data);
  }else if (permission !== "denied") {
    Notification.requestPermission().then(perm => {
      // If the user accepts, let's create a notification
      if (perm === "granted")
        createNativeNotification(data);
    });
  }

};

export default {
  methods: {
    createAppNotificacion
  }
}