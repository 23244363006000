import Vue from "vue";
import Component from "vue-class-component";
import {
  DxDataGrid,
  DxColumn,
  DxFilterRow,
  DxPager,
  DxPaging,
  DxOperationDescriptions,
  DxExport,
  DxHeaderFilter,
  DxSearchPanel,
  DxToolbar,
  DxItem,
  DxColumnChooser,
  DxGrouping,
  DxLoadPanel,
} from "devextreme-vue/data-grid";
import DxButton from "devextreme-vue/button";
import { Prop, Ref, Watch } from "vue-property-decorator";
import CustomStore from "devextreme/data/custom_store";
import { Column } from "devextreme/ui/data_grid";

import AppListMenu from "../AppListMenu.vue";

import i18n, { translateText } from "@/lang/i18n";
import DateHelper from "@/helpers/dateHelper";
import DxfExcelHelper from "@/helpers/DxfExcelHelper";

import InstallationStatusChip from "../../views/Installations/InstallationStatusChip/InstallationStatusChip.vue";
import DxfPdfHelper from "@/helpers/DxfPdfHelper";

import LanguagesHelper from "@/helpers/languagesHelper";

import StatusChip from "../StatusChip/StatusChip.vue";

import AppChipInfo from "../AppChipInfo.vue";

import ChipJobStatus from "@/ui/components/ChipJobStatus/ChipJobStatus.vue";

@Component({
  components: {
    DxDataGrid,
    DxColumn,
    DxFilterRow,
    DxPager,
    DxPaging,
    DxOperationDescriptions,
    DxExport,
    DxHeaderFilter,
    DxSearchPanel,
    DxToolbar,
    DxItem,
    DxButton,
    DxColumnChooser,
    DxGrouping,
    DxLoadPanel,
    AppListMenu,
    InstallationStatusChip,
    StatusChip,
    AppChipInfo,
    ChipJobStatus,
  },
})
export default class DxfDataGrid extends Vue {
  /** Datos de la tabla. */
  @Prop() dataStore!: CustomStore;
  /** Columnas de la tabla. */
  @Prop() columns!: Column[];
  /** Nombre del fichero exportado */
  @Prop({ default: "table" }) titleTable!: string;

  @Prop({ default: false }) showExport!: boolean;
  // eslint-disable-next-line
  @Prop() menuOptions!: any;

  @Prop({ default: false }) showFilter!: boolean;

  /** Referencia a la tabla. */
  @Ref("tableDevExtreme") tableDevExtreme!: DxDataGrid;

  dateHelper = DateHelper;

  constructor() {
    super();
    LanguagesHelper.getInstance()
      .getEmitter()
      .on("change", this.onChangeLanguage);
  }
  // eslint-disable-next-line
  onChangeLanguage(lang: string) {
    this.tableDevExtreme?.instance?.refresh();
  }

  @Watch("dataStore")
  onChangeDataStore() {
    this.tableDevExtreme.instance?.pageIndex(0);
  }

  public setLoading(loading) {
    if (this.tableDevExtreme.instance) {
      if (loading) {
        this.tableDevExtreme.instance.beginCustomLoading(
          translateText("general.messages.loading")
        );
      } else {
        this.tableDevExtreme.instance.endCustomLoading();
      }
    }
  }

  public updateRecord(id, record) {
    if (this.tableDevExtreme.instance) {
      const items = this.tableDevExtreme.instance.getDataSource().items();
      const item = items.find((item) => {
        return item.id === id;
      });
      Object.assign(item, record);
      this.tableDevExtreme.instance.refresh(id);
    }
  }

  public refresh() {
    if (this.tableDevExtreme.instance) {
      this.tableDevExtreme.instance.refresh();
    }
  }

  async onExportingExcel(e) {
    e.cancel = true;
    try {
      const excel = new DxfExcelHelper(this.titleTable);
      excel.export(e.component);
    } catch (error) {
      console.log(error);
    }
  }

  async exportToPdf() {
    try {
      const pdf = new DxfPdfHelper(this.titleTable);
      if (this.tableDevExtreme.instance) {
        pdf.export(this.tableDevExtreme.instance);
      }
    } catch (error) {
      console.log(error);
    }
  }

  dateFormat(date: string, format: string) {
    return DateHelper.dateFormat(date, format);
  }
  dateFormatUnix(date: number, format: string) {
    return DateHelper.dateFormatUnix(date, format);
  }

  onClickDetail(cellData) {
    this.$emit("clickDetail", cellData);
  }

  setStyleClass(item: number) {
    let classStyle = "";
    if (item === 0) {
      classStyle = "lowOpacity";
    }

    return classStyle;
  }

  isOpenditRole(cellData) {
    return cellData?.data?.device === "MONITOR WIFI" &&
      this.$store.getters["internalusers/roleUserData"]?.at(0) ===
        "ROLE_OPENDIT"
      ? true
      : false;
  }
}
