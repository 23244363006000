import { render, staticRenderFns } from "./FilterTableHeaderSimple.vue?vue&type=template&id=72c7a960"
import script from "./FilterTableHeaderSimple.vue?vue&type=script&lang=ts"
export * from "./FilterTableHeaderSimple.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports