/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import Api from "./index";

const SERVICE_PATH = "/firmware/api/v1";
const SERVICE_PATH2 = "/firmware/api/v2";

export default {
  getFirmwares: () => Api(true).get(`${SERVICE_PATH2}/firmware`),

  getFirmwaresByType: (device) => {
    let url = `${SERVICE_PATH2}/firmware?family=${device.family}`;
    
    if (
      device.type &&
      device.subtype &&      
      device.family !== "PANEL" &&
      device.family !== "EDIBOX"
    )
      url += `&type=${device.type}&subtype=${device.subtype}`;

   if (
       device.type &&        
       device.family === "PANEL" && device.type === "CORA"
    )
      url += `&type=${device.type}`;

    if (
      device.versionHW !== null &&
      device.versionHW !== undefined &&
      device.versionHW.trim() !== ""
    )
      url += `&versionHW=${device.versionHW}`;

    /*  if(device.versionSW !== null && device.versionSW !== undefined && device.versionSW.trim() !== '')
      url += `&versionSW=${device.versionSW}`; */
    /*  if (device.versionSW !== null && device.versionSW !== undefined && device.versionSW.trim() !== '') {        
        url += `&versionSW=${device.versionSW}`;
      } else */ if (device.version) {
      url += `&versionSW=${device.version}`;
    }

    return Api(true).get(url);
  },

  uploadFirmware: (sha, file, onUploadProgress = (progressEvent) => {}) =>
    Api(true).put(`${SERVICE_PATH2}/firmware?sha256=${sha}`, file, {
      headers: { "Content-Type": "multipart/form-data" },
      onUploadProgress: onUploadProgress,
    }),

  uploadFirmwareDetails: (id, details) =>
    Api(true).post(`${SERVICE_PATH2}/firmware/${id}`, details, {
      headers: { "Content-Type": "application/json" },
    }),

  deleteFirmware: (id) => Api(true).delete(`${SERVICE_PATH}/firmware/${id}`),

  setPropertiesEdit: (id, changelog) =>
    Api(true).post(`${SERVICE_PATH}/firmware/${id}/changelog`, changelog, {
      headers: { "Content-Type": "application/json" },
    }),

  setDetails: (id, details) =>
    Api(true).post(`${SERVICE_PATH}/firmware/${id}/details`, details, {
      headers: { "Content-Type": "application/json" },
    }),

  setCritical: (id, critical) =>
    Api(true).post(`${SERVICE_PATH}/firmware/${id}/critical`, critical, {
      headers: { "Content-Type": "application/json" },
    }),

  //setAutomaticUpdate: (id, automaticUpdate) => Api(true).post(`${SERVICE_PATH}/firmware/${id}/automatic-update`, automaticUpdate, {headers: {"Content-Type": "application/json"}}),

  removeFirmware: (id) => Api(true).delete(`${SERVICE_PATH}/firmware/${id}`),

  /** Obtiene un listado de firmwares compatibles con la familia, typo y subtipo.
   * Si es un PANEL, puede tener el type "CORA" con lo cual hay que buscar por type.
   */
  getFirmwareshardwareCompatible: (device) => {
    let url = `${SERVICE_PATH2}/firmware-hardware-compatible?family=${device.family}`;

    if (device.type && device.subtype && device.family !== "EDIBOX") {
      url += `&type=${device.type}&subtype=${device.subtype}`;
    } else if (device.family === "PANEL" && device.type) {
      url += `&type=${device.type}`;
    }

    return Api(true).get(url);
  },

  setStatus: (id, status) =>
    Api(true).put(`${SERVICE_PATH2}/firmware/${id}/status/${status}`, status, {
      headers: { "Content-Type": "application/json" },
    }),

  getExistsLatestAutomatic: (automatic, family, type, subtype, hw) => {
    let url;
    automatic
      ? (url = `${SERVICE_PATH2}/exists-automatic-update?family=${family}`)
      : (url = `${SERVICE_PATH2}/exists-latest?family=${family}`);

    if (type && subtype && (family !== "PANEL" || family !== "EDIBOX"))
      url += `&type=${type}&subtype=${subtype}`;

    if (hw !== null) url += `&versionHW=${hw}`;

    return Api(true).get(url);
  },

  getFirmwareById: (id) =>
    Api(true).get(`${SERVICE_PATH}/firmware/${id}`, {
      headers: { "Content-Type": "application/json" },
    }),

  getDeviceTypes: (family) =>
    Api(true).get(`${SERVICE_PATH}/family/${family}/type`),

  getNumberOfDeviceTypes: () => Api(true).get(`${SERVICE_PATH}/subtypes/count`),

  getDeviceSubTypes: (family, type, connectable) =>
    Api(true).get(
      `${SERVICE_PATH}/family/${family}/type/${type}/subtype?` +
        (connectable != null
          ? connectable
            ? `connectable=true`
            : "connectable=false"
          : "")
    ),

  getDeviceFamilies: () => Api(true).get(`${SERVICE_PATH}/family`),
};
