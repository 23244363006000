export const usersList = state => state.usersList; 
export const internalUsers = state => state.internalUsers;
export const internalUserData = state => state.internalUserData;
export const usersInfo = state => state.usersInfo;
export const internalInfo = state => state.internalInfo;
export const oneUserData = state => state.oneUserData;
export const roleUserData = state => state.roleUserData;
export const rolesList = state => state.rolesList; 



