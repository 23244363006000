import axios from "axios";

import store from "@/store";

export default (withAuth, baseUrl = process.env.VUE_APP_PRE_URI) => {
  const instance = axios.create({
    baseURL: baseUrl,
    withCredentials: false,
  });

  if (withAuth) {
    instance.interceptors.request.use((config) => {
      return store.dispatch("renewTimeout", {}).then(
        (token) => {
          config.headers = {
            ...config.headers,
            Authorization: "Bearer " + token,
          };

          return Promise.resolve(config);
        },
        (err) => {
          return Promise.reject(err);
        }
      );
    });
  }
  return instance;
};

export const ApiBasicAuth = (
  withAuth,
  baseUrl = process.env.VUE_APP_PRE_URI
) => {
  const instance = axios.create({
    baseURL: baseUrl,
    withCredentials: false,
  });

  if (withAuth)
    instance.interceptors.request.use((config) => {
      return store.dispatch("renewTimeout", {}).then(
        () => {
          config.headers = {
            ...config.headers,
            Authorization: "Basic " + store.getters.getMyAuth,
          };
          return Promise.resolve(config);
        },
        (err) => {
          return Promise.reject(err);
        }
      );
    });

  return instance;
};

export const displayErrorMessage = (error, displayMessages) => {
  console.error(error);

  store.dispatch("errorHandler", {
    error: error,
    genericErrorText: displayMessages.general,
    badRequestText: displayMessages.badRequest,
    forbiddenText: displayMessages.forbidden,
    notFoundText: displayMessages.notFound,
    notAcceptableText: displayMessages.notAcceptable,
    conflictText: displayMessages.conflict,
    tooManyRequestsText: displayMessages.tooManyRequests,
    lengthHeaderRequired: displayMessages.lengthHeaderRequired,
  });
};
