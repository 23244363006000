import Vue from "vue";
import Component from "vue-class-component";

import { Prop, Ref } from "vue-property-decorator";
import DxfDataGrid from "../../../components/DxfDataGrid/DxfDataGrid.vue";
import { translateText } from "@/lang/i18n";

import { Column } from "devextreme/ui/data_grid";

@Component({
  components: {
    DxfDataGrid,
  },
})
export default class PlansTable extends Vue {
  // eslint-disable-next-line
  @Prop() dataArray!: Array<any>;
  //@Prop() menuOptions!: Array<any>;

  /** Referencia a la tabla. */
  @Ref("refDxfDataGrid") tableDevExtreme!: DxfDataGrid;

  title = translateText("menu.plans");

  showExport = this.$ability.can("export", "plans");

  get columns(): Column[] {
    const columns: Column[] = [
      {
        dataField: "code",
        caption: translateText("plans.code"),
        cellTemplate: "linkDetail",
      },
      {
        dataField: "name",
        caption: translateText("plans.name"),
      },
      {
        dataField: "services",
        caption: translateText("plans.services"),
        cellTemplate: "multiChip",
        cssClass: "whiteSpaceNormal",
      },
      {
        dataField: "plan.renewalPeriod",
        caption: translateText("plans.renovation"),
        // eslint-disable-next-line
        calculateDisplayValue: (rowData: any) => {
          return translateText(
            "plans.renewalTypes." +
              (rowData.plan.renewalPeriod || "NO_BILLING_PERIOD")
          );
        },
      },
      {
        dataField: "plan.trial",
        caption: translateText("plans.trialAvailable"),
        cellTemplate: "PlanTrial",
        // eslint-disable-next-line
        calculateDisplayValue: (rowData: any) => {
          if (rowData.plan.trial) {
            return translateText("general.yes");
          } else {
            return translateText("general.no");
          }
        },
      },
      {
        cellTemplate: "menu",
        width: 60,
        allowExporting: false,
      },
    ];

    return columns;
  }

  get menuOptions() {
    return [
      {
        icon: "list_alt",
        text: this.$t("general.details"),
        to: (item) => ({
          name: "PlanDetails",
          params: { planCode: item.plan.planId },
        }),
        allowed: this.$ability.can("details", "plans"),
      },
    ];
  }
  // eslint-disable-next-line
  onClickDetail(item: any) {
    this.$router.push({
      name: "PlanDetails",
      params: { planCode: item.data.plan.planId },
    });
  }
}
