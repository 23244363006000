import store from "@/store";

import adminRules from "./role_admin.json";
import experimentalRules from "./role_experimental.json";
import managementRules from "./role_management.json";
import managerRules from "./role_manager.json";
import intlOutlierRules from "./role_intl_outlier.json";
import spanishOutlierRules from "./role_spanish_outlier.json";
import openditRules from "./role_opendit.json";

const parseToken = (token) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

// Recibe el nombre de un rol y devuelve si el usuario lo tiene
export const hasRol = (rol) => {
  const token = store.getters["accessToken"];
  const payloadToken = parseToken(token);
  return (
    payloadToken &&
    payloadToken.authorities &&
    payloadToken.authorities.findIndex((x) => x === rol) !== -1
  );
};

// Recibe un array de roles y devuelve si el usuario tiene alguno de los roles indicados
export const hasAnyOfThisRoles = (roles) => {
  if (roles === null || roles === undefined) {
    return false;
  }

  let hasAnyRol = false;
  for (let index = 0; index < roles.length && !hasAnyRol; index++)
    hasAnyRol = hasRol(roles[index]);

  return hasAnyRol;
};

export const defineAbilitiesFor = () => {
  let userRules = [];

  if (hasAnyOfThisRoles(["ROLE_ADMIN"])) {
    userRules = adminRules;
  } else if (hasAnyOfThisRoles(["ROLE_EXPERIMENTAL"])) {
    userRules = experimentalRules;
  } else if (hasAnyOfThisRoles(["ROLE_MANAGER"])) {
    userRules = managerRules;
  } else if (hasAnyOfThisRoles(["ROLE_MANAGEMENT"])) {
    userRules = managementRules;
  } else if (hasAnyOfThisRoles(["ROLE_SPANISH_OUTLIER"])) {
    userRules = spanishOutlierRules;
  } else if (hasAnyOfThisRoles(["ROLE_INTL_OUTLIER"])) {
    userRules = intlOutlierRules;
  } else if (hasAnyOfThisRoles(["ROLE_OPENDIT"])) {
    userRules = openditRules;
  }

  return userRules;
};
