<template>
  <v-menu left v-if="items.length > 0 && hasAllowedMenuOptions()">
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" icon class="ma-0" :loading="loading">
        <v-icon>more_vert</v-icon>
      </v-btn>
    </template>

    <v-list>
      <v-list-item
        v-for="(item, i) in itemsFilters()"
        :key="i"
        @click="onItemClick(item)"
        :to="item.to && item.to(itemData)"
      >
        <v-list-item-action>
          <v-icon v-bind="item.iconProps || {}">{{ item.icon }}</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ item.text }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<!-- eslint-disable @typescript-eslint/ban-types -->
<!-- eslint-disable @typescript-eslint/no-explicit-any -->
<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator";

export const hasAllowedMenuOptions = (options) =>
  options.filter((o) => o.allowed).length > 0;

@Component
export default class AppListMenu extends Vue {
  @Prop(Array) value!: Array<any>;
  @Prop(Object) itemData!: Object;
  @Prop(Boolean) loading!: boolean;

  itemsFilters() {
    //Solo oculta la opcion de editar el firmware si el firmware es 00
    return this.items.filter(
      (i) =>
        !(
          i?.icon == "edit" &&
          (this.itemData as any)?.firmwareVersionV2?.fwMajor == "00"
        )
    );
  }

  onItemClick(item) {
    //Solo deshabilitar la opcion de editar el firmware si el firmware es 00
    if (
      (this.itemData as any)?.firmwareVersionV2?.fwMajor == "00" &&
      item.icon == "edit"
    ) {
      this.$store.dispatch("snackbarInfo", {
        text: this.$t("firmware.disabledOption"),
      });
      return;
    }
    if (item.action) item.action(this.itemData);
  }

  get items() {
    return this.value.filter(
      (i) => i.allowed && (!i.allowedForItem || i.allowedForItem(this.itemData))
    );
  }
  hasAllowedMenuOptions() {
    return hasAllowedMenuOptions(this.items);
  }
}
</script>
