<template>
  <v-layout row align-center wrap>
    <v-tooltip bottom v-if="$vuetify.breakpoint.xsOnly">
      <template v-slot:activator="{ on }">
        <v-icon v-on="on">{{ icon }}</v-icon>
      </template>

      <span>{{ label }}</span>
    </v-tooltip>
    <v-icon class="hidden-xs-only || pr-1">{{ icon }}</v-icon>
    <span class="hidden-xs-only">{{ label }}:</span>
    <AppChipInfo class="ml-1" type="info" :params="{number: value}" />
  </v-layout>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import AppChipInfo from "@/components/AppChipInfo.vue";

@Component({
  components: {
    AppChipInfo
  }
})
export default class AppKpiChip extends Vue {
  @Prop(String) icon!: string
  @Prop(String) label!: string
  @Prop(Number) value!: number
}
</script>