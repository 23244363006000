/* eslint-disable @typescript-eslint/no-unused-vars */
import { displayErrorMessage } from "@/api";
import firmwareService from "@/api/firmware";
import i18n from "@/lang/i18n";

export const getFirmwaresList = ({ commit }) =>
  new Promise((resolve, reject) => {
    firmwareService
      .getFirmwares()
      .then((res) => {
        const firmwaresList = res.data.filter((item) => {
          let getTypeOrFallback = (key) =>
            i18n.te(`device.deviceDetails.${key}`)
              ? i18n.t(`device.deviceDetails.${key}`)
              : key;

          let type = getTypeOrFallback(item.type);
          let subtype = getTypeOrFallback(item.subtype);

          item.typeAndSubtype = [type, subtype].filter(Boolean).join(" ");

          if (item.versionSW) {
            item.version = item?.versionHW + "." + item?.versionSW;
          } else {
            item.version =
              item.firmwareVersionV2?.fwMajor +
              "." +
              item.firmwareVersionV2?.fwMinor +
              "." +
              item.firmwareVersionV2?.fwBuild;
          }
          return item.status === "COMPLETED";
        });

        const buildOptions = [
          ...new Set(
            firmwaresList
              .filter((item) => item.build !== null)
              .map((item) => item.build)
          ),
        ];
        const totalFirmwares = firmwaresList.length;

        const firmwaresInfo = {
          buildOptions: buildOptions,
          totalFirmwares: totalFirmwares,
          lista: firmwaresList,
          list: [],
        };

        commit("setFirmwaresInfo", firmwaresInfo);

        resolve(firmwaresInfo);
      })
      .catch((err) => {
        displayErrorMessage(err, { general: ["firmware.error.getError"] });

        reject(err);
      });
  });

export const getNumberOfDeviceTypes = ({ commit }) =>
  new Promise((resolve, reject) => {
    firmwareService
      .getNumberOfDeviceTypes()
      .then((res) => {
        commit("setNumberDevices", res.data);

        resolve(res.data);
      })
      .catch((err) => {
        displayErrorMessage(err, { general: ["firmware.error.getError"] });

        reject(err);
      });
  });

export const getOneFirmwareByType = ({ commit }, payload) =>
  new Promise((resolve, reject) => {
    firmwareService
      .getFirmwaresByType(payload.device)
      .then((res) => {
        const fwVersionData = res.data[0];
        if (fwVersionData) commit("setOneFirmwareInfoByType", fwVersionData);

        resolve(fwVersionData);
      })
      .catch((err) => {
        displayErrorMessage(err, { general: ["firmware.error.notFound"] });
        reject(err);
      });
  });

export const getFirmwaresPerType = ({ commit }, payload) =>
  new Promise((resolve, reject) => {
    firmwareService
      .getFirmwaresByType(payload.device)
      .then((res) => {
        const fwVersions = res.data.filter(function (item) {
          if (item.versionSW) {
            item.version = item?.versionHW + "." + item?.versionSW;
          } else {
            item.version =
              item.firmwareVersionV2?.fwMajor +
              "." +
              item.firmwareVersionV2?.fwMinor +
              "." +
              item.firmwareVersionV2?.fwBuild;
          }
          return item.status === "COMPLETED";
        });

        fwVersions.sort(
          (a, b) =>
            b.latest - a.latest ||
            b.versionHW - a.versionHW ||
            b.versionSW - a.versionSW
        );
        commit("setFirmwaresPerType", fwVersions);

        resolve(fwVersions);
      })
      .catch((err) => {
        displayErrorMessage(err, {
          general: ["general.error.loadingDataForm"],
        });
        reject(err);
      });
  });

export const getDeviceFamily = ({ commit }) =>
  new Promise((resolve, reject) => {
    firmwareService
      .getDeviceFamilies()
      .then((res) => {
        commit("setListFamilies", res.data);

        resolve(res.data);
      })
      .catch((err) => {
        displayErrorMessage(err, {
          general: ["general.error.loadingDataForm"],
        });
        reject(err);
      });
  });

export const getDeviceType = ({ commit }, payload) =>
  new Promise((resolve, reject) => {
    if (payload.family === null || payload.family === undefined) {
      commit("setListTypes", []);

      resolve(null);
    }

    firmwareService
      .getDeviceTypes(payload.family)
      .then((res) => {
        commit("setListTypes", res.data);

        resolve(res.data);
      })
      .catch((err) => {
        displayErrorMessage(err, {
          general: ["general.error.loadingDataForm"],
        });
        reject(err);
      });
  });

export const getDeviceSubtype = ({ commit }, payload) =>
  new Promise((resolve, reject) => {
    if (
      payload.family === null ||
      payload.family === undefined ||
      payload.type === null ||
      payload.type === undefined
    ) {
      commit("setListSubtypes", []);

      resolve(null);
    }

    firmwareService
      .getDeviceSubTypes(payload.family, payload.type, payload.connectable)
      .then((res) => {
        commit("setListSubtypes", res.data);

        resolve(res.data);
      })
      .catch((err) => {
        displayErrorMessage(err, {
          general: ["general.error.loadingDataForm"],
        });
        reject(err);
      });
  });

export const getFirmwareId = ({ commit }, payload) =>
  new Promise((resolve, reject) => {
    firmwareService
      .getFirmwareById(payload)
      .then((res) => {
        commit("setFirmwareRecord", res.data);

        resolve(res.data);
      })
      .catch((err) => {
        displayErrorMessage(err, {
          general: ["general.error.loadingDataForm"],
        });
        reject(err);
      });
  });
