
import L from 'leaflet';

const mapOptions = {
  worldCopyJump: true, // Si se muestran varios mapas del mundo, copia añadidos de un mapa a otro
  zoomControl: true, // Indica que se debe mostrar el control con + y - para zoom
  minZoom: 1.9, // Zoom mínimo admitido, se limita para evitar zonas grises arriba y abajo al alejarse
  zoomSnap: 0.1, // zoomSnap y zoomDelta habilitan el poder granular el nivel de zoom en tramos más pequeños
  maxBoundsViscosity: 1.0, // Si se fija un maxbounds, este valor 1.0 evita que el usuario pueda hacer panning fuera de la zona permitida
  maxBounds: [
    [-90, -180],
    [90, 180],
  ], // Este valor de maxBounds comprende todo el planeta
  // y reduce a lo mínimo
  //las zonas grises sin mapa en pantallas grandes cuando se aplica tileLayerOptions.noWrap
};

const markerIcon = (path = "marker-icon_grey.png") => {
    const url = process.env.VUE_APP_PUBLIC_PATH + "assets/markers/" + path;
    
    return L.icon({ iconUrl: url, iconAnchor: [15, 30] });
  }

const getMarkerLatLong=(name, marker) => {
    const latLong = [marker.latitude, marker.longitude];

    if ((!marker.latitude && marker.latitude !== 0) || (!marker.longitude && marker.longitude !== 0)) {
      latLong[0] = LAT_FERMAX;
      latLong[1] = LONG_FERMAX;

      console.warn(`Geo not defined for marker ${name}, defaulting to Fermax warehouse`);
    }

    return latLong;
  }

  const getColorInGrades=(value, grades, colors) => {
    if (
      grades &&
      grades.length > 0 &&
      colors &&
      colors.length > 0
    ) {
      for (let i = 0; i < grades.length - 1; i++) {
        if (value >= grades[i] && value < grades[i + 1]) {
          return colors[i];
        }
      }

      return colors[colors.length - 1];
    }
    return null;
  }   

export const LAT_FERMAX = 39.453489;
export const LONG_FERMAX = -0.3989459;


export {
    mapOptions,
    markerIcon,
    getMarkerLatLong,
    getColorInGrades
}