<template lang="html" src="./DevicesPropertiesItem.html"></template>

<!-- eslint-disable @typescript-eslint/no-explicit-any -->
<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator";
import { sortByKey } from "../DeviceProperties.vue";

@Component({
  // It looks like it's the only way to register it for recursive calls
  name: "DevicesPropertiesItem",
})
export default class DevicesPropertiesItem extends Vue {
  @Prop(String) title!: string;
  @Prop() value!: any;

  sortByKey = (value) => sortByKey(value);
}
</script>
<style>
.properties-sublist > .v-list {
  height: auto;
}
.properties-sublist > .v-list > .v-list-item:first-child {
  padding-left: 0;
}
.properties-sublist > .v-list > .v-list-item:not(:first-child) {
  padding-left: 30px;
  padding-right: 0;
}
</style>
<style scoped>
.prop-key,
.properties-sublist > .v-list > .v-list-item:first-child .v-list-item__title {
  white-space: nowrap;
  font-size: 14px;
  font-weight: normal;
  color: #444;
}
.prop-value {
  max-width: -moz-available;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
