import * as getters from "../roles/getters";
import * as actions from "../roles/actions";
import * as mutations from "../roles/mutations";

const state = {

    rolesList: [],
    rolesPermission: [],
    roleUserData: null,
  };

  export default ({
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  });