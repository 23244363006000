<template lang="html" src="./JobCancelModal.html"></template>

<!-- eslint-disable @typescript-eslint/ban-types -->
<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import jobService from "@/api/job";

@Component
export default class JobCancelModal extends Vue {
  @Prop(Boolean) value!: boolean;
  @Prop(String) jobId!: string;
  @Prop(Function) onSuccess!: Function;

  get show() {
    return this.value;
  }

  set show(localValue) {
    this.$emit("input", localValue);
  }

  execute(jobId) {
    this.show = false;

    jobService.cancelJob(jobId).then(
      res => {
        this.$store.dispatch("snackbarInfo", {
          text: this.$t("jobs.info.cancelJobInfo")
        });

        if (this.onSuccess) this.onSuccess(res);
      },
      error => {
        this.$store.dispatch("errorHandler", {
          error: error,
          conflictText: ["jobs.error.jobConflict"],
          genericErrorText: ["jobs.error.cancelFails"]
        });
      }
    );
  }
}
</script>