import DeviceEntity from "../entities/DeviceEntity";
import InstallationService from "../services/InstallationService";

export default class InstallationController {
  protected installationService: InstallationService;
  constructor() {
    this.installationService = new InstallationService();
  }

  /** Obtiene si el dispositivo tiene el servicio de llamadas activo o no. */
  public async getCallDivert(deviceEntity: DeviceEntity): Promise<boolean> {
    const installationId = deviceEntity.installationId;
    const logicalId = deviceEntity.getLogisticId();
    if (logicalId === "") {
      return false;
    }
    return await this.installationService
      .getCallDivert(installationId, logicalId)
      .then((response: boolean) => {
        return response;
      });
  }

  /** Obtiene los dispositivos emparejados. */
  public async getPairedDevices(deviceEntity: DeviceEntity): Promise<string[]> {
    const serialNumberDevice = deviceEntity.serialNumber;
    return await this.installationService
      .getPairedDevices(serialNumberDevice)
      .then((response: string[]) => {
        return response;
      });
  }
}
