<template>
  <v-breadcrumbs
    :items="itemsBread"
    divider=">"
    class="mb-1"
    :class="{'px-2': $vuetify.breakpoint.xsOnly, 'px-4': $vuetify.breakpoint.smAndUp}"
    large
  >
    <v-breadcrumbs-item
      slot="items"
      slot-scope="props"
      :disabled="props.item.disabled"
      :to="props.item.to"
      :key="props.item.text"
    >{{ item.text }}</v-breadcrumbs-item>
  </v-breadcrumbs>
</template>

<!-- eslint-disable @typescript-eslint/no-explicit-any -->
<!-- eslint-disable @typescript-eslint/ban-types -->

<script lang="ts">

import { Vue, Component } from 'vue-property-decorator'
import { mapGetters } from "vuex";

@Component({
  computed: {
    ...mapGetters(["breadcrumbItems"]),
  }
})
export default class AppBreadcrumbs extends Vue {
// eslint-disable-next-line no-undef
[x: string]: any;
  // eslint-disable-next-line @typescript-eslint/ban-types
  breadcrumbItems!: Function;

  get itemsBread() {
    return this.breadcrumbItems({ path: this.$route });
  }
}
</script>