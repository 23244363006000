<template lang="html" src="./Login.html"></template>

<!-- eslint-disable vue/multi-word-component-names -->
<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import { goTo } from "@/router";
import { hasRol } from "@/casl";

import { defineAbilitiesFor } from "@/casl";
import sessionStorageMixin from "@/mixins/session.storage.mixin";

export default {
  name: "Login",

  mixins: [sessionStorageMixin],

  data() {
    return {
      remember: false,
      valid: false,
      email: null,
      emailRules: [
        (v) => !!v || this.$t("login.error.emptyEmail"),
        (v) => /.+@.+/.test(v) || this.$t("login.error.email"),
      ],
      password: "",
      passwordRules: [
        (v) => !!v || this.$t("login.error.emptyPassword"),
        () => this.password.length > 1 || this.$t("login.error.password"),
      ],
      showPass: false,
      loginError: false,
    };
  },

  computed: {
    ...mapGetters(["getMyOauth"]),
    imgSrcLogin() {
      return process.env.VUE_APP_PUBLIC_PATH + "assets/blue.png";
    },
  },

  created() {
    // Si estando logado se accede directamente a la ruta de Login, deslogamos para no mostrar el menú
    if (this.$store.getters["isUserLogged"]) {
      this.setUserLogout();
    }
  },

  methods: {
    ...mapActions(["renewTimeout"]),
    ...mapMutations(["setUserLogged", "setUserLogout"]),
    login() {
      this.loginError = false;
      this.session_clearStorage();

      this.$store
        .dispatch("renewTimeout", {
          user: this.email,
          password: this.password,
          remember: this.remember,
          accessToken: "",
          expiresIn: 0,
          getMyOauth: this.getMyOauth,
        })
        .then(this.onLoginOk, (error) => {
          console.error("Error: " + JSON.stringify(error));
          this.loginError = true;
        });
    },
    onLoginOk() {
      this.$ability.update(defineAbilitiesFor());
      if (hasRol("ROLE_OPENDIT")) {
        this.$router.push({
          name: "Installations",
          params: { lang: this.$route.params.lang ?? "en" },
        });
      } else {
        goTo("/");
      }
    },
  },
};
</script>
