export default class Topology {
  public id!: string;
  public bloque!: number;
  public bloqueName!: string;
  public device!: string;
  public model!: string;
  public direccionBloque!: string;
  public direccionVivienda!: string;
  public tag!: string;
  public version!: string;
  public build!: string;
  public estate!: string;
  public signal!: string;
  public name!: string;
  //public callDivertServiceEnabled!: string;
  public callDivertServiceEnabled!: boolean;
  public coverage!: string;
  public versionHW!: string;
  public deployed!: string;
}
