/* eslint-disable @typescript-eslint/no-unused-vars */
// import moment from "moment";
// import i18n from '@/lang/i18n';

//const XlsxPopulate = require('xlsx-populate');

/*
  Column number to excel column letter.
  Examples: 1 -> A, 27 -> AA
*/
// const numToExcelColumnLetter = num => {
//   let s = '', t = 0;

//   while (num > 0) {
//     t = (num - 1) % 26;
//     s = String.fromCharCode(65 + t) + s;
//     num = (num - t) / 26 | 0;
//   }
//   return s || undefined;
// }

/*
  Get difference of timezone passed respect UTC in milliseconds
*/
// const tzToMilliseconds = (tz) => {
//   const hours = (+tz.slice(1, 3)) * 60 * 60000;
//   const minutes = (+tz.slice(4, 6)) * 60000;

//   return (hours + minutes) * (tz[0] === '+' ? 1 : -1);
// }

/*
  Moment date to Excel date number
*/
// const dateToExcelDateNumber = v =>
//   (
//     v.diff(new Date(Date.UTC(1899, 11, 30)), 'milliseconds') +
//     tzToMilliseconds(v.format("Z"))
//   ) / (24 * 60 * 60 * 1000);

export default (appliedFilters, headers, data_rows, name) => {
  //const data = [headers[0], ...data_rows];
  // XlsxPopulate.fromBlankAsync()
  //   .then(wb => {
  //     const createSheetTitle = (sheet, title) => {
  //       sheet.row(1).height(28);
  //       const titleCell = sheet.row(1).cell(1);
  //       titleCell.value(title);
  //       titleCell.style("bold", true);
  //       titleCell.style("fontSize", 16);
  //       titleCell.style("verticalAlignment", "center");
  //     }
  //     // List (sheet 1)
  //     const ws = wb.sheet(0);
  //     ws.name(i18n.t('general.list'));
  //     const listTitle = name + (appliedFilters.length > 0 ? '*' : '');
  //     createSheetTitle(ws, listTitle);
  //     let num_columns = 0;
  //     for (let i = 0; i < data.length; i++) {
  //       for (let j = 0; j < data[i].length; j++) {
  //         const cell = ws.row(i + 2).cell(j + 1);
  //         if (i === 0) {
  //           cell.value(data[i][j]);
  //           cell.style("fill", "2F75B5");
  //           cell.style("fontColor", "ffffff");
  //           num_columns++;
  //         } else {
  //           if (i === 1)
  //             ws.column(j + 1).width(data[i][j].width || 35);
  //           if (data[i][j].value !== null) {
  //             cell.value(data[i][j].value);
  //             if (data[i][j].style) {
  //               const style = data[i][j].style;
  //               if (style.color) cell.style("fontColor", style.color);
  //               if (style.background) cell.style("fill", style.background);
  //               if (style.cursive) cell.style("cursive", true);
  //               if (style.bold) cell.style("bold", true);
  //             }
  //             if (data[i][j].format === 'number') {
  //               cell.style("numberFormat", "0");
  //             } else if (data[i][j].format === 'decimalNumber') {
  //               cell.style("numberFormat", "0.00");
  //             } else if (data[i][j].format === 'date' || data[i][j].format === 'datetime') {
  //               cell.style("numberFormat", "dd/mm/yyyy" + (data[i][j].format === 'datetime' ? ' HH:mm' : ''));
  //               cell.value(dateToExcelDateNumber(data[i][j].value));
  //             }
  //           }
  //         }
  //       }
  //     }
  //     const lastDataColumn = numToExcelColumnLetter(num_columns);
  //     ws.range("A1:" + lastDataColumn + "1").merged(true);
  //     ws.range("A2:" + lastDataColumn + "2").autoFilter();
  //     // Applied filters (sheet 2)
  //     if (appliedFilters.length > 0) {
  //       const filtersAppliedTitle = i18n.t('general.filtersApplied');
  //       const wsaf = wb.addSheet(`${filtersAppliedTitle} (${appliedFilters.length})`);
  //       createSheetTitle(wsaf, filtersAppliedTitle);
  //       wsaf.range("A1:E1").merged(true);
  //       for (let i = 1; i < 6; i++)
  //         wsaf.column(i).width(30);
  //       const FILTER_COLUMNS = 5;
  //       let currentColumn = 1;
  //       let columnsSize = Array(FILTER_COLUMNS).fill(0);
  //       const getLastColumnNumberPosition = elements => (1 + elements * 3);
  //       for (let i = 0; i < appliedFilters.length; i++) {
  //         const filter = appliedFilters[i];
  //         const startFilterRow = getLastColumnNumberPosition(columnsSize[currentColumn - 1]) + 1;
  //         const labelCell = wsaf.row(startFilterRow).cell(currentColumn);
  //         labelCell.value(filter.title);
  //         labelCell.style("bold", true);
  //         const valueCell = wsaf.row(startFilterRow + 1).cell(currentColumn);
  //         valueCell.value(filter.value);
  //         switch (filter.format) {
  //           case "number":
  //             if (!isNaN(filter.value)) {
  //               valueCell.value(+filter.value);
  //               valueCell.style("numberFormat", "0");
  //             }
  //             break;
  //           case "decimalnumber":
  //             if (!isNaN(filter.value)) {
  //               valueCell.value(+filter.value);
  //               valueCell.style("numberFormat", "0.00");
  //             }
  //             break;
  //           case "date":
  //             valueCell.style("numberFormat", "dd/mm/yyyy");
  //             valueCell.value(dateToExcelDateNumber(moment(filter.value)));
  //             break;
  //         }
  //         columnsSize[currentColumn - 1]++;
  //         currentColumn = (currentColumn < FILTER_COLUMNS) ? currentColumn + 1 : 1;
  //       }
  //       // Background color for filters section
  //       wsaf.range("A2:E" + (getLastColumnNumberPosition(Math.max.apply(null, columnsSize)) - 1)).style({
  //         fill: "dddddd"
  //       });
  //     }
  //     wb.outputAsync()
  //       .then(blob => {
  //         if (window.navigator && window.navigator.msSaveOrOpenBlob) {
  //           // If IE, you must uses a different method.
  //           window.navigator.msSaveOrOpenBlob(blob, name.toLowerCase().replace(/ /g, '-') + ".xlsx");
  //         } else {
  //           const url = window.URL.createObjectURL(blob);
  //           const a = document.createElement("a");
  //           document.body.appendChild(a);
  //           a.href = url;
  //           a.download = name.toLowerCase().replace(/ /g, '-') + ".xlsx";
  //           a.click();
  //           window.URL.revokeObjectURL(url);
  //           document.body.removeChild(a);
  //         }
  //       });
  //   })
  //   .catch(err => console.error(err));
};
