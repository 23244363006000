import Api from "./index";
import { ApiBasicAuth } from "./index";
import { downloadData } from "@/utils/data";

const SERVICE_PATH = "/user/api/v1";
const PUBLIC_SERVICE_PATH = "/user/public/api/v3";
const PAIRING_SERVICE_PATH = "/pairing/api/v3";

const urlToUsers = (
  filterOptions,
  pagination = null,
  search = null,
  column = null,
  isOpenditRole = false
) => {
  const sortDirection = pagination && pagination.descending ? ",desc" : ",asc";

  let urlUsers = SERVICE_PATH + "/users?";

  if (pagination) {
    urlUsers +=
      "page=" + pagination.page + "&size=" + pagination.rowsPerPage + "&";
  }

  if (search) {
    urlUsers += "question=" + encodeURIComponent(search) + "&";
  }

  if (column) {
    urlUsers += "sort=" + column + sortDirection + "&";
  }

  if (filterOptions.emailFilter) {
    urlUsers += "email=" + encodeURIComponent(filterOptions.emailFilter) + "&";
  }

  if (filterOptions.langFilter) {
    urlUsers += "locale=" + filterOptions.langFilter + "&";
  }

  if (filterOptions.dateValueStart) {
    urlUsers +=
      "createDateStart=" + filterOptions.dateValueStart + " 00:00:00" + "&";
  }

  if (filterOptions.dateValueEnd) {
    urlUsers +=
      "createDateEnd=" + filterOptions.dateValueEnd + " 23:59:59" + "&";
  }

  if (filterOptions.disabledFilter === true) {
    urlUsers += "enable=false" + "&";
  }

  if (filterOptions.countryFilter) {
    urlUsers += "country=" + filterOptions.countryFilter + "&";
  }

  if (filterOptions.privacyFilter) {
    urlUsers += "Acceptprivacy=" + filterOptions.privacyFilter + "&";
  }

  if (filterOptions.sharingFilter) {
    urlUsers += "AcceptSharing=" + filterOptions.sharingFilter + "&";
  }

  if (isOpenditRole) {
    urlUsers += "provider=opendit&";
  }

  return urlUsers.slice(0, urlUsers.length - 1);
};

export default {
  getUsers: (
    filterOptions,
    pagination = null,
    search,
    column = null,
    axiosSource = null,
    isOpenditRole = false
  ) => {
    const url = urlToUsers(
      filterOptions,
      pagination,
      search,
      column,
      isOpenditRole
    );

    return Api(true).get(url, {
      cancelToken: axiosSource ? axiosSource.token : null,
    });
  },

  getNumberOfUsers: () => Api(true).get(`${SERVICE_PATH}/users/count`),

  getUserId: (email) =>
    Api(true).get(`${SERVICE_PATH}/userid?email=${encodeURIComponent(email)}`),

  getUser: (id) => Api(true).get(`${SERVICE_PATH}/users/${id}`),

  getUserPairings: (id) =>
    Api(true).get(`${PAIRING_SERVICE_PATH}/pairings?userId=${id}`),

  exportData: (id, email) =>
    Api(true)
      .get(`${SERVICE_PATH}/users/${id}/data`)
      .then((res) => {
        const csv = "data:text/csv;charset=utf-8," + res.data;
        const data = encodeURI(csv);

        const emailAdapted = email.replace(/[^a-zA-Z0-9]/g, "_");

        return downloadData(emailAdapted, "csv", data);
      }),

  resetPassword: (email) =>
    ApiBasicAuth(true).post(
      `${PUBLIC_SERVICE_PATH}/forgotpassword?email=${encodeURIComponent(email)}`
    ),
  resetLocation: (userId) => Api(true).delete(`${SERVICE_PATH}/geo/${userId}`),
};
