<template lang="html" src="./PlanCreateModal.html"></template>

<!-- eslint-disable @typescript-eslint/no-explicit-any -->
<!-- eslint-disable @typescript-eslint/ban-types -->
<script lang="ts">
import { Vue, Component, Prop, Watch } from "vue-property-decorator";

import katalogService from "@/api/katalog";

import { getPeriodText } from "@/utils/plan";

import dateMixin from "@/mixins/date.mixin";
import planMixin from "@/mixins/plan.mixin";

import AppSelect from "@/components/AppSelect.vue";
import AppInputDate from "@/components/AppInputDate.vue";

import { displayErrorMessage } from "@/api";

@Component({
  components: {
    AppSelect,
    AppInputDate,
  },
  mixins: [dateMixin, planMixin],
})
export default class PlanCreateModal extends Vue {
  @Prop(Boolean) value!: boolean;
  @Prop(Array) items!: Array<any>;
  @Prop(Function) onSuccess!: Function;
  internalizedSpanishText!: Function;
  availavilityRegionSpain!: Function;

  date_format!: Function;
  $refs!: {
    formStep1: any;
    formStep2: any;
    formStep3: any;
    formStep4: any;
  };

  dialogSteps = 1;
  wharnWhenFinished = false;

  isCreating = false;
  applyConfirmDialog = false;

  // Step 1
  basedPlan = null;
  planChosen = {
    regionalDetailsList: null,
    plan: {
      planId: null,
      trial: { period: null },
      renewalPeriod: null,
      services: [{ commercial: false, name: "" }],
    },
    availabilityRegionList: null,
  };
  planName = null;
  planLongDescription = null;
  planDescription = null;

  // Step 2
  hasTrial = false;
  trialPeriod = null;
  planPrice = null;
  planVAT = null;
  planPeriod = null;
  initialDate = null;

  // Step 3
  listNameService: string[] = [];
  planServices: string[] = [];

  // Validators
  validFormStep1 = false;
  validFormStep2 = false;
  validFormStep3 = false;
  validFormStep4 = false;

  get show() {
    return this.value;
  }

  set show(localValue) {
    this.$emit("input", localValue);
  }

  get serviceList() {
    return this.$store.getters["plans/servicesList"];
  }

  getServicesList() {
    this.$store.dispatch("plans/getServices");
  }

  get nameServicesList() {
    if (this.listNameService < this.serviceList.length) {
      for (var i = 0; i < this.serviceList.length; i++) {
        if (this.serviceList[i].commercial === true) {
          this.listNameService.push(this.serviceList[i].name);
        }
      }
    }
    return this.listNameService;
  }

  get parentPlansList() {
    return this.$store.getters["plans/parentPlansList"];
  }

  getParentPlansList() {
    this.$store.dispatch("plans/getParentPlans");
  }

  // Step 1
  get nameRules() {
    return [
      (v) => !!v || this.$t("jobs.rules.nameRequired"),
      (v) => !v || v.length <= 32 || this.$t("jobs.rules.jobNameMaxLength"),
    ];
  }

  get notEmptyRule() {
    return [(v) => !!v || this.$t("firmware.fieldrequired")];
  }

  // Step 2

  get period() {
    const objectPeriod = this.$t("plans.renewalTypes", { returnObjects: true });
    return Object.keys(objectPeriod).map((i) => {
      return { text: objectPeriod[i], value: i };
    });
  }

  get periodTrial() {
    const objectPeriod = this.$t("plans.renewalTypes", { returnObjects: true });
    return Object.keys(objectPeriod)
      .filter((i) => !i.includes("DAYS"))
      .map((i) => {
        return { text: getPeriodText(i), value: i };
      });
  }

  // Step 3
  get numberRules() {
    return [
      (v) => !isNaN(v) || this.$t("oauthclients.rules.onlynumbers"),
      (v) => v >= 0 || this.$t("oauthclients.rules.positive"),
      (v) => !!v || this.$t("firmware.fieldrequired"),
    ];
  }

  @Watch("show")
  onChangeShow() {
    if (this.show) this.openDialog();
  }

  openDialog() {
    this.getServicesList();
    this.getParentPlansList();
    if (this.$refs.formStep1) this.$refs.formStep1.reset();
  }

  cancelDialog() {
    if (!this.isCreating) {
      this.dialogSteps = 1;
      this.show = false;

      this.resetFormValues();
    }
  }

  goStep(stepNumber) {
    this.dialogSteps = stepNumber;
  }

  resetFormValues() {
    this.basedPlan = null;
    this.planChosen = {
      regionalDetailsList: null,
      plan: {
        planId: null,
        trial: { period: null },
        renewalPeriod: null,
        services: [{ commercial: false, name: "" }],
      },
      availabilityRegionList: null,
    };
    this.planName = null;
    this.planLongDescription = null;
    this.planDescription = null;
    this.hasTrial = false;
    this.trialPeriod = null;
    this.planPrice = null;
    this.planVAT = null;
    this.planPeriod = null;
    this.initialDate = null;
    this.planServices = [];

    this.$refs.formStep1.resetValidation();
    this.$refs.formStep2.resetValidation();
    this.$refs.formStep3.resetValidation();
    this.$refs.formStep4.resetValidation();
  }

  updatePlanData() {
    this.planChosen = this.parentPlansList.find(
      (plan) => plan.planParentId == this.basedPlan
    ).plan;
    const internalizedText = this.internalizedSpanishText(
      this.planChosen.regionalDetailsList
    );
    this.planName = internalizedText.commercialName;
    this.planLongDescription = internalizedText.longDescription;
    this.planDescription = internalizedText.description;
    this.hasTrial = this.planChosen.plan.trial !== null;
    this.trialPeriod =
      this.planChosen.plan.trial !== null
        ? this.planChosen.plan.trial.period
        : null;
    const availabilityRegion = this.availavilityRegionSpain(
      this.planChosen.availabilityRegionList
    );
    this.planPrice = availabilityRegion.price;
    this.planVAT = availabilityRegion.taxRate;
    this.planPeriod = this.planChosen.plan.renewalPeriod;
    this.planServices = this.planChosen.plan.services
      .filter((service) => service.commercial == true)
      .map((service) => {
        return service.name;
      });
  }

  createPlan() {
    this.isCreating = true;
    let planTrial = this.hasTrial ? { period: this.trialPeriod } : null;
    let newPlanServices = this.planChosen.plan.services.filter(
      (service) => service.commercial == false
    );
    this.planServices.forEach((service) => {
      newPlanServices.push(
        ...this.serviceList.filter(
          (originalService) => originalService.name == service
        )
      );
    });
    let planInfo = {
      basedPlan: this.basedPlan,
      startingDate: this.initialDate,
      regionalDetailsList: [
        {
          commercialName: this.planName,
          description: this.planDescription,
          longDescription: this.planLongDescription,
          language: "ES",
        },
      ],
      plan: {
        renewalPeriod: this.planPeriod,
        trial: planTrial,
        services: newPlanServices,
      },
      availabilityRegionList: [
        {
          country: "ES",
          currency: "EUR",
          price: this.planPrice,
          taxRate: this.planVAT,
        },
      ],
    };
    katalogService
      .createPlan(planInfo)
      .then(() => {
        this.goStep(4);
        if (this.onSuccess) this.onSuccess();
      })
      .catch((err) =>
        displayErrorMessage(err, {
          general: ["plans.error.newPlan"],
        })
      )
      .finally(() => (this.isCreating = false));
  }

  downloadXML() {
    katalogService.downloadXML().catch((err) =>
      displayErrorMessage(err, {
        general: ["general.error.exportingData"],
      })
    );
  }
}
</script>
