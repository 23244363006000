/* eslint-disable @typescript-eslint/no-non-null-assertion */
import Vue from "vue";
import Router from "vue-router";
import Login from "@/views/Login.vue";
import Dashboard from "@/views/Dashboard/Dashboard.vue";
import Installations from "@/views/Installations/Installations.vue";
import InstallationDetails from "@/views/Installations/InstallationDetails/InstallationDetails.vue";
import Devices from "@/views/Devices/Devices.vue";
import DeviceDetails from "@/views/Devices/DeviceDetails/DeviceDetails.vue";
import Users from "@/views/Users/Users.vue";
import UserDetails from "@/views/Users/UserDetails/UserDetails.vue";
import Billing from "@/views/Billing/Billing.vue";
import InternalDetails from "@/views/InternalUsers/InternalDetails/InternalDetails.vue";
import Firmware from "@/views/Firmware/Firmware.vue";
import Jobs from "@/views/Jobs/Jobs.vue";
import JobDetails from "@/views/Jobs/JobDetails/JobDetails.vue";
import OAuthClients from "@/views/OAuthClients/OAuthClients.vue";
import Plans from "@/views/Plans/Plans.vue";
import PlanDetails from "@/views/Plans/PlanDetails/PlanDetails.vue";

import InternalUsers from "@/views/InternalUsers/InternalUsers.vue";
import Roles from "@/views/Roles/Roles.vue";
import DetailsRoles from "@/views/Roles/DetailsRoles/DetailsRoles.vue";
import Taboo from "@/views/Taboo/Taboo.vue";

import i18n from "@/lang/i18n";
import axios from "axios";
import store from "@/store";
import { hasRol } from "@/casl";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.VUE_APP_PUBLIC_PATH,
  routes: [
    {
      path: "/:lang",
      // create a container component
      component: {
        render(c) {
          return c("router-view");
        },
      },
      children: [
        {
          path: "login",
          component: Login,
          name: "Login",
        },
        {
          path: "plans",
          component: Plans,
          name: "Plans",
        },
        {
          path: "plan-:planCode",
          component: PlanDetails,
          name: "PlanDetails",
        },
        {
          path: "installations",
          component: Installations,
          name: "Installations",
        },
        {
          path: "installation-:installationId",
          component: InstallationDetails,
          name: "InstallationDetails",
        },
        {
          path: "devices",
          component: Devices,
          name: "Devices",
        },
        {
          path: "device-:deviceId",
          component: DeviceDetails,
          name: "DeviceDetails",
        },
        {
          path: "users",
          component: Users,
          name: "Users",
        },
        {
          path: "user-:email",
          component: UserDetails,
          name: "UserDetails",
        },
        {
          path: "billing",
          component: Billing,
          name: "Billing",
        },
        {
          path: "internal-:id",
          component: InternalDetails,
          name: "InternalDetails",
        },
        {
          path: "dashboard",
          component: Dashboard,
          name: "Dashboard",
        },
        {
          path: "firmwares",
          component: Firmware,
          name: "Firmwares",
        },
        {
          path: "jobs",
          component: Jobs,
          name: "Jobs",
        },
        {
          path: "job-:jobid",
          component: JobDetails,
          name: "JobDetails",
        },
        {
          path: "oauthclients",
          component: OAuthClients,
          name: "OAuthClients",
        },
        {
          path: "internalusers",
          component: InternalUsers,
          name: "InternalUsers",
        },
        {
          path: "detailsroles-:rol",
          component: DetailsRoles,
          name: "DetailsRoles",
        },
        {
          path: "roles",
          component: Roles,
          name: "Roles",
        },
        {
          path: "taboo",
          component: Taboo,
          name: "Taboo",
        },

        {
          path: "*",
          redirect: "/:lang/dashboard",
        },
      ],
    },
    {
      path: "*",
      redirect: "/en/dashboard",
    },
  ],
});

// Guarda para impedir navegar a rutas protegidas cuando no estás logado
router.beforeEach((to, from, next) => {
  const hasOpenditRole = hasRol("ROLE_OPENDIT");

  // Si es una ruta sin el idioma (cosa que ocurre al arrancarlo desde PWA porque al cerrar y abrir se pierde el valor de :lang)
  if (!isValidLang(to.params.lang)) {
    console.warn(
      "Language not supported, changing to default (" +
        process.env.VUE_APP_I18N_LOCALE +
        ")"
    );
    if (hasOpenditRole) {
      next(`/${process.env.VUE_APP_I18N_LOCALE}/installations`);
    } else {
      next(`/${process.env.VUE_APP_I18N_LOCALE}/dashboard`);
    }
  } else {
    setI18nLanguage(to.params.lang);
  }

  const isUserLogged = store.getters["isUserLogged"];
  if (to.name === "Login" || to.fullPath.indexOf("login") !== -1) {
    next();
  } else if (isUserLogged) {
    if (router.app.$ability.cannot("view", to.name)) {
      if (hasOpenditRole) {
        return next({
          name: "Installations",
          params: { lang: to.params.lang },
        });
      } else {
        return next({ name: "Dashboard", params: { lang: to.params.lang } });
      }
    }

    let documentTitle = process.env.VUE_APP_TITLE;
    if (to.name && i18n.t("documentTitle")[to.name.toLowerCase()]) {
      documentTitle +=
        ": " +
        i18n.t("documentTitle." + to.name.toLowerCase(), { ...to.params });
    }
    document.title = documentTitle!;

    next();
  } else {
    next({ name: "Login", params: { lang: to.params.lang } });
  }
});

function isValidLang(lang) {
  const messages = i18n.messages[lang];
  return messages !== null && messages !== undefined;
}

function setI18nLanguage(lang) {
  if (i18n.locale === lang) {
    return;
  }

  i18n.locale = lang;
  axios.defaults.headers.common["Accept-Language"] = lang;
  const html = document.querySelector("html");
  if (html) {
    html.setAttribute("lang", lang);
  }
  return lang;
}

export default router;

export const goTo = (path) => {
  router.push(path);
};
