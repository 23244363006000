import Api from "@/api";

import { downloadData } from "@/utils/data";

const SERVICE_PATH = "/katalog/api/v1";

export default {
  getPlans: () => Api(true).get(`${SERVICE_PATH}/plans/detailed`),

  getPlan: (id) => Api(true).get(`${SERVICE_PATH}/plans/info/${id}`),

  getCancellationReasons: () => Api(true).get(`${SERVICE_PATH}/cancellation/reasons/technical`).then(res => ({ data: res.data.map(r => r.key) })),

  getPossibleUpgradePlans: (id) => Api(true).get(`${SERVICE_PATH}/plans/upgrade?fromPlanId=${id}&isTrial=false`),

  modifyPlanPrice: (planCode, region) => {
    const regionToSave = {...region};
    delete regionToSave.iva;

    return Api(true).post(`${SERVICE_PATH}/plans/price/changes`, { ...regionToSave, plan: { planId: planCode }	});
  },

  savePlanLanguage: (planCode, language) => Api(true).post(`${SERVICE_PATH}/plans/regional/details`, { ...language, plan: { planId: planCode } }),

  getServices: () => Api(true).get(`${SERVICE_PATH}/services`),

  getServicesByPlan: (id , commercial) => {
    if(commercial == true){
      return Api(true).get(`${SERVICE_PATH}/plans/${id}/services`)
    }else{
      return Api(true).get(`${SERVICE_PATH}/plans/${id}/services?commercial=${!commercial}`)
    }
  },

  getCommercialServices: (id) => Api(true).get(`${SERVICE_PATH}/plans/${id}/services?commercial=true`),

  setInsertServices: (id, services) => Api(true).post(`${SERVICE_PATH}/plans/${id}/services`, services, {headers: {"Content-Type": "application/json"}}),

  deletePlanLanguages: (planCode, languages) => Api(true).delete(`${SERVICE_PATH}/plans/regional/details`, { data: languages.map(l => ({ ...l, plan: { planId: planCode } }))}),

  createPlan: (info) => Api(true).post(`${SERVICE_PATH}/plans`, info, {headers: {"Content-Type": "application/json"}}),

  setCommercialService: (id, commercial) => Api(true).post(`${SERVICE_PATH}/services/${id}/commercial?commercial=${commercial}`),

  getParentPlans: () => Api(true).get(`${SERVICE_PATH}/planparents`),

  getRegionalVAT: (region) => Api(true).get(`${SERVICE_PATH}/plans/regional/vat?country=${region}`),

  setRegionalVAT: (region, vat) => Api(true).post(`${SERVICE_PATH}/plans/regional/vat?country=${region}&taxrate=${vat}`),

  downloadXML: () => Api(true).get(`${SERVICE_PATH}/katalog/killbill/xml`).then(res => {

    const data = 'data:text/xml;charset=utf-8,' + encodeURIComponent(res.data);

    return downloadData('killbill', 'xml', data);
  }),
}