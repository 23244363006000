import Vue from "vue";
import VueI18n from "vue-i18n";
import enLocale from "./en.json";
import esLocale from "./es.json";

Vue.use(VueI18n);

const messages = {
  es: esLocale,
  en: enLocale,
};

const i18n = new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || "en",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
  messages,
});

export function translate(text: string) {
  return i18n.t(text);
}

export function translateText(text: string): string {
  return i18n.t(text).toString();
}

export function translateExists(text: string): boolean {
  return i18n.te(text);
}

export function translatePlural(text: string, count: number): string {
  return i18n.tc(text, count);
}

export default i18n;
