import * as getters from "../dashboard/getters";
import * as actions from "../dashboard/actions";
import * as mutations from "../dashboard/mutations";

const state = {
  totalDeviceDeployed: null,
  totalDeviceManufactured: null,
  totalDeviceOnLine: null,
  
  totalDevicesUpdated: null,
  totalDevices: null,

  totalOnlineWifiMonitors: null,
  totalOfflineWifiMonitors: null,

  totalWifiMonitorsDeployed: null,
  totalWifiMonitorsManufactured: null,

  totalPairedUsers: null,
  totalUserNumber: null,  
  
  totalLastYearUserNumber: null,
  totalLastMonthUserNumber: null
};

export default ({
  namespaced: true,
  state,
  getters,
  actions,
  mutations
});