import { displayErrorMessage } from "@/api";
import installationService from "@/api/installation";

export const getInstallationsListDevExtreme = (payload) =>
  new Promise((resolve, reject) => {
    installationService
      .getInstallations(
        payload.filterOptions,
        payload.pagination,
        payload.search,
        payload.column
      )
      .then((response) => {
        //commit('setInstallationsList', response.data);

        resolve(response.data);
      })
      .catch((error) => {
        displayErrorMessage(error, {
          general: ["installation.error.getError"],
        });

        reject(error);
      });
  });

export const getInstallationsList = ({ commit }, payload) =>
  new Promise((resolve, reject) => {
    installationService
      .getInstallations(
        payload.filterOptions,
        payload.pagination,
        payload.search,
        payload.column
      )
      .then((response) => {
        commit("setInstallationsList", response.data);

        resolve(response.data);
      })
      .catch((error) => {
        displayErrorMessage(error, {
          general: ["installation.error.getError"],
        });

        reject(error);
      });
  });

export const getNumberOfInstallations = ({ commit }) =>
  new Promise((resolve, reject) => {
    installationService.getNumberOfInstallations().then(
      (res) => {
        commit("setNumberOfInstallations", res.data);

        resolve(res.data);
      },
      (err) => {
        displayErrorMessage(err, { general: ["installation.error.getError"] });

        reject(err);
      }
    );
  });

export const getInstallationDetails = ({ commit }, payload) =>
  new Promise((resolve, reject) => {
    commit("setInstallationDetails", null);

    installationService
      .getInstallation(payload.id)
      .then((response) => {
        commit("setInstallationDetails", response.data);

        resolve(response.data);
      })
      .catch((error) => {
        displayErrorMessage(error, {
          general: ["installationDetails.error.getError"],
          notFound: [
            "installationDetails.error.getDetailsNotFound",
            [payload.id],
          ],
        });

        reject(error);
      });
  });

export const getInstallationTopology = ({ commit }, payload) =>
  new Promise((resolve, reject) => {
    commit("setInstallationTopology", {});

    installationService
      .getInstallationTopology(payload.id)
      .then((response) => {
        commit("setInstallationTopology", response.data);

        resolve(response.data);
      })
      .catch((error) => {
        displayErrorMessage(error, {
          general: ["installationDetails.error.getTopologyError"],
        });

        reject(error);
      });
  });

export const getNumberOfTypeInstallations = ({ commit }) =>
  new Promise((resolve, reject) => {
    installationService.getNumberOfTypeInstallations().then(
      (res) => {
        commit("setNumberOfTypeInstallations", res.data);

        resolve(res.data);
      },
      (err) => {
        displayErrorMessage(err, { general: ["installation.error.getError"] });

        reject(err);
      }
    );
  });
