import { Vue, Component } from "vue-property-decorator";

import DialogUploadFwStep1 from "./DialogUploadFwStep1/DialogUploadFwStep1.vue";
import DialogUploadFwStep2 from "./DialogUploadFwStep2/DialogUploadFwStep2.vue";
import DialogUploadFwStep3 from "./DialogUploadFwStep3/DialogUploadFwStep3.vue";
import { FirmwareHWCompatibleRes, HWList } from "@/interfaces/firmwares";
import { FirmwareController } from "@/domain/controllers/FirmwareController";
import { FirmwareInterface } from "@/domain/entities/FirmwareEntity";
import DeviceController from "@/domain/controllers/DeviceController";
import { FirmwareModel } from "@/ui/models/FirmwareModel";
import Toast from "@/ui/helpers/Toast";
import DialogOk from "@/ui/components/DialogOk/DialogOk.vue";

@Component({
  name: "DialogUploadFW",
  components: {
    DialogUploadFwStep1,
    DialogUploadFwStep2,
    DialogUploadFwStep3,
    DialogOk,
  },
})
export default class DialogUploadFW extends Vue {
  /** Controlador de firmwares. */
  ctrlFirmware = new FirmwareController();
  /** Controlador de devices */
  ctrlDevice = new DeviceController();
  /** Muestra u oculta el diálogo. */
  showDialog = false;
  /** Posición del step activo. */
  activeStep = 1;
  /** Muestra u oculta el mensaje del checkbox de la actualización automática. */
  showConfirmCheckboxAutUpd = false;
  /** Muestra u oculta el mensaje del checkbox del más reciente. */
  showConfirmCheckboxLatest = false;
  /** Firmware que se está trabajando. */
  firmwareUpload: FirmwareModel = new FirmwareModel();
  /** Lista de HW compatibles. */
  hwCompatibles: HWList[] = [];
  /** Muestra u oculta el progreso de subida del fichero. */
  showProgress = false;
  indeterminate = false;
  fileProgress = 0;

  /** Abre el diálogo. */
  openDialog() {
    this.activeStep = 1;
    this.clearData();
    this.showDialog = true;
  }
  /** Sale del diálogo. */
  exitDialog() {
    if (this.firmwareUpload.existsId()) {
      this.ctrlFirmware.delete(this.firmwareUpload.id);
    }
    this.showDialog = false;
    this.clearData();
  }
  clearData() {
    this.firmwareUpload = new FirmwareModel();
  }
  /** Al terminar el primer step se sube el fichero. */
  onContinueStep1() {
    this.uploadFile();
  }
  /** Al terminar el segundo step se obtienen los HW compatibles. */
  onContinueStep2() {
    this.FwHwCompatible();
  }
  /** Al terminar el tercer step se guardan los datos. */
  onContinueStep3() {
    this.save();
  }

  /** Sube un fichero con el Firmware. */
  private uploadFile() {
    if (this.firmwareUpload.existsFile()) {
      this.ctrlFirmware
        .uploadFirmware(
          this.firmwareUpload.sha256,
          this.firmwareUpload.file,
          this.uploadFileProgress
        )
        .then((response: FirmwareInterface) => {
          this.firmwareUpload.critical = response.critical;
          this.firmwareUpload.automaticUpdate = response.automaticUpdate;
          this.firmwareUpload.id = response.id;
          this.activeStep = 2;
        })
        .catch((error: Error) => {
          Toast.error(error.message);
        })
        .finally(() => {
          this.fileProgress = 0;
          this.indeterminate = false;
          this.showProgress = false;
        });
    }
  }

  /** Muestra el progreso de subida del fichero. */
  private uploadFileProgress(progressEvent) {
    this.showProgress = true;
    this.fileProgress = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total
    );
    if (this.fileProgress === 100) {
      this.indeterminate = true;
    }
  }

  /** Obtiene los HW compatibles */
  private FwHwCompatible() {
    this.ctrlFirmware
      .getFwHwCompatible(
        this.firmwareUpload.family,
        this.firmwareUpload.type,
        this.firmwareUpload.subtype
      )
      .then((res: FirmwareHWCompatibleRes) => {
        this.hwCompatibles = res.hwList.map((hw: string) => {
          const hwI: HWList = {
            selectHW: false,
            hw: hw,
            automaticUpdate: false,
            latest: false,
          };
          return hwI;
        });
        this.activeStep = 3;
      });
  }

  /** Informa que solo puede haber uno activado del mismo tipo. */
  onChangeCheckBoxAutUpdate(dataRow: HWList) {
    if (dataRow.automaticUpdate) {
      this.ctrlFirmware
        .existAutomaticUpdate(
          this.firmwareUpload.family,
          this.firmwareUpload.type,
          this.firmwareUpload.subtype,
          dataRow.hw
        )
        .then((res: boolean) => {
          this.showConfirmCheckboxAutUpd = res;
        });
    }
  }

  /** Informa que solo puede haber uno activado del mismo tipo. */
  onChangeCheckBoxLatest(dataRow: HWList) {
    if (dataRow.latest) {
      this.ctrlFirmware
        .existLatest(
          this.firmwareUpload.family,
          this.firmwareUpload.type,
          this.firmwareUpload.subtype,
          dataRow.hw
        )
        .then((res: boolean) => {
          this.showConfirmCheckboxLatest = res;
        });
    }
  }

  /** Sube el fichero y guarda los datos. */
  private save() {
    if (this.firmwareUpload.isHWSelectedOk()) {
      this.ctrlFirmware
        .update(this.firmwareUpload.getEntity())
        .then(() => {
          this.$emit("insertFirmware");
          this.clearData();
          this.exitDialog();
        })
        .catch((error) => {
          Toast.error(error.message);
        });
    } else {
      Toast.error(
        this.firmwareUpload.isFirmwareV2()
          ? "firmware.needAnyHW"
          : "firmware.needOneHW"
      );
    }
  }
}
