<template lang="html" src="./PlanDetails.html"></template>

<!-- eslint-disable @typescript-eslint/ban-types -->
<script lang="ts">
import { Vue, Component, Watch } from "vue-property-decorator";
import AppBreadcrumbs from "@/components/AppBreadcrumbs.vue";
import AppListMenu from "@/components/AppListMenu.vue";
import { getPeriodText } from "@/utils/plan";
import planMixin from "@/mixins/plan.mixin";

import PlanAvailability from "./PlanAvailability/PlanAvailability.vue";
import PlanServices from "./PlanServices/PlanServices.vue";
import PlanEditModal from "./PlanEditModal/PlanEditModal.vue";

@Component({
  components: {
    AppBreadcrumbs,
    AppListMenu,
    PlanAvailability,
    PlanServices,
    PlanEditModal,
  },

  mixins: [planMixin],
})
export default class PlanDetails extends Vue {
  internalizedText!: Function;

  showEditModal = false;

  get menuOptions() {
    return [
      {
        icon: "edit",
        text: this.$t("general.edit"),
        action: () => this.setShowEditModal(true),
        allowed: this.$ability.can("edit", "plans"),
      },
    ];
  }

  get planCode() {
    return this.$route.params.planCode + "";
  }

  get details() {
    return this.$store.getters["plans/planDetails"];
  }

  get planInternalization() {
    return this.internalizedText(this.details.regionalDetailsList);
  }

  get planName() {
    return this.planInternalization && this.planInternalization.commercialName;
  }

  get planLongDescription() {
    return this.planInternalization && this.planInternalization.longDescription;
  }

  get planDescription() {
    return this.planInternalization && this.planInternalization.description;
  }

  get renewalPeriod() {
    return this.$t(
      "plans.renewalTypes." +
        (this.details.plan.renewalPeriod || "NO_BILLING_PERIOD")
    );
  }

  @Watch("planCode")
  onChangePlanCode() {
    this.obtainInformation();
  }

  beforeMount() {
    this.obtainInformation();
  }

  getPeriodText = getPeriodText;

  setShowEditModal(v) {
    this.showEditModal = v;
  }

  onCloseFunction() {
    this.showEditModal = false;
    this.obtainInformation();
  }

  getDetails() {
    return this.$store.dispatch("plans/getPlanDetails", this.planCode);
  }

  obtainInformation() {
    this.getDetails();
  }
}
</script>
<style>
.details-label-value {
  white-space: nowrap;
}
.trial-info-check {
  margin: 0;
  padding: 0;
  height: 24px;
}
</style>
