<template lang="html" src="./Billing.html"></template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'
import { displayErrorMessage } from "@/api";
import paymentService, { forceDownloadDocument } from "@/api/payment";

import AppBreadcrumbs from "@/components/AppBreadcrumbs.vue";

@Component({
  components: {
    AppBreadcrumbs
  }
})
export default class Billing extends Vue {
  search = "";
  isLoading = false;

  $refs!: {
    form: HTMLFormElement
  }

  get billingIdRules() {
    return [
      v => !!v || this.$t("general.notEmptyRule"),
      v => !!v && !isNaN(v) && v % 1 === 0 && v.indexOf(".") < 0 || this.$t("general.notNumber"),
    ];
  }

  download(e) {
    e.preventDefault();
    
    if(!this.$refs.form.validate())
      return;

    this.isLoading = true;
    
    const invoiceId = this.search;

    paymentService.generateInvoiceDoc(invoiceId)
      .then(res => {
        forceDownloadDocument(invoiceId, res.data);
        this.isLoading = false;
      })
      .catch(err => {
        this.isLoading = false;
        displayErrorMessage(err, { general: ["billing.downloadError"], notFound: ["billing.invoiceNotFoundError"] });
      })
  }
}
</script>
<style scoped>
.subheading{
  color: rgb(153, 153, 153);
}
.search-box{
  width: 100%;
  max-width: 620px
}
</style>