export interface InternalUserRolInterface {
  id: number;
  name: string;
  authority: string;
}

export interface InternalUserInterface {
  id: string;
  email: string;
  password?: string;
  roles: InternalUserRolInterface[];
}

const defaultInternalUser: InternalUserInterface = {
  id: "",
  email: "",
  roles: [],
};

export class InternalUserEntity implements InternalUserInterface {
  public id: string;
  public email: string;
  public password?: string;
  public roles: InternalUserRolInterface[];

  constructor(internalUser: InternalUserInterface = defaultInternalUser) {
    this.id = internalUser.id;
    this.email = internalUser.email;
    this.password = internalUser.password;
    this.roles = internalUser.roles;
  }

  /** Devuelve si parte de la cadena de texto se encuentra en algún campo del usuario */
  public searchText(text: string): boolean {
    text = text.toLocaleLowerCase();
    return (
      this.id.toLocaleLowerCase().includes(text) ||
      this.email.toLocaleLowerCase().includes(text) ||
      this.searchRoleText(text)
    );
  }

  /** Devuelve si parte de la cadena de texto se encuentra en algún nombre del rol */
  private searchRoleText(text: string): boolean {
    return this.roles.some((rol: InternalUserRolInterface) =>
      rol.name.toLocaleLowerCase().includes(text)
    );
  }

  /** Devuelve los roles en formato string separados por comas */
  public get rolesString(): string {
    return this.roles.map((rol) => rol.name).join(", ");
  }

  /** Setea los roles en formato string separados por comas */
  public setRolesText(roles: string[]) {
    this.roles = roles.map((rol: string, index: number) => {
      return {
        id: index,
        name: rol,
        authority: rol,
      };
    });
  }

  /** Devuelve si el usuario es administrador. */
  public get isAdmin(): boolean {
    return this.roles.some((rol) => rol.name === "ROLE_ADMIN");
  }

  /** Devuelve si el usuario es super administrador. */
  public get isSuperAdmin(): boolean {
    return (
      this.isAdmin && (this.email === "admin@fermax.com" || this.id === "1")
    );
  }

  /** Genera y asigna una contraseña aleatoria */
  public generatePassword() {
    this.password = this.generatePassSecret();
  }

  /** Genera una contraseña aleatoria */
  private generatePassSecret = () => {
    let result = "";
    let seg1 = "";
    let min = "";
    let num = "";
    const charactersnum = "0123456789";
    const charactersnumLength = charactersnum.length;

    for (let i = 0; i < 2; i++) {
      num += charactersnum.charAt(
        Math.floor(Math.random() * charactersnumLength)
      );
    }

    const charactermin = "abcdefghijklmnopqrstuvwxyz";
    const characterminLength = charactermin.length;

    for (let x = 0; x < 2; x++) {
      min += charactermin.charAt(
        Math.floor(Math.random() * characterminLength)
      );
    }

    const charactermayus = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const charactermayusLength = charactermayus.length;

    for (let x = 0; x < 2; x++) {
      result += charactermayus.charAt(
        Math.floor(Math.random() * charactermayusLength)
      );
    }

    const characterseg1 = "-/:;()&@.,?!%*";
    const characterseg1Length = characterseg1.length;

    for (let x = 0; x < 2; x++) {
      seg1 += characterseg1.charAt(
        Math.floor(Math.random() * characterseg1Length)
      );
    }

    return min + result + num + seg1;
  };
}
