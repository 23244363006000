export const setTotalDeviceDeployed = (state, response) => {
  state.totalDeviceDeployed = response;
};

export const setTotalDeviceManufactured = (state, response) => {
  state.totalDeviceManufactured = response;
};

export const setTotalDeviceOnLine = (state, response) => {
  state.totalDeviceOnLine = response;
};

export const setTotalDevicesUpdated = (state, response) => {
  state.totalDevicesUpdated = response;
};

export const setTotalDevices = (state, response) => {
  state.totalDevices = response;
};

export const setTotalOnlineWifiMonitors = (state, response) => {
  state.totalOnlineWifiMonitors = response;
};

export const setTotalOfflineWifiMonitors = (state, response) => {
  state.totalOfflineWifiMonitors = response;
};

export const setTotalWifiMonitorsDeployed = (state, response) => {
  state.totalWifiMonitorsDeployed = response;
};

export const setTotalWifiMonitorsManufactured = (state, response) => {
  state.totalWifiMonitorsManufactured = response;
};

export const setTotalPairedUsers = (state, response) => {
  state.totalPairedUsers = response;
};

export const setTotalUserNumber = (state, response) => {
  state.totalUserNumber = response;
};

export const setTotalLastYearUserNumber = (state, response) => {
  state.totalLastYearUserNumber = response;
};

export const setTotalLastMonthUserNumber = (state, response) => {
  state.totalLastMonthUserNumber = response;
};
