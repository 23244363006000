const DATA_LOADER_SIZE = 500;

import Vue from "vue";

export default class extends Vue {
  fullData = {};

  _loadAndPutFullData(petition, page, data, resolve, reject, state) {
    petition(page, DATA_LOADER_SIZE)
      .then((res) => {
        data = [...data, ...res.data.content];

        state.loaded = page;

        if (res.data.last) return resolve(data);

        let numTotalPages = res.data.totalElements / DATA_LOADER_SIZE;
        if (numTotalPages % 10 !== 0)
          numTotalPages = Math.floor(numTotalPages) + 1;

        state.total = numTotalPages;

        this._loadAndPutFullData(
          petition,
          page + 1,
          data,
          resolve,
          reject,
          state
        );
      })
      .catch((err) => reject(err));
  }

  loadFullData(petition, state) {
    state.loaded = 0;
    state.data = [];

    return new Promise((resolve, reject) =>
      this._loadAndPutFullData(petition, 1, state.data, resolve, reject, state)
    );
  }

  /** Inicializa los datos. */
  newFullDataState() {
    return {
      loaded: null,
      total: null,
      data: [],
    };
  }

  /** Resetea los datos. */
  resetFullDataState(state) {
    state.loaded = null;
    state.total = null;
  }
}
