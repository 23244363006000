/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from "vue";
import L from 'leaflet';
declare const window: any;

export const LAT_FERMAX = 39.453489;
export const LONG_FERMAX = -0.3989459;

export default class extends Vue {

  
  tileLayerUrl = `https://api.maptiler.com/maps/${window.maptilerconfig.MAP_ID}/{z}/{x}/{y}.png?key=${window.maptilerconfig.KEY}` // Idioma local en cada pais
  tileLayerAttribution = '<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>'
  mapOptions= {
    worldCopyJump: true,  // Si se muestran varios mapas del mundo, copia añadidos de un mapa a otro
    zoomControl: true,    // Indica que se debe mostrar el control con + y - para zoom
    minZoom: 1.9,        // Zoom mínimo admitido, se limita para evitar zonas grises arriba y abajo al alejarse
    zoomSnap: 0.1,        // zoomSnap y zoomDelta habilitan el poder granular el nivel de zoom en tramos más pequeños
    maxBoundsViscosity: 1.0, // Si se fija un maxbounds, este valor 1.0 evita que el usuario pueda hacer panning fuera de la zona permitida
    //maxBounds: [[-90, -180], [90, 180]] // Este valor de maxBounds comprende todo el planeta 
    // y reduce a lo mínimo las zonas grises sin mapa en pantallas grandes cuando se aplica tileLayerOptions.noWrap
  }

    markerIcon(path = "marker-icon_grey.png") {
      const url = process.env.VUE_APP_PUBLIC_PATH + "assets/markers/" + path;
      
      return L.icon({ iconUrl: url, iconAnchor: [15, 30] });
    }

    getMarkerLatLong(name, marker) {
      const latLong = [marker.latitude, marker.longitude];

      if ((!marker.latitude && marker.latitude !== 0) || (!marker.longitude && marker.longitude !== 0)) {
        latLong[0] = LAT_FERMAX;
        latLong[1] = LONG_FERMAX;

        console.warn(`Geo not defined for marker ${name}, defaulting to Fermax warehouse`);
      }

      return latLong;
    }

    getColorInGrades(value, grades, colors) {
      if (
        grades &&
        grades.length > 0 &&
        colors &&
        colors.length > 0
      ) {
        for (let i = 0; i < grades.length - 1; i++) {
          if (value >= grades[i] && value < grades[i + 1]) {
            return colors[i];
          }
        }

        return colors[colors.length - 1];
      }
      return null;
    }      
}