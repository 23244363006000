/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from "vue";
import Component from "vue-class-component";

import { Prop, Ref, Watch } from "vue-property-decorator";
import CustomStore from "devextreme/data/custom_store";
import { LoadResultObject } from "devextreme/common/data/custom-store";

import { getInstallationsListDevExtreme } from "../../../store/installations/actions";
import DxfDataGrid from "../../../components/DxfDataGrid/DxfDataGrid.vue";
import { translatePlural, translateText } from "@/lang/i18n";

import InstallationSummary from "../InstallationSummary/InstallationSummary.vue";
import installationService from "@/api/installation";
import { Column } from "devextreme/ui/data_grid";

export interface TableFilterTab {
  family: string;
  subtype: string;
}

export interface TableFilter {
  idTab: TableFilterTab | string;
  createdAtValueEnd?: string;
  createdAtValueStart?: string;
}

export interface TablePagination {
  rowsPerPage: number;
  page: number;
  descending: boolean;
}

@Component({
  components: {
    DxfDataGrid,
  },
})
export default class InstallationsTable extends Vue {
  /** Filtro que viene de pantalla. */
  // eslint-disable-next-line
  @Prop() filterOptions!: any;
  /** Palabra a buscar. */
  @Prop() search!: string | null;
  /** Identificador del tab al que pertenece esta tabla. */
  @Prop() tabIndex!: number;

  /** Referencia a la tabla. */
  @Ref("refDxfDataGrid") tableDevExtreme!: DxfDataGrid;

  title = translateText("installation.installations");

  //TODO: Hay límite para exportar

  /** Listado de tabs. */
  //tabsList: any;

  /** Filtro que se le va a mandar al endpoint. */
  filter: TableFilter = {
    idTab: { family: "PANEL", subtype: "4G" },
  };

  /** Conexión para obtener los datos de la tabla. */
  dataStore: CustomStore;

  /** Columnas de la tabla. */
  columns: Column[];

  /** Se inicializan las variables. */
  constructor() {
    super();
    /*this.tabsList = [
      { family: "PANEL", subtype: "4G" },
      { family: "EDIBOX", subtype: "EDIBOX" },
      { family: "EDIBOX", subtype: "BOXM2" },
      "ALL",
    ];*/

    this.dataStore = this.getNewCustomStore();
    this.columns = this.getColumns();
  }

  /** Cuando cambia el fitro se recarga la tabla. */
  @Watch("filterOptions", { immediate: true, deep: true })
  // eslint-disable-next-line
  onChangeFilterOptions(newValue: any) {
    if (this.tabIndex === this.getCurrentTab()) {
      if (newValue.idTab !== undefined) {
        this.dataStore = this.getNewCustomStore();
        this.columns = this.getColumns();
      }
    }
  }

  /** Cuando cambia la palabra a buscar se recarga la tabla. */
  @Watch("search", { immediate: true, deep: true })
  // eslint-disable-next-line
  onChangeSearch(newValue: any) {
    if (this.tabIndex === this.getCurrentTab()) {
      this.dataStore = this.getNewCustomStore();
    }
  }

  /** Obtiene el objeto store y hace un load de los datos. */
  getNewCustomStore(): CustomStore {
    const loadRecursively = async (pagination: TablePagination) => {
      try {
        const sort = "serialNumber";
        const res = await getInstallationsListDevExtreme({
          filterOptions: this.filterOptions,
          pagination: pagination,
          search: this.search,
          column: sort,
        });

        loadedInstallations = loadedInstallations.concat(res.content);

        if (loadedInstallations.length < res.totalElements) {
          const nextPage = pagination.page + 1;
          const nextPagination: TablePagination = {
            ...pagination,
            page: nextPage,
          };
          await loadRecursively(nextPagination);
        } else {
          allInstallationsLoaded = true;
        }
      } catch (err) {
        console.error(err);
      }
    };

    let loadedInstallations: any[] = [];
    let allInstallationsLoaded = false;

    return new CustomStore({
      key: "id",
      load: async (loadOptions: any) => {
        if (loadOptions.isLoadingAll === true) {
          loadedInstallations = [];

          const pagination: TablePagination = {
            rowsPerPage: 2000,
            page: 1,
            descending: false,
          };

          if (!allInstallationsLoaded) {
            await loadRecursively(pagination);
          }
        } else {
          const pagination: TablePagination = {
            rowsPerPage: loadOptions.take ?? 10,
            page: Math.ceil(
              ((loadOptions.skip ?? 0) + 1) / (loadOptions.take ?? 0)
            ),
            descending: loadOptions.sort?.[0]?.desc ?? false,
          };

          const sort = loadOptions.sort?.[0]?.selector ?? "serialNumber";

          try {
            const res = await getInstallationsListDevExtreme({
              filterOptions: this.filterOptions,
              pagination: pagination,
              search: this.search,
              column: sort,
            });

            loadedInstallations = res.content;

            const result: LoadResultObject = {
              data: loadedInstallations,
              totalCount: res.totalElements,
            };

            return result;
          } catch (err) {
            console.error(err);
          }
        }

        const data = allInstallationsLoaded ? loadedInstallations : [];

        const result: LoadResultObject = {
          data: data,
          totalCount: allInstallationsLoaded ? loadedInstallations.length : 0,
        };

        allInstallationsLoaded = false;
        return result;
      },
    });
  }

  getColumns(): Column[] {
    const columns: Column[] = [
      {
        dataField: "id",
        caption: translateText("installation.id"),
        cellTemplate: this.$ability.can("details", "installations")
          ? "linkDetail"
          : "notLinkDetail",
      },
      {
        dataField: "tag",
        caption: translateText("installation.tag"),
      },
      {
        dataField: "status",
        caption: translateText("installation.status"),
        cellTemplate: "statusInstallation",
        width: 150,
      },
      {
        dataField: "city",
        caption: translateText("installation.city"),
      },
      {
        dataField: "createdAt",
        caption: translateText("installation.createdAt"),
        cellTemplate: "dateFormat",
        width: 150,
      },
      {
        dataField: "lastTelemetryDate",
        caption: translateText("installation.lastTelemetryDate"),
        cellTemplate: "dateFormat",
        width: 150,
      },
      {
        dataField: "signal",
        caption: translateText("installation.coverage"),
        calculateCellValue: function (rowData) {
          return rowData.signal + " %";
        },
        width: 100,
      },
      {
        dataField: "summary",
        caption: translateText("installation.summary"),
        cellTemplate: function (container, options) {
          const installationSummary = new InstallationSummary({
            propsData: {
              value: options.data,
            },
          });
          installationSummary.$mount();
          container.appendChild(installationSummary.$el);
        },
        width: 300,
        allowExporting: false,
      },
      {
        cellTemplate: "menu",
        width: 60,
        allowExporting: false,
      },
    ];

    const idTab = this.getCurrentTab();
    if (idTab === 0) {
      columns.push({
        dataField: "panels",
        caption: translateText("installationDetails.panels"),
        visible: false,
      });
      columns.push({
        dataField: "nonConnectableMonitors",
        caption: translatePlural("installationDetails.device.monitorNoWifi", 2),
        visible: false,
        calculateCellValue: function (rowData) {
          return rowData.nonConnectableMonitors || 0;
        },
      });
      columns.push({
        dataField: "connectableMonitors",
        caption: translatePlural("installationDetails.device.monitorWifi", 2),
        visible: false,
        calculateCellValue: function (rowData) {
          return rowData.connectableMonitors || 0;
        },
      });
      columns.push({
        dataField: "phones",
        caption: translateText("installationDetails.phones"),
        visible: false,
      });
      columns.push({
        dataField: "gu",
        caption: translateText("installationDetails.gu"),
        visible: false,
      });
    } else {
      columns.push({
        dataField: "ediboxes",
        caption: translateText("installationDetails.ediboxes"),
        visible: false,
      });
      columns.push({
        dataField: "monitors",
        caption: translateText("installationDetails.monitors"),
        visible: false,
      });
    }

    return columns;
  }

  /** Obtiene el identificador del tab del filtro. */
  getCurrentTab(): number {
    const idTab: string = sessionStorage.getItem("Installations.tab") ?? "0";
    return parseInt(idTab);
  }

  /** Opciones del menú desplegable. */
  get menuOptions() {
    return [
      {
        icon: "list_alt",
        text: this.$t("general.details"),
        to: (item) => ({
          name: "InstallationDetails",
          params: { installationId: item.id, idTab: this.getCurrentTab() },
        }),
        allowed: this.$ability.can("details", "installations"),
      },
      {
        icon: "place",
        text: this.$t("device.viewOnMap"),
        action: (item) => {
          this.$emit("viewOnMap", item);
        },
        allowed: this.$ability.can("map", "installations"),
      },
      {
        icon: "refresh",
        text: this.$t("installationDetails.refresh"),
        action: (item) => this.refreshItem(item.id),
        allowed: this.$ability.can("refresh", "installations"),
      },
    ];
  }

  /** Obtiene del back la información de un registro. */
  refreshItem(id: string) {
    this.tableDevExtreme.setLoading(true);
    installationService
      .refreshInstallation(id)
      .then((res) => {
        this.$store.dispatch("snackbarInfo", {
          text: this.$t("installationDetails.refreshedInfo"),
        });
        this.tableDevExtreme.updateRecord(res.data.id, res.data);
      })
      .finally(() => {
        this.tableDevExtreme.setLoading(false);
      });
  }

  onClickDetail(cellData) {
    this.$router.push({
      name: "InstallationDetails",
      params: {
        installationId: cellData.data.id,
        idTab: this.getCurrentTab() + "",
      },
    });
  }

  /*getExcelCustomizeCell({ gridCell, excelCell }) {
    if (gridCell.column.dataField === "status") {
      if (gridCell.value === "CONNECTED") {
        excelCell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "4caf50" },
        };
        excelCell.value = translateText("installation.online");
      } else if (gridCell.value === "DISCONNECTED") {
        excelCell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "616161" },
        };
        excelCell.value = translateText("installation.offline");
      } else if (gridCell.value === "WARNING") {
        excelCell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "ffa000" },
        };
        excelCell.value = translateText("installation.warning");
      }
    } else if (gridCell.column.dataField === "createdAt") {
      excelCell.value = DateHelper.dateFormat(
        gridCell.value,
        "DD/MM/YYYY HH:mm"
      );
    } else if (gridCell.column.dataField === "updatedAt") {
      excelCell.value = DateHelper.dateFormat(
        gridCell.value,
        "DD/MM/YYYY HH:mm"
      );
    }
  }*/
  get showExport() {
    return this.$ability.can("export", "installations");
  }
}

/*      
if (gridCell.rowType === 'data') {
        if (gridCell.column.dataField === 'Phone') {
          excelCell.value = parseInt(gridCell.value, 10);
          excelCell.numFmt = '[<=9999999]###-####;(###) ###-####';
        }
        if (gridCell.column.dataField === 'Website') {
          excelCell.value = { text: gridCell.value, hyperlink: gridCell.value };
          excelCell.font = { color: { argb: 'FF0000FF' }, underline: true };
          excelCell.alignment = { horizontal: 'left' };
        }
      }
      if (gridCell.rowType === 'group') {
        excelCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'BEDFE6' } };
      }
      if (gridCell.rowType === 'totalFooter' && excelCell.value) {
        excelCell.font.italic = true;
      }
          
        },*/
