<template>
  <v-card :class="{'lowOpacity': disabled}">
    <v-card-title class="py-3">
      <v-layout>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <span v-on="on" class="subheading ellipsis grey--text pr-2">
              <span class="font">{{ title }}</span>
            </span>
          </template>

          <span>
            {{ tool }}
            <br />
            {{ toolsecond }}
            <br />
            {{ toold }}
          </span>
        </v-tooltip>

        <v-spacer />
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <span class="last-update grey--text" v-on="on">{{ composeUpdatedLabel(updatedHour) }}</span>
          </template>

          <span>{{ composeUpdatedTooltip(updatedHour) }}</span>
        </v-tooltip>
      </v-layout>
    </v-card-title>

    <v-card-text>
      <div class="chart-container" :style="chartStyle">
        <slot />
      </div>
    </v-card-text>
  </v-card>
</template>

<!-- eslint-disable @typescript-eslint/no-explicit-any -->
<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import moment from 'moment';

@Component
export default class ChartCard extends Vue {
  @Prop(String) readonly title!: string;
  @Prop(String) tool!: string;
  @Prop(String) toolsecond!: string;
  @Prop(String) toold!: string;
  @Prop(Number) updatedHour!: number;
  @Prop(Number) height!: number;
  @Prop(Boolean) disabled!: boolean;

  //$moment!: any;

  get chartStyle() {
    return {
      position: "relative",
      width: "100%",
      overflow: "hidden",
      height: this.height - 55 + "px",
      "pointer-events": this.disabled ? 'none' : null
    };
  }

  composeUpdatedLabel(hours) {
    if (hours === null || hours === undefined) {
      return null;
    }
    let text = hours + "h";
    if (hours >= 24) {
      const days = Math.floor(hours / 24);
      const restHours = hours % 24;
      text = days + "d " + restHours + "h";
    }
    return this.$t("dashboard.lastUpdate", [text]);
  }

  composeUpdatedTooltip(hours) {
    return moment()
      .subtract(hours, "hours")
      .format("DD/MM/YYYY HH:mm");
  }
}
</script>
<style scoped>
span.subheading {
  font-size: 18px;
}
.v-card__text {
  overflow: hidden;
}
.last-update {
  font-size: 12px;
  white-space: nowrap;
}
</style>