import * as getters from "../internalusers/getters";
import * as actions from "../internalusers/actions";
import * as mutations from "../internalusers/mutations";

const state = {
  
  usersInfo: {
    typeOptions: [],
    subtypeOptions: [],
    buildOptions: []    
  },

  internalInfo:{
    internalNames: []
  },
  usersList: [],
  rolesList: [],
  internalUsers: 0,
  
  oneUserData: null,
  roleUserData: null,

  
  // User info
  internalUserData: null,
  oneUserPairings: []
};

export default ({
  namespaced: true,
  state,
  getters,
  actions,
  mutations
});
