<template lang="html" src="./PairingStatus.html"></template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
const STATUS_COLORS = {
  PAIRED: 'green',
  INPROGRESS: 'blue'
}

@Component
export default class PairingStatus extends Vue{
  @Prop(String) value!: string;
  
  get name() {
    return this.$t('user.pairingStatus.'+(this.value.toLowerCase()));
  }

  get color() {
    return STATUS_COLORS[this.value];
  }
}
</script>
