<template lang="html" src="./MapFilter.html"></template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class MapFilter extends Vue {
  @Prop(Boolean) hasAnyActiveFilter!: boolean;

  dialogVisible = false;

  openDialog() {
    this.dialogVisible = true;
  }

  closeDialog() {
    this.dialogVisible = false;
  }
}
</script>

<style scoped>
.table-markers-filter .filter-active {
  color: #82b1ff !important;
}
.table-markers-filter .v-btn .v-btn__content .v-icon {
  color: rgb(0, 0, 0, 0.54);
}
</style>
