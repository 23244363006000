import { Vue, Component, Prop } from "vue-property-decorator";

import DxfDataGrid from "@/components/DxfDataGrid/DxfDataGrid.vue";
import { Column } from "devextreme/ui/data_grid";
import InstallationController from "@/domain/controllers/InstallationController";
import DeviceEntity from "@/domain/entities/DeviceEntity";

@Component({
  components: {
    DxfDataGrid,
  },
})
export default class CallDivertCard extends Vue {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Prop(Object) deviceData!: any;

  ctrl!: InstallationController;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dataStore: any[] = [];

  columns: Column[] = [
    {
      dataField: "id",
      caption: this.$t("deviceDetails.callDivert.address") as string,
    },
  ];

  async created() {
    this.ctrl = new InstallationController();
    const deviceEntity = new DeviceEntity(this.deviceData);
    await this.ctrl
      .getPairedDevices(deviceEntity)
      .then((response: string[]) => {
        this.dataStore = response.map((item) => {
          return { id: item };
        });
      });
  }
}
