<template lang="html" src="./PlanServices.html"></template>

<!-- eslint-disable @typescript-eslint/no-explicit-any -->
<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import { displayErrorMessage } from "@/api";

import Katalog from "@/api/katalog";

@Component
export default class PlanServices extends Vue {

  @Prop(String) planCode!: string;
  @Prop(String) planName!: string;
  @Prop(Array) services!: Array<any>;

  dialogService = false;
  showAll = false;
  listNameService : string [] = [];
  modelService: Array<any> | null = [];
  infoDialog = false;

  get serviceList() { 
    return this.$store.getters["plans/servicesList"];
  }

  getServicesList() {
    this.$store.dispatch("plans/getServices");
  }
  
  get serviceListContent() { 
    let resp = this.$store.getters["plans/servicesByPlan"];
    const servName = resp.map(s => s.name)
    return servName;
  }

  get serviceListCommercialContent() { 
    let resp = this.$store.getters["plans/commercialServicesByPlan"];
    const servName = resp.map(s => s.name)
    return servName;
  }

  getServicesListContent(showList) {
    this.$store.dispatch("plans/getServicesByPlan", { id : this.planCode, commercial : showList});
  }

  getCommercialServicesListContent() {
    this.$store.dispatch("plans/getCommercialServices", { id : this.planCode});
  }

  get nameServicesList(){
    if(this.listNameService < this.serviceList.length){
      for (var i = 0; i < this.serviceList.length ; i++){
        if(this.serviceList[i].commercial === true){
        this.listNameService.push(this.serviceList[i].name);
        }
      }
    }
    return this.listNameService;
  }

  prepareToEditPlans(){
    this.getCommercialServicesListContent();
    this.dialogService = true;
    this.modelService = this.serviceListCommercialContent;    
  }

  mounted() {
    this.getCommercialServicesListContent();
    this.getServicesList();
    this.getServicesListContent(this.showAll);
  }

  editUserRole() {
    this.dialogService = false;
    this.infoDialog = false;
    
    Katalog.setInsertServices(this.planCode, this.modelService)
      .then(() => {
        this.getServicesListContent(this.showAll);
        this.getCommercialServicesListContent();
      })
      .catch(err => {
        displayErrorMessage(err, {
          general: ["planDetails.err_services"]
        });      
      });
  }

}
</script>
<style scoped>
.v-card__text{
  min-height: 175px;
}
.v-list {
  border: 1px solid #ccc;
  background: #fbf8f8;
  overflow: auto;
  max-height: 155px;
}
.v-list .v-list-item__title {
  line-height: normal;
}
div[role="listitem"]:not(:last-child) {
  border-bottom: 1px solid #ddd;
}
.v-input--switch {
  margin: 0;
  padding: 0;
  height: 25px;
  margin-left: auto;
}
</style>
