<template lang="html" src="./LegendScale.html"></template>

<!-- eslint-disable @typescript-eslint/no-explicit-any -->
<script lang="ts">
import { Vue, Component, Mixins, Prop } from 'vue-property-decorator'
import mapMixin from "../../commons/map.mixin";

@Component
export default class LegendScale extends Mixins(Vue, mapMixin) {
  @Prop(Array) legendGrades!: Array<any>;
  @Prop(Array) legendGradesColors!: Array<any>;

  getColorInGrades!: any;

  tooltipText(i) {
    if (this.legendGrades) {
      let minNum = Number(this.legendGrades[i]);
      let maxNum = Number(this.legendGrades[i + 1] );
      const max = this.legendGrades[i + 1] ? maxNum.toLocaleString() : "+";

      if(minNum === 0) {
        return minNum.toLocaleString()
      }
      return minNum.toLocaleString()  + " - " + max;
    }
    return null;
  }

  styleBackground(i) {
    if (this.legendGrades && this.legendGradesColors) {
      const color = this.getColorInGrades(
        this.legendGrades[i],
        this.legendGrades,
        this.legendGradesColors
      );
      return "background:" + color;
    }
  }
}
</script>

<style scoped>
.legend-color {
  padding: 6px 8px;
  font: 14px/16px Arial, Helvetica, sans-serif;
  line-height: 18px;
  color: #555;
  display: flex;
}
.legend-color em {
  width: 18px;
  height: 18px;
  float: left;
  opacity: 0.7;
}
</style>
