<template lang="html" src="./LoadingCount.html"></template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class LoadingCount extends Vue {
  @Prop({ type: Number, default: 0 }) parcialCount!: number;
  @Prop({ type: Number, default: 0 }) totalCount!: number;
  @Prop(Boolean) isLoading!: boolean;

  imgWidth = 18;

  get imgSrcOnLine() {
    return process.env.VUE_APP_PUBLIC_PATH + 'assets/status/green.png';
  }

  get imgSrcOffLine() {
    return process.env.VUE_APP_PUBLIC_PATH + 'assets/status/grey.png';
  }
}
</script>

<style scoped>
.legend-count {
  padding: 5px;  
}

.legend-count img {
  vertical-align: bottom;
}

.legend-count h5 {
  color: #777;
  display: inline;  
}

.loader{
width: 20px;
height: 20px;
border-radius: 50%;
border: 3px solid #7e7b7b;
border-top-color: transparent;
animation: spin 1.2s 
    linear  infinite;
    margin:auto;
}

@keyframes spin {
  to{
    transform: rotate(360deg);
  }
}


</style>
