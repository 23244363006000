import axios from "axios";
import HttpStatus from "http-status-codes";
import i18n from "@/lang/i18n";

export const renewTimeout = ({ commit, state, getters }, payload) =>
  new Promise((resolve, reject) => {
    let localState = state;

    if (!state.isUserLogged) localState = payload;

    const timeNow = new Date().getTime();
    if (timeNow >= localState.expiresIn) {
      // Refresh token first
      return axios
        .post(
          getters.getMyOauth + "/oauth/token",
          "grant_type=password&username=" +
            localState.user +
            "&password=" +
            encodeURIComponent(localState.password),
          {
            headers: {
              Authorization: "Basic " + getters.getMyAuth,
              "Content-type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then(
          (response) => {
            const timeExpires =
              new Date().getTime() + response.data.expires_in * 1000;

            commit("setUserLogged", {
              user: localState.user,
              password: localState.password,
              remember: localState.remember,
              isUserLogged: true,
              accessToken: response.data.access_token,
              expiresIn: timeExpires,
            });

            resolve(response.data.access_token);
          },
          (error) => {
            reject(error);
          }
        );
    } else resolve(getters.accessToken);
  });

export const errorHandler = ({ commit }, payload) => {
  const errorStatus = payload.error.response
    ? payload.error.response.status
    : null;
  let text = i18n.t(payload.genericErrorText[0], payload.genericErrorText[1]);

  switch (errorStatus) {
    case HttpStatus.UNAUTHORIZED:
      text = i18n.t("general.error.userNotAuth");
      break;
    case HttpStatus.BAD_REQUEST:
      if (payload.badRequestText) {
        text = i18n.t(payload.badRequestText[0], payload.badRequestText[1]);
      }
      break;
    case HttpStatus.FORBIDDEN:
      if (payload.forbiddenText) {
        text = i18n.t(payload.forbiddenText[0], payload.forbiddenText[1]);
      }
      break;
    case HttpStatus.NOT_FOUND:
      if (payload.notFoundText) {
        text = i18n.t(payload.notFoundText[0], payload.notFoundText[1]);
      }
      break;
    case HttpStatus.NOT_ACCEPTABLE:
      if (payload.notAcceptableText) {
        text = i18n.t(
          payload.notAcceptableText[0],
          payload.notAcceptableText[1]
        );
      }
      break;
    case HttpStatus.CONFLICT:
      if (payload.conflictText) {
        text = i18n.t(payload.conflictText[0], payload.conflictText[1]);
      }
      break;
    case HttpStatus.LENGTH_REQUIRED:
      if (payload.lengthHeaderRequired) {
        text = i18n.t(
          payload.lengthHeaderRequired[0],
          payload.lengthHeaderRequired[1]
        );
      }
      break;
    case HttpStatus.TOO_MANY_REQUESTS:
      if (payload.tooManyRequestsText) {
        text = i18n.t(
          payload.tooManyRequestsText[0],
          payload.tooManyRequestsText[1]
        );
      }
      break;
    default:
      break;
  }
  commit("snackbarError", { active: true, text: text });
};
export const errorHandlerCustom = ({ commit }, payload) => {
  commit("snackbarError", { active: true, text: i18n.t(payload.text) });
};

export const snackbarInfo = ({ commit }, payload) => {
  const active = payload.active !== undefined ? payload.active : true;

  commit("snackbarInfo", { active: active, text: payload.text });
};

export const snackbarError = ({ commit }, payload) => {
  const active = payload.active !== undefined ? payload.active : true;

  commit("snackbarError", { active: active, text: payload.text });
};

export const dismissAllNotifications = ({ commit, state }) => {
  const notifications = [...state.notifications];

  commit(
    "setNotifications",
    notifications.map((n) => ({ ...n, dismissed: true }))
  );
};

export const dismissNotification = ({ commit, state }, payload) => {
  const notifications = [...state.notifications];

  const notification = notifications.find((n) => n.id === payload);
  notification.dismissed = true;

  commit("setNotifications", notifications);
};

export const createNotification = ({ commit }, payload) => {
  commit("addNotification", payload);
};
