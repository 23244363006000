<template lang="html">
  <LineComponent 
    :options="options" 
    :chartData="data" 
    style="position: relative; width: 100%; height: 100%;" 
    v-if="data"
  />
</template>

<!-- eslint-disable @typescript-eslint/no-explicit-any -->
<script lang="ts">
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import LineComponent from "@/components/Charts/Line";

@Component({
  components: {
    LineComponent
  }
})
export default class LineChart extends Vue {
  @Prop(Array) tooltips!: Array<any>;
  @Prop(Array) value!: Array<any>;

  data: any | null = null;
  options = {
    responsive: true,
    scaleBeginAtZero: true,
    maintainAspectRatio: false,
    legend: {
      display: false
    },
    scales: {
      xAxes: [
        {
          ticks: {
            autoSkip: false,
            maxRotation: 50,
            minRotation: 45
          },
          gridLines: {
            // You can change the color, the dash effect, the main axe color, etc.
            borderDash: [8, 4],
            borderWidth: 1,
            color: "#DDDDDD"
          }
        }
      ],
      yAxes: [
        {
          gridLines: {
            // You can change the color, the dash effect, the main axe color, etc.
            borderDash: [8, 4],
            borderWidth: 1,
            color: "#DDDDDD"
          }
        }
      ]
    },
    tooltips: {
      callbacks: {
        label: (tooltipItem, data) => {
          const dataset = data.datasets[tooltipItem.datasetIndex];
          const currentValue = dataset.data[tooltipItem.index];

          return " " + currentValue;
        }
      }
    }
  }

  @Watch('value')
  onChangeValue(next, latest) {
    if (JSON.stringify(next) !== JSON.stringify(latest)) this.calculateData();
  }

  mounted() {
    this.calculateData();
  }

  composeLineData(values, labels) {
    return {
      labels: labels,
      datasets: [
        {
          data: values,
          fill: false,
          borderColor: "#82b1ff"
        }
      ]
    };
  }

  calculateData() {
    let data: any | null = null;

    if (
      this.value &&
      this.value.length > 0 &&
      this.value[0] !== null &&
      this.value[1] !== null
    )
      data = this.composeLineData(this.value, this.tooltips);

    this.data = data;
  }
}
</script>
<style scoped>
span.subheading {
  font-size: 16px;
}
.v-card__text {
  overflow: hidden;
}
.last-update {
  font-size: 12px;
  white-space: nowrap;
}
</style>