const is_empty = (str) => {
  return !str || 0 === str.length;
};

const has_content = (str) => {
  return is_empty(str);
};

const all_has_content = (...strs) => {
  let result = true;
  let i = 0;

  while (i < strs.length && result) {
    if (is_empty(strs[i])) result = false;
    i++;
  }

  return result;
};

export default {
    is_empty,
    has_content,
    all_has_content
}
