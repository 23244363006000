import {
  InternalUserEntity,
  InternalUserInterface,
} from "../entities/InternalUserEntity";
import { InternalUserServiceInterface } from "../interfaces/InternalUserServerInterface";
import { InternalUserService } from "../services/InternalUserService";

export class InternalUserController {
  /** Servicio de usuarios internos. */
  protected internalUserService: InternalUserServiceInterface;

  constructor(internalUserService?: InternalUserServiceInterface) {
    if (internalUserService) {
      this.internalUserService = internalUserService;
    } else {
      this.internalUserService = new InternalUserService();
    }
  }
  /** Obtiene todos los usuarios internos. */
  public async getAll(): Promise<InternalUserEntity[]> {
    return await this.internalUserService
      .getAll()
      .then((response: InternalUserInterface[]) => {
        return response.map((user) => new InternalUserEntity(user));
      });
  }

  public async get(id: string): Promise<InternalUserEntity> {
    return await this.internalUserService
      .get(id)
      .then((response: InternalUserInterface) => {
        return new InternalUserEntity(response);
      });
  }

  /** Obtiene los posibles roles para los usuarios internos */
  public async getRoles(): Promise<Array<string>> {
    return await this.internalUserService.getRoles();
  }

  public async insert(internalUser: InternalUserEntity): Promise<void> {
    if (internalUser.password && internalUser.email) {
      return await this.internalUserService.insert({
        email: internalUser.email,
        password: internalUser.password,
        roles: internalUser.roles.map((rol) => rol.name),
      });
    } else {
      throw new Error("oauthclients.error.updateFail");
    }
  }

  /** Actualiza la contraseña de un usuario interno. */
  public async changePassword(internalUser: InternalUserEntity): Promise<void> {
    if (internalUser.password && internalUser.email) {
      return await this.internalUserService.changePassword({
        email: internalUser.email,
        password: internalUser.password,
      });
    } else {
      throw new Error("oauthclients.error.updateFail");
    }
  }

  /** Actualiza los roles de un usuario interno. */
  public async changeRoles(internalUser: InternalUserEntity): Promise<void> {
    const roles = internalUser.roles.map((rol) => rol.name);
    return await this.internalUserService.changeRoles(internalUser.id, roles);
  }

  /** Elimina un usuario interno.
   * @param internalUser Usuario interno a eliminar.
   * @throws El usuario superAdmin no puede ser eliminado.
   */
  public async delete(internalUser: InternalUserEntity): Promise<void> {
    if (internalUser.isSuperAdmin) {
      throw new Error("internalusers.error.deleteSuperAdmin");
    }
    return await this.internalUserService.delete(internalUser.id);
  }
}
