<template>
  <v-dialog v-model="localValue" max-width="500px">
    <v-card>
      <v-img :src="imgSrcLogin" alt="logo" position="center" height="200px" contain />
      <v-card-text>
        <span>{{ $t('general.version') }} {{ appVersion }}</span>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import pck from '../../package.json'

@Component
export default class AppAbout extends Vue {
  @Prop({type: Boolean, default: false}) value!: boolean;

  get appVersion() {
    return pck.version;
  }

  get imgSrcLogin() {
    return process.env.VUE_APP_PUBLIC_PATH + "assets/blue.png";
  }

  get localValue() {
    return this.value;
  }

  set localValue(localValue) {
    this.$emit("input", localValue);
  }
}
</script>
