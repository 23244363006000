/* eslint-disable @typescript-eslint/no-explicit-any */
import i18n from "@/lang/i18n";
export default class TranslateText {
  public static t(
    text: string,
    values?: any[] | { [key: string]: any }
  ): string {
    if (i18n.te(text)) {
      return i18n.t(text, values).toString();
    } else {
      return text;
    }
  }
}
