import Api from "@/api";

const KPI_SERVICE_PATH = "/kpiservice/api/v1";

export default {
  
  getGeodataZones: (deviceType) => {
    return Api(true).get(`${KPI_SERVICE_PATH}/kpi/`+deviceType+`_ZONES`).then(res => (res.data && res.data.value && res.data.value !== '-' ? JSON.parse(res.data.value) : []));
  },

  getGeodataSubzones: (deviceType) => {
    return Api(true).get(`${KPI_SERVICE_PATH}/kpi/`+deviceType+`_SUBZONES`).then(res => (res.data && res.data.value && res.data.value !== '-' ? JSON.parse(res.data.value) : []));
  },
  
  getGeodataAreas: (deviceType) => {
    return Api(true).get(`${KPI_SERVICE_PATH}/kpi/`+deviceType+`_AREAS`).then(res => (res.data && res.data.value && res.data.value !== '-' ? JSON.parse(res.data.value) : []));
  },
}
