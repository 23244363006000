import moment from 'moment';

export default {
  methods: {
    checkHasAnyActiveFilter(filterObj) {      
      return (
        filterObj &&
        Object.values(filterObj).filter(v => v !== null && v != undefined && v.toString().trim() != "")
          .length > 0
      );
    },

    filterStartWith(item, value) {
      return (item.toString().toLowerCase()).startsWith(value.trim().toLowerCase());
    },

    filterDate(item, startDate = null, endDate = null) {
      const fwDate = moment(item);

      if (
        (!endDate || fwDate.isSameOrBefore(endDate, "day")) &&
        (!startDate || fwDate.isSameOrAfter(startDate, "day"))
      )
        return true;

      return false;
    },

    filterNumberBetween(item, start, end) {
      if (
        (!end || item <= end) && 
        (!start || item >= start)
      )
        return true;

      return false;
    },

    filterContains(item, value) {
      return (item.toLowerCase()).includes(value.toLowerCase().trim());
    },

    filterInList(list, key, value) {
      return list.find(item => item[key] === value) !== undefined;
    },

    filterInListMultiple(list, key, values) {
      return values.every(r => list.map(i => i[key]).includes(r));
    }
  }
}