import { displayErrorMessage } from "@/api";
import katalogService from "@/api/katalog";

export const getPlansList = ({ commit }) => new Promise((resolve, reject) => {
  katalogService.getPlans().then(response => {
    const plans = response.data;

    const regions = [];
    const services = [];
    for (const plan of plans) {
      for (const region of plan.availabilityRegionList) {
        if (!regions.find(r => r.country === region.country))
          regions.push({ country: region.country });
      }

      for (const service of plan.plan.services) {
        if (!services.find(s => s.id === service.serviceId))
          services.push({ id: service.serviceId, name: service.name });
      }
    }

    let plansInfo = {
      list: plans,
      regions,
      services,
      total: plans.length
    };

    commit('setPlansListInfo', plansInfo);

    resolve(plansInfo);
  }).catch(error => {
    displayErrorMessage(error, { general: ["plans.error.getPlanList"] });

    reject(error);
  });
});

export const getPlanDetails = ({ commit }, payload) => new Promise((resolve, reject) => {
  commit('setPlanDetails', null);

  katalogService.getPlan(payload).then(planRes => {
    let plan = planRes.data;

    katalogService.getPossibleUpgradePlans(payload).then(possRes => {
      plan = { ...plan, upgradeableTo: possRes.data };
      commit('setPlanDetails', plan);

      resolve(plan);
    }).catch(error => {
      displayErrorMessage(error, { general: ["planDetails.error.getPlan"] });

      reject(error);
    });

  }).catch(error => {
    displayErrorMessage(error, { general: ["planDetails.error.getPlan"] });

    reject(error);
  });
});

export const getServices = ({ commit }) => new Promise((resolve, reject) => {
  katalogService.getServices().then(res => {
    const servicesList = res.data;

    commit('setServicesList', servicesList);
    resolve(servicesList);
  }).catch(err => {
    displayErrorMessage(err, { general: ["user.error.getError"] });

    reject(err);
  });
});

export const getServicesByPlan = ({ commit }, payload) => new Promise((resolve, reject) => {
  katalogService.getServicesByPlan(payload.id, payload.commercial).then(res => {
    const servicesByPlan = res.data;

    commit('setServicesByPlan', servicesByPlan);
    resolve(servicesByPlan);
  }, err => {
    displayErrorMessage(err, { general: ["userdetails.error.getError"] });

    reject(err);
  });
});

export const getCommercialServices = ({ commit }, payload) => new Promise((resolve, reject) => {
  katalogService.getCommercialServices(payload.id).then(res => {
    const commercialServicesByPlan = res.data;
    commit('setCommercialServicesByPlan', commercialServicesByPlan);
    resolve(commercialServicesByPlan);
  }, err => {
    displayErrorMessage(err, { general: ["userdetails.error.getError"] });

    reject(err);
  });
});

export const getParentPlans = ({ commit }) => new Promise((resolve, reject) => {
  katalogService.getParentPlans().then(res => {
    const parentPlansList = res.data;

    commit('setParentPlansList', parentPlansList);
    resolve(parentPlansList);
  }).catch(err => {
    displayErrorMessage(err, { general: ["user.error.getError"] });

    reject(err);
  });
});