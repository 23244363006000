<template lang="html" src="./SubscriptionDetailsModal.html"></template>

<!-- eslint-disable @typescript-eslint/ban-types -->
<!-- eslint-disable @typescript-eslint/no-explicit-any -->
<script lang="ts">
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import AppListMenu from "@/components/AppListMenu.vue";

import dateMixin from "@/mixins/date.mixin";
import planMixin from "@/mixins/plan.mixin";

import { getCardIcon } from "@/utils/plan";

import { displayErrorMessage } from "@/api";
import katalogService from "@/api/katalog";
import fermaxSubscriptionService from "@/api/fermaxSubscription";

import { goTo } from "@/router";

const WIFI_PLAN_SKU = "fullservice-annual-wifi";

const TRIAL_END_REASON = "TRIAL_END";
const NOT_PAID_REASON = "NOT_PAID";
const ANOTHER_REASON = "ANOTHER";

@Component({
  components: {
    AppListMenu
  },
  mixins: [dateMixin, planMixin]
})
export default class SubscriptionDetailsModal extends Vue {
  @Prop(Object) data!: any;

  internalizedText!: Function;

  WIFI_PLAN_SKU = WIFI_PLAN_SKU;
  ANOTHER_REASON = ANOTHER_REASON;

  showConfirmCancel = false;
  isCancelling = false;
  cancelReason: any = null;
  otherReason: any = null;
  cancelReasonOptions = null;
  cancelImmediate = false;
  showConfirmImmediateCancel = false;

  get menuOptions() {
    return [
      {
        icon: "cancel",
        text: this.$t("userdetails.subscriptionInfo.cancelSubscription"),
        action: () => this.setShowConfirmCancel(true),
        allowed: this.$ability.can('changeSubscription','users') && this.plan.plan.sku !== WIFI_PLAN_SKU && !this.isCanceled
      }
    ];
  }

  get plan() {
    return this.data && this.data.planAdvancedDTO;
  }

  get isCanceled() {
    return (
      this.data &&
      this.data.cancelAt &&
      (this.data.cancellationReasonSubDTO === null ||
        this.data.cancellationReasonSubDTO.reason !== TRIAL_END_REASON)
    );
  }

  get internalizedPlan() {
    return this.plan && this.internalizedText(this.plan.regionalDetailsList);
  }

  get regionPlan() {
    return this.plan && this.plan.availabilityRegionList[0]; //
  }

  get planRenovation() {
    return this.plan && this.plan.plan.trial
      ? this.plan.plan.trial.period
      : this.plan.plan.renewalPeriod;
  }

  @Watch('cancelReason')
  onChangeCancelReason() {
    this.otherReason = undefined;
  }

  @Watch('showConfirmCancel')
  onChangeShowConfirmCancel() {
    // reset form
    this.cancelImmediate = this.plan && !!this.plan.plan.trial;
    this.cancelReason = undefined;
  }

  mounted() {
    this.loadCancellationReasons();
  }

  getCardIcon = getCardIcon;

  setShowConfirmCancel(v) {
    this.showConfirmCancel = v;
  }

  loadCancellationReasons() {
    katalogService
      .getCancellationReasons()
      .then(res => {
        this.cancelReasonOptions = res.data.filter(r => r !== NOT_PAID_REASON);
      })
      .catch(err => console.error(err));
  }

  confirmImmediateCancellation() {
    if (this.cancelImmediate) this.showConfirmImmediateCancel = true;
    else this.cancelSubscription();
  }

  onConfirmCancellationImmediate() {
    this.showConfirmImmediateCancel = false;
    this.cancelSubscription();
  }

  cancelSubscription() {
    this.isCancelling = true;

    fermaxSubscriptionService
      .cancelSubscription(
        this.data.id,
        this.cancelImmediate,
        this.cancelReason,
        this.otherReason
      )
      .then(
        () => {
          this.isCancelling = false;
          this.showConfirmCancel = false;

          this.close(true);
        },
        err => {
          displayErrorMessage(err, {
            general: ["userdetails.subscriptionInfo.cancelConfirm.error"]
          });
          this.isCancelling = false;
        }
      );
  }

  getReasonText(name) {
    return this.$t(
      "userdetails.subscriptionInfo.cancelConfirm.reasons." + name
    );
  }

  seePlanDetails() {
    goTo(`plan-${this.plan.plan.planId}`);
    this.close();
  }

  close(res = false) {
    this.$emit("onCloseRequest", res);
  }
}
</script>
<style scoped>
.v-btn.plan-link {
  width: auto;
  height: auto;
}
.v-btn.plan-link .v-icon {
  font-size: 18px;
}
.trial-label {
  font-size: 12px;
  color: #666;
}
.plan-name span {
  display: flex;
  align-items: center;
  white-space: nowrap;
}
</style>