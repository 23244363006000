import HttpStatus from 'http-status-codes';

import { goTo } from "@/router";

export default {
  methods: {
    checkItemNotFound(err, redirect) {
      if(err.response.status === HttpStatus.NOT_FOUND)
        goTo(redirect);
    }
  }
}