/* eslint-disable @typescript-eslint/no-empty-function */
import { displayErrorMessage } from "@/api";
import axios from "axios";

import userService from "@/api/user";
import deviceService from "@/api/device";
import paymentService from "@/api/payment";

import fermaxSubscriptionService from "@/api/fermaxSubscription";

export const getUsersList = ({ commit }, payload) => new Promise((resolve, reject) => {
  userService.getUsers(payload.filterOptions, payload.pagination, payload.search, payload.column, payload.axiosSource, payload.isOpenditRole).then(res => {

    commit('setUsersList', res.data);
    resolve(res.data);

  }).catch(err => {
    if (!axios.isCancel(err))
      displayErrorMessage(err, { general: ["user.error.getError"] });

    reject(err);
  });
});

export const getNumberOfUsers = ({ commit }) => new Promise((resolve, reject) => {
  userService.getNumberOfUsers().then(res => {
    commit('setNumberOfUsers', res.data);

    resolve(res.data);
  }, err => {
    displayErrorMessage(err, { general: ["user.error.getError"] });

    reject(err);
  });
});

export const getUser = ({ commit }, payload) => new Promise((resolve, reject) => {
  userService.getUser(payload.id).then(res => {
    const user = res.data;
    
    commit('setOneUserData', user);
    resolve(user);

  }, err => {
    displayErrorMessage(err, { general: ["userdetails.error.getError"] });

    reject(err);
  });
});

export const getUserCredit = ({ commit }, payload) => new Promise((resolve) => {
  commit('setOneUserCredit', null);

  const getCredit = async () => {
    let resAmount = null;
    try {
      resAmount = await paymentService.getUserAmount(payload.id);
    } catch (err) {
      console.error(err);
    }

    const amount = resAmount ? resAmount.data : false;

    commit('setOneUserCredit', amount);
    resolve(amount);
  };

  getCredit();
});

export const getOneUserPairings = ({ commit }, payload) => new Promise((resolve, reject) => {
  commit('setOneUserPairings', []);

  userService.getUserPairings(payload.userId).then(res => {
    let userPairings = res.data.content;
    let userPairingResult = [];

    // In case the second get is not called
    if (userPairings.length === 0) {
      resolve(null);
    } else {
      const getDevice = pairing => deviceService.getDeviceCache(pairing.deviceId).then(resDevice => {
        const monitorInfo = resDevice.data;
        pairing.family = monitorInfo.family;
        pairing.type = monitorInfo.type;
        pairing.subtype = monitorInfo.subtype;

        if (!(payload.isOpenditRole && pairing.subtype == "WIFI")) {
          userPairingResult.push(pairing);
        }
      }).catch(() => { });

      const getSubscription = device => fermaxSubscriptionService.getSubscriptionForDevice(device.deviceId, payload.userId).then(resSubscription => {
        device.subscription = resSubscription.data && resSubscription.data[0];
      }).catch(() => { });
      //Se comenta la llamada al servico de invoice hasta que se requiera utilizar.
      //const getLastInvoice = device => paymentService.getUserDeviceLastInvoice(payload.userId, device.deviceId).then(resInvoice => device.lastInvoice = resInvoice.data).catch(() => { })

      const getLastInvoice = () => null

      const devicePromises = Array(userPairings.length).fill(null).map((item, pos) => getDevice(userPairings[pos]));
      for (let i = 0; i < userPairings.length; i++) {
        devicePromises.push(getSubscription(userPairings[i]));
        devicePromises.push(getLastInvoice(userPairings[i]));
      }

      axios.all(devicePromises).then(() => {

        // Sort by master
        userPairingResult.sort((x, y) => {
          return y.master - x.master === 0 ? y.updatedAt - x.updatedAt : y.master - x.master;
        });

        // if (userPairingResult.length < userPairings.length)
        //   displayErrorMessage({}, { general: ["userdetails.error.deviceError"] });

        // Save the results in the state
        commit('setOneUserPairings', userPairingResult);
        resolve(userPairingResult);
      });
    }
  }).catch(err => {
    reject(err);

    displayErrorMessage(err, { general: ["userdetails.error.getError"] });
  });
});
