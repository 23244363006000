<template>
  <thead class="v-data-table-header">
    <tr>
      <th scope="col" class="column sortable" :class="[header.align ? 'text-xs-'+header.align : 'text-xs-left', (header.sticky ? 'sticky-actions' : null), currentSort[1] ? 'desc' : 'asc', header.value === currentSort[0] ? 'active' : '']"
        v-for="header in headers" :key="header.text" @click="header.sortable ? changeSort(header.value) : ''">
        <v-layout row>
          <span>{{ header.text }}</span>
          <v-icon class="v-data-table-header__icon" v-if="header.sortable" small>arrow_upward</v-icon>
        </v-layout>
      </th>
    </tr>
  </thead>
</template>

<!-- eslint-disable @typescript-eslint/no-explicit-any -->
<!-- eslint-disable @typescript-eslint/ban-types -->

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class SimpleThead extends Vue {
  @Prop(Array) headers!: Array<any>;
  @Prop(Array) currentSort!: Array<any>;
  @Prop(Function) changeSort!: Function;
}
</script>
<style scoped>
.text-xs-center > .layout.row > span{
  margin-left: auto;
  margin-right: auto
}
</style>