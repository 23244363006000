import * as getters from "../devices/getters";
import * as actions from "../devices/actions";
import * as mutations from "../devices/mutations";

const state = {
  oneDeviceData: null,
  oneDeviceCacheData: [],

  oneDevicePairings: [],
  oneDeviceSim: null,
};

export default ({
  namespaced: true,
  state,
  getters,
  actions,
  mutations
});