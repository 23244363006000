import FilterHelper, { PaginationInterface } from "@/ui/helpers/FilterHelper";
import Api from "./index";
import { downloadData } from "@/utils/data";

export const FAMILY = {
  MONITOR: "MONITOR",
  PHONE: "PHONE",
};

const SERVICE_PATH = "/installation/api/v1";
const SERVICE_PATH_V2 = "/installation/api/v2";

interface FilterOptionsInstallationInterface {
  idFilter?: string;
  tagFilter?: string;
  statusFilter?: string;
  createdAtValueStart?: string;
  createdAtValueEnd?: string;
  updatedAtValueStart?: string;
  updatedAtValueEnd?: string;
  lastTelemetryDateValueStart?: string;
  lastTelemetryDateValueEnd?: string;
  onlyProblemsFilter?: boolean;
  panels?: string;
  monitors?: string;
  phone?: string;
  guard?: string;
  ediboxes?: string;
  zoneFilter?: string;
  subzoneFilter?: string;
  areaFilter?: string;
  coverageFilter?: string;
  cityFilter?: string;
  idTab?: { family: string; subtype: string } | string;
  olFilter?: string;
}

function urlToInstallations(
  filterOptions: FilterOptionsInstallationInterface,
  pagination: PaginationInterface | null = null,
  search = null,
  column = null
) {
  const filter = new FilterHelper(SERVICE_PATH + "/installation?");
  filter.addPagination(pagination);
  filter.addSearch(search);
  filter.addSort(column, pagination);

  filter.addParam("installationId", filterOptions.idFilter);
  filter.addParam("tag", filterOptions.tagFilter);
  filter.addParam("status", filterOptions.statusFilter);

  filter.addRangeDate(
    "createDate",
    filterOptions.createdAtValueStart,
    filterOptions.createdAtValueEnd
  );
  filter.addRangeDate(
    "updateDate",
    filterOptions.updatedAtValueStart,
    filterOptions.updatedAtValueEnd
  );

  filter.addRangeDate(
    "lastTelemetryDate",
    filterOptions.lastTelemetryDateValueStart,
    filterOptions.lastTelemetryDateValueEnd
  );

  filter.addParam("onlyProblems", filterOptions.onlyProblemsFilter);
  filter.addPanels(filterOptions.panels);
  filter.addMonitors(filterOptions.monitors);
  filter.addPhones(filterOptions.phone);
  filter.addGuards(filterOptions.guard);
  filter.addEdiboxes(filterOptions.ediboxes);
  filter.addParam("zone", filterOptions.zoneFilter);
  filter.addParam("subzone", filterOptions.subzoneFilter);
  filter.addParam("area", filterOptions.areaFilter);
  filter.addSignal(filterOptions.coverageFilter);
  filter.addParam("city", filterOptions.cityFilter);
  filter.addParam("ol", filterOptions.olFilter);

  if (
    filterOptions.idTab !== "ALL" &&
    typeof filterOptions.idTab === "object"
  ) {
    const { family, subtype } = filterOptions.idTab;
    filter.addParam("family", family);
    if (family !== "PANEL") {
      filter.addParam("type", subtype);
    }
  }

  return filter.getUrl();
}

export default {
  getInstallations: (
    filterOptions,
    pagination = null,
    search = null,
    column = null
  ) => {
    const url = urlToInstallations(filterOptions, pagination, search, column);
    return Api(true).get(url);
  },
  getNumberOfInstallations: () =>
    Api(true).get(`${SERVICE_PATH}/installations/count`),

  getNumberOfTypeInstallations: () =>
    Api(true).get(`${SERVICE_PATH_V2}/installations/all/types/count`),

  getInstallation: (id) => Api(true).get(`${SERVICE_PATH}/installation/${id}`),

  getInstallationTopology: (installationId) =>
    Api(true).get(`${SERVICE_PATH}/topology/${installationId}`),

  refreshInstallation: (id) =>
    Api(true).post(`${SERVICE_PATH}/installation/${id}/refresh`),

  penetrationInstallation: (id) =>
    Api(true).get(`${SERVICE_PATH}/installations/${id}/penetration`),

  updateInstallationTag: (id, tag) =>
    Api(true).post(`${SERVICE_PATH}/installation/${id}/tag?tag=${tag}`),

  deleteInstallation: (id) =>
    Api(true).delete(`${SERVICE_PATH}/installation/${id}`),

  getInstallationCity: () => Api(true).get(`${SERVICE_PATH}/cities/`),

  obtainBBDD: (deviceId) =>
    Api(true)
      .get(`${SERVICE_PATH}/installation/subscriptions/${deviceId}`)
      .then((res) => {
        const data =
          "data:text/json;charset=utf-8," +
          encodeURIComponent(
            JSON.stringify(res.data.panelMessageSubscriptionList)
          );

        return downloadData(deviceId + "_" + res.data.version, "json", data);
      }),
};
