<template lang="html" src="./JobDeleteModal.html"></template>

<!-- eslint-disable @typescript-eslint/ban-types -->
<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import { displayErrorMessage } from "@/api";
import jobService from "@/api/job";

@Component
export default class JobDeleteModal extends Vue {
  @Prop(Boolean) value!: boolean;
  @Prop(String) jobId!: string;
  @Prop(Function) onSuccess!: Function;

  get show() {
    return this.value;
  }

  set show(localValue) {
    this.$emit("input", localValue);
  }

  execute(jobId) {
    this.show = false;

    jobService.deleteJob(jobId).then(
      res => {
        this.$store.dispatch("snackbarInfo", {
          text: this.$t("jobs.info.deleteJob")
        });

        if(this.onSuccess) this.onSuccess(res);
      },
      error => {
        displayErrorMessage(error, { general: ["jobs.error.deleteFails"] });
      }
    );
  }
}
</script>