<template lang="html" src="./PlanEditModal.html"></template>

<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator";
import PlanEditDescription from "./PlanEditDescription/PlanEditDescription.vue";
import { RegionalDetail } from "../../../../interfaces/plans";

@Component({
  components: {
    PlanEditDescription,
  },
})
export default class PlanEditModal extends Vue {
  @Prop(Array) regionalDetails!: RegionalDetail[];

  close() {
    this.$emit("onCloseRequest");
  }
}
</script>
<style scoped>
label {
  display: block;
}
</style>
