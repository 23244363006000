import Vue from "vue";
import Component from "vue-class-component";

import { Prop, Watch } from "vue-property-decorator";
import DxfDataGrid from "../../../components/DxfDataGrid/DxfDataGrid.vue";
import { translateText } from "@/lang/i18n";

import { Column } from "devextreme/ui/data_grid";

import ApiRoles from "@/api/roles";
import { MenuOptions } from "@/interfaces/dxfDataGrid";

@Component({
  components: {
    DxfDataGrid,
  },
})
export default class RolesTable extends Vue {
  /** Cadena de texto de la búsqueda. */
  @Prop() search!: string;

  /** Filtros de la búsqueda. */
  // eslint-disable-next-line
  @Prop() filterOptions!: any;

  /** Título de la tabla. */
  title: string;

  /** Datos de la tabla. */
  // eslint-disable-next-line
  dataSource: Array<any>;

  /** Opciones del menú */
  menuOptions: MenuOptions[];

  /**
   * Como es una tabla que no hace llamadas al back,
   * Se guardan aquí los datos originales para no
   * perderlos ni tener que volver a llamar al back.
   */
  // eslint-disable-next-line
  dataSourcePersistent: Array<any>;

  /** Columnas de la tabla. */
  columns: Column[];

  showExport = this.$ability.can("export", "roles");

  /** Se inicializan las variables. */
  constructor() {
    super();
    this.title = translateText("menu.titleRoles");
    this.menuOptions = this.getMenuOptions();
    this.columns = this.getColumns(this.menuOptions);
    this.dataSource = [];
    this.dataSourcePersistent = [];
    this.loadDataSource();
  }

  /** Cuando se realiza una búsqueda, se refrescan los datos. */
  @Watch("search")
  onSearchChanged() {
    this.applyFilters();
  }

  /** Cuando se aplica un filtro, se refrescan los datos. */
  @Watch("filterOptions")
  onFilterOptionsChanged() {
    this.applyFilters();
  }

  /** Obtiene las columnas de la tabla. */
  getColumns(menuOptions: MenuOptions[] = []): Column[] {
    const columns: Column[] = [
      {
        dataField: "rol",
        caption: translateText("roles.itemName"),
        sortIndex: 1,
        sortOrder: "asc",
      },
      {
        dataField: "details[0]",
        caption: translateText("menu.dashboard"),
        allowSorting: false,
        cellTemplate: "rolTick",
      },
      {
        dataField: "details[1]",
        caption: translateText("menu.plans"),
        allowSorting: false,
        cellTemplate: "rolTick",
      },
      {
        dataField: "details[2]",
        caption: translateText("menu.installations"),
        allowSorting: false,
        cellTemplate: "rolTick",
      },
      {
        dataField: "details[3]",
        caption: translateText("menu.devices"),
        allowSorting: false,
        cellTemplate: "rolTick",
      },
      {
        dataField: "details[4]",
        caption: translateText("menu.users"),
        allowSorting: false,
        cellTemplate: "rolTick",
      },
      {
        dataField: "details[5]",
        caption: translateText("menu.jobs"),
        allowSorting: false,
        cellTemplate: "rolTick",
      },
      {
        dataField: "details[6]",
        caption: translateText("menu.firmware"),
        allowSorting: false,
        cellTemplate: "rolTick",
      },
      {
        dataField: "details[7]",
        caption: translateText("menu.oauthclients"),
        allowSorting: false,
        cellTemplate: "rolTick",
      },
      {
        dataField: "details[8]",
        caption: translateText("menu.internalUsers"),
        allowSorting: false,
        cellTemplate: "rolTick",
      },
      {
        dataField: "details[9]",
        caption: translateText("menu.titleRoles"),
        allowSorting: false,
        cellTemplate: "rolTick",
      },
      {
        dataField: "details[10]",
        caption: translateText("menu.titleManufactur"),
        allowSorting: false,
        cellTemplate: "rolTick",
      },
      {
        dataField: "details[11]",
        caption: translateText("menu.titleCall"),
        allowSorting: false,
        cellTemplate: "rolTick",
      },
    ];
    // Si no hay opciones del menú, no se muestra la columna.
    if (menuOptions.length > 0) {
      columns.push({
        cellTemplate: "menu",
        width: 60,
        allowExporting: false,
      });
    }

    return columns;
  }

  /** Carga los datos de la tabla. */
  loadDataSource() {
    ApiRoles.getRoles().then((response) => {
      this.dataSource = response;
      this.dataSourcePersistent = response;
      this.applyFilters();
    });
  }

  /**
   * Aplica los filtros de la búsqueda.
   * @deprecated Este método desaparecerá cuando se implemente la búsqueda por el componente DxDataGrid.
   */
  applyFilters() {
    if (this.search && this.filterOptions) {
      this.dataSource = this.dataSourcePersistent.filter((item) => {
        return item.rol.toLowerCase().includes(this.search.toLowerCase());
      });
      if (this.filterOptions.rol) {
        this.dataSource = this.dataSource.filter((item) => {
          return item.rol
            .toLowerCase()
            .includes(this.filterOptions.rol.toLowerCase());
        });
      }
    } else if (this.search) {
      this.dataSource = this.dataSourcePersistent.filter((item) => {
        return item.rol.toLowerCase().includes(this.search.toLowerCase());
      });
    } else if (this.filterOptions && this.filterOptions.rol) {
      this.dataSource = this.dataSourcePersistent.filter((item) => {
        return item.rol
          .toLowerCase()
          .includes(this.filterOptions.rol.toLowerCase());
      });
    } else {
      this.dataSource = this.dataSourcePersistent;
    }
  }

  /** Obtiene las opciones del menú. */
  getMenuOptions(): MenuOptions[] {
    const menuOptions: MenuOptions[] = [];
    if (this.$ability.can("details", "roles")) {
      menuOptions.push({
        icon: "people",
        text: translateText("roles.rolDetails"),
        to: (item) => ({ name: "DetailsRoles", params: { rol: item.rol } }),
        allowed: this.$ability.can("details", "roles"),
      });
    }
    return menuOptions;
  }
}
