<template lang="html" src="./InstallationsStatusChip.html"></template>

<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator";
import i18n from "@/lang/i18n";

export const getInstallationStatusChipText = (item) => {
  let status = "";

  status =
    item.status === "CONNECTED"
      ? String(i18n.t("general.online"))
      : item.status === "WARNING"
      ? String(i18n.t("installation.warning"))
      : String(i18n.t("general.offline"));

  return status;
};

@Component
export default class InstallationStatusChip extends Vue {
  @Prop(String) value!: string;
}
</script>