import JobService from "../services/JobService";

export default class JobController {
  protected service: JobService;

  constructor() {
    this.service = new JobService();
  }

  /**  Cuando un trabajo está en "Scheduled" y se cumple la fecha de inicio, se cambia a "In Progress". */
  public async calculateScheduled(): Promise<void> {
    return this.service.calculateScheduled();
  }
}
