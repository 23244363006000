import Api from "@/api";

const SERVICE_PATH = "/taboo/api/v1";

const urlToBlacklist = (filterOptions, pagination = null, search = null, column = null) => {
  const sortDirection = pagination && pagination.descending ? ",desc" : ",asc";

  let urlBlacklist = `${SERVICE_PATH}/ban/list?`;

  if (search) {
    urlBlacklist += "ip=" + escape(search) + "&";
  }

  if (pagination) {
    urlBlacklist += "page=" + (pagination.page-1) + "&size=" + pagination.rowsPerPage + "&";
  }

  if (column) {
    urlBlacklist += "sort=" + column + sortDirection + "&";
  }

  if (filterOptions.alsoExpired === true) {
    urlBlacklist += "expired=true" + "&";  
  }

  return urlBlacklist.slice(0, urlBlacklist.length - 1);
};

export default {
  
  getBlacklist: (filterOptions, pagination = null, search = null, column = null, axiosSource = null) => {
    const url = urlToBlacklist(filterOptions, pagination, search, column);
    return Api(true).get(url, { cancelToken: axiosSource ? axiosSource.token : null })
  },

  getCountActiveBans: () => Api(true).get(`${SERVICE_PATH}/ban/count`),

  banIP: (ip, expires_in) => Api(true).post(`${SERVICE_PATH}/ban?ip=${ip}&expires_in=${expires_in}`),

  unbanIP: (ip) => Api(true).delete(`${SERVICE_PATH}/ban?ip=${ip}`),

  isBanned: (ip) => Api(true).get(`${SERVICE_PATH}/ban?ip=${ip}`),
}