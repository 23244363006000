// Cada entrada del menú puede tener:
//   title: key para obtener el texto a mostrar con el vue-i18n
//   icon: icono a mostrar
//   to: name de la ruta

const menu = [
  {
    title: "menu.dashboard",
    icon: "dashboard",
    to: "dashboard",
  },
  {
    title: "menu.plans",
    icon: "store",
    to: "plans",
  },
  {
    title: "menu.installations",
    icon: "device_hub",
    to: "installations",
  },
  {
    title: "menu.devices",
    icon: "devices",
    to: "devices",
  },
  {
    title: "menu.users",
    icon: "people",
    to: "users",
  },
  /* {
    title: "menu.billing", 
    icon: "insert_drive_file", 
    to: "billing" 
  }, */
  {
    title: "menu.jobs",
    icon: "work",
    to: "jobs",
  },
  {
    title: "menu.firmware",
    icon: "inbox",
    to: "firmwares",
  },
  {
    title: "menu.oauthclients",
    icon: "apps",
    to: "oauthclients",
  },
  {
    title: "menu.internalUsers",
    icon: "business_center",
    to: "internalusers",
  },
  {
    title: "menu.titleRoles",
    icon: "security",
    to: "roles",
  },
  /*{ 
    title: "menu.titleTaboo", 
    icon: "vpn_lock", 
    to: "taboo"
  }*/
];
export default menu;
