<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on }">
      <v-btn
        text
        icon
        color="primary"
        class="ma-0 copy-button-right"
        v-clipboard:copy="value"
        v-clipboard:success="onCopyClipboard"
        v-clipboard:error="onErrorCopyClipboard"
        v-on="on"
      >
        <v-icon small>file_copy</v-icon>
      </v-btn>
    </template>
    <span>{{ $t("general.messages.copyClipboard") }}</span>
  </v-tooltip>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class AppCopyButton extends Vue {
  @Prop(String) value!: string;

  onCopyClipboard() {
    this.$store.dispatch("snackbarInfo", {
      text: this.$t("general.messages.copyClipboardSuccess")
    });
  }

  onErrorCopyClipboard() {
    this.$store.dispatch("snackbarError", {
      text: this.$t("general.error.copyClipboardFailed")
    });
  }
}
</script>
<style scoped>
.copy-button-right {
  margin-top: -10px !important;
}
</style>