<template lang="html" src="./DeviceProperties.html"></template>

<!-- eslint-disable @typescript-eslint/no-explicit-any -->
<script lang="ts">
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import AppListMenu from "@/components/AppListMenu.vue";

import DevicesPropertiesItem from "./DevicesPropertiesItem/DevicesPropertiesItem.vue";
import deviceService from "@/api/device";
import { displayErrorMessage } from "@/api";
import StatusChip from "@/components/StatusChip/StatusChip.vue";
import i18n from "@/lang/i18n";

import moment from "moment";
import DeviceEntity from "@/domain/entities/DeviceEntity";

export const sortByKey = (reported) => {
  if (!reported) return {};

  const orderedReported = {};

  Object.keys(reported)
    .sort()
    .forEach((key) => {
      orderedReported[key] = reported[key];
    });

  return orderedReported;
};

@Component({
  components: {
    AppListMenu,
    DevicesPropertiesItem,
    StatusChip,
  },
})
export default class DeviceProperties extends Vue {
  @Prop(Boolean) value!: boolean;
  @Prop(String) deviceId!: string;

  $vuetify!: any;
  v!: any; // TODO: Couldn't find it before TS, uknown usage. Check this.

  ICONS_PATH = process.env.VUE_APP_PUBLIC_PATH + "img/devices_icons";
  zones = (moment as any).tz.names();
  isLoading = false;
  isLoadingState = false;
  search: any = null;
  selected = [];
  // Dialog to create a job
  updateVersionDialog = false;
  // Map in fullscreen mode
  fullScreenMarkerMap = false;
  // Image for the monitor
  imgURL = "";
  // Array of pairings with user info
  userPairedWithDevice = null;
  // Loading bar for the users data table
  loadingData = false;
  imgHasError = false;
  isRefreshing = false;
  loadingSend = false;
  timeout = null;
  loadingProperties = false;
  dialogTime = false;
  doormaticTimeToSend = "00:00:00";
  doormaticKey = 0;
  doormaticKey2 = 0;
  timeout_zone: number | null = null;
  questionSrc =
    process.env.VUE_APP_PUBLIC_PATH + "assets/devices/" + "unknown.jpg";
  showDeviceProperties = false;
  showChangeTimezone = false;
  showConfirmSimTestExecute = false;
  result = false;
  dialogProperties = false;
  isExecutingSimTest = false;
  showConfirmSimChangeState = false;
  dndC = 0;
  isChangingSimState = false;
  editDialog = false;
  tab: number | null = null;
  deviceDetailsTableProps = {
    cols: 12,
    sm: 6,
    md: 4,
    lg: 3,
  };
  showConfirmResetTrial = false;
  isResettingTrial = false;
  maxMinutes = 59;
  maxSeconds = 59;
  deviceDetail!: any;
  colors!: any;

  $refs!: {
    pickerref: any;
  };

  get localIsVisible() {
    return this.value;
  }

  set localIsVisible(localValue) {
    this.$emit("input", localValue);
  }

  get menuOptions() {
    return [
      {
        icon: "network_check",
        text:
          this.$t("deviceDetails.uploadProperties") +
          this.i18nDetails(this.devicesDetails?.tags?.Family),
        action: () => this.updateState(),
        allowed: this.$ability.can("network", "devices"),
      },
      {
        icon: "refresh",
        text: this.$t("deviceDetails.refreshProperties"),
        action: () => this.getProperties(),
        allowed: this.$ability.can("property", "devices"),
      },
    ];
  }

  get wifiSignal() {
    return parseInt(this.devicesDetails.reported.wireless_signal);
  }

  get devicesDetails() {
    return this.$store.getters["devices/oneDeviceData"];
  }

  get deviceCacheDetails() {
    return this.$store.getters["devices/oneDeviceCacheData"];
  }

  get properties() {
    const storeProperties = this.$store.getters["devices/oneDeviceData"];

    let properties: any = {};
    properties.general = {};

    // main properties to general block
    for (const index in storeProperties) {
      const deviceItem = storeProperties[index];

      if (typeof deviceItem === "object") {
        const sectionName = index;

        properties[sectionName] = deviceItem;
      } else properties.general[index] = deviceItem;
    }

    // search
    if (this.search) {
      // check if search is in a property
      const isFounded = (property) =>
        new RegExp(this.search.trim().toUpperCase()).test(
          property.toString().toUpperCase()
        );

      // search algorythm
      const searchValue = (key, value, arr) => {
        // the search is found on key or property is a simple "key value"
        if (isFounded(key) || (typeof value !== "object" && isFounded(value))) {
          arr[key] = value;
        } else if (typeof value === "object") {
          // value is a collect of "key value"
          const tmpProperties = {};

          for (const index in value) {
            const property = value[index];

            searchValue(index, property, tmpProperties);
          }

          if (Object.entries(tmpProperties).length > 0)
            arr[key] = tmpProperties;
        }

        return arr;
      };

      const filteredProperties = {};
      for (const index in properties) {
        const deviceItem = properties[index];

        if (isFounded(this.$t("deviceDetails.propertiesBlock." + index)))
          filteredProperties[index] = deviceItem;
        else searchValue(index, deviceItem, filteredProperties);
      }

      properties = filteredProperties;
    }

    return storeProperties ? properties : null;
  }

  get propertiesAreEmpty() {
    return Object.entries(this.properties).length === 0;
  }

  get isMonitor() {
    return (
      this.devicesDetails &&
      this.devicesDetails.tags &&
      this.devicesDetails.tags.Family === "MONITOR"
    );
  }

  get isEdibox() {
    return (
      this.devicesDetails &&
      this.devicesDetails.tags &&
      this.devicesDetails.tags.Family === "EDIBOX"
    );
  }

  get isEdiboxM2() {
    return (
      this.devicesDetails &&
      this.devicesDetails.tags &&
      this.devicesDetails.tags.Family === "EDIBOX" &&
      this.devicesDetails.tags.Type === "BOXM2"
    );
  }

  get ediboxM2Online() {
    return (
      this.devicesDetails &&
      this.devicesDetails.tags &&
      this.devicesDetails.tags.Family === "EDIBOX" &&
      this.devicesDetails.connected === true
    );
  }

  get isGU() {
    return (
      this.devicesDetails &&
      this.devicesDetails.tags &&
      this.devicesDetails.tags.Family === "GUARDUNIT"
    );
  }

  get isThereStats() {
    return (
      this.devicesDetails &&
      this.devicesDetails.tags &&
      (this.devicesDetails.tags.Family === "MONITOR" ||
        this.devicesDetails.tags.Family === "PANEL") &&
      this.devicesDetails.reported.stats
    );
  }

  get isPanel() {
    return (
      this.devicesDetails &&
      this.devicesDetails.tags &&
      this.devicesDetails.tags.Family === "PANEL"
    );
  }

  get signalWifi() {
    return (
      this.devicesDetails &&
      this.devicesDetails.reported &&
      this.devicesDetails.reported.ssid
    );
  }

  get signalNetwork() {
    return (
      this.devicesDetails &&
      this.devicesDetails.reported &&
      this.devicesDetails.reported.modem_parameters &&
      this.devicesDetails.reported.modem_parameters.signal >= 0
    );
  }

  get levelSignalNetwork() {
    return this.devicesDetails.reported.modem_parameters.signal;
  }

  get hasGUGeneral() {
    if (this.isMonitor) {
      return (
        this.devicesDetails.reported.gu_ge_address != null &&
        this.devicesDetails.reported.gu_ge_address != "none"
      );
    } else if (this.isGU) {
      return this.devicesDetails.reported.num_block == 100;
    }
    return false;
  }

  get hasGUBlock() {
    if (this.isMonitor) {
      return (
        this.devicesDetails.reported.gu_block_address != null &&
        this.devicesDetails.reported.gu_block_address != "none"
      );
    } else if (this.isGU) {
      return this.devicesDetails.reported.num_block < 100;
    }
    return false;
  }

  get geGUAddress() {
    if (this.isMonitor) {
      return this.devicesDetails.reported.gu_ge_address;
    } else if (this.isGU) {
      return this.devicesDetails.reported.address;
    }
    return null;
  }

  get blockGUAddress() {
    if (this.isMonitor) {
      return this.devicesDetails.reported.gu_block_address;
    } else if (this.isGU) {
      return this.devicesDetails.reported.address;
    }
    return null;
  }

  get deviceHasTags() {
    return this.devicesDetails && this.devicesDetails.tags;
  }

  get timeZone() {
    return this.devicesDetails.reported.time_zone;
  }

  set timeZone(v) {
    this.v = v;
  }

  get volumeDownValue() {
    return this.devicesDetails.reported.volume_down;
  }

  set volumeDownValue(v) {
    this.devicesDetails.reported.volume_down = v;
  }

  get volumeUpValue() {
    return this.devicesDetails.reported.volume_up;
  }

  set volumeUpValue(v) {
    this.devicesDetails.reported.volume_up = v;
  }

  /**
   * Deshabilita el botón "Doormatic".
   * Para que el botón "Doormatic" esté habilitado
   * es necesario:
   * - Tener permisos.
   * - Que el dispositivo esté conectado.
   * - Que el dispositivo sea VEO-XS o VEO-XL.
   */
  get doormaticSwitchDisabled(): boolean {
    if (!this.$ability.can("stats", "devices")) {
      // Si no tiene permisos.
      return true;
    }

    const device = this.devicesDetails;
    if (!device.connected || !device.connectable) {
      // Si el dispositivo no está conectado.
      return true;
    }

    const type = this.devicesDetails.tags.Type;
    if (!(type === "VEO-XS" || type === "VEO-XL")) {
      // Si no es un dispositivo VEO-XS o VEO-XL.
      return true;
    }

    return false;
  }

  get doormaticActivated() {
    return (
      this.devicesDetails.reported.doormatic_mode == "on" &&
      this.devicesDetails.reported.doormatic_time !== "00:00:00"
    );
  }

  set doormaticActivated(doormaticValue) {
    if (doormaticValue == true) {
      this.doormaticTime = "00:00";
      this.dialogTime = true;
    } else {
      this.doormaticTime = "00:00";
      this.insertNewProperties("doormatic_time", this.doormaticTimeToSend);
      this.cancelTime();
    }
    this.doormaticKey2++;
  }

  get doormaticTime() {
    if (this.devicesDetails.reported.doormatic_time)
      return this.devicesDetails.reported.doormatic_time.substr(
        0,
        this.devicesDetails.reported.doormatic_time.lastIndexOf(":")
      );
    else return "00:00";
  }

  set doormaticTime(time) {
    if ((time.match(/:/g) || []).length == 1)
      this.doormaticTimeToSend = time + ":00";
    else this.doormaticTimeToSend = time;
  }

  /**
   * Cuando son PLACAS 4G, este es el listado de los idiomas.
   * Este campo está deshabilitado.
   */
  get itemsLanguage(): string[] {
    if (this.devicesDetails.reported.language === undefined) {
      return [];
    }
    return [this.devicesDetails.reported.language.display];
  }

  /** Cuando son PLACAS 4G, este es el valor del listado de los idiomas. */
  get valueLanguage(): string | null {
    if (this.devicesDetails.reported.language === undefined) {
      return null;
    }
    return this.devicesDetails.reported.language.display;
  }

  @Watch("localIsVisible")
  onChangeLocalIsVisible() {
    if (this.localIsVisible && !this.isLoading) {
      this.$store.commit("devices/setOneDeviceData", null);
      this.getDeviceInfo();
      this.getProperties();
    }

    this.changeOverflow();
  }

  beforeCreate() {
    this.$store.commit("devices/setOneDeviceData", null);
  }

  updated() {
    this.changeOverflow();
  }

  allowedHours = (v) => v <= 12;

  allowedMinutes(v) {
    return v <= this.maxMinutes;
  }

  imgSignalValue(signalValue) {
    if (!this.isPanel) {
      let value = signalValue > 4 ? 4 : signalValue;
      return this.ICONS_PATH + "/icon_wifi" + value + ".svg";
    } else {
      return this.ICONS_PATH + "/signal4g.svg";
    }
  }

  i18nStats(stat) {
    return i18n.t("device.properties." + stat);
  }

  isGeGUMode(mode) {
    if (this.isMonitor) {
      return (
        this.devicesDetails && this.devicesDetails.reported.gu_ge_mode === mode
      );
    } else if (this.isGU) {
      return this.devicesDetails.reported.gu_mode === mode;
    }
    return false;
  }

  isBlockGUMode(mode) {
    if (this.isMonitor) {
      return (
        this.devicesDetails &&
        this.devicesDetails.reported.gu_block_mode === mode
      );
    } else if (this.isGU) {
      return this.devicesDetails.reported.gu_mode === mode;
    }
    return false;
  }

  getProperties() {
    this.$store
      .dispatch("devices/getOneDeviceData", {
        deviceId: this.deviceId,
      })
      .then(() => (this.isLoading = false));
  }

  closePicker(v) {
    if (v === 12) {
      this.maxMinutes = 0;
      this.maxSeconds = 0;
    } else {
      this.maxMinutes = 59;
      this.maxSeconds = 59;
    }
  }

  buttonProperties() {
    this.dialogProperties = true;
  }

  genColor(i) {
    return this.colors[i];
  }

  prepareToEdit(propertiesDevices) {
    this.showDeviceProperties = true;
    this.deviceDetail = propertiesDevices;
  }

  changeOverflow() {
    const HTML: any = document.querySelector("html");
    HTML.style.overflowY =
      this.localIsVisible && !this.$vuetify.breakpoint.smAndUp
        ? "hidden"
        : "scroll";
  }

  cancelEdit() {
    this.localIsVisible = false;
    this.tab = 0;
  }

  insertNewProperties(property, value) {
    const properties = {
      method: "changeproperty",
      payload: {
        property: property,
        value: value,
      },
    };
    // To update the properties in edit
    this.loadingProperties = true;
    return deviceService
      .sendProperties(this.deviceId, properties)
      .then(() => {
        this.loadingProperties = false;
        if (property == "doormatic_mode" && value == "off") {
          this.doormaticTime = "00:00";
        }
        this.$store.dispatch("snackbarInfo", {
          text: this.$t("deviceDetails.alertPropertiesIot"),
        });
      })

      .catch((err) => {
        this.devicesDetails.reported[property] =
          this.devicesDetails.reported[property] == "on" ? "off" : "on";
        this.loadingProperties = false;
        displayErrorMessage(err, {
          general: ["deviceDetails.changeTimezone.error.fail"],
        });
      });
  }

  async cancelTime() {
    await this.getProperties();
    this.$refs.pickerref.selectingHour = true;
    this.doormaticKey++;
  }

  insertTimeZone(value) {
    if (this.timeout_zone) clearTimeout(this.timeout_zone);
    this.loadingProperties = true;
    this.timeout_zone = window.setTimeout(() => {
      if (value) {
        deviceService
          .changeDeviceTimezone(this.deviceId, value)
          .then(() => {
            this.loadingProperties = false;
            this.$store.dispatch("snackbarInfo", {
              text: this.$t(
                "deviceDetails.changeTimezone.tzChangedSuccesfully"
              ),
            });
          })
          .catch((error) => {
            this.loadingProperties = false;
            displayErrorMessage(error, {
              general: ["deviceDetails.changeTimezone.error.save"],
            });
          });
      } else {
        this.loadingProperties = false;
      }
    }, 1000);
  }

  updateState() {
    this.isLoadingState = true;
    deviceService
      .updateState(this.deviceId)
      .then(() => {
        this.isLoadingState = false;
        this.$store.dispatch("snackbarInfo", {
          active: true,
          text: this.$t("deviceDetails.alertProperties"),
        });
        this.getDeviceInfo();
      })
      .catch((err) => {
        this.isLoadingState = false;
        displayErrorMessage(err, {
          general: ["deviceDetails.changeTimezone.error.fail"],
        });
      });
  }

  getDeviceInfo() {
    this.isLoading = true;

    // Retrieve the device information from the regular device service
    return this.$store
      .dispatch("devices/getOneDeviceData", {
        deviceId: this.deviceId,
      })
      .then(() => (this.isLoading = false));
  }

  getSectionIcon(section) {
    let icon: string | null = null;

    switch (section) {
      case "general":
        icon = "description";
        break;
      case "tags":
        icon = "local_offer";
        break;
      case "reported":
        icon = "assignment_late";
        break;
    }

    return icon;
  }

  resetDoormaticTime() {
    this.cancelTime();
    this.dialogTime = false;
  }

  async sendDoormaticTime() {
    this.dialogTime = false;
    if (
      this.devicesDetails.reported.doormatic_mode == undefined ||
      this.devicesDetails.reported.doormatic_mode == "off"
    ) {
      await this.insertNewProperties("doormatic_mode", "on");
    }
    await this.insertNewProperties("doormatic_time", this.doormaticTimeToSend);
    await this.cancelTime();
  }

  sortByKey = sortByKey;

  volumeUpClick() {
    this.updateVolume(
      this.deviceId,
      "volume_up",
      this.devicesDetails.reported.volume_up
    );
  }

  volumeDownClick() {
    this.updateVolume(
      this.deviceId,
      "volume_down",
      this.devicesDetails.reported.volume_down
    );
  }

  updateVolume(deviceId: any, type: any, value: any) {
    deviceService
      .updateVolume(deviceId, type, value)
      .then(() => {
        this.isLoadingState = false;
        this.$store.dispatch("snackbarInfo", {
          active: true,
          text: this.$t("deviceDetails.alertPropertiesIot"),
        });
      })
      .catch((err) => {
        this.isLoadingState = false;
        displayErrorMessage(err, {
          general: ["deviceDetails.changeTimezone.error.fail"],
        });
      });
  }

  i18nDetails(stat) {
    return i18n
      .t("device.deviceDetails." + stat)
      .toString()
      .toLowerCase();
  }

  getDeviceEntity(): DeviceEntity {
    const device: DeviceEntity = new DeviceEntity();
    device.family = this.deviceCacheDetails.family;
    device.type = this.deviceCacheDetails.type;
    device.subtype = this.deviceCacheDetails.subtype;
    device.videoQuality = this.deviceCacheDetails.videoQuality;
    return device;
  }

  showVideoQuality(): boolean {
    return this.getDeviceEntity().showVideoQuality();
  }

  isVideoQualityEstandar(): boolean {
    return this.getDeviceEntity().isVideoQualityEstandar();
  }

  isVideoQualityPremium(): boolean {
    return this.getDeviceEntity().isVideoQualityPremium();
  }

  minValue() {
    return this.isGU ? 1 : 1;
  }
  maxValue() {
    return this.isGU ? 100 : 10;
  }
}
</script>
<style>
.timeTitle {
  background-color: #0d47a1;
  color: #ffff;
  text-align: center;
}
.v-input--switch > .v-input__control {
  flex-grow: 0;
  width: auto;
}

.properties-container
  > .v-list-group--active
  > .v-list-group__header.v-list-item--active
  > .v-list-item
  .v-list-item__title:not(.prop-key) {
  color: #1976d2;
}
.properties-empty {
  color: #777;
  padding-left: 60px;
}
.card_stats_disabled {
  height: 110px !important;
  width: 257px !important;
}
.sty {
  color: #0d47a1 !important;
}
.card_stats {
  background-color: #f2f2f2 !important;
}

.DeviceProperties .v-image.v-responsive {
  width: 30px;
  height: 30px;
}
.DeviceProperties .v-image.v-responsive.h130 {
  height: 130px;
  width: auto;
}
</style>
