<template lang="html" src="./FullScreenMap.html"></template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class FullScreenMap extends Vue {
  @Prop({type: Boolean, default: false}) value!: boolean;
  @Prop(String) title!: string;


  hideDialog() {
    this.localValue = false;
  }

  get localValue() {
    return this.value;
  }

  set localValue(localValue) {
    this.$emit("input", localValue);
  }
}
</script>

<style>
.content-marker > div{
  height: calc(100vh - 65px);
}
</style>