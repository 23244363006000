import { displayErrorMessage } from "@/api";

import clientService from "@/api/client";

export const getClientsList = ({ commit }) => new Promise((resolve, reject) => {
  clientService.getClients().then(res => {
    const clientsList = res.data;
    const totalClients = clientsList.length;

    // function call to sort the clients list
    tableItemsSort(clientsList);

    // set the options to filter the data
    const nameOptions = [...new Set(clientsList.map(item => item.name))];
    const idOptions = [...new Set(clientsList.map(item => item.clientId))];

    const accessOptions = [
      ...new Set(clientsList.map(item => item.accessTokenValiditySeconds))
    ];

    // function for filter by roles
    const rolesArray = [];
    const rolOptionsAux = [...new Set(clientsList.map(item => item.roles))];
    rolOptionsAux.forEach(roles =>
      roles.forEach(item => rolesArray.push(item.name))
    );

    const rolOptions = [...new Set(rolesArray)];

    // function for filter by scopes
    const scopesArray = [];
    const scopeOptionsAux = [
      ...new Set(clientsList.map(item => item.scopes))
    ];
    scopeOptionsAux.forEach(scopes =>
      scopes.forEach(item => scopesArray.push(item.name))
    );

    const scopeOptions = [...new Set(scopesArray)];

    // function for filter by grantTypes
    const typesArray = [];
    const typeOptionsAux = [
      ...new Set(clientsList.map(item => item.grantTypes))
    ];
    typeOptionsAux.forEach(types =>
      types.forEach(item => typesArray.push(item.name))
    );

    const typeOptions = [...new Set(typesArray)];

    // function for filter by the status
    const disabledOptions = [
      ...new Set(clientsList.map(item => item.enable))
    ];

    const clientsInfo = {
      totalClients: totalClients,
      nameOptions: nameOptions,
      idOptions: idOptions,
      rolOptions: rolOptions,
      scopeOptions: scopeOptions,
      typeOptions: typeOptions,
      accessOptions: accessOptions,
      disabledOptions: disabledOptions,
      list: clientsList
    };

    // llamamos a las variables creadas
    commit("setClientsInfo", clientsInfo);

    resolve(clientsInfo);
  }).catch(err => {
    reject(err);
    displayErrorMessage(err, { general: ["oauthclients.error.getError"] });
  });
});

function tableItemsSort(clientsList) {
  clientsList.forEach(item => {
    item.roles.sort(sortByName);
    item.scopes.sort(sortByName);
    item.grantTypes.sort(sortByName);
  });
}

function sortByName(a, b) {
  const textA = a.name.toUpperCase();
  const textB = b.name.toUpperCase();
  return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
}
