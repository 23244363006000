<template lang="html" src="./DeviceTabItem.html"></template>

<!-- eslint-disable @typescript-eslint/ban-types -->
<!-- eslint-disable @typescript-eslint/no-explicit-any -->
<!-- eslint-disable @typescript-eslint/no-unused-vars -->
<script lang="ts">
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import DevicesFilter, {
  FILTER_DEFINITIONS,
} from "./DevicesFilter/DevicesFilter.vue";

import FilterTableHeader from "@/components/Table/FilterTableHeader/FilterTableHeader.vue";
import ExportTableDataButton from "@/components/Table/ExportTableDataButton/ExportTableDataButton.vue";
import { PREDEFINED_STYLES } from "@/mixins/table-export.mixin";

import { displayErrorMessage } from "@/api";
import deviceService from "@/api/device";
import ApiMonitor from "@/api/monitor";

import FullScreenMap from "@/components/FullScreenMap/FullScreenMap.vue";
import MarkerMap from "@/components/MarkerMap/MarkerMap.vue";
import MapFlet from "@/components/MapFlet/MapFlet.vue";

import AppListMenu, {
  hasAllowedMenuOptions,
} from "@/components/AppListMenu.vue";

import StatusChip, {
  getStatusChipText,
} from "@/components/StatusChip/StatusChip.vue";

import { TAB_TYPE } from "../Devices.vue";

import dateMixin from "@/mixins/date.mixin";
import markerMixin from "@/mixins/marker.mixin";
import countriesMixin from "@/mixins/countries.mixin";
import filterMixin from "@/mixins/filter.mixin";

import tableMarkersMapMixin from "@/mixins/table-markers-map.mixin";

import tableBackMixin from "@/mixins/table.mixin/back";
import fullDataLoaderMixin from "@/mixins/full-data-loader.mixin";

import DeviceProperties from "../DeviceProperties/DeviceProperties.vue";

import QRCode from "@/components/QRCode.vue";

import DerviceTable from "./DerviceTable/DerviceTable.vue";
import DeviceEntity from "@/domain/entities/DeviceEntity";

// Keys para persistir los valores en la sessionStorage con el objetivo de mantenerlo tras un refresco de la página
const keyView = "Devices";
//const keySearch = keyView + ".search.";
const keyViewMap = keyView + ".viewMap.";
const keyFilterOptions = keyView + ".filterOptions.";
const QR_CODE_PATH =
  "https://www.opendit.com/es/connect?utm_source=fermax-phone&utm_medium=sticker&uuid=";

export const getMarkerIcon = (device) => {
  let type = "marker-icon";
  let color = "grey";

  if (device.family === "MONITOR" && device.type) type = device.type;

  if (
    device.family === "PANEL" ||
    (device.family === "GUARDUNIT" && device.type)
  )
    type = device.family;

  if (device.family === "PHONE" && device.type)
    type = device.family + "_" + device.type;

  if (device.status) color = "green";

  return type + "_" + color + ".png";
};

export const getMarkerIconNewMap = (device) => {
  let type = "marker-icon";
  let color = "grey";

  if (device.f === "MONITOR" && device.t) type = device.t;

  if (device.f === "PANEL" || (device.f === "GUARDUNIT" && device.t))
    type = device.f;

  if (device.f === "PHONE" && device.t) type = device.f + "_" + device.t;

  if (device.sts) color = "green";

  return type + "_" + color + ".png";
};

@Component({
  components: {
    AppListMenu,
    DevicesFilter,
    FullScreenMap,
    MarkerMap,
    StatusChip,
    FilterTableHeader,
    ExportTableDataButton,
    DeviceProperties,
    MapFlet,
    QRCode,
    DerviceTable,
  },
  mixins: [
    tableBackMixin,
    tableMarkersMapMixin,
    dateMixin,
    markerMixin,
    countriesMixin,
    filterMixin,
    fullDataLoaderMixin,
  ],
})
export default class DeviceTabItem extends Vue {
  @Prop() tabIndex!: number;

  resetFullDataState!: Function;
  loadFullData!: Function;
  newFullDataState!: Function;
  saveFilters!: Function;
  transformTablePropsForServerRequest!: Function;
  isNotAxiosCancellation!: Function;
  isHeatMap!: Function;
  configureAxiosCancelToken!: Function;
  session_getItem!: Function;
  session_setItem!: Function;
  session_setItemObject!: Function;
  checkHasAnyActiveFilter!: Function;
  geoTableColumns!: Function;
  $moment!: any;
  viewMap: any;
  search!: any;
  filterOptions_!: any;
  filterOptions!: any;
  loadingData!: boolean;
  totalServerItems!: number;
  toggleView!: any;
  axiosSource!: any;

  @Prop(String) type!: string;
  /*@Watch("type")
  onChangeType() {
    this.keySearch = keyView + ".search." + this.type;
  }*/
  constructor() {
    super();
    this.keySearch = keyView + ".search." + this.type;
  }

  TAB_TYPE = TAB_TYPE;
  keySearch = keyView + ".search." + this.type;
  keyFilterOptions = keyFilterOptions + this.type;
  tableProps: any = {
    sortBy: ["serialNumber"],
  };
  dialog = false;
  selectionOptions = {
    family: [],
    type: [],
    subtype: [],
    status: ["Online", "Offline", "Manufactured"],
  };
  devices = [];
  fwVersionSelected = false;
  datePickermodal = false;
  datePicked: any = null;
  timePicked: any = null;
  validForm = false;
  fullScreenMarkerMap = false;
  selectedMonitor = {};
  disabledFilters = false;
  expansionPanel: any = null;
  devicePropertiesId: any = null;
  isShowDeviceProperties = false;
  loadingDeviceProperties = false;
  mapMarkerInfo = {
    getName: (o) => o.serialNumber,
    getIcon: getMarkerIcon,
    getIconNewMap: getMarkerIconNewMap,
    isOnline: (o) => o.status === true,
  };

  $refs!: {
    monitorsMap: any;
    deviceFilters: any;
    mapFlet: any;
  };
  searchTable: string | null = null;

  titleList = {
    all: this.$t("device.allList"),
    edibox: this.$t("device.ediboxList"),
    panels4g: this.$t("device.panels4gList"),
    guard: this.$t("device.guard"),
    phones: this.$t("device.phones"),
    monnowifi: this.$t("device.monnowifiList"),
    monwifi: this.$t("device.monwifiList"),
  };

  titleMap = {
    panels4g: this.$t("device.panelMap"),
    phones: this.$t("device.phoneMap"),
    guard: this.$t("device.guardMap"),
    monnowifi: this.$t("device.monnowifiMap"),
    monwifi: this.$t("device.monwifiMap"),
  };

  showQrCode = false;
  url = "https://www.fermax.com/blue?type=NO_WIFI&uuid=";

  get menuOptions() {
    return [
      {
        icon: "list_alt",
        text: this.$t("menu.deviceDetails"),
        to: (item) => ({
          name: "DeviceDetails",
          params: { deviceId: item.serialNumber },
        }),
        allowed: this.$ability.can("details", "devices"),
      },
      {
        icon: "dns",
        text: this.$t("deviceDetails.showProperties"),
        action: (item) => this.showDeviceProperties(item.serialNumber),
        allowed: this.$ability.can("property", "devices"),
      },
      {
        icon: "place",
        text: this.$t("device.viewOnMap"),
        action: (item) => this.openMarkerMap(item),
        allowed: this.$ability.can("locate", "devices"),
        allowedForItem: (item) => item.deployed,
      },
      {
        icon: "refresh",
        text: this.$t("deviceDetails.refreshView"),
        action: (item) => this.refreshDeviceDetails([item]),
        allowed: this.$ability.can("refresh", "devices"),
      },
      {
        icon: "fa-qrcode",
        text: this.$t("deviceDetails.generateQR"),
        action: (item) => this.qrCode(item),
        allowed: this.$ability.can("qrCode", "devices"),
        allowedForItem: (item) => {
          const deviceEntity = new DeviceEntity();
          deviceEntity.family = item.family;
          deviceEntity.type = item.type;
          return deviceEntity.canGenerateQrCode();
        },
      },
    ];
  }

  qrCode(item: any) {
    this.url = QR_CODE_PATH + item.serialNumber;
    this.showQrCode = true;
  }

  get menuOptionsAllowed() {
    return hasAllowedMenuOptions(this.menuOptions);
  }

  get hasMapLists() {
    return (
      this.type === TAB_TYPE.WIFI_MONITORS ||
      this.type === TAB_TYPE.PANELS_4G ||
      this.type === TAB_TYPE.NOWIFI_MONITORS ||
      this.type === TAB_TYPE.PHONES ||
      this.type === TAB_TYPE.GUARD
    );
  }

  get tableTitle() {
    return this.viewMap ? this.titleMap[this.type] : this.titleList[this.type];
  }

  get exportName() {
    return this.type === TAB_TYPE.ALL_DEVICES
      ? this.$t("device.devices")
      : this.$t("device.tab." + this.type);
  }

  get headers() {
    let headers: any = [
      {
        text: this.$t("device.serialNumber"),
        value: "serialNumber",
        sortable: true,
        export: {
          width: 80,
        },
      },
      {
        text: this.$t("device.status"),
        value: "status",
        sortable: true,
        export: {
          width: 37,
          value: (row) => this.$t("general." + getStatusChipText(row)),
          style: (row) => {
            const styles = {
              online: PREDEFINED_STYLES.SUCCESS,
              offline: PREDEFINED_STYLES.DARK_GREY,
              manufactured: PREDEFINED_STYLES.GREY,
            };

            return styles[getStatusChipText(row)];
          },
        },
      },
    ];

    if (this.type === TAB_TYPE.ALL_DEVICES)
      headers.push({
        text: this.$t("device.family"),
        value: "family",
        sortable: true,
        export: {
          value: (row) =>
            this.$te("device.deviceDetails." + row.family)
              ? this.$t("device.deviceDetails." + row.family)
              : row.family,
        },
      });

    headers.push(
      {
        text: this.$t("device.type"),
        value: "typeAndSubtype",
        sortable: true,
      },
      {
        text: this.$t("device.productionDate"),
        value: "manufacturingDate",
        sortable: true,
        export: {
          format: "datetime",
          unix: true,
        },
      },
      {
        text: this.$t("general.hardware"),
        value: "versionHW",
        sortable: true,
      },
      {
        text: this.$t("general.version"),
        value: "version",
        sortable: true,
      },
      /*   {
        text: this.$t("general.build"),
        value: "build",
        sortable: true
      }, */
      {
        text: this.$t("device.ol"),
        value: "OL",
        sortable: true,
        export: {
          value: (row) => row.ol,
        },
      }
    );

    if (this.type === TAB_TYPE.PANELS_4G || this.type === TAB_TYPE.EDIBOX)
      headers.push(
        {
          text: this.$t("deviceDetails.installation"),
          value: "installationId",
          sortable: true,
        },
        {
          text: this.$t("device.properties.signal_wifi"),
          value: "wirelessSignal",
          sortable: true,
        },
        {
          text: this.$t("deviceDetails.iccid"),
          value: "iccid",
          sortable: true,
        },
        {
          text: this.$t("deviceDetails.imei"),
          value: "imei",
          sortable: true,
        },
        {
          text: this.$t("deviceDetails.simStatus"),
          value: "simStatus",
          sortable: true,
        },
        {
          text: this.$t("deviceDetails.simDate"),
          value: "simDateUpdated",
          sortable: true,
          export: {
            width: 50,
            value: (row) =>
              row.simDateUpdated !== ""
                ? this.$moment(row.simDateUpdated).format("DD/MM/YYYY")
                : "-",
          },
        }
      );

    headers = [...headers, ...this.geoTableColumns()];

    if (this.menuOptionsAllowed)
      headers.push({
        text: null,
        value: null,
        sortable: false,
        align: "right",
        sticky: true,
      });

    return headers;
  }

  get headersExport() {
    let headersExport: any = [
      {
        text: this.$t("device.serialNumber"),
        value: "s",
        sortable: true,
        export: {
          width: 80,
        },
      },
      {
        text: this.$t("device.status"),
        value: "sts",
        sortable: true,
        export: {
          width: 37,
          value: (row) => this.$t("general." + getStatusChipText(row)),
          style: (row) => {
            const styles = {
              online: PREDEFINED_STYLES.SUCCESS,
              offline: PREDEFINED_STYLES.DARK_GREY,
              manufactured: PREDEFINED_STYLES.GREY,
            };

            return styles[getStatusChipText(row)];
          },
        },
      },
    ];

    if (this.type === TAB_TYPE.ALL_DEVICES)
      headersExport.push({
        text: this.$t("device.family"),
        value: "f",
        sortable: true,
        export: {
          value: (row) =>
            this.$te("device.deviceDetails." + row.f)
              ? this.$t("device.deviceDetails." + row.f)
              : row.f,
        },
      });

    headersExport.push(
      {
        text: this.$t("device.type"),
        value: "st",
        sortable: true,
        export: {
          value: (row) => row.t + " " + row.st,
        },
      },
      {
        text: this.$t("device.productionDate"),
        value: "md",
        sortable: true,
        export: {
          format: "datetime",
          unix: true,
        },
      },
      {
        text: this.$t("general.version"),
        value: "version",
        sortable: true,
      },
      {
        text: this.$t("general.build"),
        value: "b",
        sortable: true,
      },
      {
        text: this.$t("device.ol"),
        value: "ol",
        sortable: true,
        export: {
          value: (row) => row.ol,
        },
      }
    );

    if (this.type === TAB_TYPE.PANELS_4G || this.type === TAB_TYPE.EDIBOX)
      headersExport.push(
        {
          text: this.$t("deviceDetails.installation"),
          value: "i",
          sortable: true,
        },
        {
          text: this.$t("device.properties.signal_wifi"),
          value: "wrs",
          sortable: true,
        },
        {
          text: this.$t("deviceDetails.iccid"),
          value: "ic",
          sortable: true,
        },
        {
          text: this.$t("deviceDetails.imei"),
          value: "im",
          sortable: true,
        },
        {
          text: this.$t("deviceDetails.simStatus"),
          value: "ssts",
          sortable: true,
        },
        {
          text: this.$t("deviceDetails.simDate"),
          value: "lad",
          sortable: true,
          export: {
            width: 50,
            value: (row) =>
              row.lad !== "" ? this.$moment(row.lad).format("DD/MM/YYYY") : "-",
          },
        }
      );

    headersExport.push(
      {
        text: this.$t("geo.zone"),
        value: "z",
        sortable: true,
      },
      {
        text: this.$t("geo.subzone"),
        value: "sz",
        sortable: true,
        export: {
          width: 80,
        },
      },
      {
        text: this.$t("geo.area"),
        value: "a",
        sortable: true,
        export: {
          width: 80,
        },
      }
    );

    if (this.menuOptionsAllowed)
      headersExport.push({
        text: null,
        value: null,
        sortable: false,
        align: "right",
        sticky: true,
      });

    return headersExport;
  }

  get hasAnyActiveFilter() {
    let filterOptions = { ...this.filterOptions_ };
    if (
      this.type === TAB_TYPE.WIFI_MONITORS ||
      this.type === TAB_TYPE.NOWIFI_MONITORS ||
      this.type === TAB_TYPE.PANELS_4G ||
      this.type === TAB_TYPE.PHONES ||
      this.type === TAB_TYPE.GUARD ||
      this.type === TAB_TYPE.EDIBOX
    ) {
      delete filterOptions.familyFilter;
      delete filterOptions.connectableFilter;
    }
    return this.checkHasAnyActiveFilter(filterOptions);
  }

  get exportAppliedFilters() {
    const filters = { ...this.filterOptions_ };
    delete filters.connectableFilter;
    if (
      this.type === TAB_TYPE.WIFI_MONITORS ||
      this.type === TAB_TYPE.PANELS_4G ||
      this.type === TAB_TYPE.EDIBOX
    )
      delete filters.familyFilter;

    return {
      filters,
      definitions: FILTER_DEFINITIONS,
      search: this.search && this.search.trim() !== "" ? this.search : null,
    };
  }

  getDeviceType() {
    if (this.type === TAB_TYPE.PANELS_4G) {
      return "PANEL";
    } else {
      return "MONITOR";
    }
  }

  getStatusString(status) {
    if (
      status &&
      status != null &&
      status.length > 0 &&
      this.$te("deviceDetails.status." + status)
    ) {
      return this.$t("deviceDetails.status." + status);
    } else return "";
  }

  @Watch("toggleView")
  onChangeToggleView() {
    if (
      this.viewMap === true &&
      this.filterOptions.statusFilter === "Manufactured"
    ) {
      this.filterOptions.statusFilter = null;
      this.filterOptions_.statusFilter = null;

      this.session_setItemObject(
        keyFilterOptions + this.type,
        this.filterOptions
      );
    }
    this.session_setItem(keyViewMap + this.type, this.toggleView);
  }

  created() {
    this.toggleView = +this.session_getItem(keyViewMap + this.type);
    if (this.type === TAB_TYPE.WIFI_MONITORS) {
      this.filterOptions.familyFilter = "MONITOR";
      this.filterOptions.connectableFilter = true;
      this.filterOptions_ = { ...this.filterOptions };
    } else if (this.type === TAB_TYPE.PANELS_4G) {
      this.filterOptions.familyFilter = "PANEL";
      this.filterOptions.connectableFilter = true;
      this.filterOptions_ = { ...this.filterOptions };
    } else if (this.type === TAB_TYPE.NOWIFI_MONITORS) {
      this.filterOptions.familyFilter = "MONITOR";
      this.filterOptions.connectableFilter = false;
      this.filterOptions_ = { ...this.filterOptions };
    } else if (this.type === TAB_TYPE.PHONES) {
      this.filterOptions.familyFilter = "PHONE";
      this.filterOptions_ = { ...this.filterOptions };
    } else if (this.type === TAB_TYPE.GUARD) {
      this.filterOptions.familyFilter = "GUARDUNIT";
      this.filterOptions_ = { ...this.filterOptions };
    } else if (this.type === TAB_TYPE.EDIBOX) {
      this.filterOptions.familyFilter = "EDIBOX";
      this.filterOptions_ = { ...this.filterOptions };
    }
  }

  getList(pagination, connectable = null, axiosSource = null) {
    return deviceService.getDevices(
      this.filterOptions_,
      pagination,
      this.search ? this.search.trim() : null,
      pagination.sortBy,
      connectable || undefined,
      axiosSource
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getListNewMap(pagination, axiosSource = null) {
    let allDevice = false;
    return deviceService.getDevicesMap(
      this.filterOptions_,
      pagination,
      this.search ? this.search.trim() : null,
      pagination.sortBy,
      allDevice,
      axiosSource
    );
  }

  getWithoutPagination(page, size) {
    const pagination = this.transformTablePropsForServerRequest();
    pagination.rowsPerPage = size;
    pagination.page = page;

    return this.getListNewMap(pagination);
  }

  getWithoutPaginationExport(page) {
    const pagination = this.transformTablePropsForServerRequest();
    pagination.rowsPerPage = 2000;
    pagination.page = page;

    return this.getListExport(pagination);
  }

  getListExport(pagination, axiosSource = null) {
    let allDevice = true;
    this.filterOptions_.deployed = false;
    return deviceService.getDevicesMap(
      this.filterOptions_,
      pagination,
      this.search ? this.search.trim() : null,
      pagination.sortBy,
      allDevice,
      axiosSource
    );
  }

  showDeviceProperties(deviceId) {
    this.devicePropertiesId = deviceId;
    this.isShowDeviceProperties = true;
  }

  refreshList() {
    //this.loadingData = true;
    //const pagination = this.transformTablePropsForServerRequest();

    this.configureAxiosCancelToken();
    this.searchTable = this.session_getItem("Devices.search." + this.type);
    /*this.getList(pagination, null, this.axiosSource).then(
      (response) => {
        this.devices = response.data.content;
        this.totalServerItems = response.data.totalElements;

        setTimeout(() => {
          this.loadingData = false;
        }, 500);
      },
      (error) => {
        if (this.isNotAxiosCancellation(error)) {
          this.loadingData = false;

          this.$store.dispatch("errorHandler", {
            error: error,
            genericErrorText: ["device.error.getError"],
          });
        }
      }
    );*/
  }

  refreshDevices() {
    if (!this.viewMap || this.disableFilters) this.refreshList();
  }

  onSearch(newValue) {
    this.session_setItem("Devices.search." + this.type, newValue);
    this.refreshListSearch();
  }

  refreshListSearch() {
    this.tableProps.page = 1;
    this.refreshDevices();
  }

  infoMessage(text) {
    this.$store.dispatch("snackbarInfo", {
      text: text,
    });
  }

  // Refresh only one monitor
  refreshDeviceDetails(selection) {
    this.loadingData = true;
    let stringMonitorsUrl = "";

    selection.forEach((item) => {
      stringMonitorsUrl += "devices=" + item.serialNumber;
    });

    deviceService.refreshDevice(stringMonitorsUrl).then(
      () => {
        this.refreshList();

        const text = this.$t("device.info.refreshDeviceInfo");
        this.infoMessage(text);

        setTimeout(() => {
          this.loadingData = false;
        }, 500);
      },
      (error) => {
        this.loadingData = false;

        this.$store.dispatch("errorHandler", {
          error: error,
          genericErrorText: ["general.error.refreshError"],
        });
      }
    );
  }

  filterClick() {
    if (this.$refs.monitorsMap && !this.isHeatMap)
      this.$refs.monitorsMap.updateMarkers();

    this.saveFilters();
    this.tableProps.page = 1;
    this.toggleView == 1
      ? this.$refs.mapFlet.loadForMarkers()
      : this.refreshList();
  }

  openMarkerMap(monitor) {
    this.selectedMonitor = monitor;
    this.fullScreenMarkerMap = true;
  }

  applySearch(newSearch) {
    this.search = newSearch;
    this.refreshDevices();
  }

  applyFilter(newFilter) {
    this.filterOptions = newFilter;
    this.filterClick();
  }

  disableFilters(disabled) {
    if (disabled) {
      this.expansionPanel = null;
      this.search = null;

      if (this.$refs.deviceFilters) this.$refs.deviceFilters.cleanFilters();
    }

    this.disabledFilters = disabled;
  }

  viewDeviceDetails(serialNumber) {
    return {
      name: "DeviceDetails",
      params: { deviceId: serialNumber },
    };
  }

  getDataAreas() {
    return ApiMonitor.getGeodataAreas(this.getDeviceType());
  }

  getDataZones() {
    return ApiMonitor.getGeodataZones(this.getDeviceType());
  }

  getDataSubzones() {
    return ApiMonitor.getGeodataSubzones(this.getDeviceType());
  }

  getMapData() {
    return new Promise((resolve, reject) => {
      const state = this.newFullDataState();

      this.loadFullData(this.getWithoutPagination, state)
        .then((res) => {
          this.resetFullDataState(state);
          resolve(res);
        })
        .catch((err) => {
          displayErrorMessage("geo.error.getDataError", err);

          this.resetFullDataState(state);
          reject(err);
        });
    });
  }
}
</script>

<style scoped>
.monitors-map-switch {
  pointer-events: auto;
}
.monitors-map-switch.v-input--selection-controls {
  margin-top: 0px;
}
.monitors-map-switch .theme--light.v-messages {
  display: none;
}
</style>

<style>
.device-filters .v-expansion-panel__header {
  padding-top: 0;
  padding-bottom: 0;
}
.monitors-map-switch .v-input__control {
  height: 28px;
}

.monitors-map-switch .v-input__slot {
  margin-bottom: 0px !important;
}
</style>
