import { displayErrorMessage } from "@/api";
import dashboardService from "@/api/dashboard";

export const getTotalDeviceDeployed = ({ commit }, payload) => {
  const kpiName = "TOTAL_DEVICE_DEPLOYED";
  const kpiMutation = "setTotalDeviceDeployed";
  const errorText = "getTotalDeviceDeployed";
  return getKpi({ commit }, payload, kpiName, kpiMutation, errorText);
}

export const getTotalDeviceManufactured = ({ commit }, payload) => {
  const kpiName = "TOTAL_DEVICE_MANUFACTURED";
  const kpiMutation = "setTotalDeviceManufactured";
  const errorText = "getTotalDeviceManufactured";
  return getKpi({ commit }, payload, kpiName, kpiMutation, errorText);
}

export const getTotalDeviceOnLine = ({ commit }, payload) => {
  const kpiName = "TOTAL_DEVICE_ONLINE";
  const kpiMutation = "setTotalDeviceOnLine";
  const errorText = "getTotalDeviceOnLine";
  return getKpi({ commit }, payload, kpiName, kpiMutation, errorText);
}

export const getTotalDevicesUpdated = ({ commit }, payload) => {
  const kpiName = "TOTAL_DEVICES_UPDATED";
  const kpiMutation = "setTotalDevicesUpdated";
  const errorText = "getTotalDevicesUpdated";
  return getKpi({ commit }, payload, kpiName, kpiMutation, errorText);
}

export const getTotalDevices = ({ commit }, payload) => {
  const kpiName = "TOTAL_DEVICES";
  const kpiMutation = "setTotalDevices";
  const errorText = "getTotalDevices";
  return getKpi({ commit }, payload, kpiName, kpiMutation, errorText);
}

export const getTotalPairedUsers = ({ commit }, payload) => {
  const kpiName = "TOTAL_PAIRED_USERS";
  const kpiMutation = "setTotalPairedUsers";
  const errorText = "getTotalPairedUsers";
  return getKpi({ commit }, payload, kpiName, kpiMutation, errorText);
}

export const getTotalOnlineWifiMonitors = ({ commit }, payload) => {
  const kpiName = "TOTAL_CONNECTED_MONITOR_ONLINE";
  const kpiMutation = "setTotalOnlineWifiMonitors";
  const errorText = "getTotalOnlineWifiMonitors";
  return getKpi({ commit }, payload, kpiName, kpiMutation, errorText);
}

export const getTotalOfflineWifiMonitors = ({ commit }, payload) => {
  const kpiName = "TOTAL_CONNECTED_MONITOR_OFFLINE";
  const kpiMutation = "setTotalOfflineWifiMonitors";
  const errorText = "getTotalOfflineWifiMonitors";
  return getKpi({ commit }, payload, kpiName, kpiMutation, errorText);
}

export const getTotalWifiMonitorsDeployed = ({ commit }, payload) => {
  const kpiName = "TOTAL_CONNECTED_MONITOR_DEPLOYED"; //
  const kpiMutation = "setTotalWifiMonitorsDeployed";
  const errorText = "getTotalWifiMonitorsDeployed";
  return getKpi({ commit }, payload, kpiName, kpiMutation, errorText);
}

export const getTotalWifiMonitorsManufactured = ({ commit }, payload) => {
  const kpiName = "TOTAL_CONNECTED_MONITOR_MANUFACTURED"; //
  const kpiMutation = "setTotalWifiMonitorsManufactured";
  const errorText = "getTotalWifiMonitorsManufactured";
  return getKpi({ commit }, payload, kpiName, kpiMutation, errorText);
}

export const getTotalUserNumber = ({ commit }, payload) => {
  const kpiName = "TOTAL_USER_NUMBER";
  const kpiMutation = "setTotalUserNumber";
  const errorText = "getTotalUserNumber";
  return getKpi({ commit }, payload, kpiName, kpiMutation, errorText);
}

export const getTotalLastYearUserNumber = ({ commit }, payload) => {
  const kpiName = "TOTAL_LAST_YEAR_USER_NUMBER";
  const kpiMutation = "setTotalLastYearUserNumber";
  const errorText = "getTotalLastYearUserNumber";
  return getKpi({ commit }, payload, kpiName, kpiMutation, errorText);
}

export const getTotalLastMonthUserNumber = ({ commit }, payload) => {
  const kpiName = "TOTAL_LAST_MONTH_USER_NUMBER";
  const kpiMutation = "setTotalLastMonthUserNumber";
  const errorText = "getTotalLastMonthUserNumber";
  return getKpi({ commit }, payload, kpiName, kpiMutation, errorText);
}

export const getKpi = ({ commit }, payload, kpiName, kpiMutation, errorText) => new Promise((resolve, reject) => {
  dashboardService.getKpi(kpiName).then(response => {
    commit(kpiMutation, response.data);

    resolve(response.data);
  }).catch(error => {
    displayErrorMessage(error, { general: ["dashboard.error." + errorText] });
    reject(error);
  });
});